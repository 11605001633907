import { observer } from "mobx-react";
import Breadcrumb from "../../../../Components/Breadcrumb/Breadcrumb";
import PageTitle from "../../../../Components/PageTitle";
import ParagraphText from "../../../../Components/ParagraphText.js/ParagraphText";
import SubmitButton from "../../../../Components/Buttons/SubmitButton";
import { useState } from "react";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";

const SoftwareManagement = () => {
  const [loading, setLoading] = useState(false);

  const handleGoToSoftwareManagement = () => {
    setLoading(true);
    window.open(
      "https://support.cambiumnetworks.com/login?camefrom=https%3A%2F%2Fsupport.cambiumnetworks.com%2Fcloud%2Flogin%3Fsso%3DeyJub25jZSI6IjM1OGRiNDUwLTkzNTAtMTFlZS1hZjNlLThiMTM5ZmU4ZTYyZSIsInJldHVybnRvIjoiaHR0cHM6Ly9jbG91ZC5jYW1iaXVtbmV0d29ya3MuY29tL2NuLXJ0ci9sb2dpbiJ9%26sig%3D55679c285f688831c82bf544187137542376b579f2f5b672cdfd59cc298c5773",
      "_blank"
    );
    setLoading(false);
  };

  return (
    <div>
      <Breadcrumb
        pathList={["Wifi & Επισκεψιμότητα", "Λογισμικό διαχείρισης"]}
      />
      <div className="mt-8 ml-[35px]">
        <PageTitle title="Λογισμικό διαχείρισης" />
        <ParagraphText className={"my-8"}>
          Επιλέξτε το παρακάτω κουμπί για να συνδεθείτε στο εξειδικευμένο
          λογισμικό διαχείρισης
        </ParagraphText>
        <div className="flex justify-start items-sd mt-8 mr-2">
          <SubmitButton
            loading={loading}
            type="button"
            onClick={() => handleGoToSoftwareManagement()}
            size="large"
            label="Λογισμικό διαχείρισης"
            endIcon={<OpenInNewOutlinedIcon />}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(SoftwareManagement);
