import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text as PdfText,
  View,
} from "@react-pdf/renderer";
import Logo from "../../../../assets/images/Pdf_logo.png";
import OpenSansRegular from "../../../../assets/fonts/OpenSans-Regular.ttf";
import OpenSansBold from "../../../../assets/fonts/OpenSans-Bold.ttf";
import dayjs from "dayjs";

// Register Fonts
Font.register({
  family: "Open Sans Regular",
  src: OpenSansRegular,
});
Font.register({
  family: "Open Sans Bold",
  src: OpenSansBold,
});

// Create styles
const styles = StyleSheet.create({
  text: { fontFamily: "Open Sans Regular", fontSize: "12px" },
  boldText: { fontFamily: "Open Sans Bold", fontSize: "12px" },
  page: {
    backgroundColor: "white",
    flexDirection: "column",
    justifyContent: "space-evenly",
    paddingHorizontal: 20,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headerText: {
    fontSize: "14px",
    textDecoration: "underline",
    marginVertical: 20,
    textAlign: "center",
  },
  description: {
    marginBottom: 20,
    marginHorizontal: 20,
  },
  logoSection: {
    flexDirection: "column",
    padding: 20,
    alignItems: "center",
  },
  dateSection: {
    paddingHorizontal: 20,
    textAlign: "right",
  },
  logoImage: {
    height: "auto",
    width: "80px",
  },
  pageContent: {
    flexDirection: "column",
    justifyContent: "flex-start",
    flexGrow: 1,
  },
});

const Text = ({ children, bold = false, style }) => {
  const defaultStyle = { ...styles.text, ...style };
  const allStyles = bold
    ? { ...defaultStyle, ...styles.boldText }
    : defaultStyle;
  return <PdfText style={allStyles}>{children}</PdfText>;
};

const Section = ({ children, padded = true, style, title }) => {
  const defaultContainerStyle = {
    paddingVertical: 15,
    paddingHorizontal: padded ? 20 : 5,
    marginVertical: 15,
    marginHorizontal: 10,
    border: "1px solid #666666",
    borderRadius: "10px",
    position: "relative",
  };
  const allStyles = { ...defaultContainerStyle, ...styles.text, ...style };
  const titleStyle = {
    position: "absolute",
    backgroundColor: "white",
    top: -10,
    left: 30,
    padding: "0 10px",
    color: "#666666",
  };
  return (
    <View style={allStyles}>
      <Text bold style={titleStyle}>
        {title}
      </Text>
      {children}
    </View>
  );
};

const PdfTemplate = ({ image, inputs }) => {
  const filters = [
    { label: "Ημερομηνία/ες:", value: inputs?.dates },
    { label: "Περιοχή/ες:", value: inputs?.locations },
  ];

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.pageContent}>
          <View style={styles.header}>
            <View style={styles.logoSection}>
              <Image style={styles.logoImage} src={Logo} />
              <Text bold>ΕΛΛΗΝΙΚΗ ΔΗΜΟΚΡΑΤΙΑ</Text>
              <Text bold>ΠΕΡΙΦΕΡΕΙΑ</Text>
              <Text bold>ΑΤΤΙΚΗΣ</Text>
              <Text bold>ΔΗΜΟΣ ΓΛΥΦΑΔΑΣ</Text>
              <Text>ΑΛΣΟΥΣ 15,</Text>
              <Text>ΓΛΥΦΑΔΑ, ΤΚ 16675</Text>
            </View>
          </View>
          <View>
            <Text style={styles.dateSection}>
              {`Ημερομηνία: ${dayjs().format("DD/MM/YYYY")}`}
            </Text>
          </View>
          <Text bold style={styles.headerText}>
            ΑΝΑΦΟΡΑ
          </Text>
          <Text style={styles.description}>
            Παρουσίαση συλλεγόμενων δεδομένων από εγκατεστημένα συστήματα
            έξυπνης πόλης στο Δήμο Γλυφάδας.
          </Text>
          {/* <Section title={"Τίτλος αναφοράς"}>
          <Text>{"#1"}</Text>
        </Section> */}
          <Section title={"Κατηγορία αναφοράς"}>
            <Text>{inputs?.category}</Text>
          </Section>
          {inputs?.type && (
            <Section title={"Τύπος αναφοράς"}>
              <Text>{inputs?.type}</Text>
            </Section>
          )}
          <Section title={"Δεδομένα αναφοράς"}>
            <View
              style={{
                flexDirection: "row",
                gap: 50,
                justifyContent: "space-between",
              }}
            >
              {filters.map((filter) => (
                <View
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                    maxWidth: "300px",
                  }}
                >
                  <Text>{filter?.label}</Text>
                  <Text>{filter?.value}</Text>
                </View>
              ))}
            </View>
          </Section>

          <View wrap={false}>
            <Section padded={false} title={"Διάγραμμα"}>
              <Image style={{ width: "100%", height: "auto" }} src={image} />
            </Section>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PdfTemplate;
