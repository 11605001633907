import React, { useEffect, useState } from "react";
import DataService from "../../Services/DataService";
import DashboardSection from "../../../../Components/DashboardSection/DashboardSection";
import ActivityKpiDataRefresher from "../../Components/ActivityKpi/ActivityKpiDataRefresher";
import HighestVisitorCountKpi from "../../Components/HighestVisitorCountKpi/HighestVisitorCountKpi";
import HistoryActivityKpiDataRefresher from "../../Components/HistoryActivityKpi/HistoryActivityKpiDataRefresher";
import HighestUniqueVisitorCountKpi from "../../Components/HighestUniqueVisitorCountKpi/HighestUniqueVisitorCountKpi";
import VisitingRateKpi from "../../Components/VisitingRateKpi/VisitingRateKpi";
import DurationOfStayKpi from "../../Components/DurationOfStayKpi/DurationOfStayKpi";
import ConnectedDeviceKpi from "../../Components/ConnectedDevice/ConnectedDeviceKpi";
import WifiMap from "../../Components/Map/WifiMap";
import Breadcrumb from "../../../../Components/Breadcrumb/Breadcrumb";
import PageTitle from "../../../../Components/PageTitle";
import { CircularProgress } from "@mui/material";
import ActiveAccessPoints from "../../Components/ActiveAccessPoints/ActiveAccessPoints";

function App() {
  const [dashboardData, setDashboardData] = useState({ activeVisitors: [] });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      let data = await DataService.get_dashboard();
      setDashboardData(data.result);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <>
      {!isLoading && dashboardData ? (
        <>
          <section className="grid grid-cols-12 mb-10">
            <div className="col-span-7 mr-5">
              <Breadcrumb pathList={["Wifi & Επισκεψιμότητα", "Ανάλυση"]} />
              <PageTitle
                className="pt-2 pb-10"
                title="Ανάλυση τρέχουσας κατάστασης"
              />
              <DashboardSection
                title={"ΠΛΗΘΥΣΜΙΑΚΗ ΣΥΓΚΕΝΤΡΩΣΗ"}
                tooltipText="Απεικονίζει τον αριθμό των μοναδικών MAC από όλα τα AP μιας περιοχής τα τελευταία 5 λεπτά"
                className="flex overflow-x-auto gap-4"
              >
                <ActivityKpiDataRefresher
                  data={dashboardData?.activeVisitors}
                  interval={120}
                />
              </DashboardSection>
              <DashboardSection
                title={"ΑΘΡΟΙΣΤΙΚΗ ΕΠΙΣΚΕΨΙΜΟΤΗΤΑ"}
                tooltipText="Παρουσιάζει την επισκεψιμότητα (μοναδικές mac) που έχουν παρουσιαστεί στις περιοχές τα τελευταία 30min"
                className="flex overflow-x-auto gap-4"
                sectionClassName="mb-0"
              >
                <HighestVisitorCountKpi
                  data={dashboardData?.highestVisitorCount}
                  tooltip="Παρουσιάζει την περιοχή και την ώρα που παρατηρήθηκε η μέγιστη πληθυσμιακη συγκέντρωση"
                />
                <HistoryActivityKpiDataRefresher
                  data={dashboardData?.activeVisitorsHistory}
                  interval={60 * 30}
                />
              </DashboardSection>
            </div>

            <WifiMap data={dashboardData} interval={60 * 30} />
          </section>
          <div className="grid grid-cols-12 gap-x-6">
            <DashboardSection
              title={"ΜΕΓΙΣΤΗ ΕΠΙΣΚΕΨΙΜΟΤΗΤΑ ΜΟΝΑΔΙΚΩΝ ΕΠΙΣΚΕΠΤΩΝ"}
              tooltipText="Απεικονίζει το μέγιστο αριθμό των μοναδικών επισκεπτών (μοναδικών MAC) κάθε περιοχής"
              sectionClassName="col-span-4"
            >
              <HighestUniqueVisitorCountKpi
                data={dashboardData?.highestUniqueVisitorCount}
              />
            </DashboardSection>
            <DashboardSection
              title={"ΕΠΑΝΑΕΠΙΣΚΕΨΙΜΟΤΗΤΑ"}
              tooltipText="Παρουσιάζει την επαναληψιμότητα επίσκεψης επισκεπτών (μοναδικά mac) σε κάθε περιοχή"
              sectionClassName="col-span-8"
            >
              <VisitingRateKpi data={dashboardData?.visitingRate} />
            </DashboardSection>
          </div>

          <div className="grid grid-cols-12 gap-x-6 h-[340px] justify-between">
            <DashboardSection
              title={"ΧΡΟΝΟΣ ΠΑΡΑΜΟΝΗΣ"}
              tooltipText="Παρουσιάζει το μέσο χρόνο παραμονής των επισκεπτών στις περιοχές συνολικά, την περιοχή με το μέγιστο χρόνο παραμονής και το χρόνο παραμονής των επισκεπτών το τελευταίο 24ωρο"
              sectionClassName="col-span-6 auto-rows-auto"
            >
              <DurationOfStayKpi data={dashboardData?.visitingDuration} />
            </DashboardSection>
            <DashboardSection
              title={"ΔΙΚΤΥΟ"}
              tooltipText="Παρουσιάζει το ποσοστό των ενεργών ΑΡ και τον αριθμό των συνδεδεμένων χρηστών σε αυτά"
              sectionClassName="col-span-6 auto-rows-auto"
            >
              <div className="flex w-full flex-col xl:flex-row gap-x-3 gap-y-2 justify-between">
                <ActiveAccessPoints data={dashboardData.accessPointActivity} />
                <ConnectedDeviceKpi data={dashboardData.connectedDevices} />
              </div>
            </DashboardSection>
          </div>
        </>
      ) : (
        <div className="flex justify-center mt-16">
          <CircularProgress />
        </div>
      )}
    </>
  );
}

export default App;
