import * as React from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { Doughnut } from "react-chartjs-2";
import { Typography, useTheme } from "@mui/material";
import clsx from "clsx";
import {
  DataDoughnut,
  DataHalfDoughnut,
  OptionsDoughnut,
  OptionsHalfDoughnut,
  centerImageHalfDoughnut,
  centerImageDoughnut,
} from "../../../../Saas/Wifi/Components/VisitingRateKpi/GraphOptions";
import { colors, colorsSecondary } from "../../../../Saas/Wifi/Defaults/Global";

ChartJS.register(ArcElement, Tooltip, Legend);

const VisitingRateCard = (props) => {
  const theme = useTheme();
  const classes = clsx([
    "relative py-4 rounded-md bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]",
    props?.className,
  ]);

  return (
    <div className={classes}>
      <div className="flex gap-x-3 items-center px-[15px]">
        <span
          className="text-[13px] font-semibold uppercase min-h-[30px]"
          style={{ color: theme.palette.neutral.dark4, lineHeight: "16px" }}
        >
          {props.title}
        </span>
      </div>
      {props.children}
    </div>
  );
};

const LegendItem = ({ item }) => {
  return (
    <div className="grid grid-cols-12 ml-2 mb-1 lg:mb-2 items-center">
      <div
        className="col-span-3 h-[18px] rounded-xl mb-1 xl:mb-0 mr-2"
        style={{ backgroundColor: item?.color }}
      />
      <div className="col-span-12 xl:col-span-8 break-words">
        <Typography fontSize={11} lineHeight={"12px"}>
          {item?.label}
        </Typography>
      </div>
    </div>
  );
};

const VisitingRateSection = (props) => {
  const options = OptionsHalfDoughnut();
  const options2 = OptionsDoughnut();

  const data1 = DataHalfDoughnut(props.data, colors);
  const data2 = DataDoughnut(props.data, colorsSecondary);

  const legendItems1 = data1?.labels?.map((item, index) => ({
    label: item,
    color: data1?.datasets[0]?.backgroundColor[index],
  }));

  const legendItems2 = data2?.labels?.map((item, index) => ({
    label: item,
    color: data2?.datasets[0]?.backgroundColor[index],
  }));

  return (
    <div className="flex xl:flex-col h-full justify-between gap-5 w-full min-w-[430px]">
      <VisitingRateCard
        title={"ΠΕΡΙΟΧΗ ΜΕ ΤΗ ΜΕΓΑΛΥΤΕΡΗ ΕΠΑΝΑΕΠΙΣΚΕΨΙΜΟΤΗΤΑ"}
        className="flex-grow h-full w-full min-h-[250px]"
      >
        <div className="grid grid-cols-12 h-full ">
          <div className="col-span-6 h-[180px]">
            <Doughnut
              data={data1}
              options={{ ...options, rotation: 180, circumference: 180 }}
              plugins={[centerImageHalfDoughnut(props.data?.locationName)]}
            />
          </div>
          <div className="col-span-6 flex flex-col justify-center">
            {legendItems1?.map((item) => (
              <LegendItem key={item?.label} item={item} />
            ))}
          </div>
        </div>
      </VisitingRateCard>

      <VisitingRateCard
        title={"ΕΠΙΣΚΕΠΤΕΣ"}
        className="flex-grow h-full w-full min-h-[250px]"
      >
        <div className="grid grid-cols-12 h-full">
          <div className="col-span-6 h-[180px]">
            <Doughnut
              data={data2}
              options={options2}
              plugins={[centerImageDoughnut]}
            />
          </div>
          <div className="col-span-6 flex flex-col justify-center">
            {legendItems2?.map((item) => (
              <LegendItem key={item?.label} item={item} />
            ))}
          </div>
        </div>
      </VisitingRateCard>
    </div>
  );
};

export default VisitingRateSection;
