import axios from "axios";
import { baseUrl } from "../../../Config/constant";

export async function get_dashboard() {
  var dashboardResponse = await axios.get(`${baseUrl}/Wifi/dashboard`);

  if (dashboardResponse?.status === 200) {
    return dashboardResponse.data;
  } else {
    console.log(dashboardResponse);
  }

  return {};
}

/*
    return [{locationName:string, activeVisitors:number,changePercentage:number, increased:bool}]
*/
export async function get_active_users() {
  var activeUsersResponse = await axios.get(`${baseUrl}/Wifi/Active-Visitors`);

  if (activeUsersResponse.status === 200) {
    return activeUsersResponse.data;
  }

  return [];
}

/*
    return [{locationName:string, activeVisitors:number,averageActiveVisitors:number, activeVisitorHistory:[]:number}]
*/
export async function get_active_users_history() {
  var activeUsersResponse = await axios.get(
    `${baseUrl}/Wifi/Active-Visitors-History`
  );

  if (activeUsersResponse.status === 200) {
    return activeUsersResponse.data;
  }

  return [];
}

/*
    return {locationName:string, highestVisitors:number, date:string, time:string}
*/
export async function get_highest_visitors() {
  var highestVisitorCountResponse = await axios.get(
    `${baseUrl}/Wifi/Highest-Visitor`
  );

  if (highestVisitorCountResponse.status === 200) {
    return highestVisitorCountResponse.data;
  }

  return [];
}

/*
    return {time:string, visitors:[{locationName:string, highestVisitors:number}]}
*/
export async function get_highest_unique_visitors() {
  var highestVisitorCountResponse = await axios.get(
    `${baseUrl}/Wifi/Highest-Unique-Visitor`
  );

  if (highestVisitorCountResponse.status === 200) {
    return highestVisitorCountResponse.data;
  }

  return [];
}

/*
    return [{locationName:string, newVisitsPercentage:number,revisitsPercentage:number, lessThan2VisitsPercentage:number, between3And5VisitsPercentage:number, moreThan5VisitsPercentage:number}]
*/
export async function get_visiting_rage() {
  var visitingRateResponse = await axios.get(`${baseUrl}/Wifi/Visiting-Rate`);

  if (visitingRateResponse.status === 200) {
    return visitingRateResponse.data;
  }

  return [];
}

/*
    return {locationName:string,lessThan5Time:string, between5And10Time:string,between10And20Time:string,moreThan20Time:string, averageDurationTime:string, averageDurationHighestTime:string}
*/
export async function get_visiting_duration() {
  var durationOfStayResponse = await axios.get(
    `${baseUrl}/Wifi/Duration-Of-Stay`
  );

  if (durationOfStayResponse.status === 200) {
    return durationOfStayResponse.data;
  }

  return [];
}

/*
    return {connectedDevices:number}
*/
export async function get_connected_devices() {
  var connectedDevices = await axios.get(`${baseUrl}/Wifi/connected-devices`);

  if (connectedDevices.status === 200) {
    return connectedDevices.data;
  }

  return [];
}

/*
    return [{locationName:string, locationAddress:string, logitude:decimal, latitude:decimal, visitors:int, visitRate:int, visitDuration:int}]
*/
export async function get_map_data() {
  var connectedDevices = await axios.get(`${baseUrl}/map`);

  if (connectedDevices.status === 200) {
    return connectedDevices.data;
  }

  return [];
}

export async function get_report_locations() {
  var locations = await axios.get(`${baseUrl}/WifiReport/locations`);

  if (locations?.status === 200) {
    return locations?.data;
  }

  return [];
}

export async function get_report_data(inputs) {
  let qs = require("qs");
  var data = await axios.get(`${baseUrl}/WifiReport/${inputs.category}`, {
    params: {
      locationIds: inputs.locations,
      dates: inputs.dates,
      chartType: inputs.chartType,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  if (data.status === 200) {
    return data.data;
  }

  return [];
}

export async function get_notifications() {
  var notifications = await axios.get(`${baseUrl}/Wifi/notifications`);

  if (notifications?.status === 200) {
    return notifications.data;
  }

  return [];
}

export async function exportExcel({
  reportCategory,
  dates = [],
  locationIds = [],
  fileName,
}) {
  let url = `${baseUrl}/WifiReport/ExcelReport?reportCategory=${reportCategory}`;
  if (locationIds.length > 0) {
    locationIds.forEach((id) => {
      url += `&locationIds=${id}`;
    });
  }
  if (dates.length > 0) {
    dates.forEach((date) => {
      url += `&dates=${date}`;
    });
  }
  await axios
    .get(url, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}.xlsx`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.error(error);
    });
}

const DataService = {
  get_dashboard,
  get_active_users,
  get_active_users_history,
  get_highest_visitors,
  get_highest_unique_visitors,
  get_visiting_rage,
  get_visiting_duration,
  get_connected_devices,
  get_map_data,
  get_report_locations,
  get_report_data,
  get_notifications,
  exportExcel,
};

export default DataService;
