import MainContent from "../../../../MainAppComponents/MainContent";
import Topbar from "../../../../Components/Topbar/Topbar";
import LightsTabMenu from "../LightsTabMenu/LightsTabMenu";

const LightsLayout = ({ children, showTabs = true, ...props }) => (
  <>
    <Topbar icon="lights" title="Οδοφωτισμός" />
    {showTabs && <LightsTabMenu />}
    <MainContent>{children}</MainContent>
  </>
);

export default LightsLayout;
