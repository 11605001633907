import { observer } from "mobx-react";
import NotificationList from "./NotificationList";
import { useTheme } from "@mui/material/styles";
import { themeColors } from "../../../../Config/Themes/theme";

const Notifications = () => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);

  return (
    <div
      sx={{
        backgroundColor: colors.backgroundPrimary.main,
      }}
    >
      <NotificationList />
    </div>
  );
};

export default observer(Notifications);
