import LocationSvg from "../../Icons/LocationSvg";
import SectionAreaTitleSvg from "../../Icons/SectionAreaTitleSvg";

const MapCard = (props) => {
  return (
    <div className="w-fit h-fit rounded-md bg-white shadow-sm pb-[10px] pt-[3px]">
      <div className="flex flex-col gap-y-1">
        <div className="flex justify-between gap-x-2 pr-1 pb-1">
          <div className="flex items-center gap-x-2">
            <LocationSvg size={"22"} />
            <span lang="el" className="text-[14px] font-semibold uppercase">
              {props.data.address}
            </span>
          </div>
          <div className="flex items-center gap-x-1">
            <span className="text-[14px] font-semibold uppercase">ID:</span>
            <span className="text-[14px] uppercase">{props.data.id}</span>
          </div>
        </div>
        <div className="flex items-center gap-x-2">
          <SectionAreaTitleSvg size={"22"} color={"#505381"} />
          <div className="flex flex-col gap-y-1">
            <div className="flex items-center gap-x-1">
              <span lang="el" className="text-[14px] font-semibold uppercase">
                Γεω. Πλάτος:
              </span>
              <span lang="el" className="text-[14px] uppercase">
                {parseFloat(props.data.lat).toFixed(6)}
              </span>
            </div>
            <div className="flex items-center gap-x-1">
              <span lang="el" className="text-[14px] font-semibold uppercase">
                Γεω. Μήκος:
              </span>
              <span lang="el" className="text-[14px] uppercase">
                {parseFloat(props.data.lng).toFixed(6)}
              </span>
            </div>
          </div>
        </div>
      </div>
      <hr className="h-px my-[6px] bg-[#d4d4d4] border-0"></hr>
      <div className="flex flex-col gap-y-[6px] pl-[6px] mt-[12px] w-fit">
        <div className="flex items-center justify-between gap-x-2 w-fit">
          <div className="w-[10px] h-[10px] rounded-[50%] bg-[#3fb14d]" />
          <span className="w-fit text-[12px] font-bold uppercase">
            ΟΝΟΜΑ ΣΥΣΚΕΥΗΣ:
          </span>
          <span className="text-[12px] font-normal">
            {props.data.deviceName}
          </span>
        </div>
        <div className="flex items-center justify-between gap-x-2 w-fit">
          <div className="w-[10px] h-[10px] rounded-[50%] bg-[#f44336]" />
          <span className="w-fit text-[12px] font-bold uppercase">
            ΚΑΤΑΣΤΑΣΗ ΣΥΣΚΕΥΗΣ:
          </span>
          <span className="text-[12px] font-normal">
            {props.data.statusDescription}
          </span>
        </div>
      </div>
    </div>
  );
};

export default MapCard;
