import * as React from "react";

const ActivityCircle = (props) => {
  let circles = [];
  for (let i = 0; i < 16; i++) {
    let opacity = "opacity-20";

    let getOpacity = (start, end) => {
      let op = opacity;
      if (props.activeVisitors > start) op = "opacity-50";
      if (props.activeVisitors >= end) op = "opacity-100";

      return op;
    };
    if (i >= 12) {
      opacity = getOpacity(0, 25);
    } else if (i >= 8) {
      opacity = getOpacity(25, 50);
    } else if (i >= 4) {
      opacity = getOpacity(50, 150);
    } else if (i >= 0) {
      opacity = getOpacity(150, 300);
    }

    circles.push(
      <div
        className={`w-[10px] h-[10px] rounded-[50%] blur-[1px] ${opacity}`}
        style={{ backgroundColor: props.primaryColor }}
        key={i}
      ></div>
    );
  }

  return (
    <div className="flex flex-wrap w-[64px] h-auto justify-between gap-x-[5px]">
      {circles.map((c) => c)}
    </div>
  );
};

export default ActivityCircle;
