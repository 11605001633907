import { observer } from "mobx-react";
import { Box, CircularProgress, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import MainCard from "../../../Components/Theme/Cards/MainCard";
import CancelButton from "../../../Components/Buttons/CancelButton";
import { useAnnouncementsStore } from "../../../Stores/AnnouncementsStore";
import { dateInDateMonthYear } from "../../../Utils/formatDate/formatDate";

const AnnouncementsViewModal = ({ open, handleClose, announcementId }) => {
  const announcementsStore = useAnnouncementsStore();
  const [announcementData, setAnnouncementData] = useState(null);
  const [loading, setLoading] = useState(false);

  const loader = (
    <Box className="flex justify-center items-center p-20">
      <CircularProgress size={30} />
    </Box>
  );

  useEffect(() => {
    if (announcementId) {
      setLoading(true);
      announcementsStore
        .getAnnouncementById(announcementId)
        .then((response) => {
          setAnnouncementData(response?.result);
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [announcementId, setAnnouncementData, announcementData?.length]);

  return (
    <Modal
      className="flex items-center justify-center"
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="announcement-modal"
    >
      {loading ? (
        loader
      ) : (
        <MainCard className=" p-4 max-w-[50vw] min-w-[50vw] min-h-[20vw]">
          <div className="modal-paper">
            <Typography className="mt-4 text-[#565A87]" variant="h3">
              Προβολή ανακοίνωσης
            </Typography>
            <Box className="w-full mt-6 border-b-2 border-[#B9B9B9]" />
            <div>
              <Typography className="mt-8" variant="h4">
                <b> Κωδικός : </b> {announcementData?.id}
              </Typography>
              <Typography className="mt-8" variant="h4">
                <b> Ημερομηνία : </b>{" "}
                {dateInDateMonthYear(announcementData?.createdOn)}
              </Typography>
              <Typography
                style={{
                  maxWidth: "100%",
                  wordWrap: "break-word",
                  whiteSpace: "pre-line",
                }}
                className="mt-8"
                variant="h4"
              >
                <b> Μήνυμα : </b> {announcementData?.text}
              </Typography>
              <Typography className="mt-8" variant="h4">
                <b> Κατάσταση : </b>{" "}
                {
                  announcementsStore.announcementsStatuses?.find(
                    (status) => status.key === announcementData?.status
                  )?.name
                }
              </Typography>
            </div>
            <div className="flex justify-end items-end mt-8 mr-2">
              <CancelButton
                className="mr-6"
                type="button"
                onClick={() => handleClose(false)}
                size="large"
                label="Ακύρωση"
              />
            </div>
          </div>
        </MainCard>
      )}
    </Modal>
  );
};

export default observer(AnnouncementsViewModal);
