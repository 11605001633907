import * as React from "react";

const ConcentrationSvg = (props) => {
  return (
    <svg
      width={props.size ?? "17.474"}
      height={props.size ??"14.973" }
      viewBox="0 0 17.474 14.973"
    >
      <path
        id="Shape_882"
        data-name="Shape 882"
        d="M431.016,91.4l-4.36-7.487,4.36-7.486h8.754l4.361,7.486L439.771,91.4Zm1.2-1.042h6.352l-1.867-3.2h-2.62Zm-.906-.59,1.848-3.172L431.9,84.43h-3.7Zm6.313-3.178,1.848,3.173,3.1-5.331h-3.7ZM437.6,81.2l1.277,2.192h3.7l-3.124-5.366Zm-9.392,2.192h3.7l1.279-2.2-1.848-3.173Zm5.92-2.718h2.53l1.865-3.2h-6.26Z"
        transform="translate(-426.657 -76.424)"
        fill={props.color ?? "#545a86" }
      />
    </svg>
  );
};

export default ConcentrationSvg;
