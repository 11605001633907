import { useEffect, useMemo, useRef, useState } from "react";
import ProgressBar from "./ProgressBar";
import dayjs from "dayjs";
import CityMap from "../Map/CityMap";
import CircularPulse from "../CircularPulse/CircularPulse";
import ActionButton from "../Buttons/ActionButton";
import NotInterestedOutlinedIcon from "@mui/icons-material/NotInterestedOutlined";
import {
  MEASUREMENTS_GAS,
  MEASUREMENTS_MAIN,
} from "../../Saas/Environment/Config/Constants/measurements";
import { useCallback } from "react";

const TOO_MANY = 20;
const PLAY_INTERVAL = 1500;

const filters = {
  average: "Μέση τιμή",
  max: "Μέγιστη τιμή",
  min: "Ελάχιστη τιμή",
};

const allMeasurements = [
  ...Object.values(MEASUREMENTS_GAS),
  ...Object.values(MEASUREMENTS_MAIN),
];

const HeatMapPlayer = ({ data, devices, frequency, measurement }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("average");
  const intervalRef = useRef(null);

  const labels = data?.labels;

  const formatDate = useCallback(
    (date) => {
      switch (frequency) {
        case "hour":
          return dayjs(date).format("DD/MM HH:mm");
        case "day":
          return dayjs(date).format("DD/MM");
        case "week":
          return dayjs(date).format("DD/MM HH:mm");
        case "month":
          return dayjs(date).format("MM/YYYY");
        case "year":
          return dayjs(date).format("YYYY");
        default:
          return dayjs(date).format("DD/MM/YYYY");
      }
    },
    [frequency]
  );

  const marks = useMemo(() => {
    const step = 100 / (labels?.length - 1);
    const tooMany = labels?.length > TOO_MANY;
    const tickStep = Math.ceil(labels?.length / TOO_MANY + 1);

    if (!tooMany) {
      return labels?.map((item, index) => ({
        date: item,
        label: formatDate(item),
        popupText: formatDate(item),
        value: index * step,
        index,
      }));
    }

    return labels?.map((item, index) => {
      if (index % tickStep === 0 || index === 0) {
        return {
          date: item,
          label: formatDate(item),
          popupText: formatDate(item),
          value: index * step,
          index,
        };
      }

      return {
        date: item,
        popupText: formatDate(item),
        value: index * step,
        index,
      };
    });
  }, [formatDate, labels]);
  const selectedMark = marks[selectedIndex];

  const play = () => {
    setSelectedIndex((prev) => {
      return prev === marks?.length - 1 ? 0 : prev + 1;
    });

    intervalRef.current = setInterval(() => {
      setSelectedIndex((prev) => {
        if (prev === marks?.length - 1) {
          clearInterval(intervalRef.current);
          return prev;
        }

        return prev + 1;
      });
    }, PLAY_INTERVAL);

    setPlaying(true);
  };

  const pause = () => {
    setPlaying(false);
    clearInterval(intervalRef.current);
  };

  const onChange = (index) => {
    if (index > marks?.length - 1 || index < 0) {
      return;
    }

    if (index < 0) {
      return;
    }

    setSelectedIndex(index);
  };

  const getMarkerByValue = (value) => {
    if (!value) {
      return <></>;
    }

    const measurementDetails = allMeasurements.find(
      (item) => item.key === measurement.key
    );

    const status = measurementDetails?.getStatus
      ? measurementDetails.getStatus(value)
      : null;

    const valueWithUnit =
      typeof measurementDetails?.unit !== "string" ? (
        <div className="flex">
          <span>{value}</span>
          {measurementDetails?.unit}
        </div>
      ) : (
        <span>{`${value} ${measurementDetails?.unit}`}</span>
      );

    return (
      <div className="relative">
        <CircularPulse color={status?.color} size={status?.size || 80} />
        <div
          className="absolute -top-[15px] flex justify-center items-center"
          style={{
            borderRadius: 5,
            border: `3px solid ${status?.color || "gray"}`,
            backgroundColor: "white",
            width: status?.size || 80,
            height: 30,
          }}
        >
          {valueWithUnit}
        </div>
      </div>
    );
  };

  const getDisplayedMarkers = () => {
    return Object.values(devices)
      .map((item) => {
        const geoItem = {
          ...item,
          lng: item?.lon,
        };
        const value = data?.data?.[item.id]
          ? data.data[item.id][selectedFilter][selectedIndex]?.value
          : null;

        if (!value) {
          return null;
        }

        return {
          ...geoItem,
          markerRenderer: getMarkerByValue(value),
          onMarkerClick: () => setSelectedMarker(geoItem),
        };
      })
      .filter((item) => item);
  };

  useEffect(() => {
    if (selectedIndex === marks?.length - 1) {
      setPlaying((prev) => {
        if (prev) {
          return false;
        }
      });
    }
  }, [selectedIndex, marks]);

  console.log("marks", marks);

  return (
    <section>
      <div className="flex gap-3 pb-5">
        {Object.entries(filters)?.map(([key, value]) => (
          <ActionButton
            key={key}
            onClick={() => setSelectedFilter(key)}
            selected={selectedFilter === key}
          >
            {value}
          </ActionButton>
        ))}
      </div>
      <CityMap
        markers={getDisplayedMarkers()}
        infoWindowRenderer={(item) => <div>{item.id}</div>}
        initialZoom={15}
        className="w-full h-[400px]"
        selectedMarker={selectedMarker}
        setSelectedMarker={setSelectedMarker}
      />
      <ProgressBar
        data={data?.labels}
        marks={marks}
        onChange={onChange}
        pause={pause}
        play={play}
        playing={playing}
        selectedMark={selectedMark}
        selectedIndex={selectedIndex}
      />
    </section>
  );
};

export default HeatMapPlayer;
