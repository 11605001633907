import * as React from "react";

import ClockSvg from "../Icons/ClockSvg";
import AverageSvg from "../Icons/AverageSvg";

import { useTheme } from "@mui/material";
import { removeDiacritics } from "../../../../Utils/Text/Text";

const DurationCard = (props) => {
  const theme = useTheme();

  return (
    <div
      style={{ width: props?.compact ? 250 : "100%" }}
      className="col-span-6 relative px-[15px] pb-[10px] py-4 rounded-md bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] leading-[1.0]"
    >
      <div className="flex gap-x-3 items-center">
        <span
          className="text-[13px] font-semibold uppercase min-h-[25px]"
          style={{ color: theme.palette.neutral.dark4 }}
        >
          {props.title}
        </span>
      </div>

      <div
        className="flex items-center gap-x-5 mt-[10px]"
        style={{ color: theme.palette.background.cardTitleIcon }}
      >
        <ClockSvg color={theme.palette.background.cardTitleIcon} size={"25"} />
        <span className="text-[13px] font-semibold uppercase">
          {removeDiacritics(props.location)}
        </span>
      </div>
      <div
        className="flex items-center gap-x-5 mt-[5px]"
        style={{ color: theme.palette.background.cardTitleIcon }}
      >
        <AverageSvg color={theme.palette.background.cardTitleIcon} />
        <span className="text-[20px] xl:text-[36px] font-semibold uppercase">
          {props.duration}
        </span>
      </div>
    </div>
  );
};

export default DurationCard;
