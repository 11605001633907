import { CircularProgress, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { themeColors } from "../../../../Config/Themes/theme";
import DataService, {
  get_active_users,
} from "../../../../Saas/Wifi/Services/DataService";
import { useEffect, useState } from "react";
import useApiIntervalCall from "../../../../Saas/Wifi/Hooks/useApiIntervalCall";
import HighestVisitorCountKpi from "../../../../Saas/Wifi/Components/HighestVisitorCountKpi/HighestVisitorCountKpi";
import DurationCard from "../../../../Saas/Wifi/Components/DurationOfStayKpi/DurationCard";
import VisitingRateSection from "./VisitingRateSection";
import ActivityKpiDataRefresher from "../../../../Saas/Wifi/Components/ActivityKpi/ActivityKpiDataRefresher";
import HistoryActivityKpiDataRefresher from "../../../../Saas/Wifi/Components/HistoryActivityKpi/HistoryActivityKpiDataRefresher";
import InfoSvg from "../../../../Saas/Wifi/Components/Icons/InfoSvg";

const WifiBlock = () => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);

  const [dashboardData, setDashboardData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const activityKpiData = useApiIntervalCall(
    get_active_users,
    120,
    dashboardData?.activeVisitors
  );

  useEffect(() => {
    const fetchData = async () => {
      let data = await DataService.get_dashboard();
      setDashboardData(data.result);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="flex justify-between flex-col xl:flex-row xl:w-full gap-5 items-center xl:h-[550px] h-full">
      <div className="flex h-full py-4 px-4 xl:flex-col 3xl:flex-row items-center justify-between w-full xl:w-fit 3xl:w-full bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-md">
        <div className="lg:h-1/3 h-full min-w-[250px] w-[250px] flex flex-col gap-4 py-4 justify-between">
          <div className="flex justify-between gap-2 w-full">
            <Typography
              variant="h5"
              sx={{
                color: colors.primary.main,
              }}
            >
              Wifi hotspots
            </Typography>
            <Typography
              variant="h5"
              sx={{
                color: colors.primary.main,
              }}
            >
              {dashboardData?.connectedDevices?.connectedDevices}
            </Typography>
          </div>
          <div className="flex justify-between gap-2 w-full">
            <Typography
              variant="h5"
              sx={{
                color: colors.primary.main,
              }}
            >
              Ενεργά Wifi hotspots
            </Typography>
            <Typography
              variant="h5"
              sx={{
                color: colors.primary.main,
              }}
            >
              {dashboardData?.mapData?.length}
            </Typography>
          </div>
          <div className="flex justify-between gap-2 w-full">
            <Typography
              variant="h5"
              sx={{
                color: colors.primary.main,
              }}
            >
              Συνδεδεμένοι χρήστες
            </Typography>
            <Typography
              variant="h5"
              sx={{
                color: colors.primary.main,
              }}
            >
              {activityKpiData?.reduce(
                (acc, curr) => acc + curr?.activeVisitors,
                0
              ) ||
                dashboardData?.activeVisitors?.reduce(
                  (acc, curr) => acc + curr?.activeVisitors,
                  0
                )}
            </Typography>
          </div>
        </div>
        <div className="min-w-[250px] w-[250px] flex flex-col items-center">
          <HighestVisitorCountKpi
            data={dashboardData?.highestVisitorCount}
            tooltip="Παρουσιάζει την περιοχή και την ώρα που παρατηρήθηκε η μέγιστη πληθυσμιακη συγκέντρωση"
          />
        </div>
        <div className="min-w-[250px] w-[250px] flex flex-col items-center">
          <DurationCard
            compact
            title={"ΠΕΡΙΟΧΗ ΜΕ ΤΟ ΜΕΓΑΛΥΤΕΡΟ ΜΕΣΟ ΧΡΟΝΟ ΠΑΡΑΜΟΝΗΣ"}
            location={dashboardData?.visitingDuration?.locationName}
            duration={
              dashboardData?.visitingDuration?.averageDurationHighestTime
            }
          />
        </div>
      </div>

      <div className="w-full h-full py-4 px-4 lg:w-fit xl:max-w-[40%] 2xl:max-w-[700px] flex grow flex-col justify-between gap-4 bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-md">
        <div className="w-full">
          <div className="flex items-center">
            <div className="flex gap-x-3 items-center px-[15px]">
              <span
                lang="el"
                className="text-[13px] font-semibold uppercase"
                style={{
                  color: theme.palette.neutral.dark4,
                }}
              >
                Πληθυσμιακή συγκέντρωση
              </span>
              <Tooltip
                placement="right-start"
                title={
                  <Typography fontSize={14}>
                    Απεικονίζει τον αριθμό των μοναδικών MAC από όλα τα AP μιας
                    περιοχής τα τελευταία 5 λεπτά
                  </Typography>
                }
              >
                <div>
                  <InfoSvg size="20" color={"#322B73"} />
                </div>
              </Tooltip>
            </div>
          </div>
          <div className="mt-2 flex px-2 gap-4 py-4 overflow-x-auto">
            <ActivityKpiDataRefresher
              data={dashboardData?.activeVisitors}
              interval={120}
            />
          </div>
        </div>

        <div className="w-full">
          <div className="flex flex-1 items-center">
            <div className="flex items-center">
              <div className="flex gap-x-3 items-center px-[15px]">
                <span
                  lang="el"
                  className="text-[13px] font-semibold uppercase"
                  style={{
                    color: theme.palette.neutral.dark4,
                  }}
                >
                  Αθροιστική επισκεψιμότητα
                </span>
                <Tooltip
                  placement="right-start"
                  title={
                    <Typography fontSize={14}>
                      Παρουσιάζει την επισκεψιμότητα (μοναδικές mac) που έχουν
                      παρουσιαστεί στις περιοχές τα τελευταία 30min
                    </Typography>
                  }
                >
                  <div>
                    <InfoSvg size="20" color={"#322B73"} />
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="mt-2 flex px-2 gap-4 py-4 overflow-x-auto">
            <HistoryActivityKpiDataRefresher
              data={dashboardData?.activeVisitorsHistory}
              interval={60 * 30}
            />
          </div>
        </div>
      </div>

      <VisitingRateSection data={dashboardData?.visitingRate} />
    </div>
  );
};

export default WifiBlock;
