import { removeDiacritics } from "../../Utils/Text/Text";
import TabPanel from "../Theme/Tabs/TabPanel";
import Tabs from "../Theme/Tabs/Tabs";

const TabMenu = ({ items, onChange, keyProp, selected }) => {
  return (
    <Tabs
      onChange={onChange}
      tabs={items}
      value={selected}
      initialTab={selected}
      aria-label="tabs navigation"
    >
      {items?.map((item, index) => (
        <TabPanel
          index={index}
          key={item?.[keyProp]}
          icon={item?.icon}
          iconPosition="start"
          label={removeDiacritics(item?.title)}
          value={index}
        />
      ))}
    </Tabs>
  );
};

export default TabMenu;
