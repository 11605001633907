import { useEffect, useState } from "react";
import LightsAgent from "../../Api/LightsAgent/LightsAgent";
import { Grid, Typography } from "@mui/material";
import Icons from "../../../../assets/icons";
import CircularSpinner from "../../../../Components/Theme/Spinners/CircularSpinner";
import { themeColors } from "../../../../Config/Themes/theme";
import { useTheme } from "@mui/material/styles";
import ShadowBox from "../../../../Components/ShadowBox/ShadowBox";

const TotalConsumptionSection = ({ className }) => {
  const [counts, setCounts] = useState();
  const [loading, setLoading] = useState();
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const textColor = theme.palette.neutral.dark4;

  useEffect(() => {
    const fetchTotalConsumption = async () => {
      setLoading(true);

      await LightsAgent.analysis
        .getDevices()
        .then((response) => {
          setCounts(response?.result);
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setLoading(false);
        });
    };

    fetchTotalConsumption();
  }, []);

  return loading ? (
    <CircularSpinner />
  ) : (
    <div
      className={`grid grid-cols-12 p-2 gap-6 w-full ${className}`}
      style={{
        backgroundColor: colors.whiteAccent.main,
      }}
    >
      <ShadowBox className="col-span-6 flex flex-col justify-between w-full p-5">
        <Typography
          variant="h5"
          pb={3}
          sx={{
            color: textColor,
          }}
          className="text-[14px] font-semibold"
        >
          ΟΔΟΦΩΤΙΣΜΟΣ
        </Typography>
        <div className="flex flex-col justify-between gap-2 max-w-[400px]">
          <div className="flex justify-between">
            <Typography
              sx={{
                color: textColor,
              }}
            >
              Φωτιστικά LED
            </Typography>
            <Typography
              sx={{
                color: textColor,
              }}
            >
              {counts?.totalLightDevices || "0"}
            </Typography>
          </div>
          <div className="flex justify-between">
            <Typography
              sx={{
                color: textColor,
              }}
            >
              Ενεργά φωτιστικά LED
            </Typography>
            <Typography
              sx={{
                color: textColor,
              }}
            >
              {counts?.activeLightDevices || "0"}
            </Typography>
          </div>
          <div className="flex justify-between">
            <Typography
              sx={{
                color: textColor,
              }}
            >
              Κατανάλωση (kWh)
            </Typography>
            <Typography
              sx={{
                color: textColor,
              }}
            >
              {counts?.totalConsuption || "0"}
            </Typography>
          </div>
        </div>
      </ShadowBox>
      <ShadowBox className="col-span-6 h-full flex flex-col justify-evenly items-center px-10 py-3">
        <div
          className="py-2 px-10 w-full flex flex-col justify-center items-center"
          style={{
            backgroundColor: colors.backgroundBlueAccent.main,
          }}
        >
          <Typography
            variant="h5"
            pb={1}
            sx={{
              color: textColor,
            }}
            className="text-[14px] font-semibold"
          >
            ΩΡΕΣ ΛΕΙΤΟΥΡΓΙΑΣ
          </Typography>
          <div className="flex flex-col justify-between gap-2 w-full">
            <div className="flex justify-evenly items-center">
              <Icons name={"moon"} height={35} width={35} />
              <Typography
                variant="h5"
                sx={{
                  color: colors.primary.main,
                }}
              >
                20:15
              </Typography>
            </div>
            <div className="flex justify-evenly items-center">
              <Icons name={"sun"} height={35} width={35} />
              <Typography
                variant="h5"
                sx={{
                  color: colors.primary.main,
                }}
              >
                06:45
              </Typography>
            </div>
          </div>
        </div>
      </ShadowBox>
    </div>
  );
};

export default TotalConsumptionSection;
