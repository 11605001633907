import * as React from "react";
import { useTheme } from "@mui/material";
import clsx from "clsx";

const VisitingRateCard = (props) => {
  const theme = useTheme();
  const classes = clsx([
    "relative w-full h-[230px]  pb-[32px] pt-[6px] rounded-md bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]",
    props?.className,
  ]);

  return (
    <div className={classes}>
      <div className="flex gap-x-3 items-center px-[15px]">
        <span
          className="text-[13px] font-semibold uppercase min-h-[30px]"
          style={{ color: theme.palette.neutral.dark4, lineHeight: "16px" }}
        >
          {props.title}
        </span>
      </div>
      {props.children}
    </div>
  );
};

export default VisitingRateCard;
