import AuthCardWrapper from "../../Components/Theme/AuthCardWrapper";
import {
  Box,
  TextField,
  FormControl,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Images from "../../assets/images";
import { themeColors } from "../../Config/Themes/theme";
import { useParams, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import AnimateButton from "../../Components/Theme/Extended/AnimateButton";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { authStore } from "../../Stores/AuthStore";
import { useState } from "react";
import SubmitButton from "../../Components/Theme/Extended/SubmitButton";
import PasswordInput from "../../Components/Theme/Inputs/PasswordInput";
import { passwordRegex } from "../../Utils/Regex";

export default function ResetPassword() {
  const navigate = useNavigate();
  const { token } = useParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const [loading, setLoading] = useState(false);

  const schema = yup.object().shape({
    email: yup.string().email().required(),
    newPassword: yup
      .string()
      .trim()
      .strict(true)
      .matches(passwordRegex, t("validation.InvalidPassword")),
    confirmNewPassword: yup
      .string()
      .required()
      .oneOf([yup.ref("newPassword"), null], t("forms.passwordsMustMatch")),
  });

  const { handleSubmit, control } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    if (loading) return;

    setLoading(true);

    authStore.resetPassword(data, token).finally(() => {
      setLoading(false);
      navigate("/");
    });
  };

  return (
    <form
      className="form-background relative bg-cover bg-no-repeat"
      style={{
        backgroundImage: `url(${Images.UnAuthBg})`,
        backgroundSize: "cover",
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="relative z-20">
        <AuthCardWrapper>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} paddingBottom="1rem">
              <Typography
                color={theme.palette.primary.main}
                gutterBottom
                sx={{ textAlign: "center" }}
                variant={matchDownSM ? "h3" : "h2"}
              >
                {t("forms.ResetPassword")}
              </Typography>
            </Grid>

            <Grid item container paddingBottom="1rem" xs={12}>
              <FormControl sx={{ width: "150%" }}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="Email"
                      variant="filled"
                      type="email"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? t(error.message) : null}
                    />
                  )}
                />
              </FormControl>
              <FormControl sx={{ width: "150%", marginTop: "1rem" }}>
                <Controller
                  name="newPassword"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <PasswordInput
                      label={t("forms.Password")}
                      onChange={onChange}
                      value={value}
                      error={error}
                      variant="filled"
                    />
                  )}
                />
              </FormControl>
              <FormControl sx={{ width: "150%", marginTop: "1rem" }}>
                <Controller
                  name="confirmNewPassword"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <PasswordInput
                      label={t("forms.confirmPassword")}
                      onChange={onChange}
                      value={value}
                      error={error}
                      variant="filled"
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Box sx={{ mt: 1 }}>
              <AnimateButton>
                <SubmitButton
                  className="capitalize text-base"
                  buttonTitle="submit"
                  style={{
                    background: colors.greenAccent.main,
                  }}
                  loading={loading}
                  disableElevation
                  size="medium"
                  type="submit"
                />
              </AnimateButton>
            </Box>
          </Grid>
        </AuthCardWrapper>
      </div>
    </form>
  );
}
