import TabNavigation from "../../../../Components/TabNavigation/TabNavigation";
import Icons from "../../../../assets/icons";

const iconSize = 25;

const ScreensTabMenu = () => {
  const items = [
    {
      label: "Λογισμικό Διαχείρισης",
      route: "/software-management",
      icon: <Icons name="html" width={iconSize} height={iconSize} />,
    },
  ];

  return <TabNavigation baseRoute="screens" routes={items} />;
};

export default ScreensTabMenu;
