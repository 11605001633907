import * as React from "react";

const HumanFigureSvg = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ?? "18"}
      height={props.height ?? "56"}
      viewBox="0 0 19 56"
    >
      <path
        id="Color_Overlay"
        data-name="Color Overlay"
        d="M305.894,538a5.568,5.568,0,0,1-5.7-5.277V519.246A7.867,7.867,0,0,1,297,513.054v-11.17c0-4.5,4.05-8.182,9-8.182s9,3.682,9,8.182v11.17a7.867,7.867,0,0,1-3.194,6.192v13.477a5.567,5.567,0,0,1-5.7,5.277Zm-5.738-50.686A5.871,5.871,0,1,1,306,492.627,5.6,5.6,0,0,1,300.155,487.314Z"
        transform="translate(-297 -482)"
        fill={props.color ?? "#fff"}
      />
    </svg>
  );
};

export default HumanFigureSvg;
