import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { themeColors } from "../../../../Config/Themes/theme";
import dayjs from "dayjs";
import LineChart from "../../../../Components/Charts/LineChart/LineChart/LineChart";
import LightsAgent from "../../Api/LightsAgent/LightsAgent";

const DailyConsumptionSection = ({ className }) => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const [consumptionData, setConsumptionData] = useState([]);

  useEffect(() => {
    const fetchHistoricalConsumptionData = async () => {
      await LightsAgent.analysis
        .getDailyData()
        .then((response) => {
          setConsumptionData(response?.result);
        })
        .catch((error) => console.error(error));
    };

    fetchHistoricalConsumptionData();
  }, []);

  const chartData = {
    labels: consumptionData.map((item) => dayjs(item.date).format("D/M/YY")),
    datasets: [
      {
        label: "Ημερήσια συνολική κατανάλωση",
        data: consumptionData.map((item) => item.averageConsuption),
        borderColor: colors.primary.main,
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className={className}>
      <div className="p-4 rounded-lg h-full w-full">
        <LineChart
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              title: {
                display: false,
              },
              datalabels: {
                display: false,
              },
            },
            scales: {
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: "Κατανάλωση (MWh)",
                },
              },
              x: {
                title: {
                  display: true,
                  text: "Ημερομηνία",
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default DailyConsumptionSection;
