import { useTranslation } from "react-i18next";
import TabNavigation from "../../../../Components/TabNavigation/TabNavigation";

const SmartBinsTabMenu = () => {
  const { t } = useTranslation();

  const items = [
    {
      route: "",
      label: t("map.Map"),
    },
    {
      route: "/trashBin",
      label: t("trashBins.TrashBinsManagement"),
    },
    {
      route: "/trashBinDevice",
      label: t("trashBinDevices.TrashBinDevicesManagement"),
    },
    {
      route: "/threshold",
      label: t("thresholds.ThresholdUpdate"),
    },
    {
      route: "/alert",
      label: t("alerts.AlertsList"),
    },
    {
      route: "/reports",
      label: t("reports.reports"),
    },
    {
      route: "/fullnessHistory",
      label: t("historical.FullnessHistory"),
    },
  ];

  return <TabNavigation baseRoute="smart-bin" routes={items} />;
};

export default SmartBinsTabMenu;
