import PageTitle from "../../../../Components/PageTitle";
import { themeColors } from "../../../../Config/Themes/theme";
import { useTheme } from "@mui/material";
import { useRef } from "react";
import dayjs from "dayjs";
import Breadcrumb from "../../../../Components/Breadcrumb/Breadcrumb";
import CustomParseFormat from "dayjs/plugin/customParseFormat";
import GreekLocale from "../../Utils/GreekLocale";
import NewReport from "./NewReport";

dayjs.locale(GreekLocale);
dayjs.extend(CustomParseFormat);

const CreateReport = () => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const componentRef = useRef(null);

  return (
    <div>
      <Breadcrumb
        pathList={["Περιβάλλον - Ποιότητα αέρα", "Αναφορές - Ιστορικότητα"]}
      />
      <div ref={componentRef} className="flex flex-1 w-full h-full">
        <div className="flex-1 lg:flex-2 mr-5 w-full h-full">
          <PageTitle
            className="pt-2 pb-10"
            style={{
              borderColor: colors.greyAccent.dark4,
            }}
            title="Αναφορές - Ιστορικό μετρήσεων"
          />
          <NewReport />
        </div>
      </div>
    </div>
  );
};

export default CreateReport;
