import { useTheme } from "@mui/material";
import { themeColors } from "../../Config/Themes/theme";

const ShadowBox = ({ children, className }) => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  return (
    <div
      className={className}
      style={{
        boxShadow: `2px 3px 10px 3px ${colors.shadow.main}`,
        borderRadius: "3px",
      }}
    >
      {children}
    </div>
  );
};

export default ShadowBox;
