import * as React from "react";

const ResidenceTimeSvg = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ??"12.001" }
      height={props.size ??"15"}
      viewBox="0 0 12.001 15"
    >
      <g
        id="Vector_Smart_Object"
        data-name="Vector Smart Object"
        transform="translate(-922 -76)"
      >
        <g
          id="Vector_Smart_Object-2"
          data-name="Vector Smart Object"
          transform="translate(920.01 74.859)"
        >
          <path
            id="Path_45"
            data-name="Path 45"
            d="M13.985,4.875l-6-3.734L1.99,4.9,2,12.384,7.99,16.141l6-3.767ZM7.779,2.239l.005,6.692,3.648,2.185L13,12.062,8,15.175,2.768,11.912l0-6.536Z"
          />
        </g>
        <path
          id="Color_Overlay"
          data-name="Color Overlay"
          d="M922.005,87.242,922,79.764,927.995,76l6,3.734.005,7.5L928,91Zm.769-7.007,0,6.536,5.227,3.263,5.009-3.113-1.572-.947-3.649-2.184,0-6.691Z"
          fill={props.color ??"#545a86" }
        />
      </g>
    </svg>
  );
};

export default ResidenceTimeSvg;
