import * as React from "react";
import { colors, colorsSecondary } from "../../Defaults/Global";

import VisitingRateCard from "./VisitingRateCard";

import {
  OptionsHalfDoughnut,
  OptionsDoughnut,
  DataHalfDoughnut,
  centerImageDoughnut,
  DataDoughnut,
  centerImageHalfDoughnut,
} from "./GraphOptions";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { Doughnut } from "react-chartjs-2";
import { Typography } from "@mui/material";
import useBreakpoints from "../../../../Hooks/useBreakpoints";

ChartJS.register(ArcElement, Tooltip, Legend);

const LegendItem = ({ item }) => {
  const { xl_up } = useBreakpoints();
  return (
    <div className="grid grid-cols-12 ml-2 mb-1 lg:mb-2 items-center">
      <div
        className="col-span-6 xl:col-span-5 h-[16px] rounded-lg mb-1 xl:mb-0 mr-2"
        style={{ backgroundColor: item?.color }}
      />
      <div className="col-span-12 xl:col-span-7 break-words max-w-[100px]">
        <Typography
          fontSize={xl_up ? 12 : 10}
          lineHeight={xl_up ? "12px" : "10px"}
        >
          {item?.label}
        </Typography>
      </div>
    </div>
  );
};

const VisitingRateKpi = (props) => {
  const options = OptionsHalfDoughnut();
  const options2 = OptionsDoughnut();

  const data1 = DataHalfDoughnut(props.data, colors);
  const data2 = DataDoughnut(props.data, colorsSecondary);

  const legendItems1 = data1?.labels?.map((item, index) => ({
    label: item,
    color: data1?.datasets[0]?.backgroundColor[index],
  }));

  const legendItems2 = data2?.labels?.map((item, index) => ({
    label: item,
    color: data2?.datasets[0]?.backgroundColor[index],
  }));

  return (
    <div className="grid grid-cols-12 gap-x-3 gap-y-2">
      <VisitingRateCard
        title={"ΠΕΡΙΟΧΗ ΜΕ ΤΗ ΜΕΓΑΛΥΤΕΡΗ ΕΠΑΝΑΕΠΙΣΚΕΨΙΜΟΤΗΤΑ"}
        className="col-span-6"
      >
        <div className="grid grid-cols-12 h-[200px] -mt-[11px]">
          <div className="col-span-7 w-full h-[200px]">
            <Doughnut
              data={data1}
              options={{ ...options, rotation: 180, circumference: 180 }}
              plugins={[centerImageHalfDoughnut(props.data?.locationName)]}
            />
          </div>
          <div className="col-span-4 flex flex-col justify-center mt-5">
            {legendItems1?.map((item) => (
              <LegendItem item={item} />
            ))}
          </div>
        </div>
      </VisitingRateCard>

      <VisitingRateCard title={"ΕΠΙΣΚΕΠΤΕΣ"} className="col-span-6">
        <div className="grid grid-cols-12 w-full h-[170px]">
          <div className="col-span-7 w-full h-[210px] ml-2 -mt-[20px]">
            <Doughnut
              data={data2}
              options={options2}
              plugins={[centerImageDoughnut]}
            />
          </div>
          <div className="col-span-4 flex flex-col justify-center mt-5">
            {legendItems2?.map((item) => (
              <LegendItem item={item} />
            ))}
          </div>
        </div>
      </VisitingRateCard>
    </div>
  );
};

export default VisitingRateKpi;
