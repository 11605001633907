import { Modal, Typography, useTheme } from "@mui/material";
import { themeColors } from "../../../../Config/Themes/theme";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import CountDaysDistance from "../../Utils/CountDaysDistance";
import dayjs from "dayjs";
import BorderSection from "../../../../Components/BorderSection/BorderSection";
import TextInput from "../../../../Components/TextInput/TextInput";
import MyDatePicker from "../../../../Components/MyDatePicker/MyDatePicker";
import SubmitButton from "../../../../Components/Buttons/SubmitButton";
import ActionButton from "../../../../Components/Buttons/ActionButton";
import CancelButton from "../../../../Components/Buttons/CancelButton";
import DropdownButton from "../../../../Components/Buttons/DropdownButton";
import HeatMapPlayer from "../../../../Components/HeatMapPlayer/HeatMapPlayer";
import PdfIcon from "../../../../assets/icons/appIcons/pdf.png";
import ExcelIcon from "../../../../assets/icons/appIcons/xls.png";
import {
  calculateAverages,
  calculateMax,
  calculateMin,
} from "../../Utils/DataManipulator";
import EnvironmentAgent from "../../Api/EnvironmentAgent/EnvironmentAgent";
import PdfTemplate from "./PdfTemplate";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import CircularSpinner from "../../../../Components/Theme/Spinners/CircularSpinner";
import LineChart from "../../../../Components/Charts/LineChart/LineChart/LineChart";
import { useAuthStore } from "../../../../Stores/AuthStore";

const types = ["Διάγραμμα", "Heat Map"];
const measurementsToRender = [
  {
    key: "co2",
    name: "CO2",
    yAxisTitle: "Συγκέντρωση (ppm)",
    chartTitle: "Διάγραμμα CO₂",
  },
  {
    key: "so2",
    name: "SO2",
    yAxisTitle: "Συγκέντρωση (μg/m3)",
    chartTitle: "Διάγραμμα SO₂",
  },
  {
    key: "no2",
    name: "NO2",
    yAxisTitle: "Συγκέντρωση (μg/m3)",
    chartTitle: "Διάγραμμα NO₂",
  },
  {
    key: "o3",
    name: "O3",
    yAxisTitle: "Συγκέντρωση (μg/m3)",
    chartTitle: "Διάγραμμα O₃",
  },
  {
    key: "no",
    name: "NO",
    yAxisTitle: "Συγκέντρωση (μg/m3)",
    chartTitle: "Διάγραμμα NO",
  },
  {
    key: "pm1",
    name: "PM1.0",
    yAxisTitle: "Συγκέντρωση (μg/m3)",
    chartTitle: "Διάγραμμα PM1.0",
  },
  {
    key: "pm25",
    name: "PM2.5",
    yAxisTitle: "Συγκέντρωση (μg/m3)",
    chartTitle: "Διάγραμμα PM2.5",
  },
  {
    key: "pm10",
    name: "PM10",
    yAxisTitle: "Συγκέντρωση (μg/m3)",
    chartTitle: "Διάγραμμα PM10",
  },
  {
    key: "temperature",
    name: "ΘΕΡΜΟΚΡΑΣΙΑ",
    yAxisTitle: "Θερμοκρασία (°C)",
    chartTitle: "Διάγραμμα Θερμοκρασίας",
  },
  {
    key: "humidity",
    name: "ΥΓΡΑΣΙΑ",
    yAxisTitle: "Ποσοστό (%)",
    chartTitle: "Διάγραμμα Υγρασίας",
  },
  {
    key: "pressure",
    name: "ΠΙΕΣΗ",
    yAxisTitle: "Πίεση (bar)",
    chartTitle: "Διάγραμμα Πίεσης",
  },
];

const NewReport = () => {
  const theme = useTheme();
  const { authUser } = useAuthStore();
  const colors = themeColors(theme.palette.mode);
  const [measurementData, setMeasurementData] = useState({});
  const [dataToRender, setDataToRender] = useState({});
  const [dateFrom, setDateFrom] = useState(null);
  const [frequency, setFrequency] = useState("day");
  const [reportInitialized, setReportInitialized] = useState(false);
  const [devices, setDevices] = useState(null);
  const [heatMapMeasurements, setHeatMapMeasurements] = useState({});
  const [reportInputs, setReportInputs] = useState({
    title: "",
    dateFrom: "",
    dateTo: "",
    type: "",
    measurementListToRender: [],
    heatMapMeasurement: null,
  });
  const [chartRefs, setChartRefs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const heatMapData =
    reportInputs?.type === "Heat Map" && reportInputs?.heatMapMeasurement
      ? {
          ...reportInputs?.heatMapMeasurement,
          labels: dataToRender[reportInputs?.heatMapMeasurement?.key]?.labels,
          data: heatMapMeasurements[reportInputs?.heatMapMeasurement?.key],
        }
      : null;

  const exportToPdf = async () => {
    let fileName = "";
    if (chartRefs?.length > 0) {
      const images = chartRefs.map((ref) => {
        return ref?.toBase64Image();
      });
      const doc = <PdfTemplate images={images} inputs={reportInputs} />;
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      if (reportInputs?.title?.length > 15) {
        fileName =
          reportInputs?.title?.substring(0, 15) + ".pdf" || "untitled.pdf";
      } else {
        fileName = reportInputs?.title + ".pdf" || "untitled.pdf";
      }
      saveAs(blob, fileName);
    }
  };

  const reportSchema = yup.object().shape({
    title: yup.string().required("Το πεδίο είναι υποχρεωτικό"),
    dateFrom: yup.date().required("Το πεδίο είναι υποχρεωτικό"),
    dateTo: yup.date().required("Το πεδίο είναι υποχρεωτικό"),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(reportSchema),
  });

  const prepareChartData = (data) => {
    const preparedData = {};
    const chartData = {};

    Object.keys(data).forEach((key) => {
      preparedData[key] = {
        average: calculateAverages(data[key], frequency),
        max: calculateMax(data[key], frequency),
        min: calculateMin(data[key], frequency),
      };
    });

    Object.keys(data).forEach((key) => {
      chartData[key] = {
        labels: preparedData[key].average.map((item) => {
          switch (frequency) {
            case "hour":
              return dayjs(item.date, "DD/MM/YYYY HH:mm").toDate();
            case "day":
              return dayjs(item.date, "DD/MM/YYYY").toDate();
            case "week":
              return dayjs(item.date, "DD/MM/YYYY HH:mm").toDate();
            case "month":
              return dayjs(item.date, "MM/YYYY").toDate();
            case "year":
              return dayjs(item.date, "YYYY").toDate();
            default:
              break;
          }
        }),
        datasets: [
          {
            label: frequency === "hour" ? "Τιμή" : "Μέση τιμή",
            data: preparedData[key].average.map((item) => {
              switch (frequency) {
                case "hour":
                  return {
                    x: dayjs(item.date, "DD/MM/YYYY HH:mm").toDate(),
                    y: item.value,
                  };
                case "day":
                  return {
                    x: dayjs(item.date, "DD/MM/YYYY").toDate(),
                    y: item.value,
                  };
                case "week":
                  return {
                    x: dayjs(item.date, "DD/MM/YYYY").toDate(),
                    y: item.value,
                  };
                case "month":
                  return {
                    x: dayjs(item.date, "MM/YYYY").toDate(),
                    y: item.value,
                  };
                case "year":
                  return {
                    x: dayjs(item.date, "YYYY").toDate(),
                    y: item.value,
                  };
                default:
                  break;
              }
            }),
            backgroundColor: colors.primary.main,
            pointStyle: "circle",
            pointRadius: 2,
            pointHoverRadius: 3,
            borderColor: colors.primary.main,
            borderWidth: 2,
            fill: false,
            indexAxis: "x",
          },
          {
            label: "Μέγιστη τιμή",
            hidden: frequency === "hour",
            data: preparedData[key].max.map((item) => {
              switch (frequency) {
                case "hour":
                  return {
                    x: dayjs(item.date, "DD/MM/YYYY HH:mm").toDate(),
                    y: item.value,
                  };
                case "day":
                  return {
                    x: dayjs(item.date, "DD/MM/YYYY").toDate(),
                    y: item.value,
                  };
                case "week":
                  return {
                    x: dayjs(item.date, "DD/MM/YYYY").toDate(),
                    y: item.value,
                  };
                case "month":
                  return {
                    x: dayjs(item.date, "MM/YYYY").toDate(),
                    y: item.value,
                  };
                case "year":
                  return {
                    x: dayjs(item.date, "YYYY").toDate(),
                    y: item.value,
                  };
                default:
                  break;
              }
            }),
            backgroundColor: colors.greenAccent.main,
            pointStyle: "circle",
            pointRadius: 2,
            pointHoverRadius: 3,
            borderColor: colors.greenAccent.main,
            borderWidth: 2,
            borderDash: [5, 5],
            fill: false,
            indexAxis: "x",
          },
          {
            label: "Ελάχιστη τιμή",
            hidden: frequency === "hour",
            data: preparedData[key].min.map((item) => {
              switch (frequency) {
                case "hour":
                  return {
                    x: dayjs(item.date, "DD/MM/YYYY HH:mm").toDate(),
                    y: item.value,
                  };
                case "day":
                  return {
                    x: dayjs(item.date, "DD/MM/YYYY").toDate(),
                    y: item.value,
                  };
                case "week":
                  return {
                    x: dayjs(item.date, "DD/MM/YYYY").toDate(),
                    y: item.value,
                  };
                case "month":
                  return {
                    x: dayjs(item.date, "MM/YYYY").toDate(),
                    y: item.value,
                  };
                case "year":
                  return {
                    x: dayjs(item.date, "YYYY").toDate(),
                    y: item.value,
                  };
                default:
                  break;
              }
            }),
            backgroundColor: colors.orangeAccent.main,
            pointStyle: "circle",
            pointRadius: 2,
            pointHoverRadius: 3,
            borderColor: colors.orangeAccent.main,
            borderWidth: 2,
            borderDash: [5, 5],
            fill: false,
            indexAxis: "x",
          },
        ],
      };
    });

    return chartData;
  };

  const prepareHeatMapData = (data) => {
    const preparedData = {};

    Object.keys(data).forEach((measurementKey) => {
      Object.keys(devices).forEach((deviceKey) => {
        preparedData[measurementKey] = {
          ...preparedData[measurementKey],
          [deviceKey]: null,
        };
      });
    });

    Object.keys(data).forEach((measurementKey) => {
      Object.keys(devices).forEach((deviceKey) => {
        const relatedData = data[measurementKey]?.filter(
          (item) => item?.deviceId?.toString() === deviceKey
        );
        if (relatedData?.length > 0) {
          preparedData[measurementKey][deviceKey] = {
            average:
              relatedData?.length > 0
                ? calculateAverages(relatedData, frequency)?.map((item) => ({
                    ...item,
                    value: parseFloat(item?.value),
                  }))
                : null,
            max: calculateMax(relatedData, frequency)?.map((item) => ({
              ...item,
              value: parseFloat(item?.value),
            })),
            min: calculateMin(relatedData, frequency)?.map((item) => ({
              ...item,
              value: parseFloat(item?.value),
            })),
          };
        }
      });
    });

    setHeatMapMeasurements(preparedData);
  };

  const onMeasurementsSelect = (measurement) => {
    if (reportInputs?.type === "Heat Map") {
      setReportInputs({ ...reportInputs, heatMapMeasurement: measurement });
      return;
    }

    let measurements = [...reportInputs.measurementListToRender];

    if (measurements.find((item) => item.name === measurement.name)) {
      measurements = measurements.filter(
        (item) => item.name !== measurement.name
      );
      setReportInputs({
        ...reportInputs,
        measurementListToRender: measurements,
        heatMapMeasurement: null,
      });
      return;
    }

    measurements.push(measurement);
    setReportInputs({
      ...reportInputs,
      measurementListToRender: measurements,
    });
  };

  const onTypeSelect = (type) => {
    if (reportInputs.type === type) {
      setReportInputs({
        ...reportInputs,
        type: "",
      });
      return;
    }

    setReportInputs({
      ...reportInputs,
      type: type,
      measurementListToRender: [],
      heatMapMeasurement: null,
    });
    prepareHeatMapData(measurementData);
  };

  const onCreateReport = (data) => {
    const { title, dateFrom, dateTo } = data;
    const reportData = {
      title,
      dateFrom: dayjs(dateFrom).format("DD/MM/YYYY"),
      dateTo: dayjs(dateTo).format("DD/MM/YYYY"),
    };

    setErrorMessage(null);
    setReportInputs({
      ...reportInputs,
      ...reportData,
    });

    setReportInitialized(true);
  };

  const onCancelReport = () => {
    setReportInitialized(false);
    setReportInputs({
      title: "",
      dateFrom: "",
      dateTo: "",
      type: "",
      measurementListToRender: [],
    });
    setErrorMessage(null);
    reset();
  };

  useEffect(() => {
    if (measurementData) {
      const data = prepareChartData(measurementData);
      setDataToRender(data);
    }
  }, [measurementData]);

  useEffect(() => {
    setLoading(true);
    if (reportInputs.dateFrom && reportInputs.dateTo) {
      EnvironmentAgent.analysis
        .getEventsByDate(
          dayjs(reportInputs.dateFrom, "DD/MM/YYYY").format("YYYY-MM-DD"),
          dayjs(reportInputs.dateTo, "DD/MM/YYYY").format("YYYY-MM-DD")
        )
        .then((res) => {
          if (res?.result?.length === 0) {
            setErrorMessage("Δεν υπάρχουν δεδομένα για την επιλεγμένη περίοδο");
            return;
          }

          const devicesMap = {};
          res?.result?.forEach((item) => {
            if (!devicesMap[item?.device?.id]) {
              devicesMap[item?.device?.id] = item?.device;
            }
          });
          setDevices(devicesMap);

          const data = {
            co2: res.result
              .map((item) => ({
                date: dayjs(item.timestamp).format("DD/MM/YYYY HH:mm"),
                value: item.cO2,
                deviceId: item?.device?.id,
              }))
              .filter((item) => item.value !== null),
            so2: res.result
              .map((item) => ({
                date: dayjs(item.timestamp).format("DD/MM/YYYY HH:mm"),
                value: item.sO2,
                deviceId: item?.device?.id,
              }))
              .filter((item) => item.value !== null),
            no2: res.result
              .map((item) => ({
                date: dayjs(item.timestamp).format("DD/MM/YYYY HH:mm"),
                value: item.nO2,
                deviceId: item?.device?.id,
              }))
              .filter((item) => item.value !== null),
            o3: res.result
              .map((item) => ({
                date: dayjs(item.timestamp).format("DD/MM/YYYY HH:mm"),
                value: item.o3,
                deviceId: item?.device?.id,
              }))
              .filter((item) => item.value !== null),
            no: res.result
              .map((item) => ({
                date: dayjs(item.timestamp).format("DD/MM/YYYY HH:mm"),
                value: item.no,
                deviceId: item?.device?.id,
              }))
              .filter((item) => item.value !== null),
            pm1: res.result
              .map((item) => ({
                date: dayjs(item.timestamp).format("DD/MM/YYYY HH:mm"),
                value: item.pM1,
                deviceId: item?.device?.id,
              }))
              .filter((item) => item.value !== null),
            pm25: res.result
              .map((item) => ({
                date: dayjs(item.timestamp).format("DD/MM/YYYY HH:mm"),
                value: item.pM2_5,
                deviceId: item?.device?.id,
              }))
              .filter((item) => item.value !== null),
            pm10: res.result
              .map((item) => ({
                date: dayjs(item.timestamp).format("DD/MM/YYYY HH:mm"),
                value: item.pM10,
                deviceId: item?.device?.id,
              }))
              .filter((item) => item.value !== null),
            temperature: res.result
              .map((item) => ({
                date: dayjs(item.timestamp).format("DD/MM/YYYY HH:mm"),
                value: item.temperature,
                deviceId: item?.device?.id,
              }))
              .filter((item) => item.value !== null),
            humidity: res.result
              .map((item) => ({
                date: dayjs(item.timestamp).format("DD/MM/YYYY HH:mm"),
                value: item.hunidity,
                deviceId: item?.device?.id,
              }))
              .filter((item) => item.value !== null),
            pressure: res.result
              .map((item) => ({
                date: dayjs(item.timestamp).format("DD/MM/YYYY HH:mm"),
                value: item.pressure,
                deviceId: item?.device?.id,
              }))
              .filter((item) => item.value !== null),
          };
          setMeasurementData(data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [reportInputs.dateFrom, reportInputs.dateTo]);

  useEffect(() => {
    const days = CountDaysDistance(
      dayjs(reportInputs.dateFrom, "DD/MM/YYYY"),
      dayjs(reportInputs.dateTo, "DD/MM/YYYY")
    );

    if (days < 3) {
      setFrequency("hour");
    } else if (days < 61) {
      setFrequency("day");
    } else if (days >= 61 && days < 91) {
      setFrequency("week");
    } else if (days >= 90 && days < 365) {
      setFrequency("month");
    } else if (days >= 365) {
      setFrequency("year");
    }
  }, [reportInputs.dateFrom, reportInputs.dateTo]);

  return (
    <>
      <div className="flex gap-4 justify-between">
        <div className="flex flex-col max-w-[700px] flex-wrap">
          <Typography
            variant="h2"
            fontSize={20}
            className="pl-8 break-all"
            color="primary"
            textTransform={"uppercase"}
          >
            <span lang="el">
              {reportInitialized
                ? "Αναφορά: " + reportInputs.title
                : "Νέα αναφορά"}
            </span>
          </Typography>
        </div>
        <div className="flex gap-2">
          <Typography variant="h6" color={colors.greyAccent.dark}>
            ΗΜΕΡΟΜΗΝΙΑ ΔΗΜΙΟΥΡΓΙΑΣ:
          </Typography>
          <Typography
            variant="h6"
            color={colors.greyAccent.dark4}
            opacity={0.5}
            style={{
              textTransform: "uppercase",
            }}
          >
            <span lang="el">{dayjs().format("DD MMMM YYYY")}</span>
          </Typography>
        </div>
      </div>

      <hr className="my-5 mx-6" />

      <form onSubmit={handleSubmit((data) => onCreateReport(data))}>
        {!reportInitialized && (
          <section className="pb-5">
            <BorderSection title="Επιλογή δεδομένων" required>
              <div className="flex gap-3">
                <TextInput
                  label={"Τίτλος αναφοράς"}
                  name={"title"}
                  control={control}
                  error={errors.name}
                  className="flex-1"
                  placeholder="Εισάγετε τον τίτλο της αναφοράς"
                />
                <Controller
                  name="dateFrom"
                  control={control}
                  rules={{ required: "Το πεδίο είναι υποχρεωτικό" }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <MyDatePicker
                      label="Από"
                      maxDate={new Date()}
                      onChange={(date) => {
                        onChange(date);
                        setDateFrom(date);
                      }}
                      className="flex-1"
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
                <Controller
                  name="dateTo"
                  control={control}
                  rules={{ required: "Το πεδίο είναι υποχρεωτικό" }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <MyDatePicker
                      label="Εώς"
                      minDate={new Date(dateFrom)}
                      maxDate={new Date()}
                      disabled={!dateFrom}
                      onChange={(date) => onChange(date)}
                      className="flex-1"
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </div>
            </BorderSection>
            <div className="flex justify-end items-center gap-3 my-5 mr-5">
              <SubmitButton
                className="w-[140px]"
                label={"Δημιουργία"}
                type="submit"
                loading={false}
                onClick={handleSubmit}
              />
            </div>
          </section>
        )}

        {reportInitialized && (
          <section className="pb-5">
            <BorderSection title="Δεδομένα αναφοράς">
              <div className="flex gap-3">
                <div className="flex flex-1 flex-col gap-2">
                  <Typography
                    variant="h6"
                    color={colors.greyAccent.dark}
                    className="flex-1"
                  >
                    ΗΜΕΡΟΜΗΝΙΑ ΑΠΟ
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.primary.main}
                    className="flex-1"
                  >
                    {reportInputs.dateFrom}
                  </Typography>
                </div>
                <div className="flex flex-1 flex-col gap-2">
                  <Typography
                    variant="h6"
                    color={colors.greyAccent.dark}
                    className="flex-1"
                  >
                    ΕΩΣ
                  </Typography>
                  <Typography
                    variant="h6"
                    color={colors.primary.main}
                    className="flex-1"
                  >
                    {reportInputs.dateTo}
                  </Typography>
                </div>
                <div className="flex flex-1 flex-col gap-2">
                  <Typography
                    variant="h6"
                    color={colors.greyAccent.dark}
                    className="flex-1"
                  >
                    ΤΙΤΛΟΣ ΑΝΑΦΟΡΑΣ
                  </Typography>
                  <div className="flex flex-col max-w-[350px] flex-wrap">
                    <Typography
                      variant="h6"
                      color={colors.primary.main}
                      className="flex-1 break-all"
                      display={"flex"}
                    >
                      {reportInputs.title}
                    </Typography>
                  </div>
                </div>
              </div>
            </BorderSection>

            {errorMessage && (
              <div className="flex justify-center items-center gap-3 my-5 mr-5">
                <Typography variant="h6" color={colors.greyAccent.dark}>
                  {errorMessage}
                </Typography>
              </div>
            )}

            {!errorMessage && (
              <BorderSection
                title="Επιλογή τύπου"
                requiredText={"Υποχρεωτική επιλογή"}
                required
              >
                <div className="flex gap-3">
                  {types.map((type) => (
                    <ActionButton
                      key={type}
                      selected={reportInputs.type === type}
                      variant="outlined"
                      sx={{ backgroundColor: "blue", color: "white" }}
                      onClick={() => onTypeSelect(type)}
                    >
                      <span
                        lang="el"
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        {type}
                      </span>
                    </ActionButton>
                  ))}
                </div>
              </BorderSection>
            )}

            {!errorMessage && reportInputs.type && (
              <BorderSection
                title="Επιλογή μέτρησης προς εμφάνιση"
                required
                requiredText={"Υποχρεωτική επιλογή"}
              >
                <div className="flex gap-3">
                  {measurementsToRender.map((measurement) => (
                    <ActionButton
                      key={measurement}
                      selected={
                        reportInputs.measurementListToRender.find(
                          (item) => item.name === measurement.name
                        ) !== undefined ||
                        reportInputs?.heatMapMeasurement?.name ===
                          measurement?.name
                      }
                      variant="outlined"
                      sx={{ backgroundColor: "blue", color: "white" }}
                      onClick={() => onMeasurementsSelect(measurement)}
                    >
                      <span
                        lang="el"
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        {measurement.name}
                      </span>
                    </ActionButton>
                  ))}
                </div>
              </BorderSection>
            )}

            {loading && (
              <div className="flex justify-center items-center">
                <CircularSpinner />
              </div>
            )}

            {!errorMessage &&
              !loading &&
              reportInputs.type === "Διάγραμμα" &&
              reportInputs.measurementListToRender.length > 0 && (
                <div className="flex flex-col gap-8">
                  {reportInputs.measurementListToRender?.map((measurement) => {
                    return (
                      <BorderSection key={measurement.key} title="Διάγραμμα">
                        <div className="flex flex-col h-[600px]">
                          {dataToRender[measurement.key]?.datasets?.length >
                          0 ? (
                            <LineChart
                              setChartInstance={setChartRefs}
                              data={dataToRender[measurement.key] || []}
                              options={{
                                spanGaps: true,
                                responsive: true,
                                interaction: {
                                  mode: "nearest",
                                  axis: "x",
                                  intersect: false,
                                },
                                maintainAspectRatio: false,
                                plugins: {
                                  decimation: {
                                    enabled: true,
                                    algorithm: "min-max",
                                  },
                                  datalabels: {
                                    display: false,
                                  },
                                  legend: {
                                    display: frequency !== "hour",
                                  },
                                  title: {
                                    display: true,
                                    text: measurement.chartTitle,
                                    font: {
                                      size: 24,
                                    },
                                  },
                                  tooltip: {
                                    mode: "index",
                                    enabled: true,
                                    callbacks: {
                                      title: function (context) {
                                        var title = context[0].raw.x || "";
                                        if (title) {
                                          switch (frequency) {
                                            case "hour":
                                              title =
                                                dayjs(title).format(
                                                  "DD/MM/YYYY HH:mm"
                                                );
                                              break;
                                            case "day":
                                              title =
                                                dayjs(title).format(
                                                  "DD/MM/YYYY"
                                                );
                                              break;
                                            case "week":
                                              title =
                                                dayjs(title).format(
                                                  "DD/MM/YYYY"
                                                );
                                              break;
                                            case "month":
                                              title =
                                                dayjs(title).format("MM/YYYY");
                                              break;
                                            case "year":
                                              title =
                                                dayjs(title).format("YYYY");
                                              break;
                                            default:
                                              break;
                                          }
                                        }
                                        return title;
                                      },
                                    },
                                  },
                                },
                                scales: {
                                  y: {
                                    beginAtZero: true,
                                    title: {
                                      display: true,
                                      text: measurement.yAxisTitle,
                                    },
                                  },
                                  x: {
                                    type: "time",
                                    time: {
                                      unit: frequency,
                                      displayFormats: {
                                        hour: "DD/MM HH:mm",
                                        day: "DD/MM",
                                        week: "MMM",
                                        month: "MM/YYYY",
                                        year: "YYYY",
                                      },
                                      ticks: {
                                        source: "auto",
                                      },
                                    },
                                  },
                                },
                              }}
                            />
                          ) : (
                            <Typography
                              variant="h6"
                              color={colors.greyAccent.dark}
                            >
                              Δεν υπάρχουν δεδομένα για την επιλεγμένη περίοδο
                            </Typography>
                          )}
                        </div>
                      </BorderSection>
                    );
                  })}
                </div>
              )}

            {!errorMessage && heatMapData && (
              <BorderSection title="Heat Map">
                <div className="flex flex-col gap-3">
                  <HeatMapPlayer
                    data={heatMapData}
                    devices={devices}
                    frequency={frequency}
                    measurement={reportInputs?.heatMapMeasurement}
                  />
                </div>
              </BorderSection>
            )}

            <div className="flex justify-end items-center gap-3 my-5 mr-5">
              {!errorMessage && !reportInitialized ? (
                <SubmitButton
                  className="w-[140px]"
                  label={"Δημιουργία"}
                  type="submit"
                  loading={false}
                  onClick={handleSubmit}
                />
              ) : (
                <div className="flex gap-3 items-center">
                  <CancelButton
                    type="button"
                    onClick={() => onCancelReport()}
                    size="large"
                    label="Ακύρωση"
                  />
                  {!errorMessage && (
                    <>
                      <DropdownButton
                        label={"Αποθήκευση ως"}
                        options={[
                          {
                            label: "PDF",
                            icon: PdfIcon,
                            onSelect: async () => {
                              await exportToPdf();
                            },
                          },
                          {
                            label: "Excel",
                            icon: ExcelIcon,
                            onSelect: async () => {
                              const exportPdf =
                                EnvironmentAgent.reports.exportExcel({
                                  fileName: reportInputs.title,
                                  from: dayjs(
                                    reportInputs.dateFrom,
                                    "DD/MM/YYYY"
                                  ).format("YYYY-MM-DD"),
                                  to: dayjs(
                                    reportInputs.dateTo,
                                    "DD/MM/YYYY"
                                  ).format("YYYY-MM-DD"),
                                  id: authUser.organizationId,
                                });

                              const response = await exportPdf;
                              const success = response?.success;
                              const message = response?.message;

                              if (success === false) {
                                setErrorModalMessage(message);
                                setOpenErrorModal(true);
                              }
                            },
                          },
                        ]}
                      />
                      {/* <SubmitButton
                        label={"Εκτύπωση"}
                        type="button"
                        loading={false}
                        onClick={() => window.print()}
                      /> */}
                    </>
                  )}
                </div>
              )}
            </div>
          </section>
        )}
      </form>

      <Modal
        open={openErrorModal}
        onClose={() => setOpenErrorModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="flex justify-center items-center outline-none"
      >
        <div className="flex flex-col justify-center items-center gap-3 px-6 pb-6 bg-white rounded-lg shadow-lg max-w-md w-full">
          <button
            onClick={() => setOpenErrorModal(false)}
            className="self-end text-gray-500 hover:text-gray-700"
            aria-label="Close"
          >
            <span className="text-2xl">&times;</span>
          </button>
          <Typography
            variant="h2"
            color={colors.greyAccent.dark}
            className="text-lg font-semibold"
          >
            Σφάλμα
          </Typography>
          <Typography
            variant="h6"
            color={colors.greyAccent.dark}
            className="text-sm"
          >
            Το αρχείο δεν μπορεί να δημιουργηθεί
          </Typography>
          <Typography
            variant="h6"
            color={colors.greyAccent.dark}
            className="text-sm"
          >
            Μήνυμα σφάλματος: {errorModalMessage}
          </Typography>

          <div className="mt-2 px-4 py-2">
            <SubmitButton
              label={"Κλείσιμο"}
              type="button"
              loading={false}
              onClick={() => setOpenErrorModal(false)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NewReport;
