import Topbar from "../../../../Components/Topbar/Topbar";
import MainContent from "../../../../MainAppComponents/MainContent";
import WifiTabMenu from "../WifiTabMenu/WifiTabMenu";

const WifiLayout = ({ children, showTabs = true, ...props }) => (
  <>
    <Topbar icon="wifi" title="Wifi & Επισκεψιμότητα" />
    {showTabs && <WifiTabMenu />}
    <MainContent>{children}</MainContent>
  </>
);

export default WifiLayout;
