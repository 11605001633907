import { useTheme } from "@mui/material/styles";
import { themeColors } from "../../../Config/Themes/theme";
import { useAnnouncementsStore } from "../../../Stores/AnnouncementsStore";
import { Typography } from "@mui/material";
import { useEffect } from "react";
import { observer } from "mobx-react";
import dayjs from "dayjs";
import { toJS } from "mobx";

const LIMIT_ANNOUCEMENTS = 2;

const AnnouncementsBlock = () => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const announcementsStore = useAnnouncementsStore();

  useEffect(() => {
    announcementsStore.getAllAnnouncements();
  }, [announcementsStore]);

  return (
    <div className="flex flex-col justify-between gap-2">
      <Typography
        color={colors.primary.main}
        className="flex flex-col justify-end gap-2"
      >
        {announcementsStore.announcementsList.length > 0 ? (
          <div className="flex flex-col gap-2">
            {announcementsStore.announcementsList
              .filter((announcement) => announcement?.status === "Active")
              .slice(0, LIMIT_ANNOUCEMENTS)
              .map((announcement) => (
                <div key={announcement?.id} className="flex gap-2 w-full">
                  <Typography
                    variant="body1"
                    sx={{
                      color: colors.primary.main,
                    }}
                  >
                    {dayjs(announcement?.createdOn).format("DD/MM/YYYY")}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      color: colors.primary.main,
                      wordBreak: "break-word",
                    }}
                  >
                    {announcement?.text}
                  </Typography>
                </div>
              ))}
          </div>
        ) : (
          "Δεν υπάρχουν ανακοινώσεις"
        )}
      </Typography>
    </div>
  );
};

export default observer(AnnouncementsBlock);
