import { Tooltip, Typography, useTheme } from "@mui/material";
import { useAuthStore } from "../../Stores/AuthStore";
import ProfileToolTip from "./ProfileToolTip";
import Icons from "../../assets/icons";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";

const Profile = ({ collapsed }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { authUser, logout } = useAuthStore();
  const fullName = `${authUser?.name} ${authUser?.surName}`;

  if (!collapsed) {
    return (
      <section className="flex items-center">
        <ProfileToolTip />
        <div className="ml-2">
          <Typography className="whitespace-nowrap">{fullName}</Typography>
          <Typography
            className="whitespace-nowrap"
            color={theme.palette.primary.main}
          >
            {authUser?.roles?.at(0)?.toUpperCase()}
          </Typography>
        </div>

        <div className="flex ml-4 space-x-2 items-center">
          <Tooltip title="Ρυθμίσεις χρήστη">
            <div
              onClick={() => navigate("/profile")}
              className="cursor-pointer hover:bg-gray-200 rounded-full p-1"
            >
              <Icons
                fill={theme.palette.primary.main}
                name="gear"
                height="22"
                width="22"
              />
            </div>
          </Tooltip>
          <Tooltip title="Αποσύνδεση">
            <div className="cursor-pointer hover:bg-gray-200 rounded-full p-1 mb-1">
              <LogoutIcon
                onClick={() => {
                  logout();
                }}
                sx={{ color: theme.palette.primary.main }}
              />
            </div>
          </Tooltip>
        </div>
      </section>
    );
  }

  return <ProfileToolTip />;
};

export default Profile;
