import { useNavigate } from "react-router-dom";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import MainMapFilter from "../../Components/Map/MainMapFilter/MainMapFilter";
import { useMapStore } from "../../Stores/MapStore";
import { themeColors } from "../../Config/Themes/theme";

import getTrashbinMarkerIcon from "../../Components/Map/Markers/TrashbinMarker/TrashbinMarkers";
import MapWrapper from "../../Components/Map/MapWrapper/MapWrapper";
import renderTrashbinInfoWindow from "../../Components/Map/InfoWindows/TrashbinInfoWindow/TrashbinInfoWindow";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useState } from "react";

const MapReport = () => {
  const [isTrashBinModalOpen, setIsTrashBinModalOpen] = useState(false);
  const [isDeviceBinModalOpen, setIsDeviceBinModalOpen] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const {
    initializeData,
    resetFilters,
    historicMapStartDate,
    filteredHistoricTrashBinsForDateList,
    getTrashBinsEventsForDate,
    setSelectedTrashBinId,
    filteredTrashBinsEventsList,
    thresholdsFilter,
    trashBinsPerThreshold,
  } = useMapStore();

  const initialRegion = {
    lat: 37.98381,
    lng: 23.727539,
  };

  const containerStyle = {
    width: "100%",
    height: "75vh",
  };

  const navigate = useNavigate();

  const graphNavigation = (markerID) => {
    // navigate("/reports",{markerID: markerID});
    setSelectedTrashBinId([markerID]);
    // navigate("reports");
  };

  useEffect(() => {
    resetFilters();
    initializeData();
  }, [
    filteredTrashBinsEventsList?.splice,
    thresholdsFilter?.splice,
    trashBinsPerThreshold?.splice,
  ]);

  useEffect(() => {
    getTrashBinsEventsForDate(historicMapStartDate);
  }, [filteredHistoricTrashBinsForDateList?.splice]);

  return (
    <div className="mt-6">
      <MapWrapper
        initialRegion={initialRegion}
        markers={filteredHistoricTrashBinsForDateList}
        containerStyle={containerStyle}
        enableClustering={true}
        markerIcon={(marker) => getTrashbinMarkerIcon(marker, true)}
        infoWindow={(marker) =>
          renderTrashbinInfoWindow({
            setSelectedTrashBinId: graphNavigation,
            navigate,
            isTrashBinModalOpen,
            setIsTrashBinModalOpen,
            isDeviceBinModalOpen,
            setIsDeviceBinModalOpen,
            marker,
            destinationRoute: "../reports",
          })
        }
      />
    </div>
  );
};

export default observer(MapReport);
