import * as React from "react";
import { useTheme } from "@mui/material";

import SectionAreaTitleSvg from "./Icons/SectionAreaTitleSvg";

const AcivityCardHeader = (props) => {
  const theme = useTheme();

  return (
    <div className="flex flex-row items-center gap-[7px]">
      <div
        className="w-[27px] h-[27px] rounded-full flex justify-center items-center"
        style={{ backgroundColor: theme.palette.background.cardTitleIcon }}
      >
        <SectionAreaTitleSvg />
      </div>

      <span
        className="text-[14px] uppercase font-semibold"
        style={{ color: theme.palette.neutral.dark4 }}
      >
        {props.locationName}
      </span>
    </div>
  );
};

export default AcivityCardHeader;
