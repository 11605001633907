import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { themeColors } from "../../../Config/Themes/theme";
import { useEffect, useState } from "react";
import LightsAgent from "../../../Saas/Lights/Api/LightsAgent/LightsAgent";
import Icons from "../../../assets/icons";

const LightsBlock = () => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const [counts, setCounts] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    const fetchTotalConsumption = async () => {
      setLoading(true);

      await LightsAgent.analysis
        .getDevices()
        .then((response) => {
          setCounts({
            numberOfLeds: response?.result.totalLightDevices,
            numberOfActiveLeds: response?.result.activeLightDevices,
            totalConsumption: response?.result.totalConsuption,
          });
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setLoading(false);
        });
    };

    fetchTotalConsumption();
  }, []);

  return (
    <div className="flex flex-col sm:flex-row justify-between gap-4 lg:gap-8">
      <div className="flex flex-col justify-center gap-4 md:w-[250px] lg:w-[180px]">
        <div className="flex justify-between gap-2 w-full">
          <Typography
            variant="h6"
            sx={{
              color: colors.primary.main,
            }}
          >
            Φωτιστικά LED
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: colors.primary.main,
            }}
          >
            {counts?.numberOfLeds}
          </Typography>
        </div>
        <div className="flex justify-between gap-2 w-full">
          <Typography
            variant="h6"
            sx={{
              color: colors.primary.main,
            }}
          >
            Ενεργά φωτιστικά LED
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: colors.primary.main,
            }}
          >
            {counts?.numberOfActiveLeds}
          </Typography>
        </div>
        <div className="flex justify-between gap-2 w-full">
          <Typography
            variant="h6"
            sx={{
              color: colors.primary.main,
            }}
          >
            Κατανάλωση (kWh)
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: colors.primary.main,
            }}
          >
            {counts?.totalConsumption}
          </Typography>
        </div>
      </div>
      <div
        className="flex flex-col justify-center items-center px-2 py-2 mt-2 w-full sm:w-[300px] lg:w-[170px] rounded-md bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] leading-[1.0]"
        style={{
          backgroundColor: colors.backgroundBlueAccent.main,
        }}
      >
        <Typography
          variant="h5"
          fontWeight={"bold"}
          pb={1}
          sx={{
            color: colors.primary.main,
          }}
        >
          Ώρες λειτουργίας
        </Typography>
        <div className="flex flex-col justify-between gap-2 w-full">
          <div className="flex justify-evenly items-center">
            <Icons name={"moon"} height={35} width={35} />
            <Typography
              variant="h5"
              sx={{
                color: colors.primary.main,
              }}
            >
              20:15
            </Typography>
          </div>
          <div className="flex justify-evenly items-center">
            <Icons name={"sun"} height={35} width={35} />
            <Typography
              variant="h5"
              sx={{
                color: colors.primary.main,
              }}
            >
              06:45
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LightsBlock;
