import dayjs from "dayjs";

export const calculateAverages = (measurements, frequency) => {
  const sumsAndCounts = {};

  measurements.forEach((measurement) => {
    switch (frequency) {
      case "hour":
        const hour = dayjs(measurement.date, "DD/MM/YYYY HH:mm").format(
          "DD/MM/YYYY HH:mm"
        );
        if (!sumsAndCounts[hour]) {
          sumsAndCounts[hour] = { sum: 0, count: 0, date: "" };
        }
        sumsAndCounts[hour].sum += Number(measurement.value);
        sumsAndCounts[hour].count += 1;
        sumsAndCounts[hour].date = dayjs(
          measurement.date,
          "DD/MM/YYYY HH:mm"
        ).format("DD/MM/YYYY HH:mm");
        break;
      case "day":
        const date = dayjs(measurement.date, "DD/MM/YYYY HH:mm").format(
          "DD/MM/YYYY"
        );
        if (!sumsAndCounts[date]) {
          sumsAndCounts[date] = { sum: 0, count: 0, date: "" };
        }
        sumsAndCounts[date].sum += Number(measurement.value);
        sumsAndCounts[date].count += 1;
        sumsAndCounts[date].date = dayjs(
          measurement.date,
          "DD/MM/YYYY HH:mm"
        ).format("DD/MM/YYYY");
        break;
      case "week":
        const week = dayjs(measurement.date, "DD/MM/YYYY HH:mm").format(
          "WW/YYYY"
        );
        if (!sumsAndCounts[week]) {
          sumsAndCounts[week] = { sum: 0, count: 0, date: "" };
        }
        sumsAndCounts[week].sum += Number(measurement.value);
        sumsAndCounts[week].count += 1;
        sumsAndCounts[week].date = measurement.date;
        break;
      case "month":
        const month = dayjs(measurement.date, "DD/MM/YYYY HH:mm").format(
          "MM/YYYY"
        );
        if (!sumsAndCounts[month]) {
          sumsAndCounts[month] = { sum: 0, count: 0, date: "" };
        }
        sumsAndCounts[month].sum += Number(measurement.value);
        sumsAndCounts[month].count += 1;
        sumsAndCounts[month].date = dayjs(
          measurement.date,
          "DD/MM/YYYY HH:mm"
        ).format("DD/MM/YYYY");
        break;
      case "year":
        const year = dayjs(measurement.date, "DD/MM/YYYY HH:mm").format("YYYY");
        if (!sumsAndCounts[year]) {
          sumsAndCounts[year] = { sum: 0, count: 0, date: "" };
        }
        sumsAndCounts[year].sum += Number(measurement.value);
        sumsAndCounts[year].count += 1;
        sumsAndCounts[year].date = dayjs(
          measurement.date,
          "DD/MM/YYYY HH:mm"
        ).format("DD/MM/YYYY");
        break;
      default:
        break;
    }
  });

  const averages = [];

  for (const [date, data] of Object.entries(sumsAndCounts)) {
    switch (frequency) {
      case "hour":
        averages.push({
          date: dayjs(date, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY HH:mm"),
          value: (data.sum / data.count).toFixed(2),
        });
        break;
      case "day":
        averages.push({
          date: dayjs(date, "DD/MM/YYYY").format("DD/MM/YYYY"),
          value: (data.sum / data.count).toFixed(2),
        });
        break;
      case "week":
        averages.push({
          date: data.date,
          value: (data.sum / data.count).toFixed(2),
        });
        break;
      case "month":
        averages.push({
          date: dayjs(date, "MM/YYYY").format("MM/YYYY"),
          value: (data.sum / data.count).toFixed(2),
        });
        break;
      case "year":
        averages.push({
          date: dayjs(date, "YYYY").format("YYYY"),
          value: (data.sum / data.count).toFixed(2),
        });
        break;
      default:
        break;
    }
  }

  return averages;
};

export const calculateMax = (measurements, frequency) => {
  const maxs = {};

  measurements.forEach((measurement) => {
    switch (frequency) {
      case "hour":
        const hour = dayjs(measurement.date, "DD/MM/YYYY HH:mm").format(
          "DD/MM/YYYY HH:mm"
        );
        if (!maxs[hour]) {
          maxs[hour] = { max: 0, date: "" };
        }
        if (Number(measurement.value) > maxs[hour].max) {
          maxs[hour].max = Number(measurement.value);
          maxs[hour].date = dayjs(measurement.date, "DD/MM/YYYY HH:mm").format(
            "DD/MM/YYYY HH:mm"
          );
        }
        break;
      case "day":
        const date = dayjs(measurement.date, "DD/MM/YYYY HH:mm").format(
          "DD/MM/YYYY"
        );
        if (!maxs[date]) {
          maxs[date] = { max: 0, date: "" };
        }
        if (Number(measurement.value) > maxs[date].max) {
          maxs[date].max = Number(measurement.value);
          maxs[date].date = dayjs(measurement.date, "DD/MM/YYYY HH:mm").format(
            "DD/MM/YYYY"
          );
        }
        break;
      case "week":
        const week = dayjs(measurement.date, "DD/MM/YYYY HH:mm").format(
          "WW/YYYY"
        );
        if (!maxs[week]) {
          maxs[week] = { max: 0, date: "" };
        }
        if (Number(measurement.value) > maxs[week].max) {
          maxs[week].max = Number(measurement.value);
          maxs[week].date = measurement.date;
        }
        break;
      case "month":
        const month = dayjs(measurement.date, "DD/MM/YYYY HH:mm").format(
          "MM/YYYY"
        );
        if (!maxs[month]) {
          maxs[month] = { max: 0, date: "" };
        }
        if (Number(measurement.value) > maxs[month].max) {
          maxs[month].max = Number(measurement.value);
          maxs[month].date = dayjs(measurement.date, "DD/MM/YYYY HH:mm").format(
            "DD/MM/YYYY"
          );
        }
        break;
      case "year":
        const year = dayjs(measurement.date, "DD/MM/YYYY HH:mm").format("YYYY");
        if (!maxs[year]) {
          maxs[year] = { max: 0, date: "" };
        }
        if (Number(measurement.value) > maxs[year].max) {
          maxs[year].max = Number(measurement.value);
          maxs[year].date = dayjs(measurement.date, "DD/MM/YYYY HH:mm").format(
            "DD/MM/YYYY"
          );
        }
        break;
      default:
        break;
    }
  });

  const maxValues = [];

  for (const [date, data] of Object.entries(maxs)) {
    switch (frequency) {
      case "hour":
        maxValues.push({
          date: dayjs(date, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY HH:mm"),
          value: data.max.toFixed(2),
        });
        break;
      case "day":
        maxValues.push({
          date: dayjs(date, "DD/MM/YYYY").format("DD/MM/YYYY"),
          value: data.max.toFixed(2),
        });
        break;
      case "week":
        maxValues.push({
          date: data.date,
          value: data.max.toFixed(2),
        });
        break;
      case "month":
        maxValues.push({
          date: dayjs(date, "MM/YYYY").format("MM/YYYY"),
          value: data.max.toFixed(2),
        });
        break;
      case "year":
        maxValues.push({
          date: dayjs(date, "YYYY").format("YYYY"),
          value: data.max.toFixed(2),
        });
        break;
      default:
        break;
    }
  }

  return maxValues;
};

export const calculateMin = (measurements, frequency) => {
  const mins = {};

  measurements.forEach((measurement) => {
    switch (frequency) {
      case "hour":
        const hour = dayjs(measurement.date, "DD/MM/YYYY HH:mm").format(
          "DD/MM/YYYY HH:mm"
        );
        if (!mins[hour]) {
          mins[hour] = { min: 100000, date: "" };
        }
        if (Number(measurement.value) < mins[hour].min) {
          mins[hour].min = Number(measurement.value);
          mins[hour].date = dayjs(measurement.date, "DD/MM/YYYY HH:mm").format(
            "DD/MM/YYYY HH:mm"
          );
        }
        break;
      case "day":
        const date = dayjs(measurement.date, "DD/MM/YYYY HH:mm").format(
          "DD/MM/YYYY"
        );
        if (!mins[date]) {
          mins[date] = { min: 100000, date: "" };
        }
        if (Number(measurement.value) < mins[date].min) {
          mins[date].min = Number(measurement.value);
          mins[date].date = dayjs(measurement.date, "DD/MM/YYYY HH:mm").format(
            "DD/MM/YYYY"
          );
        }
        break;
      case "week":
        const week = dayjs(measurement.date, "DD/MM/YYYY HH:mm").format(
          "WW/YYYY"
        );
        if (!mins[week]) {
          mins[week] = { min: 100000, date: "" };
        }
        if (Number(measurement.value) < mins[week].min) {
          mins[week].min = Number(measurement.value);
          mins[week].date = measurement.date;
        }
        break;
      case "month":
        const month = dayjs(measurement.date, "DD/MM/YYYY HH:mm").format(
          "MM/YYYY"
        );
        if (!mins[month]) {
          mins[month] = { min: 100000, date: "" };
        }
        if (Number(measurement.value) < mins[month].min) {
          mins[month].min = Number(measurement.value);
          mins[month].date = dayjs(measurement.date, "DD/MM/YYYY HH:mm").format(
            "DD/MM/YYYY"
          );
        }
        break;
      case "year":
        const year = dayjs(measurement.date, "DD/MM/YYYY HH:mm").format("YYYY");
        if (!mins[year]) {
          mins[year] = { min: 100000, date: "" };
        }
        if (Number(measurement.value) < mins[year].min) {
          mins[year].min = Number(measurement.value);
          mins[year].date = dayjs(measurement.date, "DD/MM/YYYY HH:mm").format(
            "DD/MM/YYYY"
          );
        }
        break;
      default:
        break;
    }
  });

  const minValues = [];

  for (const [date, data] of Object.entries(mins)) {
    switch (frequency) {
      case "hour":
        minValues.push({
          date: dayjs(date, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY HH:mm"),
          value: data.min.toFixed(2),
        });
        break;
      case "day":
        minValues.push({
          date: dayjs(date, "DD/MM/YYYY").format("DD/MM/YYYY"),
          value: data.min.toFixed(2),
        });
        break;
      case "week":
        minValues.push({
          date: data.date,
          value: data.min.toFixed(2),
        });
        break;
      case "month":
        minValues.push({
          date: dayjs(date, "MM/YYYY").format("MM/YYYY"),
          value: data.min.toFixed(2),
        });
        break;
      case "year":
        minValues.push({
          date: dayjs(date, "YYYY").format("YYYY"),
          value: data.min.toFixed(2),
        });
        break;
      default:
        break;
    }
  }

  return minValues;
};
