import React, { FC } from "react";
import { useTheme } from "@mui/material";
import { themeColors } from "../../../../Config/Themes/theme";
import { Line } from "react-chartjs-2";
import DashboardSection from "../../../../Components/DashboardSection/DashboardSection";
import { observer } from "mobx-react";
import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";
import ShadowBox from "../../../../Components/ShadowBox/ShadowBox";
import SectionTitle from "../SectionTitle/SectionTitle";
import Gauge from "../Gauge/Gauge";
import Icons from "../../../../assets/icons";

interface HumidityChartDatasetOptions {
  stationName: string;
  labels: Array<string>;
  data: Array<number>;
}

interface HumidityChartProps {
  datasets: Array<HumidityChartDatasetOptions>;
}

const HumidityChart: FC<HumidityChartProps> = ({ datasets }) => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);

  const getLatestDataValue = (data) => {
    const latestData = data[data.length - 1];
    return latestData ? latestData : 0;
  };

  const renderHumidityChart = (item) => {
    const lineChartData = {
      labels: item.labels || [],
      datasets: [
        {
          label: "%",
          data: item.data || [],
          fill: "origin", // Fill from the origin (line to the bottom)
          backgroundColor: "transparent",
          pointBackgroundColor: colors.cyanAccent.light,
          borderColor: colors.cyanAccent.light,
          borderWidth: 1,
        },
      ],
    };

    const options = {
      scales: {
        x: {
          ticks: {
            maxRotation: 0,
            autoSkip: true,
            maxTicksLimit: item.data.length > 8 ? 8 : item.data.length, // Limit to 8 labels if there are more that 8 data points
            display: true,
            color: colors.cyanAccent.light,
          },
          grid: {
            display: false,
          },
        },
        y: {
          beginAtZero: true,
          ticks: {
            callback: function (value, index, values) {
              return `${value}%`;
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: false, // Hide data labels on the chart
          anchor: "end", // This will work when display is set to true
          align: "top", // This will work when display is set to true
          offset: -10, // This will work when display is set to true // Adjust this value to move data labels higher
        },
      },
    };

    return (
      <ShadowBox className="" key={item?.stationName}>
        <section className="flex flex-col justify-around items-center p-5 mb-5 min-w-[450px] min-h-[300px] max-h-[300px]">
          {item?.stationName && (
            <SectionTitle
              roundIcon={true}
              icon="location"
              iconWidth={25}
              iconHeight={25}
              title={item?.stationName}
            />
          )}

          {/* <div className="flex items-center justify-between w-full">
            {item?.date && (
              <div className="max-w-max flex ml-[40%] gap-x-1">
                <div
                  className="max-w-max px-2 h-[20px rounded-sm"
                  style={{
                    backgroundColor: colors.cyanAccent.light,
                    color: colors.whiteAccent.main,
                  }}
                >
                  <span className="text-xs uppercase">{item?.date}</span>
                </div>
              </div>
            )}

            <div
              className="max-w-max flex items-end gap-x-1 border-b-2"
              style={{
                borderColor: colors.cyanAccent.light,
              }}
            >
              <div
                className="max-w-max h-[20px]"
                style={{
                  color: colors.cyanAccent.light,
                }}
              >
                <span
                  className="uppercase font-bold"
                  style={{ fontSize: "0.5rem" }}
                >
                  ΗΜΕΡΑ
                </span>
              </div>
            </div>
          </div> */}

          <div className="flex items-center justify-start w-full">
            <div className="flex items-center w-full">
              <Line options={options} data={lineChartData} height="100%" />
            </div>
            <div className="flex items-center w-full mt-[-50px] px-2">
              <Gauge
                value={getLatestDataValue(item?.data)}
                pointerType="blob"
                data={item?.gaugeData}
                size={100}
                icon={
                  <Icons
                    name="humidityV2"
                    height={30}
                    width={30}
                    fill={colors.whiteAccent.main}
                  />
                }
              />
            </div>
          </div>
        </section>
      </ShadowBox>
    );
  };

  return (
    <div
      className="flex"
      style={
        datasets?.length === 1 ? { width: "fit-content" } : { width: "100%" }
      }
    >
      <DashboardSection title="Υγρασία" tooltipText="Δεδομένα υγρασίας">
        <div
          className="flex items-center gap-4 overflow-x-auto px-2 py-2"
          style={
            datasets?.length === 1
              ? { justifyContent: "space-around" }
              : { justifyContent: "space-between" }
          }
        >
          {datasets?.map((dataset: HumidityChartDatasetOptions) => {
            return renderHumidityChart(dataset);
          })}
        </div>
      </DashboardSection>
    </div>
  );
};

export default observer(HumidityChart);
