import CityMap from "../../../Components/Map/CityMap";
import { useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useEffect } from "react";
import { useAuthStore } from "../../../Stores/AuthStore";
import Wifi from "../../../assets/map_icons/wifiMarker.svg";
import Screen from "../../../assets/map_icons/screenMarker.svg";
import Lightning from "../../../assets/map_icons/lightMarker.svg";
import Environment from "../../../assets/map_icons/environmentMarker.svg";
import EnvironmentAgent from "../../../Saas/Environment/Api/EnvironmentAgent/EnvironmentAgent";
import DataService from "../../../Saas/Wifi/Services/DataService";
import LightsAgent from "../../../Saas/Lights/Api/LightsAgent/LightsAgent";
import Loader from "../../../MainAppComponents/Loader";
import EnvironmentalStationInfoWindow from "../../../Saas/Environment/Components/EnvironmentalStationInfoWindow/EnvironmentalStationInfoWindow";
import LightMapCard from "../../../Saas/Lights/Components/LightsMap/MapCard";
import WifiMapCard from "../../../Saas/Wifi/Components/Map/MapCard";
import { MEASUREMENT_QUALITY } from "../../../Saas/Environment/Config/Constants/measurements";
import ScreenMapCard from "../../../Saas/Screens/Components/Map/MapCard";

const mockMapData = [
  {
    id: 1,
    lat: 40.73061,
    lng: -73.935242,
    title: "Lamp 1",
    address: "Σύνταγμα 12",
    status: "Ενεργό",
    category: "light",
  },
  {
    id: 2,
    lat: 40.73089,
    lng: -74.934242,
    title: "Lamp 2",
    address: "Ανθέων 25",
    status: "Ενεργό",
    category: "light",
  },
  {
    id: 3,
    lat: 45.73061,
    lng: -80.935242,
    title: "Wifi 1",
    address: "Σύνταγμα 12",
    status: "Ενεργό",
    category: "wifi",
  },
  {
    id: 4,
    lat: 42.73089,
    lng: -76.934242,
    title: "Wifi 2",
    address: "Ανθέων 25",
    status: "Ενεργό",
    category: "wifi",
  },
  {
    id: 5,
    lat: 39.73061,
    lng: -71.935242,
    title: "Environment 1",
    address: "Σύνταγμα 12",
    status: "Ενεργό",
    category: "environment",
  },
  {
    id: 6,
    lat: 38.73089,
    lng: -79.934242,
    title: "Environment 2",
    address: "Ανθέων 25",
    status: "Ενεργό",
    category: "environment",
  },
  {
    id: 1,
    lat: 37.510556,
    lng: 22.37393,
    title: "Οθόνη 1",
    address: "Πλ. Σκάκι",
    status: "Ενεργή",
    category: "screen",
  },
  {
    id: 2,
    lat: 37.51072,
    lng: 22.37351,
    title: "Οθόνη 2",
    address: "Πλ. Βιβλιοθήκης",
    status: "Ενεργή",
    category: "screen",
  },
];

const InfoWindowResolver = ({ item }) => {
  const infoWindows = {
    environment: <EnvironmentalStationInfoWindow data={item} />,
    light: <LightMapCard data={item} />,
    wifi: <WifiMapCard data={item} />,
    screen: <ScreenMapCard data={item} />,
  };

  return infoWindows[item?.markerType] || <div>{`ID: ${item.id}`}</div>;
};

const MapBlock = () => {
  const { saas } = useAuthStore();
  const [selectedMarker, setSelectedMarker] = useState();
  const [markers, setMarkers] = useState([]);
  const [lightMarkers, setLightMarkers] = useState([]);
  const [wifiMarkers, setWifiMarkers] = useState([]);
  const [environmentMarkers, setEnvironmentMarkers] = useState([]);
  const [screenMarkers, setScreenMarkers] = useState([]);
  const [showLightMarkers, setShowLightMarkers] = useState(false);
  const [showWifiMarkers, setShowWifiMarkers] = useState(false);
  const [showEnvironmentMarkers, setShowEnvironmentMarkers] = useState(false);
  const [showScreenMarkers, setShowScreenMarkers] = useState(false);
  const [environmentData, setEnvironmentData] = useState([]);
  const [wifiData, setWifiData] = useState([]);
  const [lightData, setLightData] = useState([]);
  const [screenData, setScreenData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleMarkerVisibility = (markerType, isVisible) => {
    if (markerType === "light") {
      setShowLightMarkers(isVisible);
    }
    if (markerType === "wifi") {
      setShowWifiMarkers(isVisible);
    }
    if (markerType === "environment") {
      setShowEnvironmentMarkers(isVisible);
    }
    if (markerType === "screen") {
      setShowScreenMarkers(isVisible);
    }
  };

  useEffect(() => {
    if (saas.environment) {
      EnvironmentAgent.analysis
        .getEnvironmentalStation(true)
        .then((response) => {
          EnvironmentAgent.analysis
            .getLastEvents()
            .then((resp) => {
              // initialize values
              let aqi = resp?.result?.aqi;
              let color = Object.values(MEASUREMENT_QUALITY)?.find(
                (item) => item.aqi === resp?.result?.aqi
              )?.color;
              let label = Object.values(MEASUREMENT_QUALITY)?.find(
                (item) => item.aqi === resp?.result?.aqi
              )?.label;

              let stations = response?.result?.map((item) => ({
                id: item.id,
                lat: item.lat,
                lng: item.lon,
                title: item.name,
                address: item.description,
                status: item.status,
                devices: item.environmentDevices,
                color: color,
                label: label,
                aqi: aqi,
                onMarkerClick: () => setSelectedMarker(item),
                icon: Environment,
              }));
              setEnvironmentData(stations);
            })
            .catch((error) => console.error(error));
        })
        .catch((error) => console.error(error));
    }

    if (saas.wifi) {
      DataService.get_dashboard()
        .then((res) => {
          setWifiData(
            res?.result?.mapData?.map((item) => ({
              ...item,
              id: item.locationName,
              lat: item.lat,
              lng: item.lng,
              title: item.locationAddress,
              address: item.locationAddress,
              onMarkerClick: () => setSelectedMarker(item),
              icon: Wifi,
            }))
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (saas.lights) {
      LightsAgent.analysis
        .getDevices()
        .then((response) => {
          setLightData(
            response?.result?.lightDevices
              // ?.filter((item) => item.status !== "NoConnection")
              ?.map((item) => ({
                id: item.id,
                lat: item.lat,
                lng: item.lon,
                title: item.info,
                address: item.address,
                status: item.status,
                statusDescription: item.lightStatusDescription,
                deviceName: item.deviceName,
                onMarkerClick: () => setSelectedMarker(item),
                icon: Lightning,
              }))
          );
        })
        .catch((error) => console.error(error));
    }

    if (saas.screens) {
      setScreenData(
        mockMapData
          .filter((item) => item.category === "screen")
          .map((item) => ({
            ...item,
            onMarkerClick: () => setSelectedMarker(item),
            icon: Screen,
          }))
      );
    }
  }, [saas]);

  useEffect(() => {
    if (saas.lights && lightData?.length > 0) {
      setLightMarkers(lightData);
    }
    if (saas.wifi && wifiData?.length > 0) {
      setWifiMarkers(wifiData);
    }
    if (saas.environment && environmentData?.length > 0) {
      setEnvironmentMarkers(environmentData);
    }
    if (saas.screens && screenData?.length > 0) {
      setScreenMarkers(screenData);
    }
  }, [saas, environmentData, wifiData, lightData, screenData]);

  useEffect(() => {
    if (!loading) {
      let markers = [];
      if (showLightMarkers) {
        markers = [
          ...markers,
          ...lightMarkers.map((item) => ({ ...item, markerType: "light" })),
        ];
      }
      if (showWifiMarkers) {
        markers = [
          ...markers,
          ...wifiMarkers.map((item) => ({ ...item, markerType: "wifi" })),
        ];
      }
      if (showEnvironmentMarkers) {
        markers = [
          ...markers,
          ...environmentMarkers.map((item) => ({
            ...item,
            markerType: "environment",
          })),
        ];
      }
      if (showScreenMarkers) {
        markers = [
          ...markers,
          ...screenMarkers.map((item) => ({ ...item, markerType: "screen" })),
        ];
      }
      setMarkers(markers);
    }
  }, [
    showLightMarkers,
    showWifiMarkers,
    showEnvironmentMarkers,
    showScreenMarkers,
    environmentData?.length,
    wifiData?.length,
    lightData?.length,
    screenData?.length,
  ]);

  useEffect(() => {
    saas.lights && setShowLightMarkers(true);
    saas.wifi && setShowWifiMarkers(true);
    saas.environment && setShowEnvironmentMarkers(true);
    saas.screens && setShowScreenMarkers(true);
  }, [saas]);

  return (
    <div style={{ width: "100%", height: "calc(100% - 250px)" }}>
      <div className="flex justify-end items-center">
        {saas.lights && (
          <FormControlLabel
            control={
              <Checkbox
                checked={showLightMarkers}
                onChange={(e) =>
                  handleMarkerVisibility("light", e.target.checked)
                }
              />
            }
            label="Οδοφωτισμός"
          />
        )}

        {saas.wifi && (
          <FormControlLabel
            control={
              <Checkbox
                checked={showWifiMarkers}
                onChange={(e) =>
                  handleMarkerVisibility("wifi", e.target.checked)
                }
              />
            }
            label="Wifi AP"
          />
        )}

        {saas.environment && (
          <FormControlLabel
            control={
              <Checkbox
                checked={showEnvironmentMarkers}
                onChange={(e) =>
                  handleMarkerVisibility("environment", e.target.checked)
                }
              />
            }
            label="Περιβαλλοντικοί σταθμοί"
          />
        )}

        {saas.screens && (
          <FormControlLabel
            control={
              <Checkbox
                checked={showScreenMarkers}
                onChange={(e) =>
                  handleMarkerVisibility("screen", e.target.checked)
                }
              />
            }
            label="Οθόνες"
          />
        )}
      </div>

      {!loading ? (
        <CityMap
          markers={markers}
          // center={findCenter(markers)}
          infoWindowRenderer={(item) => <InfoWindowResolver item={item} />}
          initialZoom={15}
          className="h-[32rem]"
          selectedMarker={selectedMarker}
          setSelectedMarker={setSelectedMarker}
        />
      ) : (
        <Loader className="flex justify-center items-center p-20" size={30} />
      )}
    </div>
  );
};

export default MapBlock;
