import Topbar from "../../../../Components/Topbar/Topbar";
import MainContent from "../../../../MainAppComponents/MainContent";
import SmartBinsTabMenu from "../SmartBinsTabMenu/SmartBinsTabMenu";

const SmartBinsLayout = ({ children, showTabs = true, ...props }) => (
  <>
    <Topbar
      icon="smart-bin"
      iconSize={30}
      title="Smart Bins"
      iconClass="ml-2 mr-4"
    />
    {showTabs && <SmartBinsTabMenu />}
    <MainContent>{children}</MainContent>
  </>
);

export default SmartBinsLayout;
