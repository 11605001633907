import PageTitle from "../../../../Components/PageTitle";
import { themeColors } from "../../../../Config/Themes/theme";
import { CircularProgress, useTheme } from "@mui/material";
import { useRef, useState, useEffect } from "react";
import dayjs from "dayjs";
import Breadcrumb from "../../../../Components/Breadcrumb/Breadcrumb";
import CustomParseFormat from "dayjs/plugin/customParseFormat";
import GreekLocale from "../../../Environment/Utils/GreekLocale";
import NewReport from "./NewReport";
import { get_report_locations } from "../../Services/DataService";

dayjs.locale(GreekLocale);
dayjs.extend(CustomParseFormat);

const CreateReport = () => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const componentRef = useRef(null);
  const [locations, setLocations] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      let data = await get_report_locations();
      setLocations(data.result || []);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <>
      {!isLoading && locations ? (
        <div>
          <Breadcrumb
            pathList={["Wifi & Επισκεψιμότητα", "Αναφορές - Ιστορικότητα"]}
          />
          <div ref={componentRef} className="flex flex-1 w-full h-full">
            <div className="flex-1 lg:flex-2 mr-5 w-full h-full">
              <PageTitle
                className="pt-2 pb-10"
                style={{
                  borderColor: colors.greyAccent.dark4,
                }}
                title="Αναφορές - Ιστορικό μετρήσεων"
              />
              <NewReport locations={locations} />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center mt-16">
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default CreateReport;
