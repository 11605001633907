const LocationSvg = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? "500"}
      zoomAndPan="magnify"
      viewBox="0 0 375 374.999991"
      height={props.size ?? "500"}
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <rect
        x="-37.5"
        width="450"
        fill="#ffffff"
        y="-37.499999"
        height="449.999989"
        fill-opacity="1"
      />
      <rect
        x="-37.5"
        width="450"
        fill="#ffffff"
        y="-37.499999"
        height="449.999989"
        fill-opacity="1"
      />
      <path
        fill={props.color ?? "#000000"}
        d="M 183.257812 329.898438 C 152.519531 295.101562 77.511719 211.777344 77.511719 144.683594 C 77.511719 45.691406 197.757812 -4.179688 267.800781 65.863281 C 287.96875 86.03125 300.445312 113.90625 300.445312 144.683594 C 300.445312 211.769531 225.398438 295.175781 194.632812 329.976562 C 191.601562 333.402344 186.238281 333.34375 183.257812 329.898438 Z M 188.976562 90.535156 C 159.074219 90.535156 134.828125 114.78125 134.828125 144.683594 C 134.828125 192.769531 193.242188 217.003906 227.265625 182.976562 C 261.296875 148.945312 237.066406 90.535156 188.976562 90.535156 Z M 216.550781 117.113281 C 192.046875 92.609375 149.984375 110.054688 149.984375 144.683594 C 149.984375 179.316406 192.050781 196.757812 216.550781 172.261719 C 231.777344 157.03125 231.777344 132.339844 216.550781 117.113281 Z M 92.664062 144.683594 C 92.664062 203.367188 159.292969 279.992188 188.976562 313.574219 C 218.664062 279.992188 285.292969 203.367188 285.292969 144.683594 C 285.292969 59.144531 181.394531 16.0625 120.875 76.578125 C 103.449219 94.007812 92.664062 118.085938 92.664062 144.683594 Z M 92.664062 144.683594 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
    </svg>
  );
};


export default LocationSvg;