import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useAuthStore } from "../Stores/AuthStore";
import { observer } from "mobx-react";
import Loadable from "../Components/Theme/Loadable";
import { lazy, useEffect } from "react";
import SmartBinRoutes from "../Saas/SmartBins/Routes/Routes";
import EnvironmentRoutes from "../Saas/Environment/Routes/Routes";
import LightsRoutes from "../Saas/Lights/Routes/Routes";
import WifiRoutes from "../Saas/Wifi/Routes/Routes";
import ScreensRoutes from "../Saas/Screens/Routes/Routes";
import { alertStore } from "../Stores/AlertStore";
import Profile from "../Pages/Authenticated/Profile/Profile";
import Announcements from "../Pages/Authenticated/Announcements/Announcements";
import Dashboard from "../Pages/Authenticated/Dashboard/Dashboard";

const NoAccessCard = Loadable(
  lazy(() => import("../Components/NoAccessCard/NoAccessCard"))
);

const NoDataCard = Loadable(
  lazy(() => import("../Components/NoDataCard/NoDataCard"))
);

const Users = Loadable(
  lazy(() => import("../Pages/Authenticated/Users/UsersList"))
);
const UsersCreate = Loadable(
  lazy(() => import("../Pages/Authenticated/Users/UsersCreate"))
);
const EditUsers = Loadable(
  lazy(() => import("../Pages/Authenticated/Users/UsersEdit"))
);

const UsersDetails = Loadable(
  lazy(() => import("../Pages/Authenticated/Users/UsersDetails"))
);

const AuthenticatedRoutes = () => {
  const authStore = useAuthStore();
  useEffect(() => {
    alertStore.initHubConnection();

    return () => {
      alertStore.connection.stop();
      alertStore.clearAlerts();
    };
  }, []);

  return (
    <Routes>
      {(authStore.isAdmin || authStore.authUserIsNotLoaded) && (
        <>
          <Route path="/" element={<Users />} />
          <Route path="/users" element={<Outlet />}>
            <Route path="/users" element={<Users />} />
            <Route path="create" element={<UsersCreate />} />
            <Route path=":id/edit" element={<EditUsers />} />
            <Route path=":id" element={<UsersDetails />} />
          </Route>
        </>
      )}

      <Route path="/profile" element={<Profile />} />
      <Route path="/noAccessCard" element={<NoAccessCard />} />
      <Route path="/noDataCard" element={<NoDataCard />} />
      <Route path="/" element={<Dashboard />} />

      {(authStore.isUser || authStore.authUserIsNotLoaded) && (
        <>
          {authStore.saas.smartBin && (
            <Route path="/smart-bin/*" element={<SmartBinRoutes />} />
          )}
          {authStore.saas.environment && (
            <Route path="/environment/*" element={<EnvironmentRoutes />} />
          )}
          {authStore.saas.lights && (
            <Route path="/lights/*" element={<LightsRoutes />} />
          )}
          {authStore.saas.wifi && (
            <Route path="/wifi/*" element={<WifiRoutes />} />
          )}
          {authStore.saas.screens && (
            <Route path="/screens/*" element={<ScreensRoutes />} />
          )}
          <Route path="/announcements" element={<Announcements />} />
        </>
      )}

      <Route path="*" element={<Navigate to="/noAccessCard" />} />
    </Routes>
  );
};
export default observer(AuthenticatedRoutes);
