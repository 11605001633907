export const Options = (length) => {
  return {
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 20,
        left: 10,
        right: 10,
        bottom: 10,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        display: true,
        color: "black",
        align: "-90",
        font: {
          size: 8,
        },
      },
    },

    scales: {
      x: {
        ticks: {
          display: false,
        },

        grid: {
          drawBorder: false,
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        ticks: {
          display: false,
          beginAtZero: true,
        },
        grid: {
          drawBorder: false,
          display: false,
        },
        border: {
          display: false,
        },
      },
    },
  };
};

export const Data = (data, color) => {
  return {
    labels: data,
    datasets: [
      {
        label: "",
        data: data,
        borderColor: color,
        backgroundColor: color,
      },
    ],
  };
};

export default {
  Options,
  Data,
};
