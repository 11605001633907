import * as React from "react";

import ActivityCircle from "./ActivityCircle";
import HumanFigureSvg from "../Icons/HumanFigureSvg";
import ActivityCardHeader from "../ActivityCardHeader";

import { useTheme } from "@mui/material";
import { removeDiacritics } from "../../../../Utils/Text/Text";

const ActivityKpi = (props) => {
  const theme = useTheme();

  let arrow = (
    <div
      className={`w-0 h-0 border-l-[7px] border-l-transparent ${
        props.data.increased
          ? "border-b-[12px] border-b-green-500"
          : "mt-[-2px] border-t-[12px] border-t-[#ff0000]"
      }  border-r-[7px] border-r-transparent`}
    />
  );

  const getColor = (visitors) => {
    if (visitors > 50 && visitors <= 150) {
      return "#2c9dff";
    } else if (visitors > 150 && visitors <= 300) {
      return "#ff7800";
    } else if (visitors > 300) {
      return "#ff0000";
    } else {
      return "#3fb24d";
    }
  };

  return (
    <div
      className="w-fit flex flex-col justify-between lg:w-fit px-[14px] pt-[18px] pb-[22px] rounded-md bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]"
      style={{ height: 140 }}
    >
      <div className="flex flex-row h-[25px]">
        <ActivityCardHeader
          locationName={removeDiacritics(props.data.locationName)}
        />
      </div>
      <div className="flex flex-row justify-between space-x-4 mt-[18px] px-[5px]">
        <div className="flex flex-row gap-4">
          <HumanFigureSvg color={theme.palette.neutral.light4} />
          <ActivityCircle
            activeVisitors={props.data.activeVisitors}
            primaryColor={getColor(props.data.activeVisitors)}
          />
        </div>
        <div className="flex flex-col items-center justify-start mt-[-18px] w-fit">
          <span
            className={`text-[42px] font-semibold text-center`}
            style={{ color: getColor(props.data.activeVisitors) }}
          >
            {props.data.activeVisitors}
          </span>
          <div className="flex flex-row gap-x-[4px] items-baseline mt-[-7px]">
            <span
              className="text-[10px] font-semibold uppercase"
              style={{ color: theme.palette.neutral.dark4 }}
            >
              ΕΠΙΣΚΕΠΤΕΣ
            </span>
            <span
              className={`text-[15px]  mt-[-2px] font-semibold ${
                props.data.increased ? "text-green-500" : "text-[#ff0000]"
              }`}
            >
              {props.data.changePercentage}%
            </span>
            {arrow}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityKpi;
