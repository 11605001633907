export const MEASUREMENT_QUALITY = {
  XLOW: {
    id: "XLOW",
    label: "Εξαιρετικά κακή",
    color: "#D2042D",
    order: 100,
    aqi: 6,
    size: 140,
  },
  VERY_LOW: {
    id: "VERY_LOW",
    label: "Πολύ κακή",
    color: "#FF5733",
    order: 90,
    aqi: 5,
    size: 130,
  },
  LOW: {
    id: "LOW",
    label: "Κακή",
    color: "#F28C28",
    order: 70,
    aqi: 4,
    size: 120,
  },
  MEDIUM: {
    id: "MEDIUM",
    label: "Μέτρια",
    color: "#FFC300",
    order: 60,
    aqi: 3,
    size: 110,
  },
  GOOD: {
    id: "GOOD",
    label: "Καλή",
    color: "#62a67a",
    order: 30,
    aqi: 2,
    size: 100,
  },
  VERY_GOOD: {
    id: "VERY_GOOD",
    label: "Πολύ καλή",
    color: "#32CD32",
    order: 20,
    aqi: 1,
    size: 90,
  },
};

export const MEASUREMENT_AQI = {
  XLOW: {
    id: "XLOW",
    label: "Εξαιρετικά χαμηλή",
    color: "#D2042D",
    order: 10,
  },
  VERY_LOW: {
    id: "VERY_LOW",
    label: "Πολύ χαμηλή",
    color: "#FF5733",
    order: 20,
  },
  LOW: {
    id: "LOW",
    label: "Χαμηλή",
    color: "#F28C28",
    order: 30,
  },
  MEDIUM: {
    id: "MEDIUM",
    label: "Μέτρια",
    color: "#FFC300",
    order: 40,
  },
  GOOD: {
    id: "GOOD",
    label: "Καλή",
    color: "#50C878",
    order: 50,
  },
  VERY_GOOD: {
    id: "VERY_GOOD",
    label: "Πολύ καλή",
    color: "#32CD32",
    order: 60,
  },
};

export const MEASUREMENTS_GAS = {
  CO2: {
    id: "cO2",
    key: "co2",
    label: "CO2",
    unit: "ppm",
    status: MEASUREMENT_QUALITY.GOOD.id,
    getStatus: (value) => {
      if (value >= 0 && value < 400) {
        return MEASUREMENT_QUALITY.VERY_GOOD;
      }

      if (value >= 400 && value < 1000) {
        return MEASUREMENT_QUALITY.GOOD;
      }

      if (value >= 1000 && value < 2000) {
        return MEASUREMENT_QUALITY.MEDIUM;
      }

      if (value >= 2000 && value < 5000) {
        return MEASUREMENT_QUALITY.LOW;
      }

      if (value >= 5000 && value < 40000) {
        return MEASUREMENT_QUALITY.VERY_LOW;
      }

      return MEASUREMENT_QUALITY.XLOW;
    },
  },
  SO2: {
    id: "sO2",
    key: "so2",
    label: "SO2",
    unit: "μg/m3",
    status: MEASUREMENT_QUALITY.XLOW.id,
    getStatus: (value) => {
      if (value >= 0 && value < 100) {
        return MEASUREMENT_QUALITY.VERY_GOOD;
      }

      if (value >= 100 && value < 200) {
        return MEASUREMENT_QUALITY.GOOD;
      }

      if (value >= 200 && value < 350) {
        return MEASUREMENT_QUALITY.MEDIUM;
      }

      if (value >= 350 && value < 500) {
        return MEASUREMENT_QUALITY.LOW;
      }

      if (value >= 500 && value < 750) {
        return MEASUREMENT_QUALITY.VERY_LOW;
      }

      return MEASUREMENT_QUALITY.XLOW;
    },
  },
  NO2: {
    id: "nO2",
    key: "no2",
    label: "NO2",
    unit: "μg/m3",
    status: MEASUREMENT_QUALITY.LOW.id,
    getStatus: (value) => {
      if (value >= 0 && value < 40) {
        return MEASUREMENT_QUALITY.VERY_GOOD;
      }

      if (value >= 40 && value < 90) {
        return MEASUREMENT_QUALITY.GOOD;
      }

      if (value >= 90 && value < 120) {
        return MEASUREMENT_QUALITY.MEDIUM;
      }

      if (value >= 120 && value < 230) {
        return MEASUREMENT_QUALITY.LOW;
      }

      if (value >= 230 && value < 340) {
        return MEASUREMENT_QUALITY.VERY_LOW;
      }

      return MEASUREMENT_QUALITY.XLOW;
    },
  },
  O3: {
    id: "o3",
    key: "o3",
    label: "O3",
    unit: "μg/m3",
    status: MEASUREMENT_QUALITY.MEDIUM.id,
    getStatus: (value) => {
      if (value >= 0 && value < 50) {
        return MEASUREMENT_QUALITY.VERY_GOOD;
      }

      if (value >= 50 && value < 100) {
        return MEASUREMENT_QUALITY.GOOD;
      }

      if (value >= 100 && value < 130) {
        return MEASUREMENT_QUALITY.MEDIUM;
      }

      if (value >= 130 && value < 240) {
        return MEASUREMENT_QUALITY.LOW;
      }

      if (value >= 240 && value < 380) {
        return MEASUREMENT_QUALITY.VERY_LOW;
      }

      return MEASUREMENT_QUALITY.XLOW;
    },
  },
  NO: {
    id: "no",
    key: "no",
    label: "NO",
    unit: "μg/m3",
    status: MEASUREMENT_QUALITY.GOOD.id,
    getStatus: (value) => {
      if (value >= 0 && value < 40) {
        return MEASUREMENT_QUALITY.VERY_GOOD;
      }

      if (value >= 40 && value < 90) {
        return MEASUREMENT_QUALITY.GOOD;
      }

      if (value >= 90 && value < 120) {
        return MEASUREMENT_QUALITY.MEDIUM;
      }

      if (value >= 120 && value < 230) {
        return MEASUREMENT_QUALITY.LOW;
      }

      if (value >= 230 && value < 340) {
        return MEASUREMENT_QUALITY.VERY_LOW;
      }

      return MEASUREMENT_QUALITY.XLOW;
    },
  },
  PM1: {
    id: "pM1",
    key: "pm1",
    label: "PM1.0",
    unit: "μg/m3",
    status: MEASUREMENT_QUALITY.LOW.id,
    getStatus: (value) => {
      if (value >= 0 && value < 10) {
        return MEASUREMENT_QUALITY.VERY_GOOD;
      }

      if (value >= 10 && value < 20) {
        return MEASUREMENT_QUALITY.GOOD;
      }

      if (value >= 20 && value < 25) {
        return MEASUREMENT_QUALITY.MEDIUM;
      }

      if (value >= 25 && value < 50) {
        return MEASUREMENT_QUALITY.LOW;
      }

      if (value >= 50 && value < 75) {
        return MEASUREMENT_QUALITY.VERY_LOW;
      }

      return MEASUREMENT_QUALITY.XLOW;
    },
  },
  PM25: {
    id: "pM2_5",
    key: "pm25",
    label: "PM2.5",
    unit: "μg/m3",
    status: MEASUREMENT_QUALITY.GOOD.id,
    getStatus: (value) => {
      if (value >= 0 && value < 10) {
        return MEASUREMENT_QUALITY.VERY_GOOD;
      }

      if (value >= 10 && value < 20) {
        return MEASUREMENT_QUALITY.GOOD;
      }

      if (value >= 20 && value < 25) {
        return MEASUREMENT_QUALITY.MEDIUM;
      }

      if (value >= 25 && value < 50) {
        return MEASUREMENT_QUALITY.LOW;
      }

      if (value >= 50 && value < 75) {
        return MEASUREMENT_QUALITY.VERY_LOW;
      }

      return MEASUREMENT_QUALITY.XLOW;
    },
  },
  PM10: {
    id: "pM10",
    key: "pm10",
    label: "PM10",
    unit: "μg/m3",
    status: MEASUREMENT_QUALITY.VERY_LOW.id,
    getStatus: (value) => {
      if (value >= 0 && value < 20) {
        return MEASUREMENT_QUALITY.VERY_GOOD;
      }

      if (value >= 20 && value < 40) {
        return MEASUREMENT_QUALITY.GOOD;
      }

      if (value >= 40 && value < 50) {
        return MEASUREMENT_QUALITY.MEDIUM;
      }

      if (value >= 50 && value < 100) {
        return MEASUREMENT_QUALITY.LOW;
      }

      if (value >= 100 && value < 150) {
        return MEASUREMENT_QUALITY.VERY_LOW;
      }

      return MEASUREMENT_QUALITY.XLOW;
    },
  },
};

export const MEASUREMENTS_MAIN = {
  TEMPERATURE: {
    id: "temperature",
    key: "temperature",
    label: "Θερμοκρασία",
    unit: <span>&deg;C</span>,
    icon: "temperature",
  },
  HUMIDITY: {
    id: "hunidity",
    key: "humidity",
    label: "Υγρασία",
    unit: "%",
    icon: "humidity",
  },
  PRESSURE: {
    id: "pressure",
    key: "pressure",
    label: "Πίεση",
    unit: "hPa",
    icon: "pressure",
  },
};
