import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react";
import Icons from "../../assets/icons";
import clsx from "clsx";

const Topbar = ({ icon, iconSize = 40, iconClass, iconColor, title }) => {
  const iconClasses = clsx(["mr-2", iconClass]);
  return (
    <Box
      className="w-full border-b-2 border-gray-300 pb-2 bg-white h-[60px] overflow-x-auto"
      display="flex"
      justifyContent="space-between"
    >
      <div className="mt-2 flex flex-row items-center">
        <Icons
          name={icon}
          height={iconSize}
          width={iconSize}
          className={iconClasses}
          fill={iconColor}
        />
        <Typography
          component="span"
          variant="body1"
          color="primary.main"
          fontSize={20}
          className="inline-block"
        >
          {title}
        </Typography>
      </div>
    </Box>
  );
};

export default observer(Topbar);
