import { Tab, Tabs as MuiTabs } from "@mui/material";
import { useState } from "react";
import TabPanel from "./TabPanel";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { themeColors } from "../../../Config/Themes/theme";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    lang: "el",
  };
}

export default function Tabs({
  tabs,
  children,
  className,
  initialTab,
  onChange,
}) {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const [value, setValue] = useState(initialTab ?? 0);

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
    onChange && onChange(newValue);
  };

  return (
    <>
      <div className="historic-filter">
        {tabs.map(
          ({ historicFilter }, index) =>
            index === value &&
            historicFilter /* Show historicFilter above the tab labels for the currently selected tab */
        )}
      </div>

      <MuiTabs
        value={value}
        textColor="primary"
        indicatorColor="primary"
        className={`${className}`}
        variant="scrollable"
        scrollButtons="auto"
        onChange={handleChange}
        TabIndicatorProps={{
          sx: {
            height: 4,
            background:
              "linear-gradient(90deg, rgba(154,170,251,1) 0%, rgba(21,35,166,1) 100%)",
          },
        }}
        sx={{
          backgroundColor: colors.backgroundBlueAccent.main,
          minHeight: "auto",
          "& button": {
            minWidth: 100,
            maxHeight: 30,
            minHeight: 50,
            fontSize: "0.9rem",
          },
          "& a": {
            minHeight: "auto",
            maxHeight: 30,
            minWidth: 10,
            py: 1.5,
            px: 1,
            mr: 2.25,
            color: "grey.600",
          },
          "& a.Mui-selected": {
            color: "primary.main",
          },
        }}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            component={tab?.to ? Link : null}
            to={tab?.to}
            label={tab?.label}
            icon={tab?.icon}
            iconPosition="start"
            {...a11yProps(index)}
          />
        ))}
      </MuiTabs>

      {tabs.map(({ Component }, index) => (
        <TabPanel value={value} index={index} key={index}>
          <div className="mx-8">{Component}</div>
        </TabPanel>
      ))}
    </>
  );
}
