import { TextField } from "@mui/material";

const TextFieldInput = ({
  value,
  onChange,
  InputProps,
  label,
  type,
  className,
}) => {
  return (
    <TextField
      className={className}
      type={type}
      fullWidth
      label={label}
      value={value}
      onChange={onChange}
      InputProps={InputProps}
    />
  );
};

export default TextFieldInput;
