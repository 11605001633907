import { observer } from "mobx-react";
import { Box, CssBaseline } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import SidebarMenu from "../Components/SidebarMenu/SidebarMenu";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
      width: "100%",
      minHeight: "100vh",
      backgroundColor: theme.palette.background.default,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      // overflow: "auto",
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    }),
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

const AuthenticatedContent = ({ children }) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <SidebarMenu />
      <Main theme={theme}>{children}</Main>
    </Box>
  );
};

export default observer(AuthenticatedContent);
