import React from "react";

import { ReactComponent as FavoriteIcon } from "./icons/appIcons/favorite.svg";
import { ReactComponent as FavoriteSolidIcon } from "./icons/appIcons/favorite_solid.svg";
import { ReactComponent as CloseIcon } from "./icons/appIcons/delete.svg";
import { ReactComponent as ShareIcon } from "./icons/appIcons/share.svg";
import { ReactComponent as CheckmarkIcon } from "./icons/appIcons/checkmark.svg";
import { ReactComponent as ResetIcon } from "./icons/appIcons/reset.svg";
import { ReactComponent as HomeIcon } from "./icons/appIcons/home.svg";
import { ReactComponent as SearchIcon } from "./icons/appIcons/search.svg";
import { ReactComponent as NotificationsIcon } from "./icons/appIcons/notifications.svg";
import { ReactComponent as ProfileIcon } from "./icons/appIcons/profile.svg";
import { ReactComponent as UpArrowIcon } from "./icons/appIcons/up_arrow.svg";
import { ReactComponent as DownArrowIcon } from "./icons/appIcons/down_arrow.svg";
import { ReactComponent as TwitterIcon } from "./icons/appIcons/twitter.svg";
import { ReactComponent as InstagramIcon } from "./icons/appIcons/instagram.svg";
import { ReactComponent as FacebookIcon } from "./icons/appIcons/facebook.svg";
import { ReactComponent as YoutubeIcon } from "./icons/appIcons/youtube.svg";
import { ReactComponent as ExclamationIcon } from "./icons/appIcons/exclamation.svg";
import { ReactComponent as BackArrowIcon } from "./icons/appIcons/back-arrow.svg";
import { ReactComponent as RightArrowIcon } from "./icons/appIcons/right-arrow.svg";
import { ReactComponent as RoundPlusIcon } from "./icons/appIcons/round_plus.svg";
import { ReactComponent as RoundMinusIcon } from "./icons/appIcons/round_minus.svg";
import { ReactComponent as OfflineIcon } from "./icons/appIcons/offline.svg";
import { ReactComponent as DefUserAvatarIcon } from "./icons/appIcons/def_user_avatar.svg";
import { ReactComponent as TrashBinIcon } from "./icons/appIcons/trash-bin.svg";
import { ReactComponent as TrashBinCommercialIcon } from "./icons/trashBinTypes/trash-bin-commercial.svg";
import { ReactComponent as TrashBinCompostIcon } from "./icons/trashBinTypes/trash-bin-compost.svg";
import { ReactComponent as TrashBinRecycleIcon } from "./icons/trashBinTypes/trash-bin-recycle.svg";
import { ReactComponent as FilterIcon } from "./icons/appIcons/filter.svg";
import { ReactComponent as VerticalSeparatorIcon } from "./icons/appIcons/vertical-separator.svg";
import { ReactComponent as ChevronRightIcon } from "./icons/appIcons/chevron-right.svg";
import { ReactComponent as DashBoardIcon } from "./icons/appIcons/dashboard.svg";
import { ReactComponent as TrashBinDeviceIcon } from "./icons/appIcons/trash-bin-device.svg";
import { ReactComponent as GeneralWasteIcon } from "./icons/appIcons/GeneralWaste.svg";
import { ReactComponent as OrganicWasteIcon } from "./icons/appIcons/OrganicWaste.svg";
import { ReactComponent as PlasticIcon } from "./icons/appIcons/Plastic.svg";
import { ReactComponent as RecycleIcon } from "./icons/appIcons/Recycle.svg";
import { ReactComponent as PaperIcon } from "./icons/appIcons/Paper.svg";
import { ReactComponent as ElectronicWasteIcon } from "./icons/appIcons/e-Waste.svg";
import { ReactComponent as GlassIcon } from "./icons/appIcons/Glass.svg";
import { ReactComponent as MetalIcon } from "./icons/appIcons/Metal.svg";
import { ReactComponent as FullIcon } from "./icons/appIcons/Full.svg";
import { ReactComponent as FlippedIcon } from "./icons/appIcons/Flipped.svg";
import { ReactComponent as FireIcon } from "./icons/appIcons/Fire.svg";
import { ReactComponent as NoSignalIcon } from "./icons/appIcons/WifiSlash.svg";
import { ReactComponent as ReportsIcon } from "./icons/appIcons/reports.svg";
import { ReactComponent as ThresholdIcon } from "./icons/appIcons/threshold.svg";
import { ReactComponent as AlertIcon } from "./icons/appIcons/alert.svg";
import { ReactComponent as MapIcon } from "./icons/appIcons/map.svg";
import { ReactComponent as GearIcon } from "./icons/appIcons/gear.svg";
import { ReactComponent as ImportIcon } from "./icons/appIcons/import-icon.svg";
import { ReactComponent as NoDataIcon } from "./icons/appIcons/noData.svg";
import { ReactComponent as LowBatteryIcon } from "./icons/appIcons/lowBattery.svg";
import { ReactComponent as SmartBinsIcon } from "./icons/appIcons/smart-bin.svg";

import { ReactComponent as AnnouncementsIcon } from "./icons/main/announcements.svg";
import { ReactComponent as DashboardIcon } from "./icons/main/dashboard.svg";
import { ReactComponent as EnvironmentIcon } from "./icons/main/environment.svg";
import { ReactComponent as LightsIcon } from "./icons/main/lights.svg";
import { ReactComponent as NotificationsMenuIcon } from "./icons/main/notifications.svg";
import { ReactComponent as ProfileMenuIcon } from "./icons/main/profile.svg";
import { ReactComponent as ReportsMenuIcon } from "./icons/main/reports.svg";
import { ReactComponent as ScreensIcon } from "./icons/main/screens.svg";
import { ReactComponent as WifiIcon } from "./icons/main/wifi.svg";
import { ReactComponent as ClockTimeIcon } from "./icons/wifi/clock-time.svg";
import { ReactComponent as HumanFigureIcon } from "./icons/wifi/human-figure.svg";
import { ReactComponent as NetworkIcon } from "./icons/wifi/network.svg";
import { ReactComponent as PConcentrationIcon } from "./icons/wifi/p-concentration.svg";
import { ReactComponent as ResidenceTimeIcon } from "./icons/wifi/residence-time.svg";
import { ReactComponent as SectionAreaTitleIcon } from "./icons/wifi/section-area-title.svg";
import { ReactComponent as People } from "./icons/main/people-outline.svg";

// Environment
import { ReactComponent as TemperatureIcon } from "./icons/environment/temperature.svg";
import { ReactComponent as PressureIcon } from "./icons/environment/pressure.svg";
import { ReactComponent as HumidityIcon } from "./icons/environment/humidity.svg";
import { ReactComponent as HumidityV2Icon } from "./icons/environment/humidity_v2.svg";
import { ReactComponent as AnalysisIcon } from "./icons/environment/analysis.svg";
import { ReactComponent as ReportsCircleIcon } from "./icons/environment/reports-circle.svg";
import { ReactComponent as HtmlIcon } from "./icons/environment/html.svg";
import { ReactComponent as Moon } from "./icons/environment/moon.svg";
import { ReactComponent as Sun } from "./icons/environment/sun.svg";
import { ReactComponent as StreetLight } from "./icons/environment/street-light.svg";
import { ReactComponent as LocationIcon } from "./icons/environment/location.svg";

const Icons = (props) => {
  const myProps = props;

  const renderIcon = (iconName) => {
    switch (iconName) {
      case "home":
        return <HomeIcon {...myProps} />;
      case "favorite":
        return <FavoriteIcon {...myProps} />;
      case "favoriteSolid":
        return <FavoriteSolidIcon {...myProps} />;
      case "close":
        return <CloseIcon {...myProps} />;
      case "share":
        return <ShareIcon {...myProps} />;
      case "checkmark":
        return <CheckmarkIcon {...myProps} />;
      case "reset":
        return <ResetIcon {...myProps} />;
      case "search":
        return <SearchIcon {...myProps} />;
      case "notifications":
        return <NotificationsIcon {...myProps} />;
      case "profile":
        return <ProfileIcon {...myProps} />;
      case "upArrow":
        return <UpArrowIcon {...myProps} />;
      case "downArrow":
        return <DownArrowIcon {...myProps} />;
      case "twitter":
        return <TwitterIcon {...myProps} />;
      case "instagram":
        return <InstagramIcon {...myProps} />;
      case "facebook":
        return <FacebookIcon {...myProps} />;
      case "youtube":
        return <YoutubeIcon {...myProps} />;
      case "exclamation":
        return <ExclamationIcon {...myProps} />;
      case "backArrow":
        return <BackArrowIcon {...myProps} />;
      case "rightArrow":
        return <RightArrowIcon {...myProps} />;
      case "roundPlus":
        return <RoundPlusIcon {...myProps} />;
      case "roundMinus":
        return <RoundMinusIcon {...myProps} />;
      case "offline":
        return <OfflineIcon {...myProps} />;
      case "defUserAvatar":
        return <DefUserAvatarIcon {...myProps} />;
      case "trashBin":
        return <TrashBinIcon {...myProps} />;
      case "trashBinCommercial":
        return <TrashBinCommercialIcon {...myProps} />;
      case "trashBinCompost":
        return <TrashBinCompostIcon {...myProps} />;
      case "trashBinRecycle":
        return <TrashBinRecycleIcon {...myProps} />;
      case "filter":
        return <FilterIcon {...myProps} />;
      case "verticalSeparator":
        return <VerticalSeparatorIcon {...myProps} />;
      case "chevronRight":
        return <ChevronRightIcon {...myProps} />;
      case "dashBoard":
        return <DashBoardIcon {...myProps} />;
      case "trashBinDevice":
        return <TrashBinDeviceIcon {...myProps} />;
      case "generalWaste":
        return <GeneralWasteIcon {...myProps} />;
      case "organicWaste":
        return <OrganicWasteIcon {...myProps} />;
      case "plastic":
        return <PlasticIcon {...myProps} />;
      case "recycle":
        return <RecycleIcon {...myProps} />;
      case "paper":
        return <PaperIcon {...myProps} />;
      case "e-Waste":
        return <ElectronicWasteIcon {...myProps} />;
      case "glass":
        return <GlassIcon {...myProps} />;
      case "metal":
        return <MetalIcon {...myProps} />;
      case "full":
        return <FullIcon {...myProps} />;
      case "flipped":
        return <FlippedIcon {...myProps} />;
      case "fire":
        return <FireIcon {...myProps} />;
      case "noSignal":
        return <NoSignalIcon {...myProps} />;
      case "reports":
        return <ReportsIcon {...myProps} />;
      case "threshold":
        return <ThresholdIcon {...myProps} />;
      case "alert":
        return <AlertIcon {...myProps} />;
      case "map":
        return <MapIcon {...myProps} />;
      case "gear":
        return <GearIcon {...myProps} />;
      case "importIcon":
        return <ImportIcon {...myProps} />;
      case "noDataIcon":
        return <NoDataIcon {...myProps} />;
      case "lowBatteryIcon":
        return <LowBatteryIcon {...myProps} />;
      case "smart-bin":
        return <SmartBinsIcon {...myProps} />;
      case "announcements":
        return <AnnouncementsIcon {...myProps} />;
      case "dashboard":
        return <DashboardIcon {...myProps} />;
      case "environment":
        return <EnvironmentIcon {...myProps} />;
      case "lights":
        return <LightsIcon {...myProps} />;
      case "notifications-main":
        return <NotificationsMenuIcon {...myProps} />;
      case "profile-main":
        return <ProfileMenuIcon {...myProps} />;
      case "reports-main":
        return <ReportsMenuIcon {...myProps} />;
      case "screens":
        return <ScreensIcon {...myProps} />;
      case "wifi":
        return <WifiIcon {...myProps} />;
      case "clock-time":
        return <ClockTimeIcon {...myProps} />;
      case "human-figure":
        return <HumanFigureIcon {...myProps} />;
      case "network":
        return <NetworkIcon {...myProps} />;
      case "p-concentration":
        return <PConcentrationIcon {...myProps} />;
      case "residence-time":
        return <ResidenceTimeIcon {...myProps} />;
      case "section-area-title":
        return <SectionAreaTitleIcon {...myProps} />;
      case "temperature":
        return <TemperatureIcon {...myProps} />;
      case "pressure":
        return <PressureIcon {...myProps} />;
      case "humidity":
        return <HumidityIcon {...myProps} />;
      case "humidityV2":
        return <HumidityV2Icon {...myProps} />;
      case "analysis":
        return <AnalysisIcon {...myProps} />;
      case "reports-circle":
        return <ReportsCircleIcon {...myProps} />;
      case "html":
        return <HtmlIcon {...myProps} />;
      case "moon":
        return <Moon {...myProps} />;
      case "sun":
        return <Sun {...myProps} />;
      case "street-light":
        return <StreetLight {...myProps} />;
      case "location":
        return <LocationIcon {...myProps} />;
      case "people":
        return <People {...myProps} />;
      default:
        return <HomeIcon {...myProps} />;
    }
  };

  return <>{renderIcon(myProps.name)}</>;
};

export default Icons;
