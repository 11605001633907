export const Options = (maxBarValue) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    events: [],
    layout: {
      padding: {
        right: 0,
        top: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "ΕΠΙΣΚΕΠΤΕΣ",
        padding: {
          bottom: 28,
        },
        align: "start",
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: false,
        // color: function (ctx) {
        //   return ctx.dataset.backgroundColor;
        // },
        // align: "320",
        // anchor: "end",
        // font: {
        //   size: 15,
        // },
        // formatter: function (value) {
        //   /*if(value < 10) return "VISITORS\n       " + value;
        //   if(value < 999) return "VISITORS\n      " + value;
        //   if(value >= 1000) return "VISITORS\n     " + value;*/

        //   return value;
        // },
      },
    },
    scales: {
      x: {
        ticks: {
          display: true,
          font: {
            size: 12,
          },
          callback: function (value, index, values) {
            const chart = this.chart;
            const ctx = chart.ctx;

            return wrapText(ctx, this.getLabelForValue(value), 140);
          },
        },
        grid: {
          drawBorder: false,
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        ticks: {
          display: false,
          beginAtZero: true,
        },
        grid: {
          drawBorder: false,
          display: false,
        },
        border: {
          display: false,
        },
      },
    },
  };
};

export const Data = (data, colors) => {
  const sortedData = data?.sort(
    (a, b) => b.highestVisitors - a.highestVisitors
  );
  return {
    labels: sortedData?.map((d) => d.locationName) || [],
    datasets: [
      {
        barPercentage: 0.5,
        categoryPercentage: 1,
        label: ">",
        data: sortedData?.map((d) => d.highestVisitors) || [],
        borderColor: colors,
        backgroundColor: colors,
        barThickness: 50,
      },
    ],
  };
};

export const personIconNextToBar = {
  id: "centerImageDoughnut",
  afterDraw: (chart, args, pluginOptions) => {
    const { ctx } = chart;

    const svgString =
      '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="25" viewBox="0 0 18 56"><path id="Color_Overlay" data-name="Color Overlay" d="M305.894,538a5.568,5.568,0,0,1-5.7-5.277V519.246A7.867,7.867,0,0,1,297,513.054v-11.17c0-4.5,4.05-8.182,9-8.182s9,3.682,9,8.182v11.17a7.867,7.867,0,0,1-3.194,6.192v13.477a5.567,5.567,0,0,1-5.7,5.277Zm-5.738-50.686A5.871,5.871,0,1,1,306,492.627,5.6,5.6,0,0,1,300.155,487.314Z" transform="translate(-297 -482)" fill="#d4d4d4"/></svg>';
    const img = new Image();
    img.src = "data:image/svg+xml," + encodeURIComponent(svgString);

    let chartDataset = chart.getDatasetMeta(0).data;

    img.onload = () => {
      if (chartDataset.length > 0) {
        const firstElement = chartDataset[0];
        ctx.drawImage(img, firstElement.x - 40, firstElement.base - 30);
      }
    };

    ctx.textAlign = "center";
    for (const element of chartDataset) {
      ctx.font = "600 18pt Open Sans";
      ctx.fillStyle = element.options.backgroundColor;
      ctx.fillText(element.$context.raw, element.x, element.y - 5);
    }
  },
};

function wrapText(ctx, text, maxWidth) {
  const words = text.split(" ");
  let lines = [];
  let currentLine = words[0];

  for (let i = 1; i < words.length; i++) {
    const word = words[i];
    const width = ctx.measureText(currentLine + " " + word).width;
    if (width < maxWidth) {
      currentLine += " " + word;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  }
  lines.push(currentLine);
  return lines;
}

export default {
  Options,
  Data,
  personIconNextToBar,
};
