import * as React from "react";

const VisitRateSvg = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ??"15.182" }
      height={props.size ??"15.182" }
      viewBox="0 0 15.182 15.182"
    >
      <path
        id="Shape_343"
        data-name="Shape 343"
        d="M773.539,91.623l2.049-4.934a2.865,2.865,0,0,0,.76.124h.147a2.77,2.77,0,0,0,.742-.124l2.042,4.934ZM777.7,86.5a2.751,2.751,0,0,0,1.172-1.169l4.919,2.052-4.048,4.049Zm-8.692.873,4.929-2.042a2.745,2.745,0,0,0,1.162,1.159l-2.042,4.932Zm10.056-2.51a2.819,2.819,0,0,0,0-1.655L784,81.162V86.9Zm-10.25-3.7,4.931,2.042a2.839,2.839,0,0,0,0,1.655L768.818,86.9Zm.2-.478,4.049-4.049,2.042,4.929a2.772,2.772,0,0,0-1.172,1.172Zm4.517-4.243h5.739l-2.039,4.932a2.726,2.726,0,0,0-.76-.124h-.157a2.732,2.732,0,0,0-.742.127Z"
        transform="translate(-768.818 -76.441)"
        fill={props.color ?? "#545a86"}
      />
    </svg>
  );
};

export default VisitRateSvg;
