import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { themeColors } from "../../../Config/Themes/theme";

const ScreenBlock = () => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);

  return (
    <div className="flex justify-between gap-4">
      <div className="flex flex-col justify-end gap-2">
        <Typography color={colors.primary.main}>Οθόνες</Typography>
        <Typography color={colors.primary.main}>Ενεργές οθόνες</Typography>
      </div>
      <div className="flex flex-col justify-end gap-2">
        <Typography color={colors.primary.main}>2</Typography>
        <Typography color={colors.primary.main}>2</Typography>
      </div>
    </div>
  );
};

export default ScreenBlock;
