import React, { FC } from "react";
import Icons from "../../../../assets/icons";
import { useTheme } from "@mui/material";
import { themeColors } from "../../../../Config/Themes/theme";

interface SectionTitleProps {
  roundIcon?: boolean;
  icon?: string;
  iconColor?: string;
  iconWidth?: number;
  iconHeight?: number;
  title: string;
  titleColor?: string;
  titleSize?: number;
}

const SectionTitle: FC<SectionTitleProps> = ({
  roundIcon = false,
  icon,
  iconColor = themeColors("light").whiteAccent.main,
  iconWidth = 21,
  iconHeight = 21,
  title,
  titleColor = themeColors("light").greyAccent.dark4,
  titleSize = 14,
}) => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);

  return (
    <div className="flex items-center justify-start mb-2 w-full">
      {icon ? (
        <div
          className="flex items-center justify-center mr-2"
          style={
            roundIcon
              ? {
                  width: 27,
                  height: 27,
                  backgroundColor: colors.cyanAccent.light,
                  borderRadius: "50%",
                }
              : {}
          }
        >
          <Icons
            name={icon}
            width={iconWidth}
            height={iconHeight}
            fill={iconColor}
          />
        </div>
      ) : null}
      <div className="flex items-center justify-start flex-1">
        <div
          className="uppercase font-semibold"
          style={{ color: titleColor, fontSize: `${titleSize}px` }}
        >
          {title}
        </div>
      </div>
    </div>
  );
};

export default SectionTitle;
