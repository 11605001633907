import { InfoOutlined } from "@mui/icons-material";
import { Button, Tooltip, Typography, useTheme } from "@mui/material";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";

const DashboardSection = ({
  className,
  children,
  title,
  tooltipText,
  onButtonClick = null,
  sectionClassName,
}) => {
  const theme = useTheme();
  const classes = clsx(["py-5 px-5", className]);
  const sectionClasses = twMerge(
    clsx(["rounded-md bg-white mb-10 w-full", sectionClassName])
  );

  return (
    <section className={sectionClasses}>
      <div className="flex flex-col xs:flex-row justify-between items-start gap-4">
        <div
          className="rounded-tl-md pl-3 inline-block items-center h-[21px]"
          style={{
            backgroundColor: theme?.palette?.background.dashboardSection,
            color: "white",
          }}
        >
          <Typography
            className="inline-block mr-5 font-semibold"
            variant="h2"
            fontSize={12}
            sx={{
              textTransform: "uppercase",
            }}
          >
            <span lang="el">{title}</span>
          </Typography>
          {!!tooltipText && (
            <Tooltip
              className="mr-3"
              placement="right-start"
              title={<Typography fontSize={14}>{tooltipText}</Typography>}
            >
              <InfoOutlined style={{ fontSize: "17px", marginBottom: "2px" }} />
            </Tooltip>
          )}
        </div>
        {!!onButtonClick && (
          <div className="flex ml-2 xs:ml-0 justify-end">
            <Button
              className="font-bold px-2 py-0 rounded-md mt-2 mr-4"
              onClick={onButtonClick}
              sx={{
                color: theme?.palette?.background.dashboardSection,
                backgroundColor: "white",
                border: `1px solid ${theme?.palette?.background.dashboardSection}`,
                textTransform: "none",
                "&:hover": {
                  backgroundColor: theme?.palette?.background.dashboardSection,
                  color: "white",
                },
              }}
            >
              Πρόσβαση
            </Button>
          </div>
        )}
      </div>
      <div className={classes}>{children}</div>
    </section>
  );
};

export default DashboardSection;
