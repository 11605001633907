// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-datepicker-wrapper .react-datepicker__input-container input {
    border: 1px solid grey;
    padding: 0.5rem;
    border-radius: 5px;
    /* min-width: 241px; */
}

.react-datepicker__header {
    background-color: #22b8b5;
}`, "",{"version":3,"sources":["webpack://./src/Saas/SmartBins/Components/MyDatePicker/MyDatePicker.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,eAAe;IACf,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".react-datepicker-wrapper .react-datepicker__input-container input {\n    border: 1px solid grey;\n    padding: 0.5rem;\n    border-radius: 5px;\n    /* min-width: 241px; */\n}\n\n.react-datepicker__header {\n    background-color: #22b8b5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
