import { CreateDoughnutCenter, CreatePerson } from "../../Utils/GraphHelper";

export const Options = () => {
  return {
    maintainAspectRatio: false,
    events: [],
    layout: {
      padding: {
        right: 40,
        bottom: 25,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: {
          useBorderRadius: true,
          padding: 20,
        },
      },
      title: {
        display: true,
      },
      datalabels: {
        display: true,
        color: "#6a6b6d",
        align: "end",
        offset: 12,
        font: {
          size: 14,
          weight: "600",
        },
        formatter: function (value) {
          if (value === 0) {
            return "";
          }
          return value + "%";
        },
      },
    },
  };
};

export const Data = (data, colors) => {
  return {
    labels: ["<5 λεπτά", "5-10 λεπτά", "10-20 λεπτά", ">20 λεπτά"],
    datasets: [
      {
        label: "",
        data: data
          ? [
              data.lessThan5Percentage,
              data.between5And10Percentage,
              data.between10And20Percentage,
              data.moreThan20Percentage,
            ]
          : [],
        borderColor: colors,
        backgroundColor: colors,
        cutout: 45,
        rotation: 100,
      },
    ],
  };
};

export const centerImageDoughnut = {
  id: "centerImageDoughnut",
  afterDatasetsDraw: (chart, args, pluginOptions) => {
    const { ctx } = chart;
    const x = chart.getDatasetMeta(0).data[0]?.x || 0;
    const y = chart.getDatasetMeta(0).data[0]?.y || 0;

    CreatePerson(ctx, x - 12, y - 15, "#2c9dff");

    const clockSvg =
      '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 27.984 28.019"><path id="clock" d="M302.982,795a.667.667,0,0,1,.02-1.334,12.675,12.675,0,0,0,0-25.35h-.02a.667.667,0,0,1,.02-1.335A14.009,14.009,0,0,1,303,795Zm-3.6-.476a.666.666,0,1,1,.173.023A.667.667,0,0,1,299.381,794.527Zm-3.374-1.4a.666.666,0,1,1,.332.089A.667.667,0,0,1,296.007,793.128Zm-4.331-12.133A11.326,11.326,0,1,1,303,792.334,11.342,11.342,0,0,1,291.677,780.995Zm1.33,0A9.994,9.994,0,1,0,303,770.989,9.99,9.99,0,0,0,293.007,780.995Zm.1,9.906a.666.666,0,1,1,.471.195A.669.669,0,0,1,293.108,790.9Zm-2.223-2.9a.665.665,0,1,1,.577.333A.668.668,0,0,1,290.885,788Zm15.642-2.531-4-4a.67.67,0,0,1-.2-.471v-8.007a.666.666,0,0,1,.656-.674h.018a.667.667,0,0,1,.659.674v7.729l3.8,3.809,0,0a.667.667,0,1,1-.947.94Zm-17.041-.849a.665.665,0,1,1,.643.495A.668.668,0,0,1,289.487,784.62Zm-.476-3.626a.666.666,0,1,1,.666.667A.666.666,0,0,1,289.011,780.994Zm.948-2.809a.666.666,0,1,1,.173.023A.668.668,0,0,1,289.958,778.185Zm1.171-3.285a.665.665,0,1,1,.332.09A.668.668,0,0,1,291.129,774.9Zm1.979-2.869a.667.667,0,1,1,.471.2A.668.668,0,0,1,293.108,772.031Zm2.655-2.259a.665.665,0,1,1,.577.334A.666.666,0,0,1,295.763,769.772Zm3.147-1.494a.665.665,0,1,1,.643.495A.667.667,0,0,1,298.911,768.278Z" transform="translate(-289.011 -766.984)" fill="#2c9dff"/></svg>';
    const clockImg = new Image();
    clockImg.src = "data:image/svg+xml," + encodeURIComponent(clockSvg);
    clockImg.onload = () => {
      ctx.drawImage(clockImg, x, y - 17);
    };

    const averageSvg =
      '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 23 23.881"><path id="Shape_315" data-name="Shape 315" d="M673,764.99a1,1,0,0,1-1-1V752.06a1,1,0,0,1,1-1h5a1,1,0,0,1,1,1v11.93a1,1,0,0,1-1,1Zm-8,0a1,1,0,0,1-1-1V752.06a1,1,0,0,1,1-1h5a1,1,0,0,1,1,1v11.93a1,1,0,0,1-1,1Zm-8,0a1,1,0,0,1-1-1V752.06a1,1,0,0,1,1-1h5a1,1,0,0,1,1,1v11.93a1,1,0,0,1-1,1Zm15.215-16.336a.992.992,0,0,1,.225-1.39l1.56-1.184H660a1,1,0,1,1,0-1.991h14l-1.6-1.184a.992.992,0,0,1-.21-1.392,1,1,0,0,1,1.4-.21l4,2.985a.986.986,0,0,1,.01,1.592l-4,2.985a.973.973,0,0,1-1.384-.212Z" transform="translate(-656 -741.109)" fill="#2c9dff"/></svg>';
    const averageImg = new Image();
    averageImg.src = "data:image/svg+xml," + encodeURIComponent(averageSvg);
    averageImg.onload = () => {
      ctx.drawImage(averageImg, x, y);
    };

    CreateDoughnutCenter(ctx, x, y);
  },
};

export default {
  Options,
  Data,
  centerImageDoughnut,
};
