import Breadcrumb from "../../../../Components/Breadcrumb/Breadcrumb";
import PageTitle from "../../../../Components/PageTitle";
import TotalConsumptionSection from "./TotalConsumptionSection";
import DailyConsumptionSection from "./DailyConsumptionSection";
import DashboardSection from "../../../../Components/DashboardSection/DashboardSection";
import ShadowBox from "../../../../Components/ShadowBox/ShadowBox";
import { useState, useEffect } from "react";
import LightningIcon from "../../../../assets/map_icons/lightMarker.svg";
import LightsAgent from "../../Api/LightsAgent/LightsAgent";
import LightsMap from "../../Components/LightsMap/LightsMap";
import MapCard from "../../Components/LightsMap/MapCard";

const Analysis = () => {
  const [selectedMarker, setSelectedMarker] = useState();
  const [devices, setDevices] = useState([]);

  useEffect(() => {
    const fetchDevices = async () => {
      await LightsAgent.analysis
        .getDevices()
        .then((response) => {
          setDevices(response?.result?.lightDevices);
        })
        .catch((error) => console.error(error));
    };

    fetchDevices();
  }, []);

  const markers = devices?.map((item) => ({
    ...item,
    icon: LightningIcon,
    statusDescription: item.lightStatusDescription,
    deviceName: item.deviceName,
    lng: item.lon,
    onMarkerClick: () => setSelectedMarker(item),
  }));

  const findCenter = (markers) => {
    if (markers?.length === 0 || !markers) {
      return {
        lat: 37.98381,
        lng: 23.727539,
      };
    }
    let minLat = markers[0]?.lat;
    let maxLat = markers[0]?.lat;
    let minLng = markers[0]?.lng;
    let maxLng = markers[0]?.lng;

    markers.forEach((marker) => {
      if (marker?.lat < minLat) {
        minLat = marker?.lat;
      }
      if (marker?.lat > maxLat) {
        maxLat = marker?.lat;
      }
      if (marker?.lng < minLng) {
        minLng = marker?.lng;
      }
      if (marker?.lng > maxLng) {
        maxLng = marker?.lng;
      }
    });
    return {
      lat: (minLat + maxLat) / 2,
      lng: (minLng + maxLng) / 2,
    };
  };

  return (
    <section className="grid grid-cols-12">
      <section className="col-span-7 mr-5">
        <Breadcrumb pathList={["Οδοφωτισμός", "Ανάλυση"]} />
        <PageTitle
          className="pt-2 pb-10"
          title="Ανάλυση τρέχουσας κατάστασης"
        />
        <DashboardSection title="ΓΕΝΙΚΕΣ ΠΛΗΡΟΦΟΡΙΕΣ">
          <TotalConsumptionSection />
        </DashboardSection>
        <DashboardSection title="ΗΜΕΡΗΣΙΑ ΣΥΝΟΛΙΚΗ ΚΑΤΑΝΑΛΩΣΗ">
          <div
            style={{
              width: "100%",
              height: "calc(100% - 250px)",
            }}
          >
            <ShadowBox>
              <DailyConsumptionSection className="w-full h-[250px]" />
            </ShadowBox>
          </div>
        </DashboardSection>
      </section>
      <LightsMap
        markers={markers}
        center={findCenter(markers)}
        infoWindowRenderer={(item) => <MapCard data={item} />}
        initialZoom={15}
        className="sticky col-span-5 h-screen top-0"
        selectedMarker={selectedMarker}
        setSelectedMarker={setSelectedMarker}
      />
    </section>
  );
};

export default Analysis;
