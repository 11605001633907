import { requests } from "../Utils/HttpClient/HttpClient";

const { post, get, patch, put, delete: deleteRequest } = requests;

/**************************************** User Endpoints ****************************************/
const user = {
  login: (email, password) => post("/Auth/login", { email, password }),
  getProfile: () => get("/Auth/profile"),
  updateProfile: (phoneNumber) =>
    patch("/Auth/profile", { phoneNumber: `${phoneNumber}` }),
  changePassword: (currentPassword, newPassword, confirmNewPassword) =>
    patch("/Auth/updatePassword", {
      currentPassword,
      newPassword,
      confirmNewPassword,
    }),
  forgotPassword: (email) =>
    post("/Auth/forgotPassword", { email: `${email}` }),
  resetPassword: (data, token) =>
    post("/Auth/resetPassword", { ...data, token }),
  refreshToken: (body) => post("/Auth/refreshToken", body),
};

/**************************************** TrashBinDevices Endpoints ****************************************/
const trashBinDevices = {
  getTrashBinDevices: () => get("/DeviceBin"),
  getTrashBinDeviceById: (trashBinDeviceId) =>
    get(`/DeviceBin/${trashBinDeviceId}`),
  getTrashBinDevicesTypes: () => get("/DeviceBin/Types"),
  createTrashBinDevice: (body) => post("/DeviceBin", body),
  updateTrashBinDevices: (trashBinId, body) =>
    put(`/DeviceBin/${trashBinId}`, body),
  updateTrashBinDeviceStatus: (trashBinDeviceId, status) =>
    put(`/DeviceBin/${trashBinDeviceId}/update-status`, status),
  dowloadSample: () => get("/DeviceBin/download-sample"),
  importDeviceBins: (formData) => post("/DeviceBin/ImportDeviceBins", formData),
};

/**************************************** TrashBin Endpoints ****************************************/
const trashBins = {
  getTrashBinsEvents: () => get("/TrashBinEvents/LastEvents"),
  getTrashBinsEventsForDate: (fromDate) =>
    get(`/TrashBinEvents/GetForDate?eventDate=${fromDate}`),
  getTrashBinsEventsFromDateToDate: (fromDate, toDate) =>
    get(`/TrashBinEvents/GetFromDateToDate?from=${fromDate}&to=${toDate}`),
  getTrashBins: () => get("/TrashBin"),
  getTrashBinEventsById: (trashBinEventsId) =>
    get(`/TrashBinEvents/LastEventByTrashBinId/${trashBinEventsId}`),
  getTrashBinById: (trashBinId) => get(`/TrashBin/${trashBinId}`),
  getTrashBinTypes: () => get("/TrashBin/Types"),
  createTrashBin: (body) => post("/TrashBin", body),
  activateTrashBin: (trashBinId) => post(`/TrashBin/${trashBinId}/activate`),
  deactivateTrashBin: (trashBinId) =>
    post(`/TrashBin/${trashBinId}/deactivate`),
  updateTrashBins: (trashBinId, body) => put(`/TrashBin/${trashBinId}`, body),
  dowloadSample: () => get("/TrashBin/download-sample"),
  importTrashBins: (formData) => post("/TrashBin/ImportTrashBins", formData),
};

/**************************************** Users Endpoints ****************************************/
const users = {
  getUsers: () => get("/User/GetMyUsers"),
  getUserById: (userId) => get(`/User/${userId}`),
  createUser: (body) => post("/User/CreateUser", body),
  activateUser: (userId) => post(`/User/${userId}/activate`),
  deactivateUser: (userId) => post(`/User/${userId}/deactivate`),
  updateUser: (userId, body) => put(`/User/UpdateUser/${userId}`, body),
};

const claims = {
  getAllClaims: () => get("/AuthSetup/GetAllClaims"),
  getAllClaimsByRole: (roleId) =>
    get(`/AuthSetup/GetAllRoleClaims?roleId=${roleId}`),
  getUserClaims: (id) => get(`/AuthSetup/GetUserClaims?userId=${id}`),
  addClaimToUser: (body) => post("/AuthSetup/AddClaimToUser", body),
  deleteClaimFromUser: (body) => post("/AuthSetup/RemoveClaimFromUser", body),
  getUserRoles: (userId) => get(`/AuthSetup/GetUserRoles?userId=${userId}`),
};

const thresholds = {
  getThresholds: () => get("/Threshold"),
  updateThreshold: (body) => put("/Threshold", body),
};

const alerts = {
  getAlerts: () => get("/TimeLocationBinAlerts"),
  getActiveAlerts: () => get("/TimeLocationBinAlerts/Active"),
  deactivateAlert: (alertId, isActive) =>
    post(`/TimeLocationBinAlerts/${alertId}/deactivate`, isActive),
};

const textualInformation = {
  getTextualInformationFromDateToDate: (fromDate, toDate) =>
    get(`/TextualInformation?from=${fromDate}&to=${toDate}`),
};

const announcement = {
  getAnnouncements: (
    PageNumber,
    PageSize,
    key,
    OrderByDateAsc,
    OrderByDateDesc,
    OrderByIdAsc,
    OrderByIdDesc,
    OrderByStatusAsc,
    OrderByStatusDesc
  ) =>
    get(
      `/Announcement?Key=${key}&OrderByDateAsc=${OrderByDateAsc}&OrderByDateDesc=${OrderByDateDesc}&OrderByIdAsc=${OrderByIdAsc}&OrderByIdDesc=${OrderByIdDesc}&OrderByStatusAsc=${OrderByStatusAsc}&OrderByStatusDesc=${OrderByStatusDesc}&PageNumber=${PageNumber}&PageSize=${PageSize}`
    ).then((resp) => {
      const pagination = JSON.parse(resp?.headers?.pagination);
      return { pagination, data: resp?.data };
    }),
  getAnnouncementById: (id) => get(`/Announcement/${id}`),
  createAnnouncement: (body) => post("/Announcement", body),
  updateAnnouncement: (id, body) => put(`/Announcement?id=${id}`, body),
  deleteAnnouncement: (id) => deleteRequest(`/Announcement?id=${id}`),
};

const organizationPublic = {
  getOrganizations: () => get("/Organization/public"),
};

const organization = {
  getOrganizationById: (id) => get(`/Organization/${id}`),
};

export default {
  user,
  trashBinDevices,
  trashBins,
  users,
  claims,
  thresholds,
  alerts,
  textualInformation,
  announcement,
  organizationPublic,
  organization,
};
