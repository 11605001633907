import React, { useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  Decimation,
  registerables,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { observer } from "mobx-react";
import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Decimation,
  ...registerables
);

const BarChart = ({ data, title = "", options, setChartInstance }) => {
  const ref = useRef();
  const initOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: !!title,
        text: title,
      },
    },
  };

  useEffect(() => {
    setChartInstance && setChartInstance(ref?.current);
  }, [setChartInstance]);

  return (
    <Bar ref={ref} options={options || initOptions} data={data} height="100%" />
  );
};

export default observer(BarChart);
