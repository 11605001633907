import { observer } from "mobx-react";
import AnnouncementsList from "./AnnouncementsList";
import Tabs from "../../../Components/Theme/Tabs/Tabs";
import Topbar from "../../../Components/Topbar/Topbar";
import { useTheme } from "@mui/material/styles";
import { themeColors } from "../../../Config/Themes/theme";

const Announcements = () => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);

  const tabs = [
    {
      label: "ΛΙΣΤΑ ΑΝΑΚΟΙΝΩΣΕΩΝ",
      Component: <AnnouncementsList />,
    },
  ];

  return (
    <div
      style={{
        backgroundColor: colors.backgroundPrimary.main,
      }}
    >
      <Topbar icon="announcements" title="Ανακοινώσεις" />
      <Tabs tabs={tabs} />
    </div>
  );
};

export default observer(Announcements);
