import { requests } from "../../../../Utils/HttpClient/HttpClient";

const { post, get, getFile, put, delete: deleteRequest } = requests;

const lightsAlert = {
  getLightsAlerts: () => get("/LightsAlert"),
};

const announcement = {
  getAnnouncements: () => get("/Announcement"),
  getAnnouncementById: (id) => get(`/Announcement/${id}`),
  createAnnouncement: (body) => post("/Announcement", body),
  updateAnnouncement: (id, body) => put(` /Announcement?id=${id}`, body),
  deleteAnnouncement: (id) => deleteRequest(`/Announcement?id=${id}`),
};

const analysis = {
  getDevices: () => get("/Light/GetDevices"),
  getAlerts: () => get("/Light/GetAlerts"),
  getDailyData: () => get("/Light/DailyData"),
};

const reports = {
  getReportData: (startDate, endDate) =>
    get(
      "/Light/GetLightDataFromDateToDate?from=" + startDate + "&to=" + endDate
    ),
  exportExcel: async ({ id, from, to, fileName }) => {
    await getFile(
      `/Light/ExcelReport?id=${id}&from=${from}&to=${to}`,
      "blob"
    ).then((response) => {
      const url = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}.xlsx`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  },
};

export default { lightsAlert, announcement, analysis, reports };
