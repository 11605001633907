import { Route, Routes } from "react-router-dom";
import { observer } from "mobx-react";
import Loadable from "../Components/Theme/Loadable";
import { lazy } from "react";
import Reports from "../Pages/Reports/Reports";
import MapReport from "../Pages/FullnessHistory/MapReport.js";
import FullnessHistory from "../Pages/FullnessHistory/FullnessHistory";
import TableReport from "../Pages/FullnessHistory/TableReport";
import PeriodicFullnessChart from "../Pages/Reports/PeriodicFullnessChart";
import CumulativeMaxChart from "../Pages/Reports/CumulativeMaxChart";

import { useEffect } from "react";
import { alertStore } from "../Stores/AlertStore";
import SmartBinsPage from "../MainAppComponents/SmartBinsPage/SmartBinsPage";

const Map = Loadable(lazy(() => import("../Pages/Map/Map")));

const TrashBinsList = Loadable(
  lazy(() => import("../Pages/TrashBins/TrashBinsList"))
);
const TrashBinCreate = Loadable(
  lazy(() => import("../Pages/TrashBins/TrashBinsCreate"))
);

const TrashBinEdit = Loadable(
  lazy(() => import("../Pages/TrashBins/TrashBinsEdit"))
);
const TrashBinDetailTabs = Loadable(
  lazy(() => import("../Pages/TrashBins/TrashBinsDetailsTabs"))
);

const TrashBinsDevicesList = Loadable(
  lazy(() => import("../Pages/TrashBinsDevices/TrashBinsDevicesList"))
);
const TrashBinDevicesCreate = Loadable(
  lazy(() => import("../Pages/TrashBinsDevices/TrashBinsDevicesCreate"))
);

const TrashBinDevicesEdit = Loadable(
  lazy(() => import("../Pages/TrashBinsDevices/TrashBinsDevicesEdit"))
);
const TrashBinDevicesDetailTabs = Loadable(
  lazy(() => import("../Pages/TrashBinsDevices/TrashBinsDevicesDetailsTabs"))
);

const Threshold = Loadable(
  lazy(() => import("../Pages/Threshold/ThresholdPage"))
);

const Alert = Loadable(lazy(() => import("../Pages/Alert/AlertList")));

// Wraps all pages which share common layout
//
const wrap = (element, pageProps) => (
  <SmartBinsPage {...pageProps}>{element}</SmartBinsPage>
);

const SmartBinRoutes = () => {
  useEffect(() => {
    alertStore.initHubConnection();

    return () => {
      alertStore.connection.stop();
      alertStore.clearAlerts();
    };
  }, []);

  return (
    <Routes>
      <Route index element={wrap(<Map />)} />

      <Route path="trashBin" element={wrap(<TrashBinsList />)} />
      <Route
        path="trashBin/create"
        element={wrap(<TrashBinCreate />, { showTabs: false })}
      />
      <Route
        path="trashBin/:id/edit"
        element={wrap(<TrashBinEdit />, { showTabs: false })}
      />
      <Route
        path="trashBin/:id"
        element={wrap(<TrashBinDetailTabs />, { showTabs: false })}
      />

      <Route path="trashBinDevice" element={wrap(<TrashBinsDevicesList />)} />
      <Route
        path="trashBinDevice/create"
        element={wrap(<TrashBinDevicesCreate />, { showTabs: false })}
      />
      <Route
        path="trashBinDevice/:id/edit"
        element={wrap(<TrashBinDevicesEdit />, { showTabs: false })}
      />
      <Route
        path="trashBinDevice/:id"
        element={wrap(<TrashBinDevicesDetailTabs />, { showTabs: false })}
      />

      <Route path="threshold" element={wrap(<Threshold />)} />
      <Route path="alert" element={wrap(<Alert />)} />

      <Route path="reports" element={wrap(<Reports />)} />
      <Route
        path="reports/periodicFullnessChart"
        element={wrap(<PeriodicFullnessChart />)}
      />
      <Route
        path="reports/cumulativeMaxChart"
        element={wrap(<CumulativeMaxChart />)}
      />

      <Route path="fullnessHistory" element={wrap(<FullnessHistory />)} />
      <Route path="fullnessHistory/mapReport" element={wrap(<MapReport />)} />
      <Route
        path="fullnessHistory/tableReport"
        element={wrap(<TableReport />)}
      />
    </Routes>
  );
};
export default observer(SmartBinRoutes);
