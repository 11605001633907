import { CreateDoughnutCenter, CreatePerson } from "../../Utils/GraphHelper";

export const OptionsHalfDoughnut = () => {
  return {
    maintainAspectRatio: false,
    events: [],
    layout: {
      padding: {
        bottom: 16,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "right",
        labels: {
          padding: 20,
        },
      },
      title: {
        display: true,
      },
      datalabels: {
        display: true,
        color: "#6a6b6d",
        align: function (context) {
          let secondLabel = "end";
          let thirdLabel = "end";
          for (let i = 0; i < context.dataset.data.length; i++) {
            if (
              i === 1 &&
              context.dataset.data[i] < 6 &&
              context.dataset.data[context.dataset.data.length - 1] < 15
            ) {
              secondLabel = "260";
              thirdLabel = "300";
            }

            if (i === 2 && context.dataset.data[i] < 8) {
              thirdLabel = "300";
            }
          }
          return ["end", secondLabel, thirdLabel];
        },
        offset: 18,
        font: {
          size: 14,
          weight: "600",
        },
        formatter: function (value) {
          if (value === 0) {
            return "";
          }
          return value + "%";
        },
      },
    },
  };
};

export const OptionsDoughnut = () => {
  return {
    maintainAspectRatio: false,
    events: [],
    layout: {
      padding: {
        bottom: 25,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "right",
        labels: {
          useBorderRadius: true,
          padding: 20,
        },
      },
      title: {
        display: true,
      },
      datalabels: {
        display: true,
        color: "#6a6b6d",
        align: "end",
        offset: 15,
        font: {
          size: 14,
          weight: "600",
        },
        formatter: function (value) {
          if (value === 0) {
            return "";
          }
          return value + "%";
        },
      },
    },
  };
};

export const DataHalfDoughnut = (data, colors) => {
  return {
    labels: [
      "< 2 Επισκέψεις/ ημέρα",
      "3 - 5 Επισκέψεις/ ημέρα",
      "> 5 Επισκέψεις/ ημέρα",
    ],
    datasets: [
      {
        label: "",
        data: data
          ? [
              data.lessThan2VisitsPercentage,
              data.between3and5VisitsPercentage,
              data.moreThan5VisitsPercentage,
            ]
          : [],
        borderColor: colors,
        backgroundColor: colors,
        cutout: 45,
      },
    ],
  };
};

export const DataDoughnut = (data, colors) => {
  return {
    labels: ["Νέοι", "Επαναλαμβανόμενοι"],
    datasets: data
      ? [
          {
            label: "",
            data: [data.newVisitsPercentage, data.revisitsPercentage],
            borderColor: colors,
            backgroundColor: colors,
            cutout: 45,
            rotation: 50,
          },
        ]
      : [],
  };
};

export const centerImageDoughnut = {
  id: "centerImageDoughnut",
  afterDatasetDraw: (chart, args, pluginOptions) => {
    const { ctx } = chart;

    const x = chart.getDatasetMeta(0).data[0]?.x || 0;
    const y = chart.getDatasetMeta(0).data[0]?.y || 0;

    CreatePerson(ctx, x - 4.5, y - 13, "#252525");
    CreateDoughnutCenter(ctx, x, y);
  },
};

export const centerImageHalfDoughnut = (location) => {
  return {
    id: "centerImageDoughnut",
    afterDatasetDraw: (chart, args, pluginOptions) => {
      const { ctx } = chart;
      const x = chart.getDatasetMeta(0).data[0]?.x || 0;
      const y = chart.getDatasetMeta(0).data[0]?.y || 0;

      const svgString =
        '<svg xmlns="http://www.w3.org/2000/svg" width="26" height="23" viewBox="0 0 21.684 18.968"><path id="Shape_1" data-name="Shape 1" d="M296.995,447.724l7.618-3.906a17.785,17.785,0,0,1-1.339-3.344,2.732,2.732,0,0,1,.607-2.324,2.945,2.945,0,0,1,4.679,0,2.722,2.722,0,0,1,.6,2.32,13.146,13.146,0,0,1-.884,2.38l10.4,7.67-10.31,5.44Zm11.45,7.181,4.87-2.57-3.519-1.72-2.409,3.524Zm-1.791-1.3,2.321-3.394-2.968-1.451-4.309,1.254Zm7.648-1.793,2.684-1.416-4.7-3.464-4.995,1.454Zm-15.588-3.959,2.059,1.492,4.029-1.173-3.436-1.679Zm7.375-.055,5.286-1.539-3.488-2.571c-.626,1.307-1.25,2.477-1.268,2.51h0l-.4.749-.4-.747c0-.006-.246-.459-.577-1.113-.073-.145-.151-.3-.231-.461l-2.639,1.353Zm.128-2.788c.293-.569.7-1.383,1.083-2.2a14.209,14.209,0,0,0,.971-2.52,1.845,1.845,0,0,0-.416-1.57,2.033,2.033,0,0,0-3.27,0,1.854,1.854,0,0,0-.421,1.573,17.35,17.35,0,0,0,1.257,3.111h0l.126.262.076.155c.21.434.412.835.578,1.16Zm-1.362-4.868a1.363,1.363,0,1,1,1.363,1.349A1.358,1.358,0,0,1,304.854,440.144Zm1.363.45a.45.45,0,1,0-.454-.45A.453.453,0,0,0,306.217,440.594Z" transform="translate(-296.995 -436.996)" fill="#505381"/></svg>';
      const img = new Image();
      img.src = "data:image/svg+xml," + encodeURIComponent(svgString);

      img.onload = () => {
        ctx.drawImage(img, x - 11, y - 25);
      };

      CreateDoughnutCenter(ctx, x, y);

      const title = pluginOptions.location;
      ctx.font = `${title?.length > 16 ? "8px" : "9px"} Arial`;
      ctx.fillStyle = "#505381";
      ctx.textAlign = "center";
      var margin = 10;

      //Based on lengh side calculating the size of text and when to start new line.
      const step = title?.length > 16 ? 12 : 10;
      for (var i = 0; i < title?.length; i += step) {
        let end = title?.length > i + step ? i + step : title?.length;
        ctx.fillText(title?.substring(i, end), x, y + margin);
        margin += 10;
      }
    },
    defaults: {
      location: location,
    },
  };
};

export default {
  OptionsHalfDoughnut,
  OptionsDoughnut,
  DataHalfDoughnut,
  DataDoughnut,
  centerImageDoughnut,
  centerImageHalfDoughnut,
};
