import { useState } from "react";
import CityMap from "../../../../Components/Map/CityMap";
import CircularPulse from "../../../../Components/CircularPulse/CircularPulse";
import MapCard from "./MapCard";
import { get_dashboard } from "../../Services/DataService";
import useApiIntervalCall from "../../Hooks/useApiIntervalCall";

const WifiMap = (props) => {
  const [selectedMarker, setSelectedMarker] = useState();

  const data = useApiIntervalCall(get_dashboard, props.interval, props.data);
  const mapData =
    data?.mapData?.map((location) => {
      let color = "#3fb24d";
      let status = "25%";

      if (location.visitors > 50 && location.visitors <= 150) {
        color = "#2c9dff";
        status = "50%";
      } else if (location.visitors > 150 && location.visitors <= 300) {
        color = "#ff7800";
        status = "75%";
      } else if (location.visitors > 300) {
        color = "#ff0000";
        status = "100%";
      }

      return {
        ...location,
        color,
        status,
      };
    }) || [];

  const normalizeVisitorsNumber = (visitors) => {
    const min = 0;
    const max = visitors.reduce((max, p) => (p > max ? p : max), visitors[0]);
    return visitors.map((item) => ((item - min) / (max - min)) * 100);
  };

  const normalizedVisitors = normalizeVisitorsNumber(
    mapData.map((item) => item.visitors)
  );
  return (
    <CityMap
      markers={mapData.map((item, index) => ({
        ...item,
        markerRenderer: (
          <CircularPulse
            color={item.color}
            percentage={normalizedVisitors[index]}
          />
        ),
        onMarkerClick: () => setSelectedMarker(item),
      }))}
      infoWindowRenderer={(item) => <MapCard data={item} />}
      initialZoom={15}
      className="col-span-5"
      selectedMarker={selectedMarker}
      setSelectedMarker={setSelectedMarker}
    />
  );
};

export default WifiMap;
