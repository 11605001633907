import * as React from "react";
import { useTheme } from "@mui/material";

import { colors, colorsSecondary } from "../../Defaults/Global";

import {
  OptionsDoughnut,
  centerImageDoughnut,
  DataDoughnut,
} from "./GraphOptions";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const ConnectedDeviceKpi = (props) => {
  const theme = useTheme();
  const options2 = OptionsDoughnut();
  const data2 = DataDoughnut(props.data, colorsSecondary);

  return (
    <div className="h-[340px] w-full pb-[10px] pt-[6px] rounded-md bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]">
      <div className="flex gap-x-3 items-center px-[15px] pb-5">
        <span
          className="text-[13px] font-semibold uppercase"
          style={{ color: theme.palette.neutral.dark4 }}
        >
          Συνδεδεμενοι χρηστες
        </span>
      </div>
      <div className="flex justify-start h-[200px] mt-[-20px]">
        <Doughnut
          data={data2}
          options={options2}
          plugins={[centerImageDoughnut(props.data.connectedDevices)]}
        />
      </div>
      <div className="flex justify-center items-center gap-2">
        <span
          className="text-[40px] font-bold uppercase"
          style={{ color: "#6699ff" }}
        >
          {props.data.connectedDevices}
        </span>
        <span
          className="text-[13px] font-bold uppercase"
          style={{ color: "#6699ff" }}
        >
          ΣΥΝΔΕΔΕΜΕΝΟΙ ΧΡΗΣΤΕΣ
        </span>
      </div>
    </div>
  );
};

export default ConnectedDeviceKpi;
