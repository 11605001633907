import { observer } from "mobx-react";
import Breadcrumb from "../../../../Components/Breadcrumb/Breadcrumb";
import PageTitle from "../../../../Components/PageTitle";
import ParagraphText from "../../../../Components/ParagraphText.js/ParagraphText";
import SubmitButton from "../../../../Components/Buttons/SubmitButton";
import { useState } from "react";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";

const SoftwareManagement = () => {
  const [loading, setLoading] = useState(false);

  const handleGoToSoftwareManagement = () => {
    setLoading(true);
    window.open("https://led-cloud.com/#/Account/Login", "_blank");
    setLoading(false);
  };

  return (
    <div>
      <Breadcrumb pathList={["Οθόνες", "Λογισμικό διαχείρισης"]} />
      <div className="mt-8 ml-[35px]">
        <PageTitle title="Λογισμικό διαχείρισης" />
        <ParagraphText className={"my-8"}>
          Επιλέξτε το παρακάτω κουμπί για να συνδεθείτε στο εξειδικευμένο
          λογισμικό διαχείρισης
        </ParagraphText>
        <div className="flex justify-start items-sd mt-8 mr-2">
          <SubmitButton
            loading={loading}
            type="button"
            onClick={() => handleGoToSoftwareManagement()}
            size="large"
            label="Λογισμικό διαχείρισης"
            endIcon={<OpenInNewOutlinedIcon />}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(SoftwareManagement);
