import TabNavigation from "../../../../Components/TabNavigation/TabNavigation";
import Icons from "../../../../assets/icons";

const iconSize = 25;

const EnvironmentTabMenu = () => {
  const items = [
    {
      route: "",
      label: "Ανάλυση",
      icon: <Icons name="analysis" width={iconSize} height={iconSize} />,
    },
    {
      route: "/reports",
      label: "Αναφορές - Ιστορικότητα",
      icon: <Icons name="reports-circle" width={iconSize} height={iconSize} />,
    },
    {
      label: "Ειδοποιήσεις",
      route: "/notifications",
      icon: <Icons name="notifications" width={iconSize} height={iconSize} />,
    },
  ];

  return <TabNavigation baseRoute="environment" routes={items} />;
};

export default EnvironmentTabMenu;
