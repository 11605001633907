import { Box, CircularProgress } from "@mui/material";

const Loader = ({ className, size }) => {
  return (
    <>
      <Box className={className}>
        <CircularProgress size={size} />
      </Box>
    </>
  );
};

export default Loader;
