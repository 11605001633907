import { Button } from "@mui/material";
import AnimateButton from "../Theme/Extended/AnimateButton";
import { useTheme } from "@mui/material/styles";
import { themeColors } from "../../Config/Themes/theme";
import clsx from "clsx";

const CancelButton = ({ onClick, label, className, ...rest }) => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const classes = clsx(["capitalize text-sm", className]);

  return (
    <AnimateButton>
      <Button
        className={classes}
        type="button"
        variant="contained"
        onClick={onClick}
        sx={{
          backgroundColor: colors.buttonsCancel.main,
          color: colors.buttonsCancel.text,
          textUnderlineOffset: "0.15em",
          boxShadow: "none",
          borderWidth: "1px",
          borderColor: colors.buttonsCancel.border,
          borderStyle: "solid",
          "&:hover": {
            backgroundColor: colors.buttonsCancel.hover,
            color: colors.buttonsCancel.text,
            boxShadow: "none",
          },
        }}
        {...rest}
      >
        <u>{label}</u>
      </Button>
    </AnimateButton>
  );
};

export default CancelButton;
