import { decrypt, encrypt } from "../Encryption/Encryption";
import { refreshTokenKey, tokenKey, languageKey } from "../../Config/constant";

export function getTokenFromSessionStorage() {
  return decrypt(sessionStorage.getItem(tokenKey));
}

export function getRefreshTokenFromSessionStorage() {
  return decrypt(sessionStorage.getItem(refreshTokenKey));
}

export function getLanguageFromSessionStorage() {
  return decrypt(sessionStorage.getItem(languageKey));
}

export function saveTokenToSessionStorage(token) {
  sessionStorage.setItem(tokenKey, encrypt(token));
}

export function saveRefreshTokenToSessionStorage(refreshToken) {
  sessionStorage.setItem(refreshTokenKey, encrypt(refreshToken));
}

export function saveLanguageToSessionStorage(language) {
  sessionStorage.setItem(languageKey, encrypt(language));
}

export function removeTokenToSessionStorage() {
  sessionStorage.removeItem(tokenKey);
}

export function removeRefreshTokenToSessionStorage() {
  sessionStorage.removeItem(refreshTokenKey);
}

export function removeLocaleFromSessionStorage() {
  sessionStorage.removeItem(languageKey);
}
