import { useState } from "react";
import { Typography, useTheme } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { themeColors } from "../../../../Config/Themes/theme";
import { Controller, useForm } from "react-hook-form";
import MyDatePicker from "../../../../Components/MyDatePicker/MyDatePicker";
import MultiDayPicker from "../../Components/MultiDatePicker/MultiDayPicker";
import BorderSection from "../../../../Components/BorderSection/BorderSection";
import ActionButton from "../../../../Components/Buttons/ActionButton";
import SubmitButton from "../../../../Components/Buttons/SubmitButton";
import CancelButton from "../../../../Components/Buttons/CancelButton";
import DropdownButton from "../../../../Components/Buttons/DropdownButton";
import PdfIcon from "../../../../assets/icons/appIcons/pdf.png";
import ExcelIcon from "../../../../assets/icons/appIcons/xls.png";
import PdfTemplate from "./PdfTemplate";
import { saveAs } from "file-saver";
import {
  Categories,
  DateTypes,
  BuildChartData,
  DateType,
  LineGraphs,
  BarGraphs,
  DurationChartTypes,
} from "./ReportDefaults";
import { pdf } from "@react-pdf/renderer";
import { exportExcel, get_report_data } from "../../Services/DataService";
import BarChart from "../../../../Components/Charts/LineChart/BarChart/BarChart";
import LineChart from "../../../../Components/Charts/LineChart/LineChart/LineChart";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const NewReport = (props) => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const [reportInitialized, setReportInitialized] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);
  const [category, setCategory] = useState("");
  const [reportData, setReportData] = useState(null);
  const [dateType, setDateType] = useState(DateTypes[0]);
  const [durationChartType, setDurationChartType] = useState(
    DurationChartTypes[0]
  );
  const [allLocations, setAllLocations] = useState(false);
  const [chartInstance, setChartInstance] = useState(null);

  const [reportInputs, setReportInputs] = useState({
    title: "",
    dateType: "",
    chartType: "",
    dates: [],
    category: "",
    locations: [],
  });

  const reportSchema =
    dateType.type == DateType.SingleDay
      ? yup.object().shape({
          singleDay: yup.date().required("Το πεδίο είναι υποχρεωτικό"),
        })
      : yup.object().shape({
          multipleDays: yup.array().min(1, "Το πεδίο είναι υποχρεωτικό"),
        });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(reportSchema),
  });

  const onCreateReport = async (data) => {
    setReportLoading(true);
    let dates =
      dateType.type === DateType.SingleDay
        ? [dayjs(data.singleDay).format("YYYY-MM-DD 00:00:00")]
        : [
            ...data.multipleDays.map((md) =>
              dayjs(md).format("YYYY-MM-DD 00:00:00")
            ),
          ];

    setReportInputs({ ...reportInputs, dates });
    let query = { ...reportInputs, chartType: durationChartType.type, dates };

    let reportData = await get_report_data(query);
    setReportData(reportData.result);
    setReportInitialized(true);
    setReportLoading(false);
  };

  const onCategorySelected = (category) => {
    setCategory(category);
    if (reportInputs.category === category.api) {
      setReportInputs({ ...reportInputs, category: "" });
      return;
    }
    setReportInputs({ ...reportInputs, category: category.api });

    if (BarGraphs.includes(category.api)) setDateType(DateTypes[1]);
  };

  const onLocationSelected = (location) => {
    if (reportInputs.locations.includes(location.id)) {
      setReportInputs({
        ...reportInputs,
        locations:
          reportInputs.locations.filter((l) => l !== location.id) ?? [],
      });
      return;
    }
    const locations =
      dateType.type === DateType.SingleDay ||
      BarGraphs.includes(reportInputs.category)
        ? reportInputs.locations
        : [];
    setReportInputs({
      ...reportInputs,
      locations: [...locations, location.id],
    });
  };

  const onAllLocationsSelected = () => {
    if (allLocations) {
      setReportInputs({ ...reportInputs, locations: [] });
    } else {
      setReportInputs({
        ...reportInputs,
        locations: props.locations.map((l) => l.id),
      });
    }

    setAllLocations(!allLocations);
  };

  const onCancelReport = () => {
    setReportInitialized(false);
    setAllLocations(false);
    setDateType(DateTypes[0]);
    setDurationChartType(DurationChartTypes[0]);
    setReportInputs({
      title: "",
      dateType: "",
      dates: [],
      category: "",
      locations: [],
      chartType: "",
    });
    reset();
  };

  const exportToPdf = async () => {
    let type = dateType.title;
    if (BarGraphs.includes(reportInputs.category)) {
      type =
        reportInputs.category === "durationofstay"
          ? durationChartType.title
          : null;
    }

    const doc = (
      <PdfTemplate
        image={chartInstance && chartInstance?.toBase64Image()}
        inputs={{
          category: category.title,
          type: type,
          dates: reportInputs.dates
            .map((d) => dayjs(d).format("YYYY-MM-DD"))
            .join(", "),
          locations: props.locations
            .filter((l) => reportInputs.locations.includes(l.id))
            .map((l) => l.name)
            .join(", "),
        }}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `${category.title}.pdf`);
  };

  return (
    <>
      <div className="flex gap-4 justify-between">
        <Typography
          variant="h2"
          fontSize={20}
          className="pl-8"
          color="primary"
          textTransform={"uppercase"}
        >
          <span lang="el">
            {reportInitialized
              ? "Αναφορά: " + "#1" //reportInputs.title
              : "Νέα αναφορά"}
          </span>
        </Typography>
        <div className="flex gap-2">
          <Typography variant="h6" color={colors.greyAccent.dark}>
            ΗΜΕΡΟΜΗΝΙΑ ΔΗΜΙΟΥΡΓΙΑΣ
          </Typography>
          <Typography
            variant="h6"
            color={colors.greyAccent.dark4}
            opacity={0.5}
            style={{
              textTransform: "uppercase",
            }}
          >
            <span lang="el">{dayjs().format("DD MMMM YYYY")}</span>
          </Typography>
        </div>
      </div>

      <hr className="my-5 mx-6" />

      <form onSubmit={handleSubmit((data) => onCreateReport(data))}>
        <section className="pb-5">
          {!reportInitialized ? (
            <BorderSection title="Επιλογή κατηγορίας" required>
              <div className="flex gap-3">
                {Categories.map((category) => (
                  <ActionButton
                    key={category.title}
                    selected={reportInputs.category === category.api}
                    variant="outlined"
                    sx={{ backgroundColor: "blue", color: "white" }}
                    onClick={() => onCategorySelected(category)}
                  >
                    <span
                      lang="el"
                      style={{
                        textTransform: "uppercase",
                      }}
                    >
                      {category.title}
                    </span>
                  </ActionButton>
                ))}
              </div>
            </BorderSection>
          ) : (
            <BorderSection title="Κατηγορία αναφοράς" required>
              <div className="flex gap-3">
                <div className="flex flex-1 flex-row gap-2 items-center">
                  <div
                    className="flex justify-center items-center w-[32px] h-[28px]"
                    style={{
                      backgroundColor: colors.greyAccent.light2,
                    }}
                  >
                    {category.titleIcon("22", colors.primary.main)}
                  </div>

                  <Typography
                    variant="h6"
                    color={colors.primary.main}
                    className="flex-1"
                  >
                    {category.title}
                  </Typography>
                </div>
              </div>
            </BorderSection>
          )}
        </section>
        {reportInputs.category ? (
          <>
            {!BarGraphs.includes(reportInputs.category) ? (
              <section className="pb-5">
                {!reportInitialized ? (
                  <BorderSection title="Επιλογή τύπου" required>
                    <div className="flex gap-3">
                      {DateTypes.map((dType) => (
                        <ActionButton
                          key={dType.type}
                          selected={dateType.type === dType.type}
                          variant="outlined"
                          sx={{ backgroundColor: "blue", color: "white" }}
                          onClick={() => {
                            setDateType(dType);
                            setReportInputs({ ...reportInputs, locations: [] });
                            setAllLocations(false);
                            reset();
                          }}
                        >
                          <span
                            lang="el"
                            style={{
                              textTransform: "uppercase",
                            }}
                          >
                            {dType.title}
                          </span>
                        </ActionButton>
                      ))}
                    </div>
                  </BorderSection>
                ) : (
                  <BorderSection title="Τύπος αναφοράς" required>
                    <div className="flex gap-3">
                      <div className="flex flex-1 flex-row gap-2 items-center">
                        <div
                          className="flex justify-center items-center w-[32px] h-[28px]"
                          style={{
                            backgroundColor: colors.greyAccent.light2,
                          }}
                        >
                          {category.typeIcon("28", colors.primary.main)}
                        </div>
                        <Typography
                          variant="h6"
                          color={colors.primary.main}
                          className="flex-1"
                        >
                          {dateType.title}
                        </Typography>
                      </div>
                    </div>
                  </BorderSection>
                )}
              </section>
            ) : (
              <section className="pb-5">
                {category.api === "durationofstay" ? (
                  !reportInitialized ? (
                    <BorderSection title="Επιλογή τύπου" required>
                      <div className="flex gap-3">
                        {DurationChartTypes.map((dType) => (
                          <ActionButton
                            key={dType.type}
                            selected={durationChartType.type === dType.type}
                            variant="outlined"
                            sx={{ backgroundColor: "blue", color: "white" }}
                            onClick={() => {
                              setDurationChartType(dType);
                            }}
                          >
                            <span
                              lang="el"
                              style={{
                                textTransform: "uppercase",
                              }}
                            >
                              {dType.title}
                            </span>
                          </ActionButton>
                        ))}
                      </div>
                    </BorderSection>
                  ) : (
                    <BorderSection title="Τύπος αναφοράς" required>
                      <div className="flex gap-3">
                        <div className="flex flex-1 flex-row gap-2 items-center">
                          <div
                            className="flex justify-center items-center w-[32px] h-[28px]"
                            style={{
                              backgroundColor: colors.greyAccent.light2,
                            }}
                          >
                            {category.typeIcon("28", colors.primary.main)}
                          </div>
                          <Typography
                            variant="h6"
                            color={colors.primary.main}
                            className="flex-1"
                          >
                            {durationChartType.title}
                          </Typography>
                        </div>
                      </div>
                    </BorderSection>
                  )
                ) : (
                  <></>
                )}
              </section>
            )}

            <section className="pb-5">
              {!reportInitialized ? (
                <BorderSection
                  title={dateType.sectionTitle}
                  requiredText={
                    dateType.type === DateType.MultipleDays
                      ? "Επιλέξετε μέχρι δέκα ημέρες"
                      : ""
                  }
                >
                  {dateType.type === DateType.SingleDay ? (
                    <div className="w-[200px]">
                      <Controller
                        name="singleDay"
                        control={control}
                        rules={{ required: "Το πεδίο είναι υποχρεωτικό" }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <MyDatePicker
                            maxDate={new Date()}
                            onChange={onChange}
                            className="flex-1"
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    </div>
                  ) : (
                    <div className="flex">
                      <Controller
                        name="multipleDays"
                        control={control}
                        rules={{ required: "Το πεδίο είναι υποχρεωτικό" }}
                        defaultValue={[]}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <MultiDayPicker
                            maxDate={new Date()}
                            onChange={(dates) => onChange(dates)}
                            className="flex-1"
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    </div>
                  )}
                </BorderSection>
              ) : (
                <BorderSection title="Δεδομένα αναφοράς">
                  <div className="flex gap-3">
                    <div className="flex flex-1 flex-col gap-2">
                      <Typography
                        variant="h6"
                        color={colors.greyAccent.dark}
                        className="flex-1"
                      >
                        ΗΜΕΡΟΜΗΝΙΑ
                      </Typography>
                      <Typography
                        variant="h6"
                        color={colors.primary.main}
                        className="flex-1"
                      >
                        {reportInputs.dates
                          .map((md) => dayjs(md).format("YYYY-MM-DD"))
                          .join(", ")}
                      </Typography>
                    </div>
                    <div className="flex flex-1 flex-col gap-2">
                      <Typography
                        variant="h6"
                        color={colors.greyAccent.dark}
                        className="flex-1"
                      >
                        ΠΕΡΙΟΧΗ
                      </Typography>
                      <Typography
                        variant="h6"
                        color={colors.primary.main}
                        className="flex-1"
                      >
                        {allLocations
                          ? "ΟΛΕΣ"
                          : props.locations
                              .filter((l) =>
                                reportInputs.locations.includes(l.id)
                              )
                              .map((l) => l.name)
                              .join(", ")}
                      </Typography>
                    </div>
                  </div>
                </BorderSection>
              )}
            </section>

            {!reportInitialized ? (
              <section className="pb-5">
                <BorderSection
                  title="Επιλογή περιοχής"
                  requiredText={dateType.locationSectionText}
                  required
                >
                  <div className="flex gap-3">
                    {dateType.type === DateType.SingleDay ||
                    BarGraphs.includes(reportInputs.category) ? (
                      <ActionButton
                        key={"allLocations"}
                        selected={false}
                        variant="outlined"
                        sx={{ backgroundColor: "blue", color: "white" }}
                        onClick={() => onAllLocationsSelected()}
                      >
                        <span
                          lang="el"
                          style={{
                            textTransform: "uppercase",
                          }}
                        >
                          {"ΟΛΕΣ"}
                        </span>
                      </ActionButton>
                    ) : (
                      <></>
                    )}

                    {props.locations.map((location) => (
                      <ActionButton
                        key={location.id}
                        selected={reportInputs.locations.includes(location.id)}
                        variant="outlined"
                        sx={{ backgroundColor: "blue", color: "white" }}
                        onClick={() => onLocationSelected(location)}
                      >
                        <span
                          lang="el"
                          style={{
                            textTransform: "uppercase",
                          }}
                        >
                          {location.name}
                        </span>
                      </ActionButton>
                    ))}
                  </div>
                </BorderSection>
              </section>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}

        <section className="pb-5">
          {reportInitialized && reportData && (
            <BorderSection title="Διάγραμμα">
              <div className="flex flex-col gap-3">
                {LineGraphs.includes(reportInputs.category) ? (
                  <LineChart
                    setChartInstance={setChartInstance}
                    data={BuildChartData(reportData)}
                    options={{
                      spanGaps: true,
                      responsive: true,
                      interaction: {
                        mode: "nearest",
                        axis: "x",
                        intersect: false,
                      },
                      maintainAspectRatio: false,
                      plugins: {
                        decimation: {
                          enabled: true,
                          algorithm: "min-max",
                        },
                        datalabels: {
                          display: false,
                        },
                        legend: {
                          display: true,
                        },
                        title: {
                          display: false,
                        },
                        tooltip: {
                          mode: "index",
                          enabled: true,
                        },
                      },
                      scales: {
                        y: {
                          beginAtZero: true,
                          title: {
                            display: true,
                            text: category.diagramTitle,
                          },
                        },
                      },
                    }}
                  />
                ) : (
                  <BarChart
                    setChartInstance={setChartInstance}
                    data={BuildChartData(reportData)}
                    options={{
                      spanGaps: true,
                      responsive: true,
                      interaction: {
                        mode: "nearest",
                        axis: "x",
                        intersect: false,
                      },
                      maintainAspectRatio: false,
                      plugins: {
                        decimation: {
                          enabled: true,
                          algorithm: "min-max",
                        },
                        datalabels: {
                          display: false,
                        },
                        legend: {
                          display: true,
                        },
                        title: {
                          display: false,
                        },
                        tooltip: {
                          mode: "index",
                          enabled: true,
                        },
                      },
                      scales: {
                        y: {
                          beginAtZero: true,
                          title: {
                            display: true,
                            text: category.diagramTitle,
                          },
                          ticks: {
                            callback: function (value) {
                              if (category.api === "visitingrate") {
                                return value + " %";
                              }
                              return value;
                            },
                          },
                        },
                      },
                    }}
                  />
                )}
              </div>
            </BorderSection>
          )}
        </section>
        <section>
          <div className="flex justify-end items-center gap-3 my-5 mr-5">
            {!reportInitialized ? (
              reportInputs.category ? (
                <SubmitButton
                  className="w-[140px]"
                  label={"Δημιουργία"}
                  type="submit"
                  loading={reportLoading}
                  onClick={handleSubmit}
                  disabled={
                    !(
                      reportInputs.locations &&
                      reportInputs.locations.length > 0
                    )
                  }
                />
              ) : (
                <></>
              )
            ) : (
              <div className="flex gap-3 items-center">
                <CancelButton
                  type="button"
                  onClick={() => onCancelReport()}
                  size="large"
                  label="Ακύρωση"
                />
                <DropdownButton
                  label={"Αποθήκευση ως"}
                  options={[
                    {
                      label: "PDF",
                      icon: PdfIcon,
                      onSelect: async () => {
                        await exportToPdf();
                      },
                    },
                    {
                      label: "Excel",
                      icon: ExcelIcon,
                      onSelect: async () => {
                        const payload = {
                          reportCategory: Categories.find(
                            (c) => c.api === reportInputs.category
                          ).categoryName,
                          dates: reportInputs.dates.map((d) =>
                            dayjs(d).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
                          ),
                          locationIds: reportInputs.locations,
                          fileName: reportInputs.category,
                        };
                        await exportExcel(payload);
                      },
                    },
                  ]}
                />
                {/* <SubmitButton
                  label={"Εκτύπωση"}
                  type="button"
                  loading={false}
                  onClick={() => window.print()}
                /> */}
              </div>
            )}
          </div>
        </section>
      </form>
    </>
  );
};

export default NewReport;
