import { Grid, Stack } from "@mui/material";
import { gridSpacing } from "../../../Config/constant";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useAuthStore } from "../../../Stores/AuthStore";
import { phoneRegex } from "../../../Utils/Regex";
import TextInput from "../../../Components/TextInput/TextInput";
import { useState } from "react";
import CancelButton from "../../../Components/Buttons/CancelButton";
import SubmitButton from "../../../Components/Buttons/SubmitButton";

const BasicDetails = () => {
  const { t } = useTranslation();
  const authStore = useAuthStore();
  const [isEditOn, setIsEditOn] = useState(false);

  const profileSchema = yup.object().shape({
    phoneNumber: yup.string().matches(phoneRegex, t("validation.InvalidPhone")),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(profileSchema),
  });

  const onSubmit = ({ phoneNumber }) => {
    authStore.updateProfile(phoneNumber).then(() => {
      setIsEditOn(false);
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            label={t("forms.FirstName")}
            defaultValue={authStore.authUser.name}
            name={"name"}
            control={control}
            error={errors.name}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            defaultValue={authStore.authUser.surName}
            label={t("forms.LastName")}
            name={"surName"}
            control={control}
            error={errors.surName}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            label="Email"
            defaultValue={authStore.authUser.email}
            name={"email"}
            control={control}
            error={errors.email}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            defaultValue={authStore.authUser.phoneNumber}
            label={t("forms.PhoneNumber")}
            name={"phoneNumber"}
            control={control}
            error={errors.phoneNumber}
            disabled={!isEditOn}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            label={t("forms.Position")}
            defaultValue={authStore.authUser.positionInOrganization}
            name={"position"}
            control={control}
            error={errors.position}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            label={t("forms.Role")}
            defaultValue={authStore.authUser.roles[0]}
            name={"role"}
            control={control}
            error={errors.role}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            label={t("forms.UserName")}
            defaultValue={authStore.authUser.userName}
            name={"userName"}
            control={control}
            error={errors.userName}
            disabled
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={4}>
          <TextInput
            label={t("forms.Password")}
            defaultValue={authStore.authUser.password}
            name={"password"}
            control={control}
            error={errors.password}
            disabled
          />
        </Grid> */}
      </Grid>

      <Stack
        direction="row"
        gap={2}
        alignItems={"center"}
        justifyContent={"flex-end"}
        height={1}
        marginTop={4}
      >
        {isEditOn ? (
          <>
            <CancelButton
              onClick={() => setIsEditOn(false)}
              label={t("profile.cancelBtn")}
            />
            <SubmitButton label={t("profile.changeDetailsBtn")} type="submit" />
          </>
        ) : (
          <SubmitButton
            label={t("profile.editDetailsBtn")}
            onClick={() => setIsEditOn(true)}
          />
        )}
      </Stack>
    </form>
  );
};

export default observer(BasicDetails);
