import React, { FC } from "react";
import { useTheme } from "@mui/material";
import { themeColors } from "../../../../Config/Themes/theme";
import DashboardSection from "../../../../Components/DashboardSection/DashboardSection";
import ShadowBox from "../../../../Components/ShadowBox/ShadowBox";
import Gauge from "../../Components/Gauge/Gauge";
import SensorMeasurements from "../../Components/SensorMeasurements/SensorMeasurements";
import SectionTitle from "../../Components/SectionTitle/SectionTitle";

interface AirQualityIndexDatasetOptions {
  aqi: number;
  areaName: string;
  gaugeData: object;
  sensorData: object;
}

interface AirQualityIndexProps {
  datasets: Array<AirQualityIndexDatasetOptions>;
}

const AirQualityIndex: FC<AirQualityIndexProps> = ({ datasets }) => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);

  const renderAirQualityIndex = (item) => {
    return (
      <ShadowBox className="" key={item?.areaName}>
        <section className="flex flex-col justify-around items-center p-5 mb-5 min-h-[423px] max-h-[423px]">
          {item?.areaName && (
            <SectionTitle
              roundIcon={true}
              icon="location"
              iconWidth={25}
              iconHeight={25}
              title={item?.areaName}
            />
          )}{" "}
          <Gauge
            minValue={1}
            maxValue={6}
            value={item?.aqi}
            data={item?.gaugeData}
          />
          {/* <Gauge with blob pointer and custom icon for demo*/}
          {/* <Gauge
          value={50}
          pointerType="blob"
          data={item?.gaugeData}
          icon={
            <Icons
              name="humidityV2"
              height={40}
              width={40}
              fill={colors.whiteAccent.main}
            />
          }
          size={200}
        /> */}
          <div className="mt-4">
            <SensorMeasurements data={item?.sensorData} />
          </div>
        </section>
      </ShadowBox>
    );
  };

  return (
    <div
      className="flex"
      style={
        datasets?.length === 1 ? { width: "fit-content" } : { width: "100%" }
      }
    >
      <DashboardSection
        title="ΔΕΙΚΤΗΣ ΠΟΙΟΤΗΤΑΣ ΑΕΡΑ - AQI"
        tooltipText="Ευρωπαϊκός δείκτης ποιότητας αέρα, που παρέχει πληροφορίες για την τρέχουσα καστάσταση της ποιότητας του αέρα με βάση τις μετρήσεις 5 βασικών ρύπων PM2.5, PM10, O3, NO & SO2."
      >
        <div
          className="flex items-center gap-4 overflow-x-auto px-2 py-2"
          style={
            datasets?.length <= 2
              ? { justifyContent: "space-around" }
              : { justifyContent: "space-between" }
          }
        >
          {datasets?.map((dataset: AirQualityIndexDatasetOptions) => {
            return renderAirQualityIndex(dataset);
          })}
        </div>
      </DashboardSection>
    </div>
  );
};

export default AirQualityIndex;
