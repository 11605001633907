import { useState, useRef, useEffect } from "react";

import { useNavigate } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";

import MainCard from "../Theme/Cards/MainCard";
import Transitions from "../Theme/Extended/Transitions";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { observer } from "mobx-react";
import { useThemeStore } from "../../Stores/ThemeStore";
import { useAuthStore } from "../../Stores/AuthStore";
import { useTranslation } from "react-i18next";
import Icons from "../../assets/icons";

const iconSizePx = 45;

const ProfileToolTip = ({ collapsed }) => {
  const theme = useTheme();
  const themeStore = useThemeStore();
  const authStore = useAuthStore();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const anchorRef = useRef(null);
  const handleLogout = () => {
    navigate("/");
    authStore.logout();
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, route = "") => {
    themeStore.openMenuItem(route);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  if (collapsed) {
    return null;
  }

  return (
    <IconButton>
      <div>
        <Avatar
          alt="Remy Sharp"
          sx={{
            width: iconSizePx,
            height: iconSizePx,
            border: `2px solid ${theme.palette.primary.main}`,
            bgcolor: theme.palette.grey[400],
          }}
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <Icons name="profile" width={iconSizePx} height={iconSizePx} />
        </Avatar>
        <Popper
          className="rounded-[4px] z-10"
          placement="bottom-end"
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          popperOptions={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 14],
                },
              },
            ],
          }}
        >
          {({ TransitionProps }) => (
            <Transitions in={open} {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard
                    border={false}
                    elevation={16}
                    content={false}
                    boxShadow
                    shadow={theme.shadows[16]}
                  >
                    <Box sx={{ p: 2 }}>
                      <Stack>
                        <Stack
                          direction="row"
                          // spacing={0.5}
                          alignItems="center"
                        >
                          <Typography variant="h4">
                            {t("misc.Hello")},
                          </Typography>
                          &nbsp;
                          <Typography
                            component="span"
                            variant="h4"
                            // sx={{ fontWeight: 400 }}
                          >
                            {`${authStore.authUser?.name} ${authStore.authUser?.surName}`}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Box>
                    <Box sx={{ p: 2, paddingTop: 0 }}>
                      <List
                        component="nav"
                        sx={{
                          width: "100%",
                          maxWidth: 200,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: "10px",
                          [theme.breakpoints.down("md")]: {
                            minWidth: "100%",
                          },
                          "& .MuiListItemButton-root": {
                            mt: 0.5,
                          },
                        }}
                      >
                        <ListItemButton
                          sx={{ borderRadius: `4px` }}
                          selected={themeStore.isMenuItemOpen("/profile")}
                          onClick={(event) =>
                            handleListItemClick(event, "/profile")
                          }
                        >
                          <ListItemIcon>
                            <AccountCircleIcon
                              stroke={1.5}
                              size="1.3rem"
                              sx={{ fontSize: 30 }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Grid item>
                                <Typography variant="body2" fontSize={17}>
                                  {t("profile.Profile")}
                                </Typography>
                              </Grid>
                            }
                          />
                        </ListItemButton>
                        <ListItemButton
                          sx={{ borderRadius: `4px` }}
                          selected={false}
                          onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <LogoutIcon
                              stroke={1.5}
                              size="1.3rem"
                              sx={{ fontSize: 30 }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2" fontSize={17}>
                                {t("misc.Logout")}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Box>
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            </Transitions>
          )}
        </Popper>
      </div>
    </IconButton>
  );
};

export default observer(ProfileToolTip);
