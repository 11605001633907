import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Pagination, Stack, Tooltip } from "@mui/material";
import {
  DeleteOutline,
  EditOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { dateInDateMonthYear } from "../../../Utils/formatDate/formatDate";
import { useAnnouncementsStore } from "../../../Stores/AnnouncementsStore";
import AnnouncementDeleteModal from "./AnnouncementDeleteModal";
import AnnouncementsEditModal from "./AnnouncementsEditModal";
import AnnouncementsViewModal from "./AnnouncementsViewModal";

const AnnouncementsTable = ({
  announcements,
  inputValue,
  currentPage,
  setPage,
  pageSize,
  sortConfig,
  setSortConfig,
}) => {
  const [selectedAnnouncementId, setSelectedAnnouncementId] = useState(null);
  const [selectedAnnouncementIdView, setSelectedAnnouncementIdView] =
    useState(null);
  const [selectedAnnouncementIdDelete, setSelectedAnnouncementIdDelete] =
    useState(null);

  const announcementsStore = useAnnouncementsStore();

  const refreshAnnouncements = () => {
    announcementsStore.getAllAnnouncements();
  };

  const columns = [
    { key: "id", label: "Κωδ.", width: "10%" },
    { key: "createdOn", label: "Ημερομηνία", width: "15%" },
    { key: "text", label: "Μήνυμα", width: "35%" },
    { key: "status", label: "Κατάσταση", width: "20%" },
    { key: "actions", label: "Ενέργειες", width: "20%" },
  ];

  const renderAnnouncementsContent = () => {
    return sortedAnnouncements?.map((announcement) => (
      <tr style={{ fontSize: "1rem" }} key={announcement?.id}>
        <td>
          <div
            style={{
              background: "#FFFFFF",
              padding: "15px",
              verticalAlign: "top",
              textAlign: "center",
              paddingBottom: "15px",
              margin: "0px",
              marginTop: "17px",
            }}
          >
            {announcement?.id || "-"}
          </div>
        </td>
        <td>
          <div
            style={{
              background: "#FFFFFF",
              padding: "15px",
              verticalAlign: "top",
              textAlign: "center",
              margin: "0px",
              marginTop: "15px",
            }}
          >
            {dateInDateMonthYear(announcement?.createdOn || "-")}
          </div>
        </td>
        <td
          style={{
            maxWidth: "500px",
            wordWrap: "break-word",
            whiteSpace: "pre-line",
          }}
        >
          <div
            style={{
              background: "#FFFFFF",
              padding: "15px",
              verticalAlign: "top",
              textAlign: "center",
              margin: "0px",
              marginTop: "15px",
            }}
          >
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
              }}
            >
              {announcement?.text || "-"}
            </div>
          </div>
        </td>
        <td>
          <div
            style={{
              background: "#FFFFFF",
              padding: "15px",
              verticalAlign: "top",
              textAlign: "center",
              margin: "0px",
              marginTop: "15px",
            }}
          >
            {announcementsStore.announcementsStatuses.find(
              (status) => status.key === announcement?.status
            )?.name || "-"}
          </div>
        </td>
        <td>
          <div
            style={{
              background: "#FFFFFF",
              padding: "15px",
              verticalAlign: "top",
              textAlign: "center",
              margin: "0px",
              marginTop: "15px",
            }}
          >
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Tooltip
                onClick={() => handleOpenEditModal(announcement?.id)}
                placement="top"
                title={"Επεξεργασία"}
              >
                <EditOutlined
                  sx={{
                    cursor: "pointer",
                    fontSize: "1.4rem",
                    color: "#BBCCFB",
                  }}
                />
              </Tooltip>
              <Tooltip
                onClick={() => handleOpenViewModal(announcement?.id)}
                placement="top"
                title={"Προβολή"}
              >
                <VisibilityOutlined
                  sx={{
                    cursor: "pointer",
                    fontSize: "1.4rem",
                    marginLeft: "0.4rem",
                    color: "#BBCCFB",
                  }}
                />
              </Tooltip>
              <Tooltip
                onClick={() => handleOpenDeleteModal(announcement?.id)}
                placement="top"
                title={"Διαγραφή"}
              >
                <DeleteOutline
                  sx={{
                    cursor: "pointer",
                    fontSize: "1.4rem",
                    marginLeft: "0.4rem",
                    color: "#BBCCFB",
                  }}
                />
              </Tooltip>
            </Stack>
          </div>
        </td>
      </tr>
    ));
  };

  const renderAnnouncementsColumns = () => {
    return columns.map((column) => (
      <th
        key={column.key}
        style={{
          padding: "10px",
          verticalAlign: "top",
          textAlign: "center",
          cursor: ["id", "createdOn", "status"].includes(column.key)
            ? "pointer"
            : "default",
        }}
        onClick={() => handleSortColumn(column.key)}
      >
        <b>{column.label}</b>
        {sortConfig.key === column.key && (
          <span>{sortConfig.direction === "asc" ? " ▲" : " ▼"}</span>
        )}
      </th>
    ));
  };

  const handleOpenEditModal = (announcementId) => {
    setSelectedAnnouncementId(announcementId);
  };

  const handleOpenViewModal = (announcementId) => {
    setSelectedAnnouncementIdView(announcementId);
  };

  const handleSortColumn = (key) => {
    if (["id", "createdOn", "status"].includes(key)) {
      let direction = "asc";
      if (sortConfig.key === key && sortConfig.direction === "asc") {
        direction = "desc";
      }
      setSortConfig({ key, direction });
    }
  };

  const sortData = (data, config) => {
    if (!config.key) {
      return data;
    }

    const sortedData = [...data];
    sortedData.sort((a, b) => {
      if (a[config.key] < b[config.key]) {
        return config.direction === "asc" ? -1 : 1;
      }
      if (a[config.key] > b[config.key]) {
        return config.direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    return sortedData;
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);

    const orderByDateAsc =
      sortConfig.key === "createdOn" && sortConfig.direction === "asc";
    const orderByDateDesc =
      sortConfig.key === "createdOn" && sortConfig.direction === "desc";
    const orderByStatusAsc =
      sortConfig.key === "status" && sortConfig.direction === "asc";
    const orderByStatusDesc =
      sortConfig.key === "status" && sortConfig.direction === "desc";
    const orderByIdAsc =
      sortConfig.key === "id" && sortConfig.direction === "asc";
    const orderByIdDesc =
      sortConfig.key === "id" && sortConfig.direction === "desc";

    announcementsStore.getAllAnnouncements(
      newPage,
      pageSize,
      inputValue,
      orderByDateAsc,
      orderByDateDesc,
      orderByIdAsc,
      orderByIdDesc,
      orderByStatusAsc,
      orderByStatusDesc
    );
  };

  const handleOpenDeleteModal = (announcementId) => {
    setSelectedAnnouncementIdDelete(announcementId);
  };

  const sortedAnnouncements = sortData(announcements, sortConfig);

  return (
    <>
      <AnnouncementsEditModal
        open={selectedAnnouncementId !== null}
        handleClose={() => setSelectedAnnouncementId(null)}
        announcementId={selectedAnnouncementId}
        refreshList={refreshAnnouncements}
      />
      <AnnouncementDeleteModal
        open={selectedAnnouncementIdDelete !== null}
        handleClose={() => setSelectedAnnouncementIdDelete(null)}
        refreshList={refreshAnnouncements}
        announcementId={selectedAnnouncementIdDelete}
      />

      <AnnouncementsViewModal
        open={selectedAnnouncementIdView !== null}
        handleClose={() => setSelectedAnnouncementIdView(null)}
        announcementId={selectedAnnouncementIdView}
      />

      <div className="table-container">
        <table
          className="responsive-table"
          style={{ width: "100%", tableLayout: "auto", marginBottom: "10px" }}
        >
          <thead>
            <tr>{renderAnnouncementsColumns()}</tr>
          </thead>
          <tbody>{renderAnnouncementsContent()}</tbody>
        </table>
      </div>
      <Stack
        className="pt-2 mb-4"
        direction="row"
        justifyContent="center"
        alignItems="start"
      >
        <Pagination
          count={announcementsStore.totalPages}
          page={currentPage}
          onChange={handlePageChange}
        />
      </Stack>
    </>
  );
};

export default observer(AnnouncementsTable);
