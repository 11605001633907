import { observer } from "mobx-react";
import SubmitButton from "../../../Components/Buttons/SubmitButton";
import { Autocomplete, Box, Modal, TextField, Typography } from "@mui/material";
import { useState } from "react";
import MainCard from "../../../Components/Theme/Cards/MainCard";
import CancelButton from "../../../Components/Buttons/CancelButton";
import { useAnnouncementsStore } from "../../../Stores/AnnouncementsStore";
import { success } from "../../../Utils/Toast/Toast";

const AnnouncementCreateModal = ({
  open,
  handleClose,
  refreshAnnouncements,
}) => {
  const announcementsStore = useAnnouncementsStore();
  const [inputValue, setInputValue] = useState("");
  const [selectedStatusId, setSelectedStatusId] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleDropDownChange = (event, value) => {
    if (value) {
      setSelectedStatusId(value.id);
    } else {
      setSelectedStatusId(null);
    }
  };

  const handleCreateAnnouncement = () => {
    setLoading(true);
    const payload = {
      text: inputValue,
      status: selectedStatusId,
    };
    announcementsStore
      .createNewAnnouncement(payload)
      .then((resp) => {
        success("Η ανακοίνωση δημιουργήθηκε επιτυχώς");
        handleClose(false);
        refreshAnnouncements();
        setInputValue("");
        setSelectedStatusId(null);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      className="flex items-center justify-center"
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="announcement-modal"
    >
      <MainCard className="m-4 max-w-[70vw] min-w-[70vw]  min-h-[35vw] p-8 bg-[#F1F1F1]">
        <div className="modal-paper">
          <Typography className="mt-4 text-[#565A87]" variant="h3">
            Νέα ανακοίνωση
          </Typography>
          <Box className="w-full mt-6 border-b-2 border-[#B9B9B9]" />
          <div>
            <Typography className="mt-8" variant="h4">
              <b> Κείμενο ανακοίνωσης </b>
            </Typography>
            <TextField
              multiline
              rows={5}
              type="text"
              className="mt-6 bg-[#FFFF] w-full"
              label="Ανακοίνωση"
              value={inputValue}
              onChange={handleChange}
            />

            <Autocomplete
              value={
                announcementsStore.announcementsStatuses.find(
                  (status) => status.id === selectedStatusId
                ) || null
              }
              onChange={handleDropDownChange}
              options={announcementsStore.announcementsStatuses}
              getOptionLabel={(option) => option?.name || ""}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              renderInput={(params) => (
                <TextField
                  className="bg-[#FFFFFF] mt-8"
                  fullWidth
                  {...params}
                  label={"Κατάσταση"}
                />
              )}
            />
          </div>
          <div className="flex justify-end items-end mt-8 mr-2">
            <CancelButton
              className="mr-6"
              type="button"
              onClick={() => handleClose(false)}
              size="large"
              label="Ακύρωση"
            />
            <SubmitButton
              loading={loading}
              type="button"
              onClick={handleCreateAnnouncement}
              size="large"
              label="Δημοσίευση"
            />
          </div>
        </div>
      </MainCard>
    </Modal>
  );
};

export default observer(AnnouncementCreateModal);
