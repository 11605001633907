import { GoogleMap, Marker, MarkerClusterer } from "@react-google-maps/api";
import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import useMapLoader from "../../../../../Hooks/useMapLoader";

const MapWrapper = ({
  initialRegion,
  markers,
  mapOptions,
  containerStyle,
  enableClustering = false,
  markerIcon,
  infoWindow,
}) => {
  const { isLoaded } = useMapLoader();

  const markerClustererOptions = {
    imagePath:
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m", // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
    maxZoom: 20,
  };

  const [map, setMap] = useState(null);
  //   const [markers, setMarkers] = useState([]);

  const initialZoom = 11;
  const singleMarkerZoom = 15;

  const [activeMarker, setActiveMarker] = useState(null);

  const initialMapOptions = {
    disableDefaultUI: true,
    styles: [
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
    ],
  };

  const onLoad = useCallback((map) => setMap(map), []);
  const onUnmount = useCallback((map) => {
    setMap(null);
  }, []);

  useEffect(() => {
    initializeMap();
  }, [map, markers?.length]);

  const initializeMap = () => {
    setActiveMarker(null);

    if (map) {
      let bounds = new window.google.maps.LatLngBounds();

      if (markers?.length > 0) {
        // eslint-disable-next-line array-callback-return
        markers?.map((marker) => {
          bounds.extend({
            lat: marker.lat,
            lng: marker.lon,
          });
        });
        map.fitBounds(bounds);

        if (markers?.length === 1) {
          map.setZoom(singleMarkerZoom);
        }
      } else {
        map.setCenter({
          lat: initialRegion.lat,
          lng: initialRegion.lng,
        });
        map.setZoom(initialZoom);
      }
    }
  };

  const handleActiveMarker = (markerId) => {
    // console.log('activeMarker', activeMarker, 'marker.id', markerId);
    if (markerId === activeMarker) {
      return;
    }
    setActiveMarker(markerId);
  };

  const offsetMarker = (indexOfMarkersInSameLocation) => {
    let radius = 0.00000001;
    const radius_scale = 0.0000003;

    const golden_angle = Math.PI * (3 - Math.sqrt(5));
    indexOfMarkersInSameLocation.forEach((index) => {
      radius = index * radius_scale;
      const angle = index * golden_angle;
      markers[index].initialLat = markers[index].lat;
      markers[index].initialLon = markers[index].lon;
      markers[index].lat = markers[index].lat + radius * Math.cos(angle);
      markers[index].lon = markers[index].lon + radius * Math.sin(angle);
    });
  };

  const renderMainMap = (clusterer) => {
    const markerPositions = {};
    const renderedMarkers = [];
    let indexOfMarkersInSameLocation = [];
    markers.forEach((marker, index) => {
      const positionKey = `${marker.lat}_${marker.lon}`;
      if (markerPositions[positionKey]) {
        indexOfMarkersInSameLocation.push(index);
      }
      markerPositions[positionKey] = true;
    });
    offsetMarker(indexOfMarkersInSameLocation);
    markers.forEach((marker) => {
      if (marker.initialLat) {
        let line = new window.google.maps.Polyline({
          path: [
            { lat: marker.initialLat, lng: marker.initialLon },
            { lat: marker.lat, lng: marker.lon },
          ],
          geodesic: true,
          strokeColor: "#000000",
          strokeOpacity: 1.0,
          strokeWeight: 1,
        });
        line.setMap(map);
      }

      const renderedMarker = (
        <Marker
          key={marker.id}
          position={{
            lat: marker.lat,
            lng: marker.lon,
          }}
          onClick={() => handleActiveMarker(marker.id)}
          icon={markerIcon(marker)}
          clusterer={enableClustering === true ? clusterer : undefined}
        >
          {activeMarker &&
            activeMarker === marker?.id &&
            infoWindow &&
            infoWindow(marker, (onClose) => {
              setActiveMarker(null);
            })}
        </Marker>
      );

      renderedMarkers.push(renderedMarker);
    });

    return renderedMarkers;
  };

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          options={mapOptions ? mapOptions : initialMapOptions}
          mapContainerStyle={containerStyle}
          defaultZoom={initialZoom}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onClick={() => setActiveMarker(null)}
        >
          {enableClustering === true ? (
            <MarkerClusterer options={markerClustererOptions}>
              {(clusterer) => <>{renderMainMap(clusterer)}</>}
            </MarkerClusterer>
          ) : (
            renderMainMap()
          )}
        </GoogleMap>
      ) : null}
    </>
  );
};

export default observer(MapWrapper);
