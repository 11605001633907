import { Typography } from "@mui/material";
import { themeColors } from "../../Config/Themes/theme";
import { useTheme } from "@mui/material/styles";

const BorderSection = ({
  children,
  title,
  required = false,
  requiredText = null,
}) => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);

  return (
    <section className="py-5">
      <div
        className="relative border rounded-xl py-10 px-10"
        style={{
          borderColor: colors.greyAccent.dark4,
        }}
      >
        <Typography
          variant="h2"
          fontSize={20}
          className="absolute -top-[15px] left-[50px] px-5"
          color={colors.greyAccent.dark}
          sx={{ backgroundColor: colors.greyAccent.light }}
        >
          {title} {required && <span className="text-red-500">*</span>}
        </Typography>

        {requiredText && (
          <Typography
            variant="h6"
            fontSize={12}
            color={colors.greyAccent.dark}
            className="absolute top-[10px] right-[50px]"
          >
            <span className="text-red-500">*</span> {requiredText}
          </Typography>
        )}

        {children}
      </div>
    </section>
  );
};

export default BorderSection;
