import { observer } from "mobx-react";
import {
  TextField,
  Autocomplete,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { useNotificationsStore } from "../../../Environment/Stores/NotificationsStore";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import NotificationsTable from "./NotificationsTable";
import Breadcrumb from "../../../../Components/Breadcrumb/Breadcrumb";
import PageTitle from "../../../../Components/PageTitle";
import TextFieldInput from "../../../../Components/TextField";
import Loader from "../../../../MainAppComponents/Loader";

const NotificationsList = () => {
  const notificationsStore = useNotificationsStore();
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  // const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  // const handleDropDownChange = (event, value) => {
  //   if (value) {
  //     setSelectedCategoryId(value.id);
  //   } else {
  //     setSelectedCategoryId(null);
  //   }
  // };

  useEffect(() => {
    setLoading(true);
    notificationsStore
      .getAllNotifications()
      .then((resp) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [notificationsStore.notificationsList?.length, notificationsStore]);

  return (
    <>
      <Breadcrumb pathList={["Περιβάλλον - Ποιότητα αέρα", "Ειδοποιήσεις"]} />
      <div className="mt-8 ml-[35px]">
        <PageTitle
          title={`Ειδοποιήσεις (${
            notificationsStore.notificationsList?.length || "-"
          }) `}
        />
      </div>

      <div className="mt-8 ml-[34px] flex">
        <div>
          <TextFieldInput
            className="w-[230px] bg-[#FFFFFF]"
            type="text"
            fullWidth
            label={"Αναζήτηση"}
            value={inputValue}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
        </div>
        {/* <div className="ml-6">
          <Autocomplete
            fullWidth
            value={
              notificationsStore.notificationCategoriesList?.find(
                (category) => category.id === selectedCategoryId
              ) || null
            }
            onChange={handleDropDownChange}
            options={notificationsStore.notificationCategoriesList}
            getOptionLabel={(option) => option?.name || ""}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderInput={(params) => (
              <TextField
                className="w-[230px] bg-[#FFFFFF]"
                fullWidth
                {...params}
                label={"Κατηγορία"}
              />
            )}
          />
        </div> */}
      </div>

      {!loading ? (
        <div className="mt-6 ml-[20px] w-[97%]">
          {notificationsStore.notificationsList?.length > 0 ? (
            <NotificationsTable
              notifications={notificationsStore.notificationsList}
              inputValue={inputValue}
              // selectedCategoryId={selectedCategoryId}
            />
          ) : (
            <Typography variant="h5" className="ml-4 pt-2">
              {" "}
              Δεν υπάρχουν δεδομένα αυτήν την στιγμή{" "}
            </Typography>
          )}
        </div>
      ) : (
        <Loader className="flex justify-center items-center p-20" size={30} />
      )}
    </>
  );
};

export default observer(NotificationsList);
