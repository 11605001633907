import { useMemo } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { googleApiKey } from "../Config/constant";

const nonce = "nonce";

export default function useMapLoader() {
  const libraries = useMemo(() => ["places", "visualization"], []);

  const loader = useJsApiLoader({
    id: "script-loader",
    googleMapsApiKey: googleApiKey,
    libraries,
    language: "el",
    region: "gr",
    nonce,
  });

  return useMemo(() => loader, [loader]);
}
