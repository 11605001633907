import { useState, useRef, useEffect, useCallback } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import Badge from "@mui/material/Badge";

// project imports
import MainCard from "../../Theme/Cards/MainCard";
import Transitions from "../../Theme/Extended/Transitions";
import RoundIcon from "../../RoundIcon/RoundIcon";

// assets
import { observer } from "mobx-react";
import { useMapStore } from "../../../Stores/MapStore";
import { themeColors } from "../../../Config/Themes/theme";
import MyDatePicker from "../../MyDatePicker/MyDatePicker";
import { useTranslation } from "react-i18next";
import SubmitButton from "../../Theme/Extended/SubmitButton";
import AnimateButton from "../../Theme/Extended/AnimateButton";
import {
  highBinFullnessKey,
  lowBinFullnessKey,
  mediumBinFullnessKey,
  superLowBinFullnessKey,
} from "../../../Config/constant";
import Accordions from "../../Accordion/Accordion";

// ==============================|| FILTER MENU ||============================== //

const TextualInformationTabFilter = ({
  hasStartDate = false,
  hasEndDate = false,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  // const mapStore = useMapStore();
  const {
    allFilter,
    thresholdsFilter,
    typeFilter,
    trashBinsTypes,
    historicMapStartDate,
    historicMapEndDate,
    setHistoricMapStartDate,
    setHistoricMapEndDate,
    submitTextualInformationMapDates,
    toggleThresholdsStatus,
    toggleTypeStatus,
    resetFilters,
    initializeData,
  } = useMapStore();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const anchorRef = useRef(null);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const prevOpen = useRef(open);

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }
    prevOpen.current = open;
  }, [open]);

  useEffect(() => {}, [historicMapStartDate, historicMapEndDate]);

  useEffect(() => {}, [thresholdsFilter, typeFilter]);

  useEffect(() => {}, [allFilter]);

  useEffect(() => {
    resetFilters();
    initializeData();
  }, []);

  /****************************** Update values in mobx ******************************/
  const onSubmitHistoricMapDates = () => {
    if (loading) return;

    setLoading(true);

    submitTextualInformationMapDates(hasStartDate, hasEndDate).finally(() => {
      setLoading(false);
    });
  };

  const handleToggleThresholdStatus = (id) => {
    toggleThresholdsStatus(id);
    forceUpdate();
  };

  const handleToggleTypeStatus = (id) => {
    toggleTypeStatus(id);
    forceUpdate();
  };

  const renderTitle = (threshold, index) => {
    return (
      <div className="flex items-center justify-center flex-col">
        <div>{t(threshold.translationKey)}</div>
        {index === 0 && <div>{`0-${thresholdsFilter[0].value}%`}</div>}
        {index === 1 && (
          <div>{`${thresholdsFilter[0].value + 1}-${
            thresholdsFilter[1].value
          }%`}</div>
        )}
        {index === 2 && (
          <div>{`${thresholdsFilter[1].value + 1}-${
            thresholdsFilter[2].value
          }%`}</div>
        )}
        {index === 3 && (
          <div>{`${thresholdsFilter[2].value + 1}-${
            thresholdsFilter[3].value
          }%`}</div>
        )}
      </div>
    );
  };

  /****************************** Custom Helper Components ******************************/

  // eslint-disable-next-line consistent-return
  const renderBadge = (thresholdId) => {
    let bgColor = null;

    const badgeElm = () => {
      return (
        <span
          className="rounded-[25px] px-[5px] inline-block leading-none text-center whitespace-nowrap align-baseline font-bold"
          style={{ background: bgColor }}
        >
          &nbsp;
        </span>
      );
    };

    switch (thresholdId) {
      case superLowBinFullnessKey:
        bgColor = colors.primary.main;
        return badgeElm();

      case lowBinFullnessKey:
        bgColor = colors.yellowAccent.main;
        return badgeElm();

      case mediumBinFullnessKey:
        bgColor = colors.orangeAccent.main;
        return badgeElm();

      case highBinFullnessKey:
        bgColor = colors.redAccent.main;
        return badgeElm();

      default:
        bgColor = colors.primary.main;
        return badgeElm();
    }
  };

  const renderThreshold = (threshold, index) => (
    <div className="flex items-center justify-between p-1" key={threshold.id}>
      <div className="flex items-center justify-between">
        <Checkbox
          disabled
          value={threshold?.status}
          checked={threshold?.status}
          onChange={() => handleToggleThresholdStatus(threshold.id)}
          {...label}
        />
        {renderBadge(threshold?.id)}
        <Typography
          className="ml-4"
          component="span"
          variant="h6"
          sx={{ fontWeight: 300 }}
        >
          {renderTitle(threshold, index)}
        </Typography>
      </div>
    </div>
  );

  const renderType = (type) => (
    <div className="flex items-center justify-between p-1 " key={type.id}>
      <div className="flex items-center justify-between">
        <Checkbox
          disabled
          value={type?.status}
          checked={type?.status}
          onChange={() => handleToggleTypeStatus(type.id)}
          {...label}
        />
        <RoundIcon type={type.type} id={type.id} />
        <Typography
          className="ml-2"
          component="span"
          variant="h6"
          sx={{ fontWeight: 340 }}
        >
          {type?.description}
        </Typography>
      </div>
    </div>
  );

  return (
    <>
      <Accordions>
        <div>
          <MainCard
            className="w-full flex items-center justify-center bg-[#F9F9F9]"
            border={false}
            //elevation={16}
            content={false}
            //boxShadow
            //   shadow={theme.shadows[16]}
          >
            <Box className="w-full" sx={{ p: 2 }}>
              <Stack>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <div className="w-full flex items-end justify-end">
                    {historicMapStartDate && historicMapEndDate ? (
                      <div className="ml-4">
                        <AnimateButton>
                          <SubmitButton
                            className="capitalize text-lg"
                            buttonTitle="submit"
                            sx={{
                              backgroundColor: colors.greenAccent.main,
                            }}
                            disableElevation
                            loading={loading}
                            type="submit"
                            onClick={onSubmitHistoricMapDates}
                          />
                        </AnimateButton>
                      </div>
                    ) : null}
                  </div>
                </Stack>
                <Grid padding="11px">
                  <Divider />
                </Grid>
                <div className="sm:block md:block lg:flex items-center justify-evenly">
                  <div className="m-[6PX] w-[50%]">
                    <Typography
                      component="span"
                      variant="h5"
                      sx={{ fontWeight: "bold", marginLeft: "10px" }}
                    >
                      {t("historicMapFilter.Fullness")}
                    </Typography>
                    <div className="mt-4 sm:block md:block lg:grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 rounded-[12px] bg-[#FFFFFF] p-2 border-[1px] border-[#D1D5DA]">
                      {thresholdsFilter.map((o, index) =>
                        renderThreshold(o, index)
                      )}
                    </div>
                  </div>

                  <div className="min-h-[156px] m-[6PX] w-[100%]">
                    <Typography
                      component="span"
                      variant="h5"
                      sx={{ fontWeight: "bold" }}
                    >
                      {t("historicMapFilter.Type")}
                    </Typography>
                    <div className=" mt-4 rounded-[12px] bg-[#FFFFFF] p-[15px] border-[1px] border-[#D1D5DA]">
                      <span className="sm:block md:block lg:grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                        {trashBinsTypes.map((o) => renderType(o))}
                      </span>
                    </div>
                  </div>

                  <div className="m-[6PX]">
                    {hasStartDate && hasEndDate ? (
                      <Typography
                        component="span"
                        variant="h5"
                        sx={{ fontWeight: "bold" }}
                      >
                        {t("historicMapFilter.Dates")}
                      </Typography>
                    ) : (
                      <Typography
                        component="span"
                        variant="h5"
                        sx={{ fontWeight: "bold" }}
                      >
                        {t("historicMapFilter.Dates")}
                      </Typography>
                    )}

                    <div className=" mt-4 min-h-[125px] rounded-[12px] bg-[#FFFFFF] p-[9px] border-[1px] border-[#D1D5DA]">
                      <div>
                        {hasStartDate === true ? (
                          <MyDatePicker
                            value={historicMapStartDate}
                            maxDate={new Date()}
                            onChange={setHistoricMapStartDate}
                            placeholder={t("reports.selectStartDate")}
                          />
                        ) : null}
                      </div>

                      <div className="mt-6">
                        {hasEndDate === true ? (
                          <>
                            <MyDatePicker
                              value={historicMapEndDate}
                              maxDate={new Date()}
                              onChange={setHistoricMapEndDate}
                              placeholder={t("reports.selectEndDate")}
                            />
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  {/* <div>
                    <Typography
                      className=""
                      component="span"
                      variant="h6"
                      sx={{ fontWeight: "bold" }}
                    >
                      ALERTS
                    </Typography>
                    <div className="mt-5">
                      {alertFilter.map((o) => renderAlert(o))}
                    </div>
                  </div> */}
                </div>
                {/* <Grid padding="11px">
              <Divider />
            </Grid> */}
              </Stack>
            </Box>
          </MainCard>
        </div>
      </Accordions>
    </>
  );
};

export default observer(TextualInformationTabFilter);
