import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import clsx from "clsx";
import { useTheme } from "@mui/material/styles";
import { themeColors } from "../../Config/Themes/theme";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const DropdownButton = ({ label, options, onSelect = null, className }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option) => {
    onSelect ? onSelect(option) : option.onSelect();
    handleClose();
  };

  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const classes = clsx("capitalize text-sm", className);

  return (
    <div>
      <Button
        variant="contained"
        aria-controls="dropdown-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes}
        disableElevation
        endIcon={anchorEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        sx={{
          backgroundColor: colors.buttonsActive.main,
          color: colors.buttonsActive.text,
          "&:hover": {
            backgroundColor: colors.buttonsActive.hover,
          },
          "&:disabled": {
            backgroundColor: colors.buttonsActive.disabled,
            color: colors.buttonsActive.text,
          },
        }}
      >
        <span
          style={{
            textTransform: "none",
          }}
        >
          {label}
        </span>
      </Button>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        slotProps={{
          paper: {
            style: {
              width: anchorEl?.clientWidth || "160px",
            },
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            onClick={() => handleOptionClick(option)}
            className="flex items-center justify-between w-full"
          >
            {option.label}
            {option.icon ? (
              <img src={option.icon} alt="Icon" width="23px" />
            ) : (
              <></>
            )}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default DropdownButton;
