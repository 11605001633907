import { CreateDoughnutCenter, CreatePerson } from "../../Utils/GraphHelper";

export const OptionsDoughnut = () => {
  return {
    maintainAspectRatio: false,
    events: [],
    layout: {
      padding: {
        bottom: 10,
        left: 16,
        right: 16,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
      },
      datalabels: {
        display: false,
        color: "#6a6b6d",
        align: "end",
        offset: 12,
        font: {
          size: 14,
          weight: "600",
        },
        formatter: function (value) {
          return "";
        },
      },
    },
  };
};

export const DataDoughnut = (data, colors) => {
  return {
    labels: ["Συνδεδεμένοι"],
    datasets: [
      {
        label: "",
        data: [1],
        borderColor: colors,
        backgroundColor: colors,
        cutout: 45,
        rotation: 50,
      },
    ],
  };
};

export const centerImageDoughnut = (connectedDevices) => {
  return {
    id: "centerImageDoughnut",
    afterDatasetDraw: (chart, args, pluginOptions) => {
      const { ctx } = chart;

      const x = chart.getDatasetMeta(0).data[0].x;
      const y = chart.getDatasetMeta(0).data[0].y;

      CreatePerson(ctx, x - 4.5, y - 13, "#252525");
      CreateDoughnutCenter(ctx, x, y);
    },
    defaults: {
      devices: connectedDevices,
    },
  };
};

export default {
  OptionsDoughnut,
  DataDoughnut,
  centerImageDoughnut,
};
