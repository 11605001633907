import { makeObservable, observable, action } from "mobx";
import { createContext, useContext } from "react";
import LightsAgent from "../Api/LightsAgent/LightsAgent";

class NotificationsStore {
  notificationCategoriesList = [
    { id: 1, name: "Οδοφωτισμός" },
    { id: 2, name: "Wifi" },
    { id: 3, name: "Περιβάλλον" },
  ];

  notificationsList = [];

  constructor() {
    makeObservable(this, {
      notificationCategoriesList: observable,
      notificationsList: observable,
      getLightsAlerts: action,
    });
  }

  getLightsAlerts() {
    return LightsAgent.analysis
      .getAlerts()
      .then((alerts) => {
        this.notificationsList = alerts?.result;
      })
      .catch((e) => {});
  }
}

export const notificationsStore = new NotificationsStore();
export const NotificationsContext = createContext(notificationsStore);
export const NotificationsProvider = ({ children, store }) => (
  <NotificationsContext.Provider value={store}>
    {children}
  </NotificationsContext.Provider>
);
export const useNotificationsStore = () => useContext(NotificationsContext);
