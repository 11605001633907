import "./CircularPulse.css";

const CircularPulse = ({
  color = "deepskyblue",
  onClick,
  percentage = 100,
}) => {
  const maxDimension = 150;

  return (
    <div
      className="container"
      onClick={onClick}
      style={{
        width: (percentage * maxDimension) / 100,
        height: (percentage * maxDimension) / 100,
      }}
    >
      <div
        className="circle"
        style={{
          animationDelay: "0s",
          backgroundColor: color,
          width: (percentage * maxDimension) / 100,
          height: (percentage * maxDimension) / 100,
        }}
      ></div>
      <div
        className="circle"
        style={{
          animationDelay: "1s",
          backgroundColor: color,
          width: (percentage * maxDimension) / 100,
          height: (percentage * maxDimension) / 100,
        }}
      ></div>
      <div
        className="circle"
        style={{
          animationDelay: "2s",
          backgroundColor: color,
          width: (percentage * maxDimension) / 100,
          height: (percentage * maxDimension) / 100,
        }}
      ></div>
      <div
        className="circle"
        style={{
          animationDelay: "3s",
          backgroundColor: color,
          width: (percentage * maxDimension) / 100,
          height: (percentage * maxDimension) / 100,
        }}
      ></div>
    </div>
  );
};

export default CircularPulse;
