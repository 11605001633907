import { observer } from "mobx-react";
import SubmitButton from "../../../Components/Buttons/SubmitButton";
import { Autocomplete, Box, Modal, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import MainCard from "../../../Components/Theme/Cards/MainCard";
import CancelButton from "../../../Components/Buttons/CancelButton";
import { useAnnouncementsStore } from "../../../Stores/AnnouncementsStore";
import { error, success } from "../../../Utils/Toast/Toast";
import { useAuthStore } from "../../../Stores/AuthStore";
import { baseUrl } from "../../../Config/constant";

const AnnouncementEditModal = ({
  open,
  handleClose,
  announcementId,
  refreshList,
}) => {
  const announcementsStore = useAnnouncementsStore();
  const [inputValue, setInputValue] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [announcementData, setAnnouncementData] = useState(null);
  const [loading, setLoading] = useState(false);
  const authStore = useAuthStore();
  const apiBaseUrl = baseUrl;

  const updateAnnouncement = (id, requestBody) => {
    return fetch(`${apiBaseUrl}/Announcement?id=${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authStore.token}`,
      },
      body: JSON.stringify(requestBody),
    });
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleDropDownChange = (event, value) => {
    if (value) {
      setSelectedStatus(value.key);
    } else {
      setSelectedStatus(null);
    }
  };

  const resetFields = () => {
    setInputValue("");
    setSelectedStatus(null);
  };

  const handleUpdateAnnouncement = () => {
    const payload = {
      text: inputValue,
      status: selectedStatus,
    };
    setLoading(true);
    updateAnnouncement(announcementId, payload)
      .then((resp) => {
        success("Η ανακοίνωση ενημερώθηκε επιτυχώς");
        handleClose(false);
        refreshList();
        resetFields();
      })
      .catch((err) => {
        error("Κάτι πήγε στραβά κατα την ενημέρωση της ανακοίνωσης");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (announcementId) {
      setLoading(true);
      announcementsStore
        .getAnnouncementById(announcementId)
        .then((response) => {
          setAnnouncementData(response.result);
          setInputValue(announcementData?.text);
          setSelectedStatus(announcementData?.status);
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    announcementId,
    setAnnouncementData,
    announcementData?.length,
    announcementData?.status,
    announcementData?.text,
    announcementsStore,
  ]);

  return (
    <Modal
      className="flex items-center justify-center"
      open={open}
      onClose={() => {
        resetFields();
        handleClose(false);
      }}
      aria-labelledby="announcement-modal"
    >
      <MainCard className="m-4 max-w-[70vw] min-w-[70vw]  min-h-[35vw] p-8 bg-[#F1F1F1]">
        <div className="modal-paper">
          <Typography className="mt-4 text-[#565A87]" variant="h3">
            Eπεξεργασία ανακοίνωσης
          </Typography>
          <Box className="w-full mt-6 border-b-2 border-[#B9B9B9]" />
          <div>
            <Typography className="mt-8" variant="h4">
              <b> Κείμενο ανακοίνωσης </b>
            </Typography>
            <TextField
              multiline
              rows={5}
              type="text"
              className="mt-6 bg-[#FFFF] w-full"
              label="Ανακοίνωση"
              value={inputValue}
              onChange={handleChange}
            />

            <Autocomplete
              value={
                announcementsStore.announcementsStatuses.find(
                  (status) => status.key === selectedStatus
                ) || null
              }
              onChange={handleDropDownChange}
              options={announcementsStore.announcementsStatuses}
              getOptionLabel={(option) => option?.name || ""}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              renderInput={(params) => (
                <TextField
                  className="bg-[#FFFFFF] mt-8"
                  fullWidth
                  {...params}
                  label={"Κατάσταση"}
                />
              )}
            />
          </div>
          <div className="flex justify-end items-end mt-8 mr-2">
            <CancelButton
              className="mr-6"
              type="button"
              onClick={() => {
                resetFields();
                handleClose(false);
              }}
              size="large"
              label="Ακύρωση"
            />
            <SubmitButton
              loading={loading}
              type="button"
              onClick={handleUpdateAnnouncement}
              size="large"
              label="Αποθήκευση"
            />
          </div>
        </div>
      </MainCard>
    </Modal>
  );
};

export default observer(AnnouncementEditModal);
