import React, { FC, useEffect } from "react";
import GaugeComponent from "react-gauge-component";
import { MEASUREMENT_QUALITY } from "../../Config/Constants/measurements";
import { useTheme } from "@mui/material";
import { themeColors } from "../../../../Config/Themes/theme";
import { FormatNumberNoDecimals } from "../../common/FormatNumber/FormatNumberNoDecimals";

type GaugePointerTypeOptions = "needle" | "blob" | "arrow";
interface GaugeProps {
  minValue?: number;
  maxValue?: number;
  value: number;
  data: any;
  icon?: any;
  bgColor?: string;
  pointerType?: GaugePointerTypeOptions;
  size?: number;
}

const Gauge: FC<GaugeProps> = ({
  minValue,
  maxValue,
  value = 0,
  data,
  icon,
  bgColor = themeColors("light").cyanAccent.light,
  pointerType = "needle",
  size = 350,
}) => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);

  const renderCustomMainLabel = () => {
    if (pointerType === "blob") {
      return (
        <div
          className="flex items-center justify-center w-full h-full"
          style={{
            marginTop: -size / 2,
          }}
        >
          <div
            className={`text-base font-bold w-12 h-12 flex flex-col items-center justify-center box-border p-2 rounded-full`}
            style={{
              backgroundColor: colors.cyanAccent.light,
              minWidth: size / 2.5,
              minHeight: size / 2.5,
              color: colors.whiteAccent.main,
            }}
          >
            {FormatNumberNoDecimals(value)}%{icon}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="flex items-center justify-center w-full"
          style={{
            marginTop: -size / 4,
          }}
        >
          <div
            className={`text-2xl font-bold w-12 h-12 flex items-center justify-center box-border p-2 rounded-full bg-white`}
            style={{
              border: `3px solid ${data?.color}`,
              color: data?.color,
            }}
          >
            {value}
          </div>
        </div>
      );
    }
  };

  const getGaugeColors = () => {
    if (pointerType === "blob") {
      return [colors.cyanAccent.light];
    } else if (minValue === 1 && maxValue === 6) {
      return [
        MEASUREMENT_QUALITY.VERY_GOOD.color,
        MEASUREMENT_QUALITY.GOOD.color,
        MEASUREMENT_QUALITY.MEDIUM.color,
        MEASUREMENT_QUALITY.LOW.color,
        MEASUREMENT_QUALITY.VERY_LOW.color,
        MEASUREMENT_QUALITY.XLOW.color,
      ];
    }
  };

  const getGaugeSubArcs = () => {
    if (minValue === 1 && maxValue === 6) {
      return [
        {
          length: 0.16,
          tooltip: {
            text: MEASUREMENT_QUALITY.VERY_GOOD.label,
          },
        },
        {
          length: 0.16,
          tooltip: {
            text: MEASUREMENT_QUALITY.GOOD.label,
          },
        },
        {
          length: 0.16,
          tooltip: {
            text: MEASUREMENT_QUALITY.MEDIUM.label,
          },
        },
        {
          length: 0.16,
          tooltip: {
            text: MEASUREMENT_QUALITY.LOW.label,
          },
        },
        {
          length: 0.16,
          tooltip: {
            text: MEASUREMENT_QUALITY.VERY_LOW.label,
          },
        },
        {
          length: 0.16,
          tooltip: {
            text: MEASUREMENT_QUALITY.XLOW.label,
          },
        },
      ];
    }
  };

  const shrinkTickLabel = (value) => {
    const words = value.split(/\s+/);
    const firstWord = words[0];
    const remainingSentence = words.slice(1).join(" ");
    const truncatedWords =
      firstWord.length >= 4
        ? `${firstWord.substring(0, 1)}. ${remainingSentence}`
        : `${firstWord} ${remainingSentence}`;
    return truncatedWords;
  };

  const getGaugeTickLabels = (value) => {
    if (pointerType !== "blob" && minValue === 1 && maxValue === 6) {
      if (value === 1) {
        return shrinkTickLabel(MEASUREMENT_QUALITY.VERY_GOOD.label);
      } else if (value === 6) {
        return shrinkTickLabel(MEASUREMENT_QUALITY.XLOW.label);
      } else {
        return "";
      }
    } else {
      return value;
    }
  };

  const calculateGaugeHeight = () => {
    const halfSize = size / 2;

    if (pointerType !== "blob") {
      return halfSize + 40;
    } else {
      if (size >= 400) {
        return halfSize + 100;
      } else if (size >= 350 && size < 400) {
        return halfSize + 80;
      } else if (size >= 250 && size < 350) {
        return halfSize + 60;
      } else if (size >= 150 && size < 250) {
        return halfSize + 50;
      } else if (size >= 100 && size < 150) {
        return halfSize + 35;
      } else {
        return halfSize + 55;
      }
    }
  };

  return (
    <div className="flex items-center justify-center flex-col">
      <GaugeComponent
        minValue={minValue}
        maxValue={maxValue}
        value={value}
        type={pointerType === "blob" ? "radial" : "semicircle"}
        labels={{
          valueLabel: {
            hide: true,
          },
          tickLabels: {
            type: "outer",
            defaultTickValueConfig: {
              formatTextValue: (value) => getGaugeTickLabels(value),
              style: {
                fontSize: "0.5rem",
                fontWeight: "semi-bold",
                fill: colors.blackAccent.main,
              },
            },
          },
        }}
        style={{
          width: size,
          height: calculateGaugeHeight(),
        }}
        arc={{
          width: pointerType === "blob" ? 0.1 : 0.25,
          padding: 0,
          cornerRadius: 1,
          colorArray: getGaugeColors(),
          subArcs: getGaugeSubArcs(),
        }}
        pointer={{
          type: pointerType,
          elastic: true,
          animationDelay: 0,
          color: pointerType === "blob" ? bgColor : data?.color,
          width: pointerType === "blob" ? 20 : 10,
          baseColor: colors.whiteAccent.main,
        }}
      />
      {renderCustomMainLabel()}
    </div>
  );
};

export default Gauge;
