import { Route, Routes } from "react-router-dom";
import { observer } from "mobx-react";
import WifiPage from "../MainAppComponents/WifiPage/WifiPage";
import Dashboard from "../Pages/Dashboard/Dashboard";
import CreateReport from "../Pages/CreateReport/CreateReport";
import Notifications from "../Pages/Notifications/Notifications";
import SoftwareManagement from "../Pages/SoftwareManagement/SoftwareManagement";

// Wraps all pages which share common layout
//
const wrap = (element, pageProps) => (
  <WifiPage {...pageProps}>{element}</WifiPage>
);

const WifiRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={wrap(<Dashboard />)} />
      <Route path="/reports" element={wrap(<CreateReport />)} />
      <Route
        path="/software-management"
        element={wrap(<SoftwareManagement />)}
      />
      <Route path="/notifications" element={wrap(<Notifications />)} />
    </Routes>
  );
};
export default observer(WifiRoutes);
