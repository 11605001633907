import { useState, useEffect } from "react";

function useApiIntervalCall(apiCall, interval, initialData = null) {
  const [data, setData] = useState(initialData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        var response = await apiCall();
        setData(response.result);
      } catch (error) {}
    };

    const intervalId = setInterval(() => {
      fetchData();
    }, interval * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [apiCall, interval]);

  return data;
}

export default useApiIntervalCall;
