import {
  MEASUREMENTS_GAS,
  MEASUREMENTS_MAIN,
  MEASUREMENT_QUALITY,
} from "../../Config/Constants/measurements";
import AirQualityIndex from "./AirQualityIndex";
import BarometricPressureChart from "../../Components/BarometricPressureChart/BarometricPressureChart";
import TemperatureChart from "../../Components/TemperatureChart/TemperatureChart";
import HumidityChart from "../../Components/HumidityChart/HumidityChart";
import HeatMapChart from "../../Components/HeatMapComponent/HeatMapComponent";
import { useEffect, useState, useMemo } from "react";
import EnvironmentAgent from "../../Api/EnvironmentAgent/EnvironmentAgent";
import Breadcrumb from "../../../../Components/Breadcrumb/Breadcrumb";
import PageTitle from "../../../../Components/PageTitle";
import NoDataCard from "../../../../Components/NoDataCard/NoDataCard";
import CityMap from "../../../../Components/Map/CityMap";
import CircularPulse from "../../../../Components/CircularPulse/CircularPulse";
import Loader from "../../../../MainAppComponents/Loader";
import EnvironmentalStationInfoWindow from "../../Components/EnvironmentalStationInfoWindow/EnvironmentalStationInfoWindow";
import CurrentDateTime from "../../Components/CurrentDateTime/CurrentDateTime";
import dayjs from "dayjs";
import { FormatNumberNoDecimals } from "../../common/FormatNumber/FormatNumberNoDecimals";

const Analysis = () => {
  const [lastEvents, setLastEvents] = useState();
  const [aqi, setAqi] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState();
  const [markers, setMarkers] = useState([]);
  const [aqiObject, setAqiObject] = useState();
  const [envData, setEnvData] = useState([]);
  const [barometricPressureData, setBarometricPressureData] = useState([]);
  const [temperatureData, setTemperatureData] = useState([]);
  const [humidityData, setHumidityData] = useState([]);

  // Initial data for the components for testing purposes
  const barometricPressureDummyData = [
    {
      areaName: "Historical Center",
      datesRange: "12 - 16 Ιαν 2024",
      temperatures: [
        "6°/8°",
        "6°/9°",
        "6°/11°",
        "4°/7°",
        "4°/10°",
        "8°/14°",
        "7°/15°",
      ],
      labels: [
        "10 Ιαν 24",
        "11 Ιαν 24",
        "12 Ιαν 24",
        "13 Ιαν 24",
        "14 Ιαν 24",
        "15 Ιαν 24",
        "16 Ιαν 24",
      ],
      data: [900, 1065, 1010, 1200, 1045, 800, 1050],
    },
    // {
    //   areaName: "Historical Center 2",
    //   datesRange: "10 - 16 Ιαν 2024",
    //   temperatures: [
    //     "6°/8°",
    //     "6°/9°",
    //     "6°/11°",
    //     "4°/7°",
    //     "4°/10°",
    //     "8°/14°",
    //     "7°/15°",
    //   ],
    //   labels: [
    //     "10 Ιαν 24",
    //     "11 Ιαν 24",
    //     "12 Ιαν 24",
    //     "13 Ιαν 24",
    //     "14 Ιαν 24",
    //     "15 Ιαν 24",
    //     "16 Ιαν 24",
    //   ],
    //   data: [1025, 1065, 1055, 1035, 1045, 1020, 1050],
    // },
    // {
    //   areaName: "Historical Center 3",
    //   datesRange: "10 - 16 Ιαν 2024",
    //   temperatures: [
    //     "6°/8°",
    //     "6°/9°",
    //     "6°/11°",
    //     "4°/7°",
    //     "4°/10°",
    //     "8°/14°",
    //     "7°/15°",
    //   ],
    //   labels: [
    //     "10 Ιαν 24",
    //     "11 Ιαν 24",
    //     "12 Ιαν 24",
    //     "13 Ιαν 24",
    //     "14 Ιαν 24",
    //     "15 Ιαν 24",
    //     "16 Ιαν 24",
    //   ],
    //   data: [900, 1065, 1010, 1200, 1045, 800, 1050],
    // },
  ];

  const temperatureDummyData = [
    {
      areaName: "Historical Center",
      labels: [
        "00:00",
        "03:00",
        "06:00",
        "09:00",
        "12:00",
        "18:00",
        "21:00",
        "22:00",
        "23:00",
      ],
      data: [49, 20, 15, 35, 60, 32, 9, 10, 11],
    },
  ];

  const humidityDummyData = [
    {
      areaName: "Historical Center",
      labels: ["00:00", "06:00", "12:00", "18:00"],
      data: [25, 50, 75, 100],
    },
  ];

  const findCenter = (markers) => {
    const total = markers?.reduce(
      (acc, marker) => {
        acc.lat += marker?.lat;
        acc.lng += marker?.lng;
        return acc;
      },
      { lat: 0, lng: 0 }
    );

    return {
      lat: total?.lat / markers?.length,
      lng: total?.lng / markers?.length,
    };
  };

  const data = useMemo(() => {
    if (!lastEvents || !lastEvents?.length > 0) {
      return null;
    }

    return {
      [MEASUREMENTS_GAS.CO2.id]: lastEvents[0][MEASUREMENTS_GAS.CO2.id],
      [MEASUREMENTS_GAS.NO2.id]: lastEvents[0][MEASUREMENTS_GAS.NO2.id],
      [MEASUREMENTS_GAS.NO.id]: lastEvents[0][MEASUREMENTS_GAS.NO.id],
      [MEASUREMENTS_GAS.O3.id]: lastEvents[0][MEASUREMENTS_GAS.O3.id],
      [MEASUREMENTS_GAS.SO2.id]: lastEvents[1][MEASUREMENTS_GAS.SO2.id],
      [MEASUREMENTS_GAS.PM1.id]: lastEvents[1][MEASUREMENTS_GAS.PM1.id],
      [MEASUREMENTS_GAS.PM10.id]: lastEvents[1][MEASUREMENTS_GAS.PM10.id],
      [MEASUREMENTS_GAS.PM25.id]: lastEvents[1][MEASUREMENTS_GAS.PM25.id],
      [MEASUREMENTS_MAIN.TEMPERATURE.id]:
        lastEvents[1][MEASUREMENTS_MAIN.TEMPERATURE.id],
      [MEASUREMENTS_MAIN.PRESSURE.id]:
        lastEvents[1][MEASUREMENTS_MAIN.PRESSURE.id],
      [MEASUREMENTS_MAIN.HUMIDITY.id]:
        lastEvents[1][MEASUREMENTS_MAIN.HUMIDITY.id],
    };
  }, [lastEvents]);

  const getBarometricPressureData = () => {
    const todayDate = dayjs().format("YYYY-MM-DD");
    const startOfWeekDate = dayjs(todayDate)
      .subtract(7, "day")
      .format("YYYY-MM-DD");
    const dateRangeString = `${dayjs(startOfWeekDate).format("DD")} - ${dayjs(
      todayDate
    ).format("DD MMM YY")}`;

    EnvironmentAgent.analysis
      .getEnvironmentalData(startOfWeekDate, todayDate)
      .then((response) => {
        const updatedBarometricPressureData = response?.result?.map(
          (station) => {
            const pressureData = station?.pressureData?.map((data) => ({
              timestamp: data.timestamp,
              value: data.value || 0,
            }));

            const temperatureData = station?.temperatureData?.map((data) => ({
              timestamp: data.timestamp,
              maxValue: data.maxValue || 0,
              minValue: data.minValue || 0,
            }));

            return {
              areaName: station.areaName,
              datesRange: dateRangeString,
              temperatures: {
                timestamp: temperatureData?.map((data) =>
                  dayjs(data.timestamp).format("DD MMM YY")
                ),
                minMax: temperatureData?.map(
                  (data) =>
                    `${FormatNumberNoDecimals(
                      data.minValue
                    )}°/${FormatNumberNoDecimals(data.maxValue)}°`
                ),
              },
              labels: pressureData?.map((data) =>
                dayjs(data.timestamp).format("DD MMM YY HH:MM").toUpperCase()
              ),
              data: pressureData?.map((data) => data.value),
            };
          }
        );

        setBarometricPressureData(updatedBarometricPressureData);
      })
      .catch((error) => console.error(error));
  };

  const getTemperatureData = () => {
    const todayDate = dayjs().format("YYYY-MM-DD");
    const startOfWeekDate = todayDate;

    EnvironmentAgent.analysis
      .getStationTemperaturesFromDateToDate(startOfWeekDate, todayDate)
      .then((response) => {
        const updatedTemperatureData = response?.result?.map((station) => ({
          stationName: station.stationName,
          date: dayjs(todayDate).format("DD - MMM - YY"),
          labels: station?.environmentEvents?.map((event) =>
            dayjs(event?.timestamp).format("HH:mm")
          ),
          data: station?.environmentEvents?.map((event) => event?.temp),
        }));
        setTemperatureData(updatedTemperatureData);
      })
      .catch((error) => console.error(error));
  };

  const getHumidityData = () => {
    const todayDate = dayjs().format("YYYY-MM-DD");
    const startOfWeekDate = todayDate;

    EnvironmentAgent.analysis
      .GetStationHumidityFromDateToDate(startOfWeekDate, todayDate)
      .then((response) => {
        const updatedHumidityData = response?.result?.map((station) => ({
          stationName: station.stationName,
          date: dayjs(todayDate).format("DD - MMM - YY"),
          labels: station?.environmentEvents?.map((event) =>
            dayjs(event?.timestamp).format("HH:mm")
          ),
          data: station?.environmentEvents?.map((event) => event?.humidity),
        }));

        setHumidityData(updatedHumidityData);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    const fetchLastEvents = async () => {
      setLoading(true);

      await EnvironmentAgent.analysis
        .getLastEvents()
        .then((response) => {
          setLastEvents(response?.result?.lastEnvironmentEvents);
          setAqi(response?.result?.aqi);
          setAqiObject(
            Object.values(MEASUREMENT_QUALITY)?.find(
              (item) => item.aqi === response?.result?.aqi
            )
          );
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setLoading(false);
        });
    };

    const fetchEnvironmentalStations = async () => {
      setLoading(true);

      await EnvironmentAgent.analysis
        .getEnvironmentalStation(true)
        .then((response) => {
          setMarkers(
            response?.result?.map((item) => ({
              id: item.id,
              lat: item.lat,
              lng: item.lon,
              title: item.name,
              description: item.description,
              status: item.status,
              onMarkerClick: () => setSelectedMarker(item),
              devices: item.environmentDevices,
            }))
          );
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setLoading(false);
        });
    };

    fetchLastEvents();
    fetchEnvironmentalStations();
    getBarometricPressureData();
    getTemperatureData();
    getHumidityData();
  }, []);

  useEffect(() => {
    if (markers?.length > 0) {
      const data = markers?.map((item) => ({
        id: item.id,
        lat: item.lat,
        lng: item.lng,
        title: item.title,
        address: item.description,
        status: item.status,
        devices: item.devices,
        markerRenderer: (
          <CircularPulse
            color={aqiObject?.color}
            percentage={aqiObject?.size}
          />
        ),
        aqi: aqi,
        color: aqiObject?.color,
        label: aqiObject?.label,
      }))[0];

      setEnvData([
        {
          ...data,
          onMarkerClick: () => setSelectedMarker(data),
        },
      ]);
    }
  }, [markers, aqi, aqiObject]);

  const initialRegion = {
    lat: 40.73061,
    lng: -73.935242,
  };

  if (loading) {
    return (
      <Loader className="flex justify-center items-center p-20" size={30} />
    );
  }

  if (!data) {
    return <NoDataCard />;
  }

  return (
    <section className="grid grid-cols-12">
      <section className="col-span-8 md:col-span-8 sm:col-span-12 xs:col-span-12 mr-5">
        <Breadcrumb pathList={["Περιβάλλον - Ποιότητα αέρα", "Ανάλυση"]} />
        <div className="flex items-center justify-between pt-2 pb-10 w-full">
          <PageTitle title="Ανάλυση τρέχουσας κατάστασης" />
          <CurrentDateTime />
        </div>
        <div className="flex items-start gap-2 overflow-x-auto w-full min-h-[450px]">
          <AirQualityIndex
            datasets={[
              {
                aqi: aqi,
                areaName: "Περιβαλλοντογικό σταθμός Τρίπολης",
                gaugeData: aqiObject,
                sensorData: data,
              },
            ]}
          />
          <BarometricPressureChart
            // datasets={barometricPressureDummyData}
            datasets={barometricPressureData}
          />
        </div>
        <div className="flex items-start gap-2 overflow-x-auto w-full min-h-[450px]">
          <TemperatureChart
            // datasets={temperatureDummyData}
            datasets={temperatureData}
          />
          <HumidityChart
            // datasets={humidityDummyData}
            datasets={humidityData}
          />
        </div>
      </section>

      <HeatMapChart
        className="sticky col-span-4 h-screen top-0"
        initialRegion={initialRegion}
        markers={envData}
        heatmapData={{ aqi: aqiObject, sensorData: data }}
        center={markers?.length > 0 ? findCenter(markers) : null}
        infoWindowRenderer={(item) => (
          <EnvironmentalStationInfoWindow data={item} />
        )}
        initialZoom={5}
        selectedMarker={selectedMarker}
        setSelectedMarker={setSelectedMarker}
      />
    </section>
  );
};

export default Analysis;
