import { action, computed, makeObservable, observable, reaction } from "mobx";
import { createContext, useContext } from "react";
import AuthSetAgent from "../Api/AuthSetAgent";
import { setBearerToken } from "../Utils/HttpClient/HttpClient";
import { decodeJwtToken } from "../Utils/Encryption/Encryption";
import { success } from "../Utils/Toast/Toast";
import {
  getRefreshTokenFromSessionStorage,
  getTokenFromSessionStorage,
  saveRefreshTokenToSessionStorage,
  saveTokenToSessionStorage,
} from "../Utils/Storage/SessionStorage";
import i18n from "../../../Config/Localization/i18n";

class AuthStore {
  authUser = {};

  token = undefined;

  refreshToken = null;

  navigate = null;

  intendedUrl = null;

  constructor() {
    makeObservable(this, {
      authUser: observable,
      userAuthenticated: computed,
      token: observable,
      refreshToken: observable,
      logout: action,
      login: action,
      setAuthUser: action,
      initializeTokens: action,
      isUser: computed,
      isAdmin: computed,
      authUserIsNotLoaded: computed,
      canUserAdjustThreshold: computed,
    });

    reaction(() => this.token, detectTokenValidity.bind(this));
    reaction(() => this.token, getUserProfile.bind(this));
    reaction(
      () => this.refreshToken,
      (refreshToken) => saveRefreshTokenToSessionStorage(refreshToken)
    );

    this.initializeTokens();
  }

  initializeTokens() {
    this.refreshToken = getRefreshTokenFromSessionStorage();
    this.token = getTokenFromSessionStorage();
  }

  logout() {
    this.authUser = null;
    sessionStorage.clear();
    setBearerToken(null);
  }

  get userAuthenticated() {
    return !!this.authUser;
  }

  async login({ email, password }) {
    const loginResp = await AuthSetAgent.user.login(email, password);
    const token = loginResp.token;
    const refreshToken = loginResp.refreshToken;
    this.saveTokens(token, refreshToken);

    const decoded = JSON.parse(atob(token.split(".")[1]));
    success("Welcome, " + decoded?.email);
  }

  saveTokens(token, refreshToken) {
    saveTokenToSessionStorage(token);
    saveRefreshTokenToSessionStorage(refreshToken);
    this.token = token;
  }

  setAuthUser(user) {
    this.authUser = user;
  }

  updateProfile(phoneNumber) {
    AuthSetAgent.user
      .updateProfile(phoneNumber)
      .then((response) => {
        success(i18n.t("profile.ProfileWasSuccessfullyUpdated"));
      })
      .catch((e) => {});
  }

  updatePassword(currentPassword, newPassword, confirmNewPassword) {
    return AuthSetAgent.user
      .changePassword(currentPassword, newPassword, confirmNewPassword)
      .then((response) => {
        success(i18n.t("forms.PasswordWasSuccessfullyUpdated"));
      })
      .catch((e) => {});
  }

  forgotPassword(email) {
    return AuthSetAgent.user
      .forgotPassword(email)
      .then((resp) => {
        success(i18n.t("forgotPassword.ConfirmationEmail"));
      })
      .catch((e) => {});
  }

  resetPassword(data, token) {
    return AuthSetAgent.user
      .resetPassword(data, token)
      .then((resp) => {
        success(i18n.t("resetPassword.YouHaveSuccessfullyChangedYourPassword"));
      })
      .catch((e) => {});
  }

  async updateToken() {
    const params = {
      token: getTokenFromSessionStorage(),
      refreshToken: getRefreshTokenFromSessionStorage(),
    };
    const resp = await AuthSetAgent.user.refreshToken(params);
    saveTokenToSessionStorage(resp.token);
    saveRefreshTokenToSessionStorage(resp.refreshToken);
  }

  navigateToIntendedUrl() {
    if (this.navigate && this.intendedUrl) {
      this.navigate(this.intendedUrl);
      this.navigate = null;
      this.intendedUrl = null;
    }
  }

  get authUserIsNotLoaded() {
    return !Object.keys(this.authUser).length;
  }

  get isUser() {
    return this.authUser?.roles?.includes("User");
  }

  get isAdmin() {
    return this.authUser?.roles?.includes("Admin");
  }

  get canUserAdjustThreshold() {
    return this.authUser?.claims?.includes("can_manage_thresholds");
  }
}

export const authStore = new AuthStore();
export const AuthStoreContext = createContext(authStore);
export const AuthStoreProvider = ({ children, store }) => (
  <AuthStoreContext.Provider value={store}>
    {children}
  </AuthStoreContext.Provider>
);
export const useAuthStore = () => useContext(AuthStoreContext);

function detectTokenValidity() {
  let sessionToken = getTokenFromSessionStorage();
  try {
    const payload = decodeJwtToken(sessionToken);
    if (payload.exp < new Date().getTime() / 1000 && this.refreshToken)
      return this.updateToken();
    setBearerToken(sessionToken);
  } catch (e) {
    this.logout();
  }
}

function getUserProfile(token) {
  //The line below is needed to ensure proper functionality of the app
  if (!token) return this.logout();
  AuthSetAgent.user
    .getProfile()
    .then(({ userDto, roles, claims }) => {
      const userObject = {
        ...userDto,
        roles,
        claims,
      };
      this.setAuthUser(userObject);
      this.navigateToIntendedUrl();
    })
    .catch((e) => this.logout());
}
