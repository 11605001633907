import { Box, IconButton, Slider, Stack, useTheme } from "@mui/material";
import {
  FastForwardRounded,
  FastRewindRounded,
  PauseRounded,
  PlayArrowRounded,
} from "@mui/icons-material";
import { useCallback } from "react";

const ProgressBar = ({
  onChange,
  marks,
  pause,
  play,
  playing,
  selectedIndex,
  selectedMark,
}) => {
  const theme = useTheme();

  const handleChange = useCallback(
    (event, newValue) => {
      const match = marks?.find((item) => item?.value === newValue);
      onChange && onChange(match?.index);
    },
    [marks, onChange]
  );

  return (
    <section>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 1,
        }}
      >
        <IconButton
          aria-label="previous song"
          onClick={() => onChange(selectedIndex - 1)}
          disabled={selectedIndex === 0}
        >
          <FastRewindRounded
            fontSize="large"
            htmlColor={
              selectedIndex === 0
                ? theme.palette.grey[500]
                : theme.palette.primary.main
            }
          />
        </IconButton>
        {playing ? (
          <IconButton aria-label="pause" onClick={pause}>
            <PauseRounded
              sx={{ fontSize: "3rem" }}
              htmlColor={theme.palette.primary.main}
            />
          </IconButton>
        ) : (
          <IconButton aria-label="play" onClick={play}>
            <PlayArrowRounded
              sx={{ fontSize: "3rem" }}
              htmlColor={theme.palette.primary.main}
            />
          </IconButton>
        )}
        <IconButton
          aria-label="next song"
          onClick={() => onChange(selectedIndex + 1)}
          disabled={selectedIndex >= marks?.length - 1}
        >
          <FastForwardRounded
            fontSize="large"
            htmlColor={
              selectedIndex >= marks?.length - 1
                ? theme.palette.grey[500]
                : theme.palette.primary.main
            }
          />
        </IconButton>
      </Box>
      <Stack
        spacing={2}
        direction="row"
        sx={{ mb: 1, px: 2 }}
        alignItems="center"
      >
        <Slider
          onChange={handleChange}
          value={selectedMark?.value || null}
          aria-label="heat map slider"
          marks={marks}
          step={null}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => {
            return marks?.find((item) => item?.value === value)?.popupText;
          }}
          sx={{
            color: theme.palette.primary.main,
            "& .MuiSlider-track": {
              border: "none",
            },
            "& .MuiSlider-thumb": {
              width: 24,
              height: 24,
              backgroundColor: theme.palette.primary.main,
              "&:before": {
                boxShadow: "0 4px 8px rgba(0,0,0,0.4)",
              },
              "&:hover, &.Mui-focusVisible, &.Mui-active": {
                boxShadow: "none",
              },
            },
          }}
        />
      </Stack>
      {/* {selectedMark?.date ? (
        <div className="flex justify-center items-center">
          <Typography variant="h5" className="mt-3">
            {dayjs(selectedMark?.date).format("D MMM YYYY")}
          </Typography>
        </div>
      ) : null} */}
    </section>
  );
};

export default ProgressBar;
