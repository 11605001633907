import { useTranslation } from "react-i18next";

import { observer } from "mobx-react";
import MapReport from "./MapReport";
import TableReport from "./TableReport";
import Tabs from "../../Components/Theme/Tabs/Tabs";
import HistoricMapFilter from "../../Components/Map/HistoricMapFilter/HistoricMapFilter";

const FullnessHistory = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      label: t("historical.ViewMaps"),
      Component: <MapReport />,
    },
    {
      label: t("historical.ViewTable"),
      Component: <TableReport />,
    },
  ];

  return (
    <>
      <div className="mt-2">
        {/* <PageTitle>
          
        </PageTitle> */}
        <HistoricMapFilter hasStartDate={true} />

        <Tabs tabs={tabs} />
      </div>
    </>
  );
};

export default observer(FullnessHistory);
