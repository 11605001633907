import { Grid, Stack } from "@mui/material";
import { gridSpacing } from "../../../Config/constant";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { authStore } from "../../../Stores/AuthStore";
import { passwordRegex } from "../../../Utils/Regex";
import SubmitButton from "../../../Components/Buttons/SubmitButton";
import PasswordTextInput from "../../../Components/PasswordTextInput/PasswordTextInput";

const ChangePassword = () => {
  const { t } = useTranslation();

  const changePasswordSchema = yup.object().shape({
    currentPassword: yup
      .string()
      .required(t("validation.required"))
      .min(8, t("validation.min"))
      .max(32, t("validation.max"))
      .matches(passwordRegex, t("validation.InvalidPassword")),
    newPassword: yup
      .string()
      .required(t("validation.required"))
      .min(8, t("validation.min"))
      .max(32, t("validation.max"))
      .matches(passwordRegex, t("validation.InvalidPassword")),
    confirmNewPassword: yup
      .string()
      .required(t("validation.required"))
      .min(8, t("validation.min"))
      .max(32, t("validation.max"))
      .matches(passwordRegex, t("validation.InvalidPassword"))
      .oneOf([yup.ref("newPassword"), null], t("forms.passwordsMustMatch")),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(changePasswordSchema),
  });

  const onSubmit = ({ currentPassword, newPassword, confirmNewPassword }) => {
    authStore.updatePassword(currentPassword, newPassword, confirmNewPassword);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} md={6} lg={6}>
          <PasswordTextInput
            label={t("forms.currentPassword")}
            defaultValue=""
            name="currentPassword"
            control={control}
            error={errors.currentPassword}
          />
        </Grid>
      </Grid>
      <Grid container spacing={gridSpacing} mt="-10px">
        <Grid item xs={12} md={6} lg={6}>
          <PasswordTextInput
            label={t("forms.changePassword")}
            defaultValue=""
            name="newPassword"
            control={control}
            error={errors.newPassword}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <PasswordTextInput
            label={t("forms.confirmPassword")}
            defaultValue=""
            name="confirmNewPassword"
            control={control}
            error={errors.confirmNewPassword}
          />
        </Grid>
      </Grid>

      <Stack
        direction="row"
        gap={2}
        alignItems={"center"}
        justifyContent={"flex-end"}
        height={1}
        marginTop={4}
      >
        <SubmitButton label={t("forms.changePassword")} type="submit" />
      </Stack>
    </form>
  );
};

export default observer(ChangePassword);
