import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text as PdfText,
  View,
} from "@react-pdf/renderer";
import Logo from "../../../../assets/images/Pdf_logo.png";
import OpenSansRegular from "../../../../assets/fonts/OpenSans-Regular.ttf";
import OpenSansBold from "../../../../assets/fonts/OpenSans-Bold.ttf";
import dayjs from "dayjs";

// Register Fonts
Font.register({
  family: "Open Sans Regular",
  src: OpenSansRegular,
});
Font.register({
  family: "Open Sans Bold",
  src: OpenSansBold,
});

// Create styles
const styles = StyleSheet.create({
  text: { fontFamily: "Open Sans Regular", fontSize: "12px" },
  boldText: { fontFamily: "Open Sans Bold", fontSize: "12px" },
  page: {
    backgroundColor: "white",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headerText: {
    fontSize: "14px",
    textDecoration: "underline",
    marginVertical: 20,
    textAlign: "center",
  },
  description: {
    marginBottom: 20,
    marginHorizontal: 20,
  },
  logoSection: {
    flexDirection: "column",
    padding: 20,
    alignItems: "center",
  },
  dateSection: {
    paddingHorizontal: 20,
    textAlign: "right",
  },
  logoImage: {
    height: "auto",
    width: "80px",
  },
  dataSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "left",
    gap: 2,
  },
  pageContent: {
    flexDirection: "column",
    justifyContent: "flex-start",
    flexGrow: 1,
  },
});

const Text = ({ children, bold = false, style }) => {
  const defaultStyle = { ...styles.text, ...style };
  const allStyles = bold
    ? { ...defaultStyle, ...styles.boldText }
    : defaultStyle;
  return <PdfText style={allStyles}>{children}</PdfText>;
};

const Section = ({ children, padded = true, style, title }) => {
  const defaultContainerStyle = {
    paddingVertical: 15,
    paddingHorizontal: padded ? 20 : 5,
    marginVertical: 15,
    marginHorizontal: 20,
    border: "1px solid #666666",
    borderRadius: "10px",
    position: "relative",
  };
  const allStyles = { ...defaultContainerStyle, ...styles.text, ...style };
  const titleStyle = {
    position: "absolute",
    backgroundColor: "white",
    top: -10,
    left: 30,
    padding: "0 10px",
    color: "#666666",
  };
  return (
    <View style={allStyles}>
      <Text bold style={titleStyle}>
        {title}
      </Text>
      {children}
    </View>
  );
};

const PdfTemplate = ({ images, inputs }) => {
  const dates = [
    { label: "Ημερομηνία: από", value: inputs?.dateFrom },
    { label: "έως", value: inputs?.dateTo },
  ];

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.pageContent}>
          <View style={styles.header}>
            <View style={styles.logoSection}>
              <Image style={styles.logoImage} src={Logo} />
              <Text bold>ΕΛΛΗΝΙΚΗ ΔΗΜΟΚΡΑΤΙΑ</Text>
              <Text bold>ΠΕΡΙΦΕΡΕΙΑ</Text>
              <Text bold>ΠΕΛΟΠΟΝΝΗΣΟΥ</Text>
              <Text bold>ΔΗΜΟΣ ΤΡΙΠΟΛΗΣ</Text>
              <Text>Λαγοπάτη και Αταλάντης,</Text>
              <Text>Τρίπολη, ΤΚ 22131</Text>
            </View>
          </View>
          <View>
            <Text style={styles.dateSection}>
              {`Ημερομηνία: ${dayjs().format("DD/MM/YYYY")}`}
            </Text>
          </View>
          <Text bold style={styles.headerText}>
            ΑΝΑΦΟΡΑ
          </Text>
          <Text style={styles.description}>
            Παρουσίαση συλλεγόμενων δεδομένων από εγκατεστημένα συστήματα
            έξυπνης πόλης στην περιοχή του Ανοικτού Κέντρου Εμπορίου (Α.Κ.Ε.)
            του Δήμου Τρίπολης.
          </Text>

          <Section title={"Τίτλος και δεδομένα αναφοράς"}>
            <View style={styles.dataSection}>
              <View
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Text>Τίτλος: {inputs?.title}</Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "left",
                  justifyContent: "space-between",
                  gap: 2,
                }}
              >
                {dates.map((date) => (
                  <View
                    key={date?.label}
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 4,
                    }}
                  >
                    <Text>{date?.label}</Text>
                    <Text>{date?.value}</Text>
                  </View>
                ))}
              </View>
            </View>
          </Section>

          {images?.map((image, index) => {
            return (
              <View key={index} wrap={false}>
                <Section key={index} padded={false}>
                  <Image
                    style={{ width: "100%", height: "auto" }}
                    src={image}
                  />
                </Section>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

export default PdfTemplate;
