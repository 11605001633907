import * as React from "react";

const AlertSvg = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.995"
      height="18"
      viewBox="0 0 17.995 18"
    >
      <path
        id="Shape_316"
        data-name="Shape 316"
        d="M294.189,708a2.1,2.1,0,0,1-1.876-1.1,2.245,2.245,0,0,1-.078-2.113l6.8-13.539a2.151,2.151,0,0,1,3.909,0l6.8,13.618a2.054,2.054,0,0,1-.078,2.112A2.2,2.2,0,0,1,307.793,708Zm6.254-16.044-6.8,13.618a.624.624,0,0,0,.547.939h13.6a.64.64,0,0,0,.547-.939l-6.8-13.618a.579.579,0,0,0-1.1,0ZM300.21,703.3a.78.78,0,1,1,.779.78A.78.78,0,0,1,300.21,703.3Zm0-2.343v-3.913a.782.782,0,1,1,1.563,0v3.913a.782.782,0,1,1-1.563,0Z"
        transform="translate(-292.009 -690)"
        fill={props.color ?? "#FFF"}
      />
    </svg>
  );
};

export default AlertSvg;
