// import SectionAreaTitleSvg from "../Icons/SectionAreaTitleSvg";
import LocationSvg from "../../Icons/LocationSvg";

const MapCard = (props) => {
  return (
    // <div className="w-fit h-fit rounded-md bg-white shadow-sm pb-[32px] pt-[3px]">
    <div className="w-fit h-fit rounded-md bg-white shadow-sm pt-[3px]">
      <div className="flex flex-col gap-y-1">
        <div className="flex justify-between gap-x-2">
          <div className="flex items-center gap-x-1">
            {/* <SectionAreaTitleSvg color={"#505381"} /> */}
            <span lang="el" className="text-[14px] font-semibold uppercase">
              {props.data?.title ?? ""}
            </span>
          </div>
          <div className="flex items-center gap-x-1">
            <div className="relative w-[25px] h-[25px]">
              <div
                style={{ backgroundColor: props.data?.color }}
                className="absolute w-[25px] h-[25px] rounded-full opacity-20"
              ></div>
              <div
                style={{ backgroundColor: props.data?.color }}
                className="absolute w-[20px] h-[20px] rounded-full opacity-50 top-1/2 left-1/2 transform -translate-x-[47%] -translate-y-[51%]"
              ></div>
              <div
                style={{ backgroundColor: props.data?.color }}
                className="absolute w-[15px] h-[15px] rounded-full opacity-100 top-1/2 left-1/2 transform -translate-x-[47%] -translate-y-[51%]"
              ></div>
            </div>

            <span lang="el" className="text-[14px] font-semibold uppercase">
              {props.data?.status ?? ""}
            </span>
          </div>
        </div>
        <div className="flex items-center gap-x-1">
          <LocationSvg size={"22"} />
          <span className="text-[14px] font-semibold">
            {props.data?.address ?? ""}
          </span>
        </div>
      </div>
      {/* <hr className="h-px my-[6px] bg-[#d4d4d4] border-0"></hr>
      <div className="flex flex-col gap-y-[6px] pl-[6px] mt-[12px] w-fit">
        <div className="flex items-center justify-between gap-x-3">
          <div className="flex items-center gap-x-2 w-fit">
            <div className="w-[10px] h-[10px] rounded-[50%] bg-[#3fb24d]"></div>
            <span className="w-[55px] text-[12px] font-semibold uppercase text-[#6699ff]">
              {props.data?.visitors ?? ""}
            </span>
          </div>
          <span className="text-[12px] font-semibold ">ΕΠΙΣΚΕΠΤΕΣ</span>
        </div>
        <div className="flex items-center justify-between gap-x-3">
          <div className="flex items-center gap-x-2 w-fit">
            <div className="w-[10px] h-[10px] rounded-[50%] bg-[#2c9dff]"></div>
            <span className="w-[55px] text-[12px] font-semibold uppercase text-[#6699ff]">
              {props.data?.visitRate ?? ""}%
            </span>
          </div>
          <span className="text-[12px] font-semibold">ΡΥΘΜΟΣ</span>
        </div>
        <div className="flex items-center justify-between gap-x-3">
          <div className="flex items-center gap-x-2 w-fit">
            <div className="w-[10px] h-[10px] rounded-[50%] bg-[#ff7800]"></div>
            <span className="w-fit text-[12px] font-semibold uppercase text-[#6699ff]">
              {props.data?.visitDuration} λεπτα
            </span>
          </div>
          <span className="text-[12px] font-semibold">ΔΙΑΡΚΕΙΑ</span>
        </div>
      </div> */}
    </div>
  );
};

export default MapCard;
