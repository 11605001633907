import React, { FC } from "react";
import { Typography, useTheme } from "@mui/material";
import { themeColors } from "../../../../Config/Themes/theme";
import { Line } from "react-chartjs-2";
import DashboardSection from "../../../../Components/DashboardSection/DashboardSection";
import { observer } from "mobx-react";
import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";
import ShadowBox from "../../../../Components/ShadowBox/ShadowBox";
import SectionTitle from "../SectionTitle/SectionTitle";
import Thermometer from "react-thermometer-ecotropy";
import { FormatNumberNoDecimals } from "../../common/FormatNumber/FormatNumberNoDecimals";

interface TemperatureChartDatasetOptions {
  stationName: string;
  date?: string;
  labels: Array<string>;
  data: Array<number>;
}

interface TemperatureChartProps {
  datasets: Array<TemperatureChartDatasetOptions>;
}

const TemperatureChart: FC<TemperatureChartProps> = ({ datasets }) => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);

  const getLatestDataValue = (data) => {
    const latestData = data[data.length - 1];
    return latestData ? latestData : 0;
  };

  const getTemperatureColor = (temp) => {
    if (temp < 10) {
      return "#2196f3"; // Light Blue
    } else if (temp >= 10 && temp < 20) {
      return "#4895c3"; // Darker Blue
    } else if (temp >= 20 && temp < 25) {
      return "#b59271"; // Light Orange
    } else if (temp >= 25 && temp < 30) {
      return "#e99141"; // Orange
    } else if (temp >= 30 && temp < 35) {
      return "#f48636"; // Darker Orange
    } else if (temp >= 35 && temp < 40) {
      return "#f47736"; // Even Darker Orange
    } else if (temp >= 40 && temp < 45) {
      return "#f36736"; // Dark Orange
    } else if (temp >= 45 && temp < 50) {
      return "#f34b35"; // Darkest Orange
    } else {
      return "#f44336"; // Red
    }
  };

  const renderTemperatureChart = (item) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const gradient = ctx?.createLinearGradient(0, 0, canvas.width, 0);

    if (gradient) {
      item.data.forEach((value, index) => {
        const color = getTemperatureColor(value);
        const position = index / (item.data.length - 1);
        gradient.addColorStop(position, color);
      });
    }

    const lineChartData = {
      labels: item.labels || [],
      datasets: [
        {
          label: "°C",
          data: item.data || [],
          fill: true,
          backgroundColor: gradient,
          pointBackgroundColor: item.data.map((value) =>
            getTemperatureColor(value)
          ),
          borderColor: colors.whiteAccent.main,
          borderWidth: 1,
        },
      ],
    };

    const options = {
      scales: {
        x: {
          ticks: {
            maxRotation: 0,
            autoSkip: true,
            maxTicksLimit: item.data.length > 8 ? 8 : item.data.length, // Limit to 8 labels if there are more that 8 data points
            display: true,
            color: colors.cyanAccent.light,
          },
          grid: {
            display: false,
          },
        },
        y: {
          beginAtZero: true,
          ticks: {
            callback: function (value, index, values) {
              return `${value}°C`;
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: false, // Hide data labels on the chart
          anchor: "end", // This will work when display is set to true
          align: "top", // This will work when display is set to true
          offset: -10, // This will work when display is set to true // Adjust this value to move data labels higher
        },
      },
    };

    return (
      <ShadowBox className="" key={item?.stationName}>
        <section className="flex flex-col justify-around items-center p-5 mb-5 min-w-[450px] min-h-[300px] max-h-[300px]">
          {item?.stationName && (
            <SectionTitle
              roundIcon={true}
              icon="location"
              iconWidth={25}
              iconHeight={25}
              title={item?.stationName}
            />
          )}

          {/* <div className="flex items-center justify-between w-full">
            {item?.date && (
              <div className="max-w-max flex ml-[40%] gap-x-1">
                <div
                  className="max-w-max px-2 h-[20px rounded-sm"
                  style={{
                    backgroundColor: colors.cyanAccent.light,
                    color: colors.whiteAccent.main,
                  }}
                >
                  <span className="text-xs uppercase">{item?.date}</span>
                </div>
              </div>
            )}

            <div
              className="max-w-max flex items-end gap-x-1 border-b-2"
              style={{
                borderColor: colors.cyanAccent.light,
              }}
            >
              <div
                className="max-w-max h-[20px]"
                style={{
                  color: colors.cyanAccent.light,
                }}
              >
                <span
                  className="uppercase font-bold"
                  style={{ fontSize: "0.5rem" }}
                >
                  ΗΜΕΡΑ
                </span>
              </div>
            </div>
          </div> */}

          <div className="flex items-center justify-start w-full">
            <div className="flex items-center w-full">
              <Line options={options} data={lineChartData} height="100%" />
            </div>
            <div className="flex items-center w-full ml-[-20px]">
              <Thermometer
                theme="light"
                value={getLatestDataValue(item?.data)}
                max="50" // Max temperature set for the mercury thermometer
                format="°C"
                size="small"
                height="100"
                tooltipValue={false}
              />
              <div className="flex flex-col items-center justify-center ml-[-30px]">
                <Typography
                  fontWeight={"bold"}
                  fontSize={60}
                  color={getTemperatureColor(getLatestDataValue(item?.data))}
                >
                  {FormatNumberNoDecimals(getLatestDataValue(item?.data))}°
                </Typography>
                <Typography
                  fontWeight={"300"}
                  fontSize={20}
                  // color={getTemperatureColor(getLatestDataValue(item?.data))}
                  color={"black"}
                >
                  Celsius
                </Typography>
              </div>
            </div>
          </div>
        </section>
      </ShadowBox>
    );
  };

  return (
    <div
      className="flex"
      style={
        datasets?.length === 1 ? { width: "fit-content" } : { width: "100%" }
      }
    >
      <DashboardSection title="Θερμοκρασία" tooltipText="Δεδομένα θερμοκρασίας">
        <div
          className="flex items-center gap-4 overflow-x-auto px-2 py-2"
          style={
            datasets?.length === 1
              ? { justifyContent: "space-around" }
              : { justifyContent: "space-between" }
          }
        >
          {datasets?.map((dataset: TemperatureChartDatasetOptions) => {
            return renderTemperatureChart(dataset);
          })}
        </div>
      </DashboardSection>
    </div>
  );
};

export default observer(TemperatureChart);
