import "./App.css";
import { ThemeProvider } from "@mui/material";
import { useMode } from "./Config/Themes/theme";
import AppContent from "./MainAppComponents/AppContent";
import ToastContainer from "./Utils/Toast/ToastContainer";

function App() {
  const [theme] = useMode();

  return (
    <>
      <ThemeProvider theme={theme}>
        <AppContent />
      </ThemeProvider>
      <ToastContainer />
    </>
  );
}

export default App;
