import { Button } from "@mui/material";
import AnimateButton from "../Theme/Extended/AnimateButton";
import { useTheme } from "@mui/material/styles";
import { themeColors } from "../../Config/Themes/theme";
import CircularSpinner from "../../../src/Components/Theme/Spinners/CircularSpinner";
import clsx from "clsx";

const SubmitButton = ({
  className,
  onClick,
  label,
  size,
  type = "button",
  disabled = false,
  loading,
  endIcon,
  startIcon,
}) => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const classes = clsx(["capitalize text-sm", className]);

  return type === "button" ? (
    <AnimateButton>
      <Button
        loading={loading}
        size={size}
        className={classes}
        type={type}
        variant="contained"
        disabled={disabled}
        onClick={onClick}
        endIcon={endIcon}
        startIcon={startIcon}
        sx={{
          backgroundColor: colors.buttonsActive.main,
          color: colors.buttonsActive.text,
          "&:hover": {
            backgroundColor: colors.buttonsActive.hover,
          },
          "&:disabled": {
            backgroundColor: colors.buttonsActive.disabled,
            color: colors.buttonsActive.text,
          },
        }}
      >
        {loading && (
          <CircularSpinner
            size="20px"
            color="inherit"
            sx={{ marginRight: "15px" }}
          />
        )}
        <span
          style={{
            textTransform: "none",
          }}
        >
          {label}
        </span>
      </Button>
    </AnimateButton>
  ) : (
    <AnimateButton>
      <Button
        loading={loading}
        size={size}
        className={classes}
        type={type}
        variant="contained"
        disabled={disabled}
        endIcon={endIcon}
        startIcon={startIcon}
        sx={{
          backgroundColor: colors.buttonsActive.main,
          color: colors.buttonsActive.text,
          "&:hover": {
            backgroundColor: colors.buttonsActive.hover,
          },
          "&:disabled": {
            backgroundColor: colors.buttonsActive.disabled,
            color: colors.buttonsActive.text,
          },
        }}
      >
        {loading && (
          <CircularSpinner
            size="20px"
            color="inherit"
            sx={{ marginRight: "15px" }}
          />
        )}
        <span
          style={{
            textTransform: "none",
          }}
        >
          {label}
        </span>
      </Button>
    </AnimateButton>
  );
};

export default SubmitButton;
