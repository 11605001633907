import { Route, Routes } from "react-router-dom";
import { observer } from "mobx-react";
import ScreensPage from "../MainAppComponents/ScreensPage/ScreensPage";
import SoftwareManagement from "../Pages/SoftwareManagement/SoftwareManagement";

const wrap = (element, pageProps) => (
  <ScreensPage {...pageProps}>{element}</ScreensPage>
);

const ScreensRoutes = () => {
  return (
    <Routes>
      <Route
        path="/software-management"
        element={wrap(<SoftwareManagement />)}
      />
    </Routes>
  );
};

export default observer(ScreensRoutes);
