import React from "react";
import Icons from "../../assets/icons";

const RoundIcon = ({ type, id }) => {
  const renderTypeIcons = () => {
    switch (id) {
      case 1:
        return (
          <div className="border-2 border-[#4F4F4F] rounded-full p-1">
            <Icons name="generalWaste" width={17} height={15} />
          </div>
        );

      case 2:
        return (
          <div className="border-2 border-[#4F4F4F] rounded-full p-1">
            <Icons name="recycle" width={15} height={15} />
          </div>
        );

      case 3:
        return (
          <div className="border-2 border-[#4F4F4F] rounded-full p-1">
            <Icons name="paper" width={15} height={15} />
          </div>
        );

      case 4:
        return (
          <div className="border-2 border-[#4F4F4F] rounded-full p-1">
            <Icons name="glass" width={15} height={15} />
          </div>
        );

      case 5:
        return (
          <div className="border-2 border-[#4F4F4F] rounded-full p-1">
            <Icons name="plastic" width={15} height={15} />
          </div>
        );

      case 6:
        return (
          <div className="border-2 border-[#4F4F4F] rounded-full p-1">
            <Icons name="organicWaste" width={15} height={15} />
          </div>
        );

      case 7:
        return (
          <div className="border-2 border-[#4F4F4F] rounded-full p-1">
            <Icons name="metal" width={15} height={15} />
          </div>
        );

      case 8:
        return (
          <div className="border-2 border-[#4F4F4F] rounded-full p-1">
            <Icons name="e-Waste" width={15} height={15} />
          </div>
        );
      default:
        return (
          <div className="border-2 border-[#4F4F4F] rounded-full p-1">
            <Icons name="HomeIcon" width={15} height={15} />
          </div>
        );
    }
  };

  const renderAlertIcons = () => {
    switch (id) {
      case 203:
        return (
          <div className="border-2 border-[#4F4F4F] rounded-full p-1">
            <Icons name="full" width={15} height={15} />
          </div>
        );

      case 202:
        return (
          <div className="border-2 border-[#4F4F4F] rounded-full p-1">
            <Icons name="flipped" width={15} height={15} />
          </div>
        );

      case 204:
        return (
          <div className="border-2 border-[#4F4F4F] rounded-full p-1">
            <Icons name="fire" width={15} height={15} />
          </div>
        );

      case 200:
        return (
          <div className="border-2 border-[#4F4F4F] rounded-full p-1">
            <Icons name="noSignal" width={15} height={15} />
          </div>
        );

      case 201:
        return (
          <div className="border-2 border-[#4F4F4F] rounded-full p-1">
            <Icons name="lowBatteryIcon" width={15} height={15} />
          </div>
        );

      default:
        return (
          <div className="border-2 border-[#4F4F4F] rounded-full p-1">
            <Icons name="HomeIcon" width={15} height={15} />
          </div>
        );
    }
  };

  const renderRoundIcon = () => {
    if (type === "type") {
      return renderTypeIcons();
    } else if (type === "alert") {
      return renderAlertIcons();
    }
  };

  return <>{renderRoundIcon()}</>;
};

export default RoundIcon;
