import * as React from "react";

const VisitsSvg = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ??"12.947"}
      height={props.size ??"15.375"}
      viewBox="0 0 12.947 15.375"
    >
      <path
        id="Shape_342"
        data-name="Shape 342"
        d="M646.145,88.8V83.667l1.683-1v5.138l4.342,2.57-1.684,1Zm2.12-1.259V85.553l4.342,2.569,4.341-2.569v1.993l-4.341,2.569ZM644,87.512V82.373l4.341-2.569,1.683,1-4.341,2.569v5.139Zm6.923-.922,4.341-2.57V78.882l1.684,1v5.139l-4.341,2.57Zm2.211-7.025L648.794,77l1.683-1,4.341,2.569v5.138l-1.683,1Zm-4.793-.3L644,81.839V79.845l4.341-2.569,4.341,2.569v1.993Z"
        transform="translate(-644 -76)"
        fill={props.color ??"#545a86"}
      />
    </svg>
  );
};

export default VisitsSvg;
