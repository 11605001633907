import { observer } from "mobx-react";
import { Typography } from "@mui/material";
import { useAnnouncementsStore } from "../../../Stores/AnnouncementsStore";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import TextFieldInput from "../../../Components/TextField";
import PageTitle from "../../../Components/PageTitle";
import AddIcon from "@mui/icons-material/Add";
import AnnouncementsTable from "./AnnouncementsTable";
import SubmitButton from "../../../Components/Buttons/SubmitButton";
import AnnouncementCreateModal from "./AnnouncementCreateModal";
import Breadcrumb from "../../../Components/Breadcrumb/Breadcrumb";
import Loader from "../../../MainAppComponents/Loader";

const AnnouncementsList = () => {
  const announcementsStore = useAnnouncementsStore();
  const [page, setPage] = useState(announcementsStore.currentPage);
  const pageSize = announcementsStore.itemsPerPage;
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "createdOn",
    direction: "desc",
  });

  const refreshAnnouncements = () => {
    announcementsStore.getAllAnnouncements();
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
    setPage(1);
  };

  const handleOpenCreateModal = () => {
    setIsCreateModalOpen(true);
  };

  useEffect(() => {
    setLoading(true);
    let searchTimeout;
    clearTimeout(searchTimeout);

    const orderByDateAsc =
      sortConfig.key === "createdOn" && sortConfig.direction === "asc";
    const orderByDateDesc =
      sortConfig.key === "createdOn" && sortConfig.direction === "desc";
    const orderByStatusAsc =
      sortConfig.key === "status" && sortConfig.direction === "asc";
    const orderByStatusDesc =
      sortConfig.key === "status" && sortConfig.direction === "desc";
    const orderByIdAsc =
      sortConfig.key === "id" && sortConfig.direction === "asc";
    const orderByIdDesc =
      sortConfig.key === "id" && sortConfig.direction === "desc";

    searchTimeout = setTimeout(() => {
      announcementsStore
        .getAllAnnouncements(
          page,
          pageSize,
          inputValue,
          orderByDateAsc,
          orderByDateDesc,
          orderByIdAsc,
          orderByIdDesc,
          orderByStatusAsc,
          orderByStatusDesc
        )
        .then((resp) => {})
        .finally(() => {
          setLoading(false);
        });
    }, 1000);
  }, [inputValue, page, pageSize, announcementsStore, sortConfig]);

  return (
    <>
      <div className="ml-4">
        <Breadcrumb pathList={["Αρχική", "Ανακοινώσεις", "Λίστα"]} />
      </div>
      <div className="mt-8 ml-[36px]">
        <PageTitle
          title={`Ανακοινώσεις (${announcementsStore.totalItems || 0}) `}
        />
      </div>

      <div className="mt-8 ml-[34px] sm:flex sm:justify-between">
        <TextFieldInput
          className="w-[230px] bg-[#FFFFFF]"
          type="text"
          fullWidth
          label={"Αναζήτηση"}
          value={inputValue}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
        <SubmitButton
          type={"button"}
          onClick={handleOpenCreateModal}
          size={"large"}
          className="sm:mr-[170px] sm:mb-1"
          label={"Νέα ανακοίνωση"}
          startIcon={<AddIcon fontSize="large" />}
        />

        <AnnouncementCreateModal
          open={isCreateModalOpen}
          handleClose={setIsCreateModalOpen}
          refreshAnnouncements={refreshAnnouncements}
        />
      </div>

      {!loading ? (
        <div className="mt-6 sm:mt-6 ml-[20px] w-[90%]">
          {announcementsStore.announcementsList?.length ? (
            <AnnouncementsTable
              announcements={announcementsStore.announcementsList}
              inputValue={inputValue}
              currentPage={page}
              setPage={setPage}
              pageSize={pageSize}
              sortConfig={sortConfig}
              setSortConfig={setSortConfig}
            />
          ) : (
            <Typography variant="h5" className="ml-4 pt-2">
              Δεν υπάρχουν δεδομένα αυτήν την στιγμή
            </Typography>
          )}
        </div>
      ) : (
        <Loader className="flex justify-center items-center p-20" size={30} />
      )}
    </>
  );
};

export default observer(AnnouncementsList);
