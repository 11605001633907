import { Controller } from "react-hook-form";
import {
  FormLabel,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { themeColors } from "../../Config/Themes/theme";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useCallback } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const TextInput = ({
  label,
  name,
  control,
  size = "16px",
  disabled = false,
  className = "",
  error,
  placeholder = "",
  defaultValue = "",
  isPasswordField = false,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const toggleVisibility = useCallback(() => {
    setPasswordVisible((oldPassword) => !oldPassword);
  }, [setPasswordVisible]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div
      className={className}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "6px",
        width: "100%",
      }}
    >
      <FormLabel
        sx={{
          color: colors.greyAccent.dark,
          fontSize: size,
          textTransform: "uppercase",
        }}
      >
        <span lang="el">{label}</span>
      </FormLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return isPasswordField ? (
            <TextField
              fullWidth
              variant={"outlined"}
              value={value}
              type={passwordVisible ? "text" : "password"}
              onChange={onChange}
              error={!!error}
              helperText={error ? t(error?.message, { min: 8, max: 32 }) : null}
              disabled={disabled}
              placeholder={placeholder}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: colors.whiteAccent.main,
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleVisibility}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {passwordVisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <TextField
              fullWidth
              variant={"outlined"}
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? t(error.message) : null}
              disabled={disabled}
              placeholder={placeholder}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: colors.whiteAccent.main,
                },
              }}
            />
          );
        }}
      />
    </div>
  );
};

export default TextInput;
