import React, { useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Decimation,
  registerables,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { observer } from "mobx-react";
import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Decimation,
  ...registerables
);

const LineChart = ({ data, title = "", options, setChartInstance }) => {
  const ref = useRef();
  const initOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: !!title,
        text: title,
      },
    },
  };

  useEffect(() => {
    const chart = ref?.current;
    setChartInstance && setChartInstance((prev) => [...prev, chart]);

    return () => {
      setChartInstance &&
        setChartInstance((prev) => prev.filter((el) => el !== chart));
    };
  }, [setChartInstance]);

  return (
    <Line
      ref={ref}
      options={options || initOptions}
      data={data}
      height="100%"
    />
  );
};

export default observer(LineChart);
