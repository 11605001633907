import * as React from "react";

import { useTheme } from "@mui/material";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { Options, Data } from "./GraphOptions";

import SectionAreaTitleSvg from "../Icons/SectionAreaTitleSvg";
import AverageSvg from "../Icons/AverageSvg";
import HumanFigureSvg from "../Icons/HumanFigureSvg";
import ActivityCardHeader from "../ActivityCardHeader";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
} from "chart.js";
import { removeDiacritics } from "../../../../Utils/Text/Text";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  ChartDataLabels
);
const HistoryActivityKpi = (props) => {
  const theme = useTheme();
  const options = Options(props.data.activeVisitorHistory.length);
  const data = Data(
    props.data.activeVisitorHistory,
    theme.palette.background.cardTitleIcon
  );
  const showHeader = props.showHeader;

  return (
    <div
      className="w-[250px] lg:w-[280px] px-[15px] pb-[22px] bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-md"
      style={{
        height: showHeader ? 190 : 160,
        paddingTop: showHeader ? 15 : 5,
      }}
    >
      {showHeader && (
        <ActivityCardHeader
          locationName={removeDiacritics(props.data.locationName)}
        />
      )}
      <div
        className="flex flex-col mt-[18px]"
        style={{ color: theme.palette.background.cardBackground }}
      >
        <div className="flex flex-row justify-between">
          <div className="h-10 text-center font-semibold">
            <span
              className="text-[14px] block leading-[1.1]"
              style={{ color: "#000" }}
            >
              ΕΠΙΣΚΕΠΤΕΣ
            </span>
            <span className="text-[28px] leading-[1.1]">
              {props.data.activeVisitors}
            </span>
          </div>
          <AverageSvg />
          <div className="h-10 text-center font-semibold">
            <span
              className="text-[14px] block m-0 leading-[1.1]"
              style={{ color: "#000" }}
            >
              ΜΕΣΗ ΤΙΜΗ
            </span>
            <span className="text-[18px] leading-[1.1]">
              {props.data.averageActiveVisitors}
            </span>
          </div>
        </div>
        <div className="flex justify-center relative">
          <div className="absolute left-[-4px] bottom-[30px]">
            <HumanFigureSvg
              color={theme.palette.neutral.light4}
              height={"25"}
              width={"20"}
            />
          </div>
          <Line height={"90px"} data={data} options={options} />
        </div>
      </div>
    </div>
  );
};

export default HistoryActivityKpi;
