import Topbar from "../../../Components/Topbar/Topbar";
import MainContent from "../../../MainAppComponents/MainContent";
import DashboardSection from "../../../Components/DashboardSection/DashboardSection";
import { useAuthStore } from "../../../Stores/AuthStore";
import { useNavigate } from "react-router-dom";
import ScreenBlock from "./ScreensBlock";
import AnnouncementsBlock from "./AnnouncementsBlock";
import LightsBlock from "./LightsBlock";
import EnvironmentBlock from "./EnvironmentBlock";
import MapBlock from "./MapBlock";
import WifiBlock from "./WifiBlock/WifiBlock";
import { useEffect } from "react";
import { observer } from "mobx-react";

const Dashboard = () => {
  const authStore = useAuthStore();
  const navigate = useNavigate();

  const redirectTo = (path) => {
    navigate(path);
  };

  useEffect(() => {
    if (
      Object.values(authStore.saas).filter((value) => value !== false)
        .length === 1
    ) {
      const saas = Object.keys(authStore.saas).find(
        (key) => authStore.saas[key] === true
      );
      redirectTo(`/${saas}`);
    }
  }, [authStore.saas]);

  return (
    <div>
      <Topbar icon="dashboard" title="Επισκόπηση" />
      <MainContent>
        <section className="pt-6">
          {authStore.saas.wifi && (
            <section className="col-span-7 mr-5">
              <DashboardSection
                title="Wifi & Επισκεψιμότητα"
                onButtonClick={() => redirectTo("/wifi")}
              >
                <WifiBlock />
              </DashboardSection>
            </section>
          )}

          {authStore.saas.environment && (
            <section className="col-span-7 mr-5">
              <DashboardSection
                title="Περιβάλλον - Ποιότητα αέρα"
                onButtonClick={() => redirectTo("/environment")}
              >
                <EnvironmentBlock />
              </DashboardSection>
            </section>
          )}

          <section className="col-span-7 mr-5 flex flex-col lg:flex-row lg:gap-4">
            {authStore.saas.lights && (
              <DashboardSection
                title="Οδοφωτισμός"
                onButtonClick={() => redirectTo("/lights")}
              >
                <LightsBlock />
              </DashboardSection>
            )}

            {authStore.saas.screens && (
              <DashboardSection
                title="Οθόνες"
                onButtonClick={() => redirectTo("/screens/software-management")}
              >
                <ScreenBlock />
              </DashboardSection>
            )}

            <DashboardSection
              title="Ανακοινώσεις"
              onButtonClick={() => redirectTo("/announcements")}
            >
              <AnnouncementsBlock />
            </DashboardSection>
          </section>

          <section className="col-span-7 mr-5">
            <DashboardSection title="Χάρτης">
              <MapBlock />
            </DashboardSection>
          </section>
        </section>
      </MainContent>
    </div>
  );
};

export default observer(Dashboard);
