import React, { FC } from "react";
import { Typography, useTheme, useMediaQuery } from "@mui/material";
import { themeColors } from "../../../../Config/Themes/theme";
import { Line } from "react-chartjs-2";
import DashboardSection from "../../../../Components/DashboardSection/DashboardSection";
import { observer } from "mobx-react";
import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";
import ShadowBox from "../../../../Components/ShadowBox/ShadowBox";
import SectionTitle from "../SectionTitle/SectionTitle";
interface BarometricPressureChartTemperaturesOptions {
  timestamp: Array<string>;
  temperature: Array<number>;
}

interface BarometricPressureChartDatasetOptions {
  areaName: string;
  datesRange: string;
  temperatures: BarometricPressureChartTemperaturesOptions;
  labels: Array<string>;
  data: Array<number>;
}

interface BarometricPressureChartProps {
  datasets: Array<BarometricPressureChartDatasetOptions>;
}

const BarometricPressureChart: FC<BarometricPressureChartProps> = ({
  datasets,
}) => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const isMdSize = useMediaQuery("(min-width: 1200px)");

  const options = {
    scales: {
      x: {
        position: "top" as const,
        ticks: {
          maxRotation: 0,
          autoSkip: false,
          display: false, // Hide default x-axis labels
        },
        grid: {
          display: false, // Hide x-axis grid lines
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: true,
        align: "start" as const,
        labels: {
          boxWidth: 0,
          font: {
            weight: "bold" as const,
          },
          color: colors.cyanAccent.light,
        },
        onClick: function (e) {},
      },
      datalabels: {
        display: false, // Hide data labels on the chart
        anchor: "end", // This will work when display is set to true
        align: "top", // This will work when display is set to true
        offset: -10, // This will work when display is set to true // Adjust this value to move data labels higher
      },
    },
  };

  const renderBarometricPressureChart = (item) => {
    const lineChartData = {
      labels: item.labels || [],
      datasets: [
        {
          label: "hPa",
          data: item.data || [],
          fill: "origin", // Fill from the origin (line to the bottom)
          backgroundColor: colors.cyanAccent.light,
          borderColor: colors.whiteAccent.main,
          borderWidth: 1,
        },
      ],
    };

    return (
      <ShadowBox className="" key={item?.areaName}>
        <section className="flex flex-col justify-around items-center p-5 mb-5 min-w-[490px] min-h-[423px] max-h-[423px]">
          {item?.areaName && (
            <SectionTitle
              roundIcon={true}
              icon="location"
              iconWidth={25}
              iconHeight={25}
              title={item?.areaName}
            />
          )}

          <div className="flex items-center justify-between w-full">
            {item?.datesRange && (
              <div className="max-w-max flex ml-[40%] gap-x-1">
                <div
                  className="max-w-max px-2 h-[20px rounded-sm"
                  style={{
                    backgroundColor: colors.cyanAccent.light,
                    color: colors.whiteAccent.main,
                  }}
                >
                  <span className="text-xs uppercase">{item?.datesRange}</span>
                </div>
              </div>
            )}

            <div
              className="max-w-max flex items-end gap-x-1 border-b-2"
              style={{
                borderColor: colors.cyanAccent.light,
              }}
            >
              <div
                className="max-w-max h-[20px]"
                style={{
                  color: colors.cyanAccent.light,
                }}
              >
                <span
                  className="uppercase font-bold"
                  style={{ fontSize: "0.5rem" }}
                >
                  ΕΒΔΟΜΑΔΑ
                </span>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between ml-8 px-2 py-2 w-full">
            {isMdSize &&
              Object?.keys(item?.temperatures)?.length > 0 &&
              (item?.temperatures?.timestamp || []).map((timestamp, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center justify-center"
                >
                  <Typography
                    className="uppercase"
                    fontSize={11}
                    color={colors.blackAccent.main}
                  >
                    {item?.temperatures.timestamp[index]}
                  </Typography>
                  <Typography
                    fontSize={13}
                    fontWeight="bold"
                    color={colors.cyanAccent.light}
                  >
                    {item?.temperatures.minMax[index]}
                  </Typography>
                </div>
              ))}
          </div>
          <div className="flex items-center overflow-x-auto w-full">
            <Line options={options} data={lineChartData} height="100%" />
          </div>
        </section>
      </ShadowBox>
    );
  };

  return (
    <div
      className="flex"
      style={
        datasets?.length === 1 ? { width: "fit-content" } : { width: "100%" }
      }
    >
      <DashboardSection
        title="ΑΤΜΟΣΦΑΙΡΙΚΗ ΠΙΕΣΗ"
        tooltipText="Δεδομένα ατμοσφαιρικής πίεσης"
      >
        <div
          className="flex items-center gap-4 overflow-x-auto px-2 py-2"
          style={
            datasets?.length === 1
              ? { justifyContent: "space-around" }
              : { justifyContent: "space-between" }
          }
        >
          {datasets?.map((dataset: BarometricPressureChartDatasetOptions) => {
            return renderBarometricPressureChart(dataset);
          })}
        </div>
      </DashboardSection>
    </div>
  );
};

export default observer(BarometricPressureChart);
