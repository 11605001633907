import { observer } from "mobx-react";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import NotificationsTable from "./NotificationsTable";
import Breadcrumb from "../../../../Components/Breadcrumb/Breadcrumb";
import PageTitle from "../../../../Components/PageTitle";
import TextFieldInput from "../../../../Components/TextField";
import { get_notifications } from "../../Services/DataService";
import Loader from "../../../../MainAppComponents/Loader";

const NotificationsList = () => {
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [notifications, setNotifications] = useState(null);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      let data = await get_notifications();
      setNotifications(data.result || []);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <>
      <Breadcrumb pathList={["Wifi & Επισκεψιμότητα", "Ειδοποιήσεις"]} />
      <div className="mt-8 ml-[35px]">
        <PageTitle
          title={`Ειδοποιήσεις (${
            !isLoading && notifications ? notifications.length : "-"
          }) `}
        />
      </div>

      <div className="mt-8 ml-[34px] flex">
        <div>
          <TextFieldInput
            className="w-[230px] bg-[#FFFFFF]"
            type="text"
            fullWidth
            label={"Αναζήτηση"}
            value={inputValue}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
        </div>
      </div>

      {!isLoading && notifications ? (
        <div className="mt-6 ml-[20px] w-[97%]">
          {!isLoading && notifications ? (
            <NotificationsTable
              notifications={notifications}
              inputValue={inputValue}
            />
          ) : (
            <Typography variant="h5" className="ml-4 pt-2">
              {" "}
              Δεν υπάρχουν δεδομένα αυτήν την στιγμή{" "}
            </Typography>
          )}
        </div>
      ) : (
        <Loader className="flex justify-center items-center p-20" size={30} />
      )}
    </>
  );
};

export default observer(NotificationsList);
