import * as React from "react";

const AverageSvg = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23.881"
      viewBox="0 0 23 23.881"
    >
      <path
        id="Shape_315"
        data-name="Shape 315"
        d="M673,764.99a1,1,0,0,1-1-1V752.06a1,1,0,0,1,1-1h5a1,1,0,0,1,1,1v11.93a1,1,0,0,1-1,1Zm-8,0a1,1,0,0,1-1-1V752.06a1,1,0,0,1,1-1h5a1,1,0,0,1,1,1v11.93a1,1,0,0,1-1,1Zm-8,0a1,1,0,0,1-1-1V752.06a1,1,0,0,1,1-1h5a1,1,0,0,1,1,1v11.93a1,1,0,0,1-1,1Zm15.215-16.336a.992.992,0,0,1,.225-1.39l1.56-1.184H660a1,1,0,1,1,0-1.991h14l-1.6-1.184a.992.992,0,0,1-.21-1.392,1,1,0,0,1,1.4-.21l4,2.985a.986.986,0,0,1,.01,1.592l-4,2.985a.973.973,0,0,1-1.384-.212Z"
        transform="translate(-656 -741.109)"
        fill={props.color ?? "#2c9dff"}
      />
    </svg>
  );
};

export default AverageSvg;
