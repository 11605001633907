import { Typography, useTheme } from "@mui/material";
import React from "react";
import { themeColors } from "../../../../Config/Themes/theme";
import moment from "moment";

const CurrentDateTime: React.FC = () => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);

  const formattedDate = moment().format("DD MMM | HH:mm").toUpperCase();

  return (
    <div className="p-2" style={{ backgroundColor: colors.whiteAccent.main }}>
      <Typography
        className="font-bold"
        color={colors.cyanAccent.light}
        variant="h4"
      >
        {formattedDate}
      </Typography>
    </div>
  );
};

export default CurrentDateTime;
