import * as React from "react";

import AlertSvg from "../Icons/AlertSvg";
import SectionAreaTitleSvg from "../Icons/SectionAreaTitleSvg";
import ClockSvg from "../Icons/ClockSvg";
import HumanFigureSvg from "../Icons/HumanFigureSvg";
import InfoSvg from "../Icons/InfoSvg";
import { Tooltip, Typography } from "@mui/material";

import { useTheme } from "@mui/material";
import { removeDiacritics } from "../../../../Utils/Text/Text";

const HighestVisitorCountKpi = (props) => {
  const theme = useTheme();

  return (
    <div
      className="min-w-[250px] w-[250px] px-[15px] pt-[5px] pb-[5px] leading-10 rounded-md shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]"
      style={{
        backgroundColor: theme.palette.background.cardBackground,
        color: "#FFF",
        height: props?.compact ? 175 : 192,
      }}
    >
      <div className="flex gap-x-2 items-center py-2">
        <AlertSvg />
        <Typography
          fontFamily={
            '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
          }
          noWrap
          className="text-[12px]"
        >
          ΜΕΓΙΣΤΗ ΕΠΙΣΚΕΨΙΜΟΤΗΤΑ
        </Typography>
        {!!props.tooltip && (
          <Tooltip
            className="mr-3"
            placement="right-start"
            title={<Typography fontSize={14}>{props.tooltip}</Typography>}
          >
            <div className="mt-[-2px]">
              <InfoSvg size="22" color={"#FFF"} />
            </div>
          </Tooltip>
        )}
      </div>
      <div className="flex gap-x-[6px] items-center ml-[-4px] pt-2">
        <div
          className="w-[30px] h-[30px] border-[1.5px] border-solid flex justify-center items-center"
          style={{
            backgroundColor: theme.palette.background.cardBackground,
            borderColor: "#FFF",
            borderRadius: "50%",
          }}
        >
          <SectionAreaTitleSvg />
        </div>
        <span className="text-[16px] uppercase">
          {removeDiacritics(props.data?.locationName)}
        </span>
      </div>
      <div className="flex gap-x-[6px] items-center ml-[-4px] pt-2">
        <ClockSvg />
        <span className="text-[16px] font-semibold">{`${props.data?.date} | ${props.data?.time}`}</span>
      </div>
      <div className="flex gap-x-[6px]">
        <div className="mt-[10px]">
          <HumanFigureSvg height={"25"} width={"20"} />
        </div>
        <span className="text-[28px] leading-7 font-bold pt-2">
          {props.data?.highestVisitors}
        </span>
        <span className="text-[12px] mt-[7px]">ΕΠΙΣΚΕΠΤΕΣ</span>
      </div>
    </div>
  );
};

export default HighestVisitorCountKpi;
