import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTheme } from "@mui/material/styles";
import { themeColors } from "../../Config/Themes/theme";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import el from "date-fns/locale/el";
import { FormLabel } from "@mui/material";
import clsx from "clsx";

const MyDatePicker = ({
  className,
  label,
  disabled = false,
  size = "16px",
  value,
  onChange,
  error,
  helperText,
  placeholder,
  inputFormat = "dd/MM/yyyy",
  minDate,
  maxDate,
}) => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const [selectedDate, setSelectedDate] = useState(value);
  const classes = clsx("flex flex-col", className);

  const handleChange = (date) => {
    setSelectedDate(date);
    onChange(date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={el}>
      <div
        className={classes}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "6px",
          width: "100%",
        }}
      >
        <FormLabel
          sx={{
            color: colors.greyAccent.dark,
            fontSize: size,
            textTransform: "uppercase",
          }}
        >
          <span lang="el">{label}</span>
        </FormLabel>
        <DatePicker
          value={value}
          disabled={disabled}
          onChange={handleChange}
          maxDate={maxDate}
          minDate={minDate}
          format={inputFormat}
          sx={{
            "& .MuiOutlinedInput-root": {
              backgroundColor: colors.whiteAccent.main,
            },
          }}
          slotProps={{
            textField: {
              error: error,
              helperText: helperText,
            },
          }}
        />
      </div>
    </LocalizationProvider>
  );
};

export default MyDatePicker;
