import React, { useEffect, useMemo, useState } from "react";
import SectionAreaTitleSvg from "../../Icons/SectionAreaTitleSvg";
import { useTheme } from "@mui/material";
import { themeColors } from "../../../../Config/Themes/theme";
import LocationSvg from "../../Icons/LocationSvg";
import Icons from "../../../../assets/icons";
import EnvironmentAgent from "../../Api/EnvironmentAgent/EnvironmentAgent";
import {
  MEASUREMENTS_GAS,
  MEASUREMENTS_MAIN,
  MEASUREMENT_QUALITY,
} from "../../Config/Constants/measurements";
import { FormatNumberNoDecimals } from "../../common/FormatNumber/FormatNumberNoDecimals";

const EnvironmentalStationInfoWindow = (props) => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const [lastEvents, setLastEvents] = useState();

  const sensorData = useMemo(() => {
    if (!lastEvents || !lastEvents?.length > 0) {
      return null;
    }

    return {
      [MEASUREMENTS_GAS.CO2.id]: lastEvents[0][MEASUREMENTS_GAS.CO2.id],
      [MEASUREMENTS_GAS.NO2.id]: lastEvents[0][MEASUREMENTS_GAS.NO2.id],
      [MEASUREMENTS_GAS.NO.id]: lastEvents[0][MEASUREMENTS_GAS.NO.id],
      [MEASUREMENTS_GAS.O3.id]: lastEvents[0][MEASUREMENTS_GAS.O3.id],
      [MEASUREMENTS_GAS.SO2.id]: lastEvents[1][MEASUREMENTS_GAS.SO2.id],
      [MEASUREMENTS_GAS.PM1.id]: lastEvents[1][MEASUREMENTS_GAS.PM1.id],
      [MEASUREMENTS_GAS.PM10.id]: lastEvents[1][MEASUREMENTS_GAS.PM10.id],
      [MEASUREMENTS_GAS.PM25.id]: lastEvents[1][MEASUREMENTS_GAS.PM25.id],
      [MEASUREMENTS_MAIN.TEMPERATURE.id]:
        lastEvents[1][MEASUREMENTS_MAIN.TEMPERATURE.id],
      [MEASUREMENTS_MAIN.PRESSURE.id]:
        lastEvents[1][MEASUREMENTS_MAIN.PRESSURE.id],
      [MEASUREMENTS_MAIN.HUMIDITY.id]:
        lastEvents[1][MEASUREMENTS_MAIN.HUMIDITY.id],
    };
  }, [lastEvents]);

  useEffect(() => {
    const fetchLastEvents = async () => {
      await EnvironmentAgent.analysis
        .getLastEvents()
        .then((response) => {
          setLastEvents(response?.result?.lastEnvironmentEvents);
        })
        .catch((error) => console.error(error));
    };

    fetchLastEvents();
  }, []);

  const renderOldInfoWindowContent = () => {
    return (
      <>
        <div className="flex flex-col gap-y-1 w-fit">
          <div className="flex justify-between gap-x-2">
            <div className="flex items-center gap-x-1">
              <SectionAreaTitleSvg color={"#505381"} />
              <span className="text-[14px] font-semibold uppercase">
                <span lang="el">{`${props.data.title}`}</span>
              </span>
            </div>
            <div className="flex items-center gap-x-1">
              {/* <div className="relative w-[25px] h-[25px]">
              <div
                style={{ backgroundColor: props.data.color }}
                className="absolute w-[25px] h-[25px] rounded-full opacity-20"
              ></div>
              <div
                style={{ backgroundColor: props.data.color }}
                className="absolute w-[20px] h-[20px] rounded-full opacity-50 top-1/2 left-1/2 transform -translate-x-[47%] -translate-y-[51%]"
              ></div>
              <div
                style={{ backgroundColor: props.data.color }}
                className="absolute w-[15px] h-[15px] rounded-full opacity-100 top-1/2 left-1/2 transform -translate-x-[47%] -translate-y-[51%]"
              ></div>
            </div> */}

              <span className="text-[14px] font-semibold uppercase">
                ID: {props.data.id}
              </span>
            </div>
          </div>
          <div className="flex items-center gap-x-1">
            <LocationSvg size={"22"} />
            <span className="text-[14px] font-semibold">
              <span lang="el">{props.data.address}</span>
            </span>
          </div>
        </div>
        <hr className="h-px my-[6px] bg-[#d4d4d4] border-0" />
        <div className="flex flex-col gap-y-[6px] pl-[6px] mt-[12px] w-fit">
          <div className="flex items-center justify-between gap-x-3">
            <div className="flex items-center gap-x-2 w-fit">
              <div
                className="w-[10px] h-[10px] rounded-[50%]"
                style={{
                  backgroundColor: props.data.color,
                }}
              ></div>
              <span
                lang="el"
                className="text-[12px] font-semibold uppercase"
                style={{
                  color: props.data.color,
                }}
              >
                {props.data.label}
              </span>
            </div>
            <span lang="el" className="text-[12px] font-semibold uppercase">
              ΠΟΙΟΤΗΤΑ ΑΕΡΑ
            </span>
          </div>
          <div className="flex items-center justify-between gap-x-2">
            <div className="flex items-center gap-x-2 w-fit">
              <div className="w-[10px] h-[10px] rounded-[50%] bg-[#2c9dff]"></div>
              <span className="w-fit text-[12px] font-semibold uppercase text-[#6699ff]">
                {props.data.status}
              </span>
            </div>
            <span lang="el" className="text-[12px] font-semibold uppercase">
              ΚΑΤΑΣΤΑΣΗ
            </span>
          </div>
          {props.data?.devices?.map((device, index) => {
            return (
              <div
                key={device.nativeId}
                className="flex items-center justify-between gap-x-3"
              >
                <div className="flex items-center gap-x-2 w-fit">
                  <div
                    className="w-[10px] h-[10px] rounded-[50%]"
                    style={{
                      backgroundColor: index % 2 === 0 ? "#3fb24d" : "#ff7800",
                    }}
                  ></div>
                  <span className="w-fit text-[12px] font-semibold uppercase text-[#6699ff]">
                    {device.nativeId}
                  </span>
                </div>
                <span lang="el" className="text-[12px] font-semibold uppercase">
                  ΣΥΣΚΕΥΗ {index + 1}
                </span>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const renderInfoWindowContent = () => {
    return (
      <>
        <div className="flex flex-col gap-y-1 w-fit">
          <div className="flex justify-between gap-x-2">
            <div className="flex items-center gap-x-1">
              <LocationSvg size={"22"} />

              <span className="text-[14px] font-semibold uppercase">
                <span lang="el">{`${props?.data?.title}`}</span>
              </span>
            </div>
          </div>
          {/* {props?.data?.address && (
            <div className="flex items-center gap-x-1">
              <Icons
                name="location"
                width={22}
                height={22}
                fill={colors.blackAccent.main}
              />
              <div>
                <div>
                  <span lang="el" className="text-[12px] font-bold uppercase">
                    Διεύθυνση: &nbsp;
                  </span>
                  <span lang="el" className="text-[12px] font-normal uppercase">
                    {props?.data?.address}
                  </span>
                </div>
              </div>
            </div>
          )} */}
          {props?.data?.lat && props?.data?.lng && (
            <div className="flex items-center gap-x-1">
              <Icons
                name="location"
                width={22}
                height={22}
                fill={colors.blackAccent.main}
              />
              <div>
                <div>
                  <span lang="el" className="text-[12px] font-bold uppercase">
                    Γεω. Πλάτος: &nbsp;
                  </span>
                  <span lang="el" className="text-[12px] font-normal uppercase">
                    {props?.data?.lat}
                  </span>
                </div>
                <div>
                  <span lang="el" className="text-[12px] font-bold uppercase">
                    Γεω. Μήκος: &nbsp;
                  </span>
                  <span lang="el" className="text-[12px] font-normal uppercase">
                    {props?.data?.lng}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        <hr className="h-px my-[6px] bg-[#d4d4d4] border-0" />
        <div className="flex flex-col gap-y-[6px] pl-[6px] mt-[12px] w-fit">
          <div className="flex items-center justify-between gap-x-3">
            {props.data.label && (
              <div className="flex items-center gap-x-2 w-fit">
                <div
                  className="w-[10px] h-[10px] rounded-[50%]"
                  style={
                    props.data.color && {
                      backgroundColor: props.data.color,
                    }
                  }
                ></div>
                <span>
                  <span lang="el" className="text-[12px] font-bold uppercase">
                    ΠΟΙΟΤΗΤΑ ΑΕΡΑ:&nbsp;
                  </span>
                  <span lang="el" className="text-[12px] font-normal uppercase">
                    {props.data.label}
                  </span>
                </span>
              </div>
            )}
          </div>
          {sensorData?.temperature && (
            <div className="flex items-center justify-between gap-x-3">
              <div className="flex items-center gap-x-2 w-fit">
                <div
                  className="w-[10px] h-[10px] rounded-[50%]"
                  style={{
                    backgroundColor: "#f44336",
                  }}
                ></div>
                <span>
                  <span lang="el" className="text-[12px] font-bold uppercase">
                    Θερμοκρασία:&nbsp;
                  </span>
                  <span lang="el" className="text-[12px] font-normal">
                    {FormatNumberNoDecimals(sensorData?.temperature)}°C
                  </span>
                </span>
              </div>
            </div>
          )}
          {sensorData?.hunidity && (
            <div className="flex items-center justify-between gap-x-3">
              <div className="flex items-center gap-x-2 w-fit">
                <div
                  className="w-[10px] h-[10px] rounded-[50%]"
                  style={{
                    backgroundColor: "#348aff",
                  }}
                ></div>
                <span>
                  <span lang="el" className="text-[12px] font-bold uppercase">
                    Υγρασία:&nbsp;
                  </span>
                  <span lang="el" className="text-[12px] font-normal">
                    {FormatNumberNoDecimals(sensorData?.hunidity)}%
                  </span>
                </span>
              </div>
            </div>
          )}
          {sensorData?.pressure && (
            <div className="flex items-center justify-between gap-x-3">
              <div className="flex items-center gap-x-2 w-fit">
                <div
                  className="w-[10px] h-[10px] rounded-[50%]"
                  style={{
                    backgroundColor: "#3fb14d",
                  }}
                ></div>
                <span>
                  <span lang="el" className="text-[12px] font-bold uppercase">
                    Ατμ. Πίεση:&nbsp;
                  </span>
                  <span lang="el" className="text-[12px] font-normal">
                    {FormatNumberNoDecimals(sensorData?.pressure)} hpa
                  </span>
                </span>
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="w-fit h-fit rounded-md bg-white shadow-sm pb-[10px] pt-[3px] pr-1">
      {/* {renderOldInfoWindowContent()} */}
      {renderInfoWindowContent()}
    </div>
  );
};

export default EnvironmentalStationInfoWindow;
