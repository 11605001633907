import TabNavigation from "../../../../Components/TabNavigation/TabNavigation";
import Icons from "../../../../assets/icons";

const iconSize = 25;

const LightsTabMenu = () => {
  const items = [
    {
      label: "Ανάλυση",
      route: "",
      icon: <Icons name="analysis" width={iconSize} height={iconSize} />,
    },
    {
      label: "Αναφορές - Ιστορικότητα",
      route: "/reports",
      icon: <Icons name="reports-circle" width={iconSize} height={iconSize} />,
    },
    {
      label: "Λογισμικό Διαχείρισης",
      route: "/software-management",
      icon: <Icons name="html" width={iconSize} height={iconSize} />,
    },
    {
      label: "Ειδοποιήσεις",
      route: "/notifications",
      icon: <Icons name="notifications" width={iconSize} height={iconSize} />,
    },
  ];

  return <TabNavigation baseRoute="lights" routes={items} />;
};

export default LightsTabMenu;
