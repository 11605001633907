import { Typography, useTheme } from "@mui/material";
import Icons from "../../../../src/assets/icons";
import {
  MEASUREMENTS_GAS,
  MEASUREMENTS_MAIN,
  MEASUREMENT_QUALITY,
} from "../../../Saas/Environment/Config/Constants/measurements";
import { useEffect, useMemo, useState } from "react";
import EnvironmentAgent from "../../../Saas/Environment/Api/EnvironmentAgent/EnvironmentAgent";
import ShadowBox from "../../../Components/ShadowBox/ShadowBox";

const MeasurementField = ({ item, iconSize = 35 }) => {
  const theme = useTheme();
  const color = theme.palette.neutral.main;

  return (
    <div className="flex items-center justify-start px-2 gap-2 w-full">
      <Icons name={item?.icon} height={iconSize} width={iconSize} />
      <div className="flex justify-start">
        <Typography
          component="span"
          fontSize={18}
          color={color}
          textAlign="left"
        >
          {item?.value} {item?.unit}
        </Typography>
      </div>
    </div>
  );
};

const MeasurementCircle = ({ item }) => {
  const theme = useTheme();
  const status = item?.status;
  const color = status?.color;

  return (
    <ShadowBox className="justify-center p-2 h-[200px] w-full xs:w-[130px]">
      <Typography
        fontSize={18}
        fontWeight="bold"
        color={theme.palette.neutral.main}
        className="text-center mb-3"
      >
        {item?.label}
      </Typography>
      <div
        className={`flex flex-col justify-center items-center h-[100px] w-[100px] p-5 mx-auto`}
        style={{
          border: `5px solid ${color}`,
          borderRadius: "50%",
        }}
      >
        <Typography fontSize={18} fontWeight="bold" color={color}>
          {item?.value?.toString() || "---"}
        </Typography>
        <Typography color={color}>{item?.unit}</Typography>
      </div>
      <div className="flex justify-center items-center h-[35px]">
        <Typography
          fontSize={16}
          fontWeight="bold"
          color={color}
          className="mt-4 text-center break-words"
        >
          {status?.label}
        </Typography>
      </div>
    </ShadowBox>
  );
};

const AirQualityIndex = ({ aqi, aqiObject }) => {
  const theme = useTheme();

  return (
    <ShadowBox className="flex flex-col justify-between gap-2 items-center px-4 p-2 h-[200px] w-full xs:w-[130px]">
      <Typography
        fontSize={18}
        fontWeight="bold"
        color={theme.palette.neutral.main}
      >
        AQI
      </Typography>
      <div
        style={{
          height: "70px",
          width: "70px",
          borderRadius: "50%",
          backgroundColor: aqiObject?.color,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography fontSize={25} fontWeight="bold">
          {aqi}
        </Typography>
      </div>
      <Typography
        fontSize={16}
        fontWeight="bold"
        color={theme.palette.neutral.main}
        textAlign={"center"}
      >
        {`${aqiObject?.label} ποιότητα`}
      </Typography>
    </ShadowBox>
  );
};

const EnvironmentBlock = () => {
  const [lastEvents, setLastEvents] = useState();
  const [aqi, setAqi] = useState();
  const [loading, setLoading] = useState();
  const aqiObject = Object.values(MEASUREMENT_QUALITY)?.find(
    (item) => item.aqi === aqi
  );

  const data = useMemo(() => {
    if (!lastEvents || !lastEvents?.length > 0) {
      return null;
    }

    return {
      [MEASUREMENTS_GAS.CO2.id]: lastEvents[0][MEASUREMENTS_GAS.CO2.id],
      [MEASUREMENTS_GAS.NO2.id]: lastEvents[0][MEASUREMENTS_GAS.NO2.id],
      [MEASUREMENTS_GAS.NO.id]: lastEvents[0][MEASUREMENTS_GAS.NO.id],
      [MEASUREMENTS_GAS.O3.id]: lastEvents[0][MEASUREMENTS_GAS.O3.id],
      [MEASUREMENTS_GAS.SO2.id]: lastEvents[1][MEASUREMENTS_GAS.SO2.id],
      [MEASUREMENTS_GAS.PM1.id]: lastEvents[1][MEASUREMENTS_GAS.PM1.id],
      [MEASUREMENTS_GAS.PM10.id]: lastEvents[1][MEASUREMENTS_GAS.PM10.id],
      [MEASUREMENTS_GAS.PM25.id]: lastEvents[1][MEASUREMENTS_GAS.PM25.id],
      [MEASUREMENTS_MAIN.TEMPERATURE.id]:
        lastEvents[1][MEASUREMENTS_MAIN.TEMPERATURE.id],
      [MEASUREMENTS_MAIN.PRESSURE.id]:
        lastEvents[1][MEASUREMENTS_MAIN.PRESSURE.id],
      [MEASUREMENTS_MAIN.HUMIDITY.id]:
        lastEvents[1][MEASUREMENTS_MAIN.HUMIDITY.id],
    };
  }, [lastEvents]);

  useEffect(() => {
    const fetchLastEvents = async () => {
      setLoading(true);

      await EnvironmentAgent.analysis
        .getLastEvents()
        .then((response) => {
          setLastEvents(response?.result?.lastEnvironmentEvents);
          setAqi(response?.result?.aqi);
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setLoading(false);
        });
    };

    fetchLastEvents();
  }, []);

  if (loading) {
    return null;
  }

  if (!data) {
    return (
      <div>
        <Typography variant="h6" color="text.secondary" component="div">
          Περιβάλλον - Ποιότητα αέρα
        </Typography>
        <Typography variant="body2">Δεν υπάρχουν δεδομένα</Typography>
      </div>
    );
  }

  return (
    <div className="flex gap-2 justify-start flex-wrap p-5 max-h-[500px] xs:max-h-[fit-content] overflow-y-auto">
      <ShadowBox className="flex flex-col py-4 px-2 p-5 items-start justify-between w-full xs:w-[fit-content] h-[200px]">
        <MeasurementField
          item={{
            ...MEASUREMENTS_MAIN.TEMPERATURE,
            value: data[MEASUREMENTS_MAIN.TEMPERATURE.id],
          }}
        />
        <MeasurementField
          item={{
            ...MEASUREMENTS_MAIN.PRESSURE,
            value: data[MEASUREMENTS_MAIN.PRESSURE.id],
          }}
        />
        <MeasurementField
          item={{
            ...MEASUREMENTS_MAIN.HUMIDITY,
            value: data[MEASUREMENTS_MAIN.HUMIDITY.id],
          }}
        />
      </ShadowBox>

      {Object.values(MEASUREMENTS_GAS).map((item) => (
        <MeasurementCircle
          key={item.id}
          item={{
            ...item,
            status: item?.getStatus(data[item.id]),
            value: data[item.id],
          }}
        />
      ))}

      <AirQualityIndex aqi={aqi} aqiObject={aqiObject} />
    </div>
  );
};

export default EnvironmentBlock;
