export function removeDiacritics(text) {
  return text?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "");
}

export function removeSingleQuotes(text) {
  return text?.replace(/'/g, "");
}

export function normalizeSearchQuery(query) {
  return removeSingleQuotes(removeDiacritics(query))?.toLowerCase()?.trim();
}
