import dayjs from "dayjs";

const CountDaysDistance = (startDate, endDate) => {
  const date1 = dayjs(startDate, "DD/MM/YYYY");
  const date2 = dayjs(endDate, "DD/MM/YYYY");
  const daysDiff = date2.diff(date1, "day");
  return daysDiff;
};

export default CountDaysDistance;
