import { requests } from "../../../../Utils/HttpClient/HttpClient";

const { post, get, getFile, put, delete: deleteRequest } = requests;

const environmentAlert = {
  getEnvironmentAlerts: () => get("/EnvironmentAlert"),
};

const announcement = {
  getAnnouncements: () => get("/Announcement"),
  getAnnouncementById: (id) => get(`/Announcement/${id}`),
  createAnnouncement: (body) => post("/Announcement", body),
  updateAnnouncement: (id, body) => put(`/Announcement?id=${id}`, body),
  deleteAnnouncement: (id) => deleteRequest(`/Announcement?id=${id}`),
};

const analysis = {
  getLastEvents: () => get("/EnvironmentEvent/LastEvents"),
  getEventsByDate: (startDate, endDate) =>
    post(
      "/EnvironmentEvent/GetEventsFromDateToDate?from=" +
        startDate +
        "&to=" +
        endDate
    ),
  getEnvironmentalStation: (includeDevices = false) =>
    get("/EnvironmentalStation?includeDevices=" + includeDevices),
  getEnvironmentalStationById: (id, includeDevices) =>
    get(`/EnvironmentalStation/${id}?includeDevices=${includeDevices}`),
  createEnvironmentalStation: (body) => post("/EnvironmentalStation", body),
  updateEnvironmentalStation: (id, body) =>
    put(`/EnvironmentalStation/${id}`, body),
  getEnvironmentalData: (startDate, endDate) =>
    get(
      `/EnvironmentalStation/GetEnvironmentalData?from=${startDate}&to=${endDate}&shouldGroupByDate=${true}`
    ),
  getStationTemperaturesFromDateToDate: (startDate, endDate) =>
    post(
      `/EnvironmentEvent/GetStationTemperaturesFromDateToDate?from=${startDate}&to=${endDate}`
    ),
  GetStationHumidityFromDateToDate: (startDate, endDate) =>
    post(
      `/EnvironmentEvent/GetStationHumidityFromDateToDate?from=${startDate}&to=${endDate}`
    ),
};

const reports = {
  exportExcel: ({ id, from, to, fileName }) => {
    return getFile(
      `/EnvironmentEvent/ExcelReport?organizationId=${id}&from=${from}&to=${to}`
    )
      .then((response) => {
        const contentType = response.headers["content-type"];
        if (contentType && contentType.includes("application/json")) {
          return response.data.text().then(JSON.parse);
        } else {
          return response.data;
        }
      })
      .then((data) => {
        if (data instanceof Blob) {
          const url = URL.createObjectURL(data);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${fileName}.xlsx`);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);

          return {
            success: true,
            message: "Exported successfully",
          };
        } else {
          return {
            success: false,
            message: data.errors[0],
          };
        }
      })
      .catch((err) => {
        console.log(err);
        return {
          success: false,
          message: err,
        };
      });
  },
};

export default { environmentAlert, announcement, analysis, reports };
