import { Route, Routes } from "react-router-dom";
import { observer } from "mobx-react";
import Notifications from "../Pages/Notifications/Notifications";
import EnvironmentPage from "../MainAppComponents/EnvironmentPage/EnvironmentPage";
import Analysis from "../Pages/Analysis/Analysis";
import CreateReport from "../Pages/CreateReport/CreateReport";

// Wraps all pages which share common layout
//
const wrap = (element, pageProps) => (
  <EnvironmentPage {...pageProps}>{element}</EnvironmentPage>
);

const EnvironmentRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={wrap(<Analysis />)} />
      <Route path="/reports" element={wrap(<CreateReport />)} />
      <Route path="/heat-map" element={wrap(<div>Heat Map</div>)} />
      <Route path="/notifications" element={wrap(<Notifications />)} />
    </Routes>
  );
};
export default observer(EnvironmentRoutes);
