import { Controller } from "react-hook-form";
import {
  FormLabel,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { themeColors } from "../../Config/Themes/theme";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useCallback } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const PasswordTextInput = ({
  label,
  name,
  control,
  size = "16px",
  className = "",
  error,
  defaultValue = "",
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const toggleVisibility = useCallback(() => {
    setPasswordVisible((oldPassword) => !oldPassword);
  }, [setPasswordVisible]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={className}>
      <FormLabel
        sx={{
          color: colors.secondary.main,
          fontSize: size,
        }}
      >
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            fullWidth
            className={className}
            variant={"outlined"}
            type={passwordVisible ? "text" : "password"}
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? t(error?.message, { min: 8, max: 32 }) : null}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: colors.whiteAccent.main,
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleVisibility}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {passwordVisible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

export default PasswordTextInput;
