import { useState, useRef, useEffect, useCallback } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import Badge from "@mui/material/Badge";

// project imports
import MainCard from "../../Theme/Cards/MainCard";
import Transitions from "../../Theme/Extended/Transitions";
import RoundIcon from "../../RoundIcon/RoundIcon";

// assets
import { observer } from "mobx-react";
import { useMapStore } from "../../../Stores/MapStore";
import { toJS } from "mobx";
import Icons from "../../../assets/icons";
import { themeColors } from "../../../Config/Themes/theme";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  highBinFullnessKey,
  lowBinFullnessKey,
  mediumBinFullnessKey,
  superLowBinFullnessKey,
} from "../../../Config/constant";

// ==============================|| FILTER MENU ||============================== //

const MainMapFilter = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  // const mapStore = useMapStore();
  const {
    allFilter,
    thresholdsFilter,
    alertFilter,
    typeFilter,
    trashBinsTypes,
    toggleAllStatus,
    toggleThresholdsStatus,
    toggleAlertStatus,
    toggleTypeStatus,
  } = useMapStore();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const prevOpen = useRef(open);

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  useEffect(() => {}, [thresholdsFilter, typeFilter]);

  useEffect(() => {}, [allFilter]);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  /****************************** Update values in mobx ******************************/
  const handleToggleAllStatus = () => {
    toggleAllStatus();
    forceUpdate();
  };

  const handleToggleThresholdStatus = (id) => {
    toggleThresholdsStatus(id);
    forceUpdate();
  };

  const handleToggleAlertStatus = (id) => {
    toggleAlertStatus(id);
    forceUpdate();
  };

  const handleToggleTypeStatus = (id) => {
    toggleTypeStatus(id);
    forceUpdate();
  };

  const renderTitle = (threshold, index) => {
    return (
      <div className="flex items-center justify-center flex-col">
        <div>{t(threshold.translationKey)}</div>
        {index === 0 && <div>{`0-${thresholdsFilter[0].value}%`}</div>}
        {index === 1 && (
          <div>{`${thresholdsFilter[0].value + 1}-${
            thresholdsFilter[1].value
          }%`}</div>
        )}
        {index === 2 && (
          <div>{`${thresholdsFilter[1].value + 1}-${
            thresholdsFilter[2].value
          }%`}</div>
        )}
        {index === 3 && (
          <div>{`${thresholdsFilter[2].value + 1}-${
            thresholdsFilter[3].value
          }%`}</div>
        )}
      </div>
    );
  };

  /****************************** Custom Helper Components ******************************/

  // eslint-disable-next-line consistent-return
  const renderBadge = (thresholdId) => {
    let bgColor = null;

    const badgeElm = () => {
      return (
        <span
          className="rounded-[25px] px-[5px] inline-block leading-none text-center whitespace-nowrap align-baseline font-bold"
          style={{ background: bgColor }}
        >
          &nbsp;
        </span>
      );
    };

    switch (thresholdId) {
      case superLowBinFullnessKey:
        bgColor = colors.primary.main;
        return badgeElm();

      case lowBinFullnessKey:
        bgColor = colors.yellowAccent.main;
        return badgeElm();

      case mediumBinFullnessKey:
        bgColor = colors.orangeAccent.main;
        return badgeElm();

      case highBinFullnessKey:
        bgColor = colors.redAccent.main;
        return badgeElm();

      default:
        bgColor = colors.primary.main;
        return badgeElm();
    }
  };

  const renderThreshold = (threshold, index) => (
    <div className="flex items-center justify-between p-2" key={threshold.id}>
      <div className="flex items-center justify-between">
        <Checkbox
          value={threshold?.status}
          checked={threshold?.status}
          onChange={() => handleToggleThresholdStatus(threshold.id)}
          {...label}
        />
        {renderBadge(threshold?.id)}
        <Typography
          className="ml-4"
          component="span"
          variant="h4"
          sx={{ fontWeight: 340 }}
        >
          {renderTitle(threshold, index)}
        </Typography>
      </div>
    </div>
  );

  const renderAlert = (alert) => (
    <div className="flex items-center justify-between p-2" key={alert.id}>
      <div className="flex items-center justify-between">
        <Checkbox
          value={alert?.status}
          checked={alert?.status}
          onChange={() => handleToggleAlertStatus(alert.id)}
          {...label}
        />
        {/* <RoundIcon value={alert?.title} /> */}
        <RoundIcon type={alert.type} id={alert.id} />
        <Typography
          className="ml-4"
          component="span"
          variant="h4"
          sx={{ fontWeight: 340 }}
        >
          {t(alert?.translationKey)}
        </Typography>
      </div>
    </div>
  );

  const renderType = (type) => (
    <div className="flex items-center justify-between p-2 " key={type.id}>
      <div className="flex items-center justify-between">
        <Checkbox
          value={type?.status}
          checked={type?.status}
          onChange={() => handleToggleTypeStatus(type.id)}
          {...label}
        />
        <RoundIcon type={type.type} id={type.id} />
        <Typography
          className="ml-2"
          component="span"
          variant="h4"
          sx={{ fontWeight: 340 }}
        >
          {type?.description}
        </Typography>
      </div>
    </div>
  );

  return (
    <>
      <Chip
        sx={{
          width: "45px",
          height: "40px",
          alignItems: "center",
          borderRadius: "5px",
          transition: "all .2s ease-in-out",
          borderColor: colors.greenAccent.main,
          backgroundColor: colors.whiteAccent.main,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: colors.greenAccent.main,
            background: colors.greyAccent.light2,
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
        }}
        label={
          <Icons
            name="filter"
            width={24}
            height={24}
            fill={colors.greenAccent.main}
          />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        className="z-[1] rounded-[4px]"
        // style={{border: `1px solid ${colors.greenAccent.main}`}}
        placement="left-start"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  //   shadow={theme.shadows[16]}
                >
                  <Box sx={{ p: 2 }}>
                    <Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <div className="w-full flex items-center justify-between">
                          <div className="flex justify-center items-center">
                            <Checkbox
                              value={allFilter}
                              checked={allFilter}
                              onChange={handleToggleAllStatus}
                            />
                            <Typography
                              component="span"
                              variant="h4"
                              sx={{ fontWeight: 400 }}
                            >
                              {t("historicMapFilter.SelectAll")}
                            </Typography>
                          </div>
                        </div>
                      </Stack>
                      <Grid padding="11px">
                        <Divider />
                      </Grid>
                      <div className="flex items-start justify-between mr-10">
                        <div>
                          <Typography
                            component="span"
                            variant="h4"
                            sx={{ fontWeight: 400 }}
                          >
                            {t("historicMapFilter.Fullness")}
                          </Typography>
                          <div className="mt-5">
                            {thresholdsFilter.map((o, index) =>
                              renderThreshold(o, index)
                            )}
                          </div>
                        </div>

                        <div>
                          <Typography
                            className=""
                            component="span"
                            variant="h4"
                            sx={{ fontWeight: 400 }}
                          >
                            {t("alerts.Alerts")}
                          </Typography>
                          <div className="mt-5">
                            {alertFilter.map((o) => renderAlert(o))}
                          </div>
                        </div>
                      </div>
                      <Grid padding="11px">
                        <Divider />
                      </Grid>
                      <Typography
                        component="span"
                        variant="h4"
                        sx={{ fontWeight: 400 }}
                      >
                        {t("historicMapFilter.Type")}
                      </Typography>
                      <span className="grid grid-cols-2 gap-4">
                        {trashBinsTypes.map((o) => renderType(o))}
                      </span>
                    </Stack>
                  </Box>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default observer(MainMapFilter);
