import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { themeColors } from "../../Config/Themes/theme";
import { useTranslation } from "react-i18next";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import Icons from "../../assets/icons";
import { useAuthStore } from "../../Stores/AuthStore";
import { observer } from "mobx-react";
import Profile from "../Profile/Profile";

const iconSize = 35;
const iconClassName = "mb-0";

const Item = ({ title, to, icon, selected, setSelected, badge }) => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === to) {
      setSelected(title);
      localStorage.setItem("selectedMenuItem", title);
    }
  }, [location]);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.whiteAccent.main,
        background: selected === title ? colors.bg.whiteTransp["01"] : null,
        marginRight: selected === title ? "20px" : null,
        borderRadius: selected === title ? "4px" : null,
      }}
      onClick={() => {
        setSelected(title);
        localStorage.setItem("selectedMenuItem", title);
      }}
      icon={icon}
    >
      <div className="flex items-center justify-between">
        <Typography className="whitespace-normal break-words font-semibold">
          {title}
        </Typography>
        <Typography className="whitespace-normal break-words flex items-center justify-center">
          {badge}
        </Typography>
      </div>
      <Link to={to} />
    </MenuItem>
  );
};

const SidebarMenu = () => {
  const theme = useTheme();
  const authStore = useAuthStore();
  const { t } = useTranslation();
  const colors = themeColors(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState(
    localStorage.getItem("selectedMenuItem") || "Dashboard"
  );
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const textColor = colors.mainTitle.dark;
  const bgColor = colors.backgroundPrimary.darker;
  const [isMediumScreen, setIsMediumScreen] = useState(
    window.innerWidth < 1024
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMediumScreen(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setIsCollapsed(isMediumScreen);
  }, [isMediumScreen]);

  useEffect(() => {
    localStorage.setItem("selectedMenuItem", selected);
  }, [selected]);

  const renderMenuHeaderExpanded = () => {
    return (
      <div className="pb-10">
        <div className="flex justify-start items-center h-[40px] mb-5">
          <IconButton
            className="ml-3 mr-2"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            <MenuOutlinedIcon />
          </IconButton>
          <Typography
            variant="h2"
            className="whitespace-nowrap"
            color={theme.palette.primary.main}
          >
            My Platform
          </Typography>
        </div>
        <div className="ml-1">
          <Profile />
        </div>
      </div>
    );
  };

  const renderMenuHeaderCollapsed = () => (
    <div className="pb-10">
      <div className="flex flex-col justify-center items-center">
        <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
          <MenuOutlinedIcon />
        </IconButton>

        <div className="mb-2">{/* <LanguageSwitcher compact /> */}</div>

        <div className="mb-2">
          <Profile collapsed />
        </div>
      </div>
    </div>
  );

  const renderUserMenuItems = () => {
    return (
      <>
        {Object.values(authStore.saas).filter(
          (value) => value !== false && value !== undefined
        ).length > 1 && (
          <Item
            title={t("misc.Dashboard")}
            to="/"
            icon={
              <Icons
                name="dashboard"
                width={iconSize}
                height={iconSize}
                className={iconClassName}
              />
            }
            selected={selected}
            setSelected={setSelected}
          />
        )}

        {authStore.saas.smartBin && (
          <Item
            title={"Smart Bins"}
            to="/smart-bin"
            icon={
              <Icons
                name="smart-bin"
                width={iconSize - 12}
                height={iconSize - 12}
                className={iconClassName}
              />
            }
            selected={selected}
            setSelected={setSelected}
          />
        )}

        {authStore.saas.wifi && (
          <Item
            title={"Wifi & Επισκεψιμότητα"}
            to="/wifi"
            icon={
              <Icons
                name="wifi"
                width={iconSize}
                height={iconSize}
                className={iconClassName}
              />
            }
            selected={selected}
            setSelected={setSelected}
          />
        )}

        {authStore.saas.environment && (
          <Item
            title={"Περιβάλλον - Ποιότητα αέρα"}
            to="/environment"
            icon={
              <Icons
                name="environment"
                width={iconSize}
                height={iconSize}
                className={iconClassName}
              />
            }
            selected={selected}
            setSelected={setSelected}
          />
        )}

        {authStore.saas.lights && (
          <Item
            title={"Οδοφωτισμός"}
            to="/lights"
            icon={
              <Icons
                name="lights"
                width={iconSize}
                height={iconSize}
                className={iconClassName}
              />
            }
            selected={selected}
            setSelected={setSelected}
          />
        )}

        {authStore.saas.screens && (
          <Item
            title={"Οθόνες"}
            to="/screens/software-management"
            icon={
              <Icons
                name="screens"
                width={iconSize}
                height={iconSize}
                className={iconClassName}
              />
            }
            selected={selected}
            setSelected={setSelected}
          />
        )}

        <Item
          title={"Ανακοινώσεις"}
          to="/announcements"
          icon={
            <Icons
              name="announcements"
              width={iconSize}
              height={iconSize}
              className={iconClassName}
            />
          }
          selected={selected}
          setSelected={setSelected}
        />
      </>
    );
  };

  const renderAdminMenuItems = () => {
    return (
      <Item
        title={t("users.UsersList")}
        to="/users"
        icon={
          <Icons
            name="people"
            width={28}
            height={28}
            className={iconClassName}
            fill={colors.secondary.main}
          />
        }
        selected={selected}
        setSelected={setSelected}
      />
    );
  };

  return (
    <Box
      className="sticky top-0 h-screen"
      sx={{
        "& .MuiBox-root": {
          paddingLeft: 0,
        },
        "& .pro-sidebar-inner": {
          background: "white !important",
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          cursor: "pointer",
          height: "100%",
          width: "100%",
        },
        "& .pro-inner-item:hover": {
          backgroundColor: `${bgColor} !important`,
          color: `${textColor} !important`,
        },
        "& .pro-inner-item:active": {
          color: "white !important",
        },
        "& .pro-inner-item:active:hover": {
          backgroundColor: `${bgColor} !important`,
        },
        "& .pro-menu-item": {
          color: `${textColor} !important`,
          marginRight: "0 !important",
          borderRadius: "0 !important",
          borderBottom: `1px solid ${bgColor}`,
          display: "flex",
          alignItems: "center",
          height: "70px",
        },
        "& .pro-menu-item:active": {
          backgroundColor: `${bgColor} !important`,
        },
        "& .pro-menu-item.active": {
          backgroundColor: `${bgColor} !important`,
        },
      }}
      style={{ height: screenHeight }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square" className="pl-0">
          {/* LOGO AND MENU ICON */}
          {isCollapsed
            ? renderMenuHeaderCollapsed()
            : renderMenuHeaderExpanded()}

          {/* MENU ITEMS */}
          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <>
              <Box
                className="pb-3 pl-2 min-h-[3px]"
                style={{ borderBottom: "1px solid #E5E4E2" }}
              >
                {!isCollapsed && (
                  <Typography variant="h7" className="pl-5">
                    {t("misc.Navigation")?.toUpperCase()}
                  </Typography>
                )}
              </Box>
              {authStore.isUser && renderUserMenuItems()}
              {authStore.isAdmin && renderAdminMenuItems()}
            </>
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default observer(SidebarMenu);
