import { action, computed, makeObservable, observable, reaction } from "mobx";
import { createContext, useContext } from "react";
import AuthSetAgent from "../Api/AuthSetAgent";
import { setBearerToken } from "../Utils/HttpClient/HttpClient";
import { decodeJwtToken } from "../Utils/Encryption/Encryption";
import { success } from "../Utils/Toast/Toast";
import {
  getRefreshTokenFromSessionStorage,
  getTokenFromSessionStorage,
  saveRefreshTokenToSessionStorage,
  saveTokenToSessionStorage,
} from "../Utils/Storage/SessionStorage";
import i18n from "../Config/Localization/i18n";

class AuthStore {
  authUser = null;

  token = undefined;

  refreshToken = null;

  navigate = null;

  intendedUrl = null;

  saas = {
    smartBin: false,
    environment: false,
    wifi: false,
    screens: false,
    lights: false,
    screens: false,
  };

  constructor() {
    makeObservable(this, {
      authUser: observable,
      userAuthenticated: computed,
      token: observable,
      refreshToken: observable,
      saas: observable,
      logout: action,
      login: action,
      setAuthUser: action,
      initializeTokens: action,
      isUser: computed,
      isAdmin: computed,
      authUserIsNotLoaded: computed,
      canUserAdjustThreshold: computed,
      updateProfile: action,
      updatePassword: action,
      forgotPassword: action,
      resetPassword: action,
      updateToken: action,
      navigateToIntendedUrl: action,
    });

    this.logout = this.logout.bind(this);

    reaction(() => this.token, detectTokenValidity.bind(this));
    reaction(() => this.token, getUserProfile.bind(this));
    reaction(
      () => this.refreshToken,
      (refreshToken) => saveRefreshTokenToSessionStorage(refreshToken)
    );

    this.initializeTokens();
  }

  initializeTokens() {
    this.refreshToken = getRefreshTokenFromSessionStorage();
    this.token = getTokenFromSessionStorage();
  }

  logout() {
    this.authUser = null;
    sessionStorage.clear();
    setBearerToken(null);
    this.saveTokens(null, null);
  }

  get userAuthenticated() {
    return !!this.authUser && !!this.token;
  }

  async login({ email, password }) {
    try {
      const loginResp = await AuthSetAgent.user.login(email, password);
      if (!loginResp) {
        return;
      }
      const token = loginResp.token;
      const refreshToken = loginResp.refreshToken;

      if (!token || !refreshToken) {
        return;
      }
      this.saveTokens(token, refreshToken);
      const decoded = JSON.parse(atob(token.split(".")[1]));
      success("Welcome, " + decoded?.email);
    } catch (error) {}
  }

  getOrganization = (orgId) => {
    AuthSetAgent.organization
      .getOrganizationById(orgId)
      .then((response) => {
        this.saas = {
          smartBin: response.organization.hasBins || this.saas.smartBin,
          environment:
            response.organization.hasEnvironmental || this.saas.environment,
          wifi: response.organization.hasWifi || this.saas.wifi,
          screens: response.organization.hasScreens || this.saas.screens,
          lights: response.organization.hasLights || this.saas.lights,
        };
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  saveTokens(token, refreshToken) {
    saveTokenToSessionStorage(token);
    saveRefreshTokenToSessionStorage(refreshToken);
    this.token = token;
  }

  setAuthUser(user) {
    this.authUser = user;
  }

  setUserClaims(claims) {
    this.saas = {
      smartBin: claims?.includes("can_manage_bins"),
      environment: claims?.includes("can_manage_environmental"),
      wifi: claims?.includes("can_manage_wifi"),
      lights: claims?.includes("can_manage_lights"),
      screens: claims?.includes("can_manage_screens"),
    };
  }

  updateProfile(phoneNumber) {
    return AuthSetAgent.user
      .updateProfile(phoneNumber)
      .then((response) => {
        success(i18n.t("profile.ProfileWasSuccessfullyUpdated"));
        this.setAuthUser(
          Object.assign(this.authUser, {
            phoneNumber: response.userDto.phoneNumber,
          })
        );
      })
      .catch((e) => {
        console.log("e", e);
      });
  }

  updatePassword(currentPassword, newPassword, confirmNewPassword) {
    return AuthSetAgent.user
      .changePassword(currentPassword, newPassword, confirmNewPassword)
      .then((response) => {
        success(i18n.t("forms.PasswordWasSuccessfullyUpdated"));
      })
      .catch((e) => {});
  }

  forgotPassword(email) {
    return AuthSetAgent.user
      .forgotPassword(email)
      .then((resp) => {
        success(i18n.t("forgotPassword.ConfirmationEmail"));
      })
      .catch((e) => {});
  }

  resetPassword(data, token) {
    return AuthSetAgent.user
      .resetPassword(data, token)
      .then((resp) => {
        success(i18n.t("resetPassword.YouHaveSuccessfullyChangedYourPassword"));
      })
      .catch((e) => {});
  }

  async updateToken() {
    try {
      const token = getTokenFromSessionStorage();
      const refreshToken = getRefreshTokenFromSessionStorage();

      if (!token || !refreshToken) {
        return;
      }

      const params = {
        token: token,
        refreshToken: refreshToken,
      };

      const resp = await AuthSetAgent.user.refreshToken(params);
      saveTokenToSessionStorage(resp?.token);
      saveRefreshTokenToSessionStorage(resp?.refreshToken);
    } catch (error) {
      this.logout();
    }
  }

  navigateToIntendedUrl() {
    if (this.navigate && this.intendedUrl) {
      this.navigate(this.intendedUrl);
      this.navigate = null;
      this.intendedUrl = null;
    }
  }

  get authUserIsNotLoaded() {
    return !Object.keys(this.authUser).length;
  }

  get isUser() {
    return this.authUser?.roles?.includes("User");
  }

  get isAdmin() {
    return this.authUser?.roles?.includes("Admin");
  }

  get canUserAdjustThreshold() {
    return this.authUser?.claims?.includes("can_manage_thresholds");
  }
}

export const authStore = new AuthStore();
export const AuthStoreContext = createContext(authStore);
export const AuthStoreProvider = ({ children, store }) => (
  <AuthStoreContext.Provider value={store}>
    {children}
  </AuthStoreContext.Provider>
);
export const useAuthStore = () => useContext(AuthStoreContext);

function detectTokenValidity() {
  let sessionToken = getTokenFromSessionStorage();
  if (!sessionToken) {
    this.logout();
    return;
  }

  try {
    const payload = decodeJwtToken(sessionToken);
    const tokenExpired = payload.exp <= Math.floor(Date.now() / 1000);
    if (tokenExpired) {
      this.logout();
    } else {
      setBearerToken(sessionToken);
    }
  } catch (e) {
    this.logout();
  }
}

function getUserProfile(token) {
  //The line below is needed to ensure proper functionality of the app
  if (!token) return this.logout();
  AuthSetAgent.user
    .getProfile()
    .then(({ userDto, roles, claims }) => {
      const userObject = {
        ...userDto,
        roles,
        claims,
      };
      this.getOrganization(userObject.organizationId);
      this.setAuthUser(userObject);
      this.setUserClaims(claims);
      this.navigateToIntendedUrl();
    })
    .catch((e) => this.logout());
}
