import * as React from "react";

const InfoSvg = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ??"30"}
      zoomAndPan="magnify"
      viewBox="0 0 375 374.999991"
      height={props.size ??"30"}
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <clipPath id="623243eefa">
          <path
            d="M 37.5 48.484375 L 338.25 48.484375 L 338.25 349.234375 L 37.5 349.234375 Z M 37.5 48.484375 "
            clipRule="nonzero"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#623243eefa)">
        <path
          fill={props.color ?? "#150e60"}
          d="M 187.761719 75.804688 C 119.863281 75.804688 64.820312 130.847656 64.820312 198.746094 C 64.820312 266.648438 119.863281 321.691406 187.761719 321.691406 C 255.664062 321.691406 310.707031 266.648438 310.707031 198.746094 C 310.707031 130.847656 255.664062 75.804688 187.761719 75.804688 Z M 37.5 198.746094 C 37.5 115.757812 104.773438 48.484375 187.761719 48.484375 C 270.75 48.484375 338.027344 115.757812 338.027344 198.746094 C 338.027344 281.734375 270.75 349.011719 187.761719 349.011719 C 104.773438 349.011719 37.5 281.734375 37.5 198.746094 Z M 37.5 198.746094 "
          fillOpacity="1"
          fillRule="evenodd"
        />
      </g>
      <path
        fill={props.color ?? "#150e60"}
        d="M 174.101562 153.210938 L 201.425781 153.210938 L 201.425781 289.816406 L 174.101562 289.816406 Z M 174.101562 153.210938 "
        fillOpacity="1"
        fillRule="evenodd"
      />
      <path
        fill={props.color ?? "#150e60"}
        d="M 174.101562 107.675781 L 201.425781 107.675781 L 201.425781 138.035156 L 174.101562 138.035156 Z M 174.101562 107.675781 "
        fillOpacity="1"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default InfoSvg;
