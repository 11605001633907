import MainContent from "../../../../MainAppComponents/MainContent";
import Topbar from "../../../../Components/Topbar/Topbar";
import ScreensTabMenu from "../ScreensTabMenu/ScreensTabMenu";

const ScreensLayout = ({ children, showTabs = true, ...props }) => (
  <>
    <Topbar icon="screens" title="Οθόνες" />
    {showTabs && <ScreensTabMenu />}
    <MainContent>{children}</MainContent>
  </>
);

export default ScreensLayout;
