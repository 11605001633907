import { makeObservable, observable, action } from "mobx";
import { createContext, useContext } from "react";
import AuthSetAgent from "../Api/AuthSetAgent";

class AnnouncementsStore {
  announcementsStatuses = [
    { key: "Inactive", name: "Ανενεργή" },
    { key: "Active", name: "Ενεργή" },
  ];

  announcementsList = [];
  totalPages = 1;
  currentPage = 1;
  itemsPerPage = 10;
  totalItems = null;

  constructor() {
    makeObservable(this, {
      announcementsStatuses: observable,
      announcementsList: observable,
      totalPages: observable,
      currentPage: observable,
      itemsPerPage: observable,
      totalItems: observable,
      getAllAnnouncements: action,
      getAnnouncementById: action,
      createNewAnnouncement: action,
      updateExistingAnnouncement: action,
    });
  }

  getAllAnnouncements(
    PageNumber,
    PageSize,
    key,
    OrderByDateAsc,
    OrderByDateDesc,
    OrderByIdAsc,
    OrderByIdDesc,
    OrderByStatusAsc,
    OrderByStatusDesc
  ) {
    return AuthSetAgent.announcement
      .getAnnouncements(
        PageNumber || 1,
        PageSize || 10,
        key || "",
        OrderByDateAsc || false,
        OrderByDateDesc || true,
        OrderByIdAsc || false,
        OrderByIdDesc || false,
        OrderByStatusAsc || false,
        OrderByStatusDesc || false
      )
      .then((response) => {
        const data = response?.data?.result;
        const totalPages = response?.pagination?.totalPages;
        const currentPage = response?.pagination?.currentPage;
        const itemsPerPage = response?.pagination?.itemsPerPage;
        const totalItems = response?.pagination?.totalItems;
        this.announcementsList = data;
        this.totalPages = totalPages;
        this.currentPage = currentPage;
        this.itemsPerPage = itemsPerPage;
        this.totalItems = totalItems;
      })
      .catch((e) => {});
  }

  getAnnouncementById = (id) =>
    AuthSetAgent.announcement.getAnnouncementById(id);

  createNewAnnouncement(data) {
    return AuthSetAgent.announcement.createAnnouncement(data);
  }

  updateExistingAnnouncement(id, body) {
    return AuthSetAgent.announcement.updateAnnouncement(id, body);
  }
}

export const announcementsStore = new AnnouncementsStore();
export const AnnouncementsContext = createContext(announcementsStore);
export const AnnouncementsProvider = ({ children, store }) => (
  <AnnouncementsContext.Provider value={store}>
    {children}
  </AnnouncementsContext.Provider>
);
export const useAnnouncementsStore = () => useContext(AnnouncementsContext);
