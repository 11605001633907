import { Typography } from "@mui/material";

const PageTitle = ({ className, style, title }) => {
  return (
    <Typography
      className={className}
      color="primary.main"
      style={style}
      variant="h4"
    >
      {title}
    </Typography>
  );
};

export default PageTitle;
