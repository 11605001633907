import { observer } from "mobx-react";
import { useState } from "react";
import { Pagination, Stack, Typography } from "@mui/material";
import { normalizeSearchQuery } from "../../../../Utils/Text/Text";
import { dateInDateMonthYear } from "../../../../Utils/formatDate/formatDate";
import { toJS } from "mobx";

const NotificationsTable = ({
  notifications,
  inputValue,
  selectedCategoryId,
}) => {
  const [sortColumns, setSortColumns] = useState({
    id: "asc",
    timestamp: "asc",
    category: "asc",
    description: "asc",
  });

  const statusTranslations = {
    Lights: "Οδοφωτισμός",
    Wifi: "Wifi",
    Environment: "Περιβάλλον",
  };

  const [page, setPage] = useState(1);
  const pageSize = 15;
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const columns = [
    { key: "id", label: "Κωδ.", width: "10%" },
    { key: "timestamp", label: "Ημερομηνία", width: "15%" },
    { key: "category", label: "Κατηγορία", width: "20%" },
    { key: "description", label: "Περιγραφή", width: "35%" },
  ];

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleSort = (column) => {
    setSortColumns((prevSortColumns) => {
      const sortOrder = prevSortColumns[column] === "asc" ? "desc" : "asc";
      const updatedSortColumns = { ...prevSortColumns };
      for (const key in updatedSortColumns) {
        if (key !== column) {
          updatedSortColumns[key] = "none";
        }
      }

      updatedSortColumns[column] = sortOrder;
      return updatedSortColumns;
    });
  };

  const sortedNotifications = notifications
    .filter((notification) => {
      if (inputValue) {
        const inputValueNormalized = normalizeSearchQuery(inputValue);
        const searchPattern = new RegExp(inputValueNormalized, "i");

        return (
          searchPattern.test(
            normalizeSearchQuery(notification.id.toString())
          ) ||
          searchPattern.test(
            normalizeSearchQuery(dateInDateMonthYear(notification.timestamp))
          ) ||
          searchPattern.test(
            normalizeSearchQuery(statusTranslations[notification?.category])
          ) ||
          searchPattern.test(normalizeSearchQuery(notification.description))
        );
      }
      return true;
    })
    // .filter((notification) => {
    //   if (selectedCategoryId) {
    //     return notification.category === selectedCategoryId;
    //   }
    //   return true;
    // })
    .sort((a, b) => {
      const activeColumn = Object.keys(sortColumns).find(
        (column) => sortColumns[column] !== "none"
      );

      if (activeColumn) {
        if (sortColumns[activeColumn] === "asc") {
          return a[activeColumn] > b[activeColumn] ? 1 : -1;
        } else {
          return a[activeColumn] < b[activeColumn] ? 1 : -1;
        }
      }
      return 0;
    });

  const notificationsWithPagination = sortedNotifications.slice(
    startIndex,
    endIndex
  );

  return (
    <div className="table-container">
      <table
        style={{ width: "100%", tableLayout: "auto", marginBottom: "10px" }}
        className="responsive-table"
      >
        <thead>
          <tr>
            {columns.map((column) => (
              <th
                key={column.key}
                style={{
                  padding: "10px",
                  verticalAlign: "top",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() => handleSort(column.key)}
              >
                <b>{column.label}</b>
                {sortColumns[column.key] === "asc" ? (
                  <span className="text-black"> ▲</span>
                ) : sortColumns[column.key] === "desc" ? (
                  <span className="text-black"> ▼</span>
                ) : (
                  ""
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {notificationsWithPagination.length > 0 ? (
            notificationsWithPagination?.map((notification) => (
              <tr style={{ fontSize: "1rem" }} key={notification?.id}>
                <td>
                  <div
                    style={{
                      background: "#FFFFFF",
                      padding: "15px",
                      verticalAlign: "top",
                      textAlign: "center",
                      paddingBottom: "15px",
                      margin: "0px",
                      marginTop: "17px",
                    }}
                  >
                    {notification?.id || "-"}
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      background: "#FFFFFF",
                      padding: "15px",
                      verticalAlign: "top",
                      textAlign: "center",
                      margin: "0px",
                      marginTop: "15px",
                    }}
                  >
                    {dateInDateMonthYear(notification?.timestamp) || "-"}
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      background: "#FFFFFF",
                      padding: "15px",
                      verticalAlign: "top",
                      textAlign: "center",
                      margin: "0px",
                      marginTop: "15px",
                    }}
                  >
                    {statusTranslations[notification?.category] || "-"}
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      background: "#FFFFFF",
                      padding: "15px",
                      verticalAlign: "top",
                      textAlign: "center",
                      margin: "0px",
                      marginTop: "15px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {notification?.description || "-"}
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4}>
                <div
                  style={{
                    background: "#FFFFFF",
                    padding: "15px",
                    verticalAlign: "top",
                    textAlign: "center",
                    margin: "0px",
                    marginTop: "15px",
                  }}
                >
                  Δεν υπάρχουν ειδοποιήσεις με τα συγκεκριμένα κριτήρια
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Stack
        className="pt-2 mb-4"
        direction="row"
        justifyContent="center"
        alignItems="start"
      >
        <Pagination
          count={Math.ceil(sortedNotifications.length / pageSize)}
          page={page}
          onChange={handlePageChange}
        />
      </Stack>
    </div>
  );
};

export default observer(NotificationsTable);
