import { CreateHalfDoughnutCenter } from "../../Utils/GraphHelper";

export const OptionsDoughnut = () => {
  return {
    maintainAspectRatio: false,
    events: [],
    layout: {
      padding: {
        left: 25,
        right: 25,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
      },
      datalabels: {
        display: true,
        color: "#6a6b6d",
        align: "end",
        offset: 28,
        font: {
          size: 14,
          weight: "600",
        },
        formatter: function (value, context) {
          if (context.dataIndex === 1) {
            return "";
          }
          if (value === 0) {
            return "";
          }
          return value + "%";
        },
      },
    },
  };
};

export const DataDoughnut = (data) => {
  let percentage = Math.round(
    (data.filter((d) => d.status).length * 100) / data.length
  );

  return {
    labels: ["ΕΝΕΡΓΑ AP"],
    datasets: [
      {
        label: "",
        data: [percentage, 100 - percentage],
        borderColor: ["#ff7800", "white"],
        backgroundColor: ["#ff7800", "white"],
        cutout: 50,
      },
    ],
  };
};

export const centerImageDoughnut = () => {
  return {
    id: "centerImageDoughnut",
    afterDatasetDraw: (chart, args, pluginOptions) => {
      const { ctx } = chart;

      const x = chart.getDatasetMeta(0).data[0].x;
      const y = chart.getDatasetMeta(0).data[0].y;
      CreateHalfDoughnutCenter(ctx, x, y);
    },
  };
};

export default {
  OptionsDoughnut,
  DataDoughnut,
  centerImageDoughnut,
};
