import { useTranslation } from "react-i18next";
import Tabs from "../../../Components/Theme/Tabs/Tabs";
import BasicDetails from "./BasicDetails";
import Breadcrumb from "../../../Components/Breadcrumb/Breadcrumb";
import ChangePassword from "./ChangePassword";
import Topbar from "../../../Components/Topbar/Topbar";

const Profile = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      label: t("profile.BasicDetails"),
      Component: (
        <>
          <Breadcrumb pathList={["Αρχική", "Προφίλ", "Στοιχεία"]} />
          <BasicDetails />
        </>
      ),
    },
    {
      label: t("forms.changePassword"),
      Component: (
        <>
          <Breadcrumb pathList={["Αρχική", "Προφίλ", "Αλλαγή Κωδικού"]} />
          <ChangePassword />
        </>
      ),
    },
  ];

  return (
    <div>
      <Topbar icon="profile-main" title="Ρυθμίσεις χρήστη" />
      <Tabs tabs={tabs} />
    </div>
  );
};

export default Profile;
