import Topbar from "../../../../Components/Topbar/Topbar";
import MainContent from "../../../../MainAppComponents/MainContent";
import EnvironmentTabMenu from "../EnvironmentTabMenu/EnvironmentTabMenu";

const EnvrionmentLayout = ({ children, showTabs = true, ...props }) => (
  <>
    <Topbar icon="environment" title="Περιβάλλον - Ποιότητα αέρα" />
    {showTabs && <EnvironmentTabMenu />}
    <MainContent>{children}</MainContent>
  </>
);

export default EnvrionmentLayout;
