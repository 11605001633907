//import * as FileSaver from 'file-saver';
//import XLSX from "sheetjs-style";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import SubmitButton from "../Theme/Extended/SubmitButton";
import AnimateButton from "../Theme/Extended/AnimateButton";
import { themeColors } from "../../Config/Themes/theme";
import { useTheme } from "@mui/material/styles";
import { formatDateInDateMonthYearHour } from "../../Utils/formatDate/formatDate";

const fileExtension = ".xlsx";
const currentDay = formatDateInDateMonthYearHour(new Date());

const ExportToExcel = ({ tableData, fileName }) => {
  const theme = useTheme();

  const colors = themeColors(theme.palette.mode);

  const downloadExcelFile = async () => {
    const worksheet = XLSX.utils.json_to_sheet(Object.values(tableData));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const fileBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "buffer",
    });
    saveAs(
      new Blob([fileBuffer], { type: "application/octet-stream" }),
      fileName + "_" + currentDay + fileExtension
    );
  };

  return (
    <div className="flex items-center justify-end mr-2">
      <AnimateButton>
        <SubmitButton
          sx={{ backgroundColor: colors.greenAccent.main }}
          className="capitalize text-base"
          buttonTitle="export"
          style={{
            background: colors.greenAccent.main,
          }}
          disableElevation
          type="submit"
          onClick={downloadExcelFile}
        />
      </AnimateButton>
    </div>
  );
};

export default ExportToExcel;
