import ConcentrationSvg from "../../Components/Icons/ConcentrationSvg";
import TimeSvg from "../../Components/Icons/TimeSvg";
import VisitsSvg from "../../Components/Icons/VisitsSvg";
import VisitRateSvg from "../../Components/Icons/VisitRateSvg";
import ResidenceTimeSvg from "../../Components/Icons/ResidenceTimeSvg";

export const Colors = [
  "#009299",
  "#003f72",
  "#7ed957",
  "#c2185b",
  "#5271ff",
  "#36A2EB",
  "#FE6385",
  "#4BC0C0",
  "#FE9E40",
  "#9966FF",
  "#FECC56",
];

export const Categories = [
  {
    title: "ΠΛΗΘΥΣΜΙΑΚΗ ΣΥΓΚΕΝΤΡΩΣΗ",
    api: "people-concentration-report",
    categoryName: "PeopleConcentration",
    diagramTitle: "ΠΛΗΘΥΣΜΙΑΚΗ ΣΥΓΚΕΝΤΡΩΣΗ",
    titleIcon: (size, color) => <ConcentrationSvg color={color} size={size} />,
    typeIcon: (size, color) => <TimeSvg color={color} size={size} />,
  },
  {
    title: "AΘΡΟΙΣΤΙΚΗ ΕΠΙΣΚΕΨΙΜΟΤΗΤΑ",
    api: "concetration-history-report",
    categoryName: "ConcentrationHistory",
    diagramTitle: "AΘΡΟΙΣΤΙΚΗ ΕΠΙΣΚΕΨΙΜΟΤΗΤΑ",
    titleIcon: (size, color) => <VisitsSvg color={color} size={size} />,
    typeIcon: (size, color) => <TimeSvg color={color} size={size} />,
  },
  {
    title: "MAX ΣΤΙΓΜΙΑΙΑ ΕΠΙΣΚΕΨΙΜΟΤΗΤΑ",
    api: "highest-visitors-report",
    categoryName: "HighestVisitors",
    diagramTitle: "MAX ΣΤΤΙΓΜΙΑΙΑ ΕΠΙΣΚΕΨΙΜΟΤΗΤΑ",
    titleIcon: (size, color) => <VisitsSvg color={color} size={size} />,
    typeIcon: (size, color) => <TimeSvg color={color} size={size} />,
  },
  {
    title: "MAX ΤΙΜΗ ΜΟΝΑΔΙΚΩΝ ΕΠΙΣΚΕΠΤΩΝ",
    api: "highest-unique-visitors-report",
    categoryName: "HighestUniqueVisitors",
    diagramTitle: "MAX ΤΙΜΗ ΜΟΝΑΔΙΚΩΝ ΕΠΙΣΚΕΠΤΩΝ",
    titleIcon: (size, color) => <VisitsSvg color={color} size={size} />,
    typeIcon: (size, color) => <TimeSvg color={color} size={size} />,
  },

  {
    title: "ΕΠΑΝΑΕΠΙΣΚΕΨΙΜΟΤΗΤΑ (%)",
    api: "visiting-rate-report",
    categoryName: "VisitingRate",
    diagramTitle: "ΕΠΑΝΑΕΠΙΣΚΕΨΙΜΟΤΗΤΑ (%)",
    titleIcon: (size, color) => <VisitRateSvg color={color} size={size} />,
    typeIcon: (size, color) => <TimeSvg color={color} size={size} />,
  },
  {
    title: "ΧΡΟΝΟΣ ΠΑΡΑΜΟΝΗΣ",
    api: "duration-of-stay",
    categoryName: "DurationOfStayAverage",
    diagramTitle: "ΧΡΟΝΟΣ ΠΑΡΑΜΟΝΗΣ (min)",
    titleIcon: (size, color) => <ResidenceTimeSvg color={color} size={size} />,
    typeIcon: (size, color) => <TimeSvg color={color} size={size} />,
  },
];

export const LineGraphs = ["peopleconcentration", "cumulativeconcentration"];
export const BarGraphs = [
  "highestvisitors",
  "highestuniquevisitors",
  "visitingrate",
  "durationofstay",
];

export const DateType = {
  SingleDay: "single_day",
  MultipleDays: "multiple_days",
};

export const DateTypes = [
  {
    title: "ΗΜΕΡΗΣΙΑ (24H)",
    type: DateType.SingleDay,
    sectionTitle: "Επιλογή ημέρας",
    locationSectionText: "Δυνατότητα πολλαπλής επιλογής περιοχής",
  },
  {
    title: "ΧΡΟΝΙΚΗ ΔΙΑΡΚΕΙΑ",
    type: DateType.MultipleDays,
    sectionTitle: "Επιλογή χρονικού διαστήματος",
    locationSectionText: "Επιλογή μίας περιοχής",
  },
];

export const DurationChartTypes = [
  {
    title: "ΜΕΓΙΣΤΟΣ ΧΡΟΝΟΣ ΠΑΡΑΜΟΝΗΣ",
    type: "max",
  },
  {
    title: "ΜΕΣΟΣ ΧΡΟΝΟΣ ΠΑΡΑΜΟΝΗΣ",
    type: "average",
  },
];

export const BuildChartData = (reportData) => {
  return {
    labels: reportData.chartLabels,
    datasets: reportData.chartData.map((cd, ix) => {
      return {
        label: cd.label,
        data: cd.chartValues,
        borderColor: Colors[ix],
        backgroundColor: Colors[ix],
      };
    }),
  };
};
