import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  OverlayView,
  MarkerClusterer,
} from "@react-google-maps/api";
import React, { cloneElement } from "react";
import { useState } from "react";
import useMapLoader from "../../../../Hooks/useMapLoader";
import { useCallback } from "react";
import { useEffect } from "react";

const mapCenter = {
  lat: 40.73061,
  lng: -73.935242,
};

const CUSTOM_STYLES = [
  {
    featureType: "poi",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "transit",
    stylers: [{ visibility: "off" }],
  },
];

const singleMarkerZoom = 16;

const LightsMap = ({
  markers = [],
  center,
  className,
  containerStyle = {
    width: "100%",
    height: "100%",
  },
  infoWindowRenderer,
  initialZoom,
  selectedMarker,
  setSelectedMarker,
}) => {
  const { isLoaded, loadError } = useMapLoader();
  const [map, setMap] = useState();
  const [zoom] = useState(initialZoom);

  const onMapClick = useCallback(() => {
    setSelectedMarker(null);
  }, [setSelectedMarker]);

  const onLoadMap = useCallback(
    (mapInstance) => {
      mapInstance.setOptions({
        draggableCursor: "pointer",
        draggingCursor: "grabbing",
        disableDoubleClickZoom: true,
        streetViewControl: false,
        styles: CUSTOM_STYLES,
        mapTypeControlOptions: {
          mapTypeIds: [],
        },
      });

      setMap(mapInstance);

      let bounds = new window.google.maps.LatLngBounds();
      if (markers && markers?.length > 0) {
        markers?.forEach((marker) => {
          bounds.extend({
            lat: +marker?.lat,
            lng: +marker?.lon,
          });
        });
        mapInstance.fitBounds(bounds);
        if (markers.length === 1) {
          mapInstance.setZoom(singleMarkerZoom);
        }
      }
    },
    [markers]
  );

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      markers.map((marker) => {
        bounds.extend({
          lat: +marker?.lat,
          lng: +marker?.lon,
        });
      });
      markers.length > 0 ? map.fitBounds(bounds) : map.setCenter(mapCenter);
    }
  }, [map, markers]);

  if (loadError) {
    return (
      <span>
        Παρουσιάστηκε σφάλμα κατά τη φόρτωση του χάρτη. Παρακαλώ δοκιμάστε ξανά.
      </span>
    );
  }

  if (!isLoaded) {
    return null;
  }

  return (
    <section className={className}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center || mapCenter}
        zoom={zoom || 10}
        mapTypeId="terrain"
        onLoad={onLoadMap}
        onUnmount={onUnmount}
        onClick={onMapClick}
      >
        {/* <MarkerClusterer> */}
        {markers?.map((item) =>
          item.icon ? (
            <MarkerF
              key={item.id}
              position={{ lat: item.lat, lng: item.lon }}
              onClick={() => {
                setSelectedMarker(item);
              }}
              icon={item.icon}
              // clusterer={clusterer}
            />
          ) : (
            <OverlayView
              key={item.id}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              position={{ lat: item.lat, lng: item.lon }}
            >
              {cloneElement(item?.markerRenderer, {
                onClick: (event) => {
                  event.stopPropagation();
                  item?.onMarkerClick();
                },
              })}
            </OverlayView>
          )
        )}
        {/* </MarkerClusterer> */}
        {selectedMarker && (
          <InfoWindowF
            position={{
              lat: +selectedMarker?.lat,
              lng: +selectedMarker?.lon,
            }}
            onCloseClick={() => setSelectedMarker(null)}
          >
            {infoWindowRenderer(selectedMarker)}
          </InfoWindowF>
        )}
      </GoogleMap>
    </section>
  );
};

export default LightsMap;
