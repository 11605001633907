import { Typography } from "@mui/material";
import { themeColors } from "../../Config/Themes/theme";
import { useTheme } from "@mui/material/styles";

const Breadcrumb = ({ pathList }) => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);

  return (
    // <div className="flex flex-row items-center mb-2">
    //     <Typography color={colors.breadcrumbs.main} className="text-sm">
    //       Αρχική | Προφίλ | Στοιχεία
    //     </Typography>
    //   </div>

    <div className="flex flex-row items-center my-4">
      {pathList.map((path, index) => (
        <>
          <Typography color={colors.breadcrumbs.main} className="text-sm">
            {path}
          </Typography>
          {index !== pathList.length - 1 && (
            <Typography
              className="text-sm mx-1"
              color={colors.breadcrumbs.main}
            >
              |
            </Typography>
          )}
        </>
      ))}
    </div>
  );
};

export default Breadcrumb;
