import * as React from "react";

import { useTheme } from "@mui/material";

import { colors } from "../../Defaults/Global";

import { Options, Data, centerImageDoughnut } from "./GraphOptions";
import DurationCard from "./DurationCard";

import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Doughnut } from "react-chartjs-2";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const DurationOfStayKpi = (props) => {
  const theme = useTheme();
  const options2 = Options();
  const donutData = Data(props.data, colors);

  return (
    <div className="flex w-full flex-col">
      <div className="grid grid-cols-12 gap-2">
        <DurationCard
          title={"ΜΕΣΟΣ ΧΡΟΝΟΣ ΠΑΡΑΜΟΝΗΣ"}
          location={"ΟΛΟΙ ΟΙ ΧΩΡΟΙ"}
          duration={props.data?.averageDurationTime}
        />
        <DurationCard
          title={"ΠΕΡΙΟΧΗ ΜΕ ΤΟ ΜΕΓΑΛΥΤΕΡΟ ΜΕΣΟ ΧΡΟΝΟ ΠΑΡΑΜΟΝΗΣ"}
          location={props.data?.locationName}
          duration={props.data?.averageDurationHighestTime}
        />
      </div>
      <div className="relative w-full h-[200px] pb-[32px] pt-[6px] rounded-md bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] mt-[10px]">
        <div className="flex gap-x-3 items-center px-[15px]">
          <span
            className="text-[12px] font-semibold uppercase"
            style={{ color: theme.palette.neutral.dark4 }}
          >
            ΠΟΣΟΣΤΟ ΠΑΡΑΜΟΝΗΣ (min)
          </span>
        </div>
        <div className="flex justify-center w-full h-[200px] mt-[-20px]">
          <Doughnut
            data={donutData}
            options={options2}
            plugins={[centerImageDoughnut]}
          />
        </div>
      </div>
    </div>
  );
};

export default DurationOfStayKpi;
