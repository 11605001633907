// HeatMapComponent.js
import React, { FC, useCallback, useEffect, useState } from "react";
import {
  GoogleMap,
  HeatmapLayer,
  Marker,
  InfoWindowF,
} from "@react-google-maps/api";
import useMapLoader from "../../../../Hooks/useMapLoader";

// const mapCenter = {
//   lat: 40.73061,
//   lng: -73.935242,
// };

const CUSTOM_STYLES = [
  {
    featureType: "poi",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "transit",
    stylers: [{ visibility: "off" }],
  },
];

const singleMarkerZoom = 16;

interface HeatmapDataProps {
  aqi: any;
  sensorData: any;
}

interface ContainerStyleProps {
  width: string;
  height: string;
}

interface HeatMapComponentProps {
  initialRegion: any;
  markers?: any[];
  heatmapData?: HeatmapDataProps;
  center?: any;
  className?: string;
  containerStyle?: ContainerStyleProps;
  infoWindowRenderer?: any;
  initialZoom?: number;
  selectedMarker?: any;
  setSelectedMarker?: any;
}

const HeatMapComponent: FC<HeatMapComponentProps> = ({
  initialRegion,
  markers = [],
  heatmapData,
  center,
  className,
  containerStyle = {
    width: "100%",
    height: "100%",
  },
  infoWindowRenderer,
  initialZoom,
  selectedMarker,
  setSelectedMarker,
}: HeatMapComponentProps) => {
  const { isLoaded, loadError } = useMapLoader();
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const [zoom] = useState(initialZoom);

  const onMapClick = useCallback(() => {
    setSelectedMarker(null);
  }, [setSelectedMarker]);

  const [formatedHeatmapData, setFormatedHeatmapData] = useState<any>([]);

  const onLoadMap = useCallback(
    (mapInstance) => {
      mapInstance.setOptions({
        draggableCursor: "pointer",
        draggingCursor: "grabbing",
        disableDoubleClickZoom: true,
        streetViewControl: false,
        styles: CUSTOM_STYLES,
        mapTypeControlOptions: {
          mapTypeIds: [],
        },
      });

      setMap(mapInstance);

      let bounds = new window.google.maps.LatLngBounds();
      if (markers && markers?.length > 0) {
        markers?.forEach((marker: any) => {
          setFormatedHeatmapData((prevState) => [
            ...prevState,
            new window.google.maps.LatLng(marker?.lat, marker?.lng),
          ]);

          bounds?.extend({
            lat: +marker?.lat || 0,
            lng: +marker?.lng || 0,
          });
        });
        mapInstance.fitBounds(bounds);
        if (markers.length === 1) {
          mapInstance.setZoom(singleMarkerZoom);
        }
      }
    },
    [markers]
  );
  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      markers?.map((marker: any) => {
        bounds?.extend({
          lat: marker?.lat,
          lng: marker?.lng,
        });
      });
      markers.length > 0
        ? map.fitBounds(bounds)
        : map.setCenter(
            new window.google.maps.LatLng(initialRegion.lat, initialRegion.lng)
          );
    }
  }, [map, markers]);

  // Helper function to convert hex color to RGB
  const hexToRgb = (hex) => {
    // Remove the hash character if present
    hex = hex.replace(/^#/, "");

    // Parse the hex value
    const bigint = parseInt(hex, 16);

    // Extract RGB components
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `${r}, ${g}, ${b}`;
  };

  // Helper function to calculate the gradient for the heatmap
  const calculateHeatmapGradient = () => {
    if (!heatmapData || !heatmapData.aqi) {
      return ["transparent"];
    }

    // Extract the color from the aqi property in heatmapData
    const color = heatmapData?.aqi?.color;

    let gradient = ["transparent"];

    // Aqi Color with opacity logic
    gradient.push(`rgba(${hexToRgb(color)}, 0)`);
    gradient.push(`rgba(${hexToRgb(color)}, 0.6)`);
    gradient.push(`rgba(${hexToRgb(color)}, 0.6)`);
    gradient.push(`rgba(${hexToRgb(color)}, 0.7)`);
    gradient.push(`rgba(${hexToRgb(color)}, 0.8)`);
    gradient.push(`rgba(${hexToRgb(color)}, 0.8)`);
    gradient.push(`rgba(${hexToRgb(color)}, 0.7)`);
    gradient.push(`rgba(${hexToRgb(color)}, 0.7)`);
    gradient.push(`rgba(${hexToRgb(color)}, 0.8)`);
    gradient.push(`rgba(${hexToRgb(color)}, 0.9)`);
    gradient.push(`rgba(${hexToRgb(color)}, 0.9)`);
    gradient.push(`rgba(${hexToRgb(color)}, 1)`);
    return gradient;
  };

  if (loadError) {
    return (
      <span>
        Παρουσιάστηκε σφάλμα κατά τη φόρτωση του χάρτη. Παρακαλώ δοκιμάστε ξανά.
      </span>
    );
  }

  if (!isLoaded) {
    return null;
  }

  return (
    <section className={className}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center || initialRegion}
        zoom={zoom || 12}
        mapTypeId="terrain"
        onLoad={onLoadMap}
        onUnmount={onUnmount}
        onClick={onMapClick}
      >
        {markers?.map((item: any) => (
          <Marker
            key={item.id + item?.lat + item?.lng}
            position={{ lat: item?.lat, lng: item?.lng }}
            onClick={() => setSelectedMarker(item)}
            // icon={'Icon logic here'}
          />
        ))}

        {selectedMarker && (
          <InfoWindowF
            position={{
              lat: selectedMarker?.lat,
              lng: selectedMarker?.lng,
            }}
            onCloseClick={() => setSelectedMarker(null)}
          >
            {infoWindowRenderer(selectedMarker)}
          </InfoWindowF>
        )}
        <HeatmapLayer
          data={formatedHeatmapData}
          options={{
            radius: 60,
            dissipating: false,
            opacity: 1,
            gradient: calculateHeatmapGradient(),
          }}
        />
      </GoogleMap>
    </section>
  );
};

export default HeatMapComponent;
