import HistoryActivityKpi from "./HistoryActivityKpi";

import useApiIntervalCall from "../../Hooks/useApiIntervalCall";
import { get_active_users_history } from "../../Services/DataService";

const HistoryActivityKpiDataRefresher = (props) => {
  const data =
    useApiIntervalCall(get_active_users_history, props.interval, props.data) ||
    [];

  let activities = data.map((ap, ix) => {
    return <HistoryActivityKpi data={ap} key={ap.locationName} showHeader />;
  });

  return <>{activities}</>;
};

export default HistoryActivityKpiDataRefresher;
