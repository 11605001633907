import React, { FC } from "react";
import { MEASUREMENTS_GAS } from "../../Config/Constants/measurements";
import { Tooltip, useTheme } from "@mui/material";
import { themeColors } from "../../../../Config/Themes/theme";
import { FormatNumberNoDecimals } from "../../common/FormatNumber/FormatNumberNoDecimals";
import { FormatNumberTwoDecimals } from "../../common/FormatNumber/FormatNumberTwoDecimals";

interface SensorProps {
  cO2: number;
  sO2: number;
  nO2: number;
  o3: number;
  no: number;
  pM1: number;
  pM2_5: number;
  pM10: number;
  hunidity?: number;
  pressure?: number;
  temperature?: number;
}

interface SensorMeasurementsProps {
  data: SensorProps;
}

const SensorMeasurements: FC<SensorMeasurementsProps> = ({ data }) => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);

  const renderTableHeader = () => (
    <tr className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
      {Object.values(MEASUREMENTS_GAS).map((item: any, index) => (
        <th
          key={index}
          className="p-1 text-center border-grey-light border min-w-[51px]"
        >
          {item?.label}
        </th>
      ))}
    </tr>
  );

  const getSensorBgColor = (item) => {
    const color = item?.color;
    return color ? color : colors.greyAccent.dark4;
  };

  const renderDynamicTableBody = () => {
    return (
      <tr className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
        {Object.values(MEASUREMENTS_GAS).map((item: any, index) =>
          item.id === "cO2" ? (
            <Tooltip title="ppm">
              <td
                key={index}
                className="p-1 text-center border-grey-light border hover:bg-gray-400 truncat"
                style={{
                  color: colors.whiteAccent.main,
                  backgroundColor: getSensorBgColor(
                    item?.getStatus(data[item.id])
                  ),
                }}
              >
                {data[item.id]}
              </td>
            </Tooltip>
          ) : (
            <Tooltip title="μg/m3">
              <td
                key={index}
                className="p-1 text-center border-grey-light border hover:bg-gray-400 truncat"
                style={{
                  color: colors.whiteAccent.main,
                  backgroundColor: getSensorBgColor(
                    item?.getStatus(data[item.id])
                  ),
                }}
              >
                {data[item.id]}
              </td>
            </Tooltip>
          )
        )}
      </tr>
    );
  };

  const renderStaticTableBody = () => {
    return (
      <tr className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
        {Object.values(MEASUREMENTS_GAS).map((item: any, index) =>
          item.id === "cO2" ? (
            <Tooltip title="ppm" key={index}>
              <td
                key={index}
                className="p-1 text-center border-grey-light border hover:bg-gray-400 truncat"
                style={{
                  color: colors.whiteAccent.main,
                  backgroundColor: getSensorBgColor(
                    item?.getStatus(data[item.id])
                  ),
                }}
              >
                {FormatNumberNoDecimals(data[item.id])}
              </td>
            </Tooltip>
          ) : (
            <Tooltip title="μg/m3" key={index}>
              <td
                className="p-1 text-center border-grey-light border hover:bg-gray-400 truncat"
                style={{
                  color: colors.whiteAccent.main,
                  backgroundColor: getSensorBgColor(
                    item?.getStatus(data[item.id])
                  ),
                }}
              >
                {FormatNumberTwoDecimals(data[item.id])}
              </td>
            </Tooltip>
          )
        )}
      </tr>
    );
  };

  return (
    <div>
      <div className="max-w-max flex gap-x-1">
        <div
          className="max-w-max px-2 h-[20px"
          style={{
            backgroundColor: colors.greyAccent.dark4,
            color: colors.whiteAccent.main,
          }}
        >
          <span className="text-xs uppercase">
            ΜΕΤΡΗΣΕΙΣ ΑΕΡΙΩΝ & ΣΩΜΑΤΙΔΙΩΝ
          </span>
        </div>
      </div>

      <div className="flex items-center justify-center w-full">
        <table className="w-full flex flex-row flex-no wrap md:inline-table sm:inline-table sm:bg-white overflow-hidden sm:shadow-lg">
          <thead style={{ color: colors.blackAccent.main }}>
            {renderTableHeader()}
          </thead>
          <tbody className="flex-1 sm:flex-none">
            {/* {renderDynamicTableBody()} */}
            {renderStaticTableBody()}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SensorMeasurements;
