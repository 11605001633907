import { observer } from "mobx-react";
import SubmitButton from "../../../Components/Buttons/SubmitButton";
import { Box, Modal, Typography } from "@mui/material";
import { useState } from "react";
import MainCard from "../../../Components/Theme/Cards/MainCard";
import CancelButton from "../../../Components/Buttons/CancelButton";
import { error, success } from "../../../Utils/Toast/Toast";
import { useAuthStore } from "../../../Stores/AuthStore";
import { baseUrl } from "../../../Config/constant";

const AnnouncementDeleteModal = ({
  open,
  handleClose,
  refreshList,
  announcementId,
}) => {
  const [actionLoading, setActionLoading] = useState(false);
  const authStore = useAuthStore();
  const apiBaseUrl = baseUrl;

  const deleteAnnouncement = (id, requestBody) => {
    return fetch(`${apiBaseUrl}/Announcement?id=${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authStore.token}`,
      },
      body: JSON.stringify(requestBody),
    });
  };

  const submitDelete = async () => {
    if (actionLoading) {
      return;
    }
    setActionLoading(true);
    try {
      const requestBody = {
        text: "",
        status: null,
      };
      await deleteAnnouncement(announcementId, requestBody);
      success("Διαγράψατε την ανακοίνωση επιτυχώς");
      handleClose(false);
      await refreshList();
    } catch (err) {
      error("Παρουσιάστηκε σφάλμα κατά την διαγραφή της ανακοίνωσης!");
    } finally {
      setActionLoading(false);
    }
  };

  return (
    <Modal
      className="flex items-center justify-center"
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="announcement-modal"
    >
      <MainCard className=" p-4 max-w-[35vw] min-w-[35vw] min-h-[10vw]">
        <div className="modal-paper">
          <Typography className="mt-4 text-[#565A87]" variant="h3">
            Διαγραφή ανακοίνωσης
          </Typography>
          <Box className="w-full mt-6 border-b-2 border-[#B9B9B9]" />
          <Box className="mt-6">
            <Typography fontSize={16}>
              Είστε βέβαιοι ότι θέλετε να διαγράψετε τη συγκεκριμένη ανακοίνωση
              ?
            </Typography>
            <Box className="flex justify-end mt-8">
              <CancelButton
                className="mr-6"
                type="button"
                onClick={() => handleClose(false)}
                size="large"
                label="Οχι"
              />
              <SubmitButton
                loading={actionLoading}
                type="button"
                onClick={submitDelete}
                size="large"
                label="Ναι"
              />
            </Box>
          </Box>
        </div>
      </MainCard>
    </Modal>
  );
};

export default observer(AnnouncementDeleteModal);
