import TabMenu from "../TabMenu/TabMenu";
import { useLocation, useNavigate } from "react-router-dom";

const TabNavigation = ({ baseRoute, routes }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const getDestination = (route) => {
    return `/${baseRoute}${route}`;
  };

  const onChange = (newValue) => {
    navigate(getDestination(routes[newValue]?.route));
  };

  const routeStrings = routes?.map((item) => `/${baseRoute}${item?.route}`);
  const selected = routeStrings.indexOf(`${location?.pathname}`);

  return (
    <TabMenu
      items={routes}
      onChange={onChange}
      selected={selected}
      keyProp="route"
    />
  );
};

export default TabNavigation;
