import { useTheme } from "@mui/material/styles";
import { themeColors } from "../../Config/Themes/theme";

const ParagraphText = ({ children, className }) => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);

  return (
    <p
      className={`text-[16px] ${className}`}
      style={{
        color: colors.paragraphText.main,
      }}
    >
      {children}
    </p>
  );
};

export default ParagraphText;
