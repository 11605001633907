import { Button } from "@mui/material";
import AnimateButton from "../Theme/Extended/AnimateButton";
import { useTheme } from "@mui/material/styles";
import { themeColors } from "../../Config/Themes/theme";
import { useMemo } from "react";

const ActionButton = ({
  children,
  onClick,
  label,
  size,
  type = "button",
  disabled = false,
  loading,
  selected = true,
}) => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const styles = useMemo(
    () =>
      selected
        ? {
            backgroundColor: colors.buttonsActionSelected.main,
            color: colors.buttonsActionSelected.text,
            "&:hover": {
              opacity: 0.8,
              backgroundColor: colors.buttonsActionSelected.main,
              color: colors.buttonsActionSelected.text,
            },
            "&:disabled": {
              backgroundColor: colors.buttonsActionSelected.disabled,
              color: colors.buttonsActionSelected.text,
            },
          }
        : {
            backgroundColor: colors.buttonsAction.main,
            borderColor: colors.buttonsAction.border,
          },
    [
      selected,
      colors.buttonsActionSelected.main,
      colors.buttonsActionSelected.text,
      colors.buttonsActionSelected.disabled,
      colors.buttonsAction.main,
      colors.buttonsAction.border,
    ]
  );

  return (
    <AnimateButton>
      <Button
        loading={loading}
        size={size}
        className="capitalize text-sm"
        type={type}
        variant="outlined"
        disabled={disabled}
        onClick={onClick}
        sx={styles}
      >
        {children}
      </Button>
    </AnimateButton>
  );
};

export default ActionButton;
