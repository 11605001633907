import { Route, Routes } from "react-router-dom";
import { observer } from "mobx-react";
import Notifications from "../Pages/Notifications/Notifications";
import LightsPage from "../MainAppComponents/LightsPage/LightsPage";
import SoftwareManagement from "../Pages/SoftwareManagement/SoftwareManagement";
import Analysis from "../Pages/Analysis/Analysis";
import CreateReport from "../Pages/CreateReport/CreateReport";

const wrap = (element, pageProps) => (
  <LightsPage {...pageProps}>{element}</LightsPage>
);

const LightsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={wrap(<Analysis />)} />
      <Route path="/reports" element={wrap(<CreateReport />)} />
      <Route
        path="/software-management"
        element={wrap(<SoftwareManagement />)}
      />
      <Route path="/notifications" element={wrap(<Notifications />)} />
    </Routes>
  );
};
export default observer(LightsRoutes);
