import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

const darkThemeColors = {};

const lightThemeColors = {
  primary: {
    main: "#384ab4",
    medium: "#384ab4",
  },
  secondary: {
    main: "#505381",
    medium: "#505381",
  },
  backgroundPrimary: {
    main: "#f7f7f7",
    darker: "#eeeeee",
  },
  backgroundBlueAccent: {
    main: "#ebeef5",
  },
  mainTitle: {
    main: "#5565CE",
    dark: "#545a86",
  },
  paragraphText: {
    main: "#5c5c5c",
  },
  shadow: {
    main: "#eeeeee",
  },
  buttonsActive: {
    main: "#5565CE",
    text: "#ffffff",
    disabled: "#dddddd",
  },
  buttonsCancel: {
    main: "#f7f7f7",
    hover: "#dedede",
    text: "#727c80",
    border: "#eef1f3",
  },
  buttonsDelete: {
    main: "#fd3334",
    hover: "#ca292a",
    border: "#fd3334",
    disabled: "#ca292a",
  },
  buttonsAction: {
    main: "#ffffff",
    border: "#bebebe",
  },
  buttonsActionSelected: {
    main: "#3949ab",
    hover: "#F2F2F2",
    hoverText: "#3949ab",
    text: "#ffffff",
    border: "#ffffff",
    disabled: "#dddddd",
  },
  breadcrumbs: {
    main: "#dad9df",
  },
  greenAccent: {
    main: "#60C15C", // Used in buttons and labels
    medium: "#25A764", // Used in the Commercial Bin Icon Background Color
  },
  yellowAccent: {
    main: "#EFDB22", // Used in Map Page
  },
  orangeAccent: {
    main: "#FF7D00", // Used in Map Page and in validation errors
  },
  redAccent: {
    main: "#D00404", // Used in Map Page
  },
  brownAccent: {
    main: "#784949", // Used in Map Page
  },
  cyanAccent: {
    main: "#0094E7", // Used in Map Page
    light: "#2c9dff", //Used in Wifi Page
    dark: "#0089FE", // Used in Wifi Page
  },
  purpleAccent: {
    main: "#7d8ef6",
  },
  greyAccent: {
    light: "#F9F9F9", // Used in bg color of pages
    light2: "#D1D5DA", // Used in the top bar bottom border
    light3: "#D1D5DB", // Used in the table borders
    light4: "#d4d4d4", // Icon colors in wifi screen
    medium: "#7B7C7C", // Used for subtitle in the Forgot Password Page
    medium2: "#6B7280", // Used in icons in top-bar
    dark: "#666666", // Used for the labels in forms
    dark2: "#333333", // Used for the main titles in forms
    dark3: "#4F4F4F", // Used in round icon border
    dark4: "#6a6b6d", // Used in section borders
  },
  whiteAccent: {
    main: "#FFFFFF",
  },
  blackAccent: {
    main: "#000000",
  },
  bg: {
    whiteTransp: {
      "01": "rgba(255, 255, 255, 0.1)",
      "02": "rgba(255, 255, 255, 0.2)",
      "03": "rgba(255, 255, 255, 0.3)",
      "04": "rgba(255, 255, 255, 0.4)",
      "05": "rgba(255, 255, 255, 0.5)",
      "06": "rgba(255, 255, 255, 0.6)",
      "07": "rgba(255, 255, 255, 0.7)",
      "08": "rgba(255, 255, 255, 0.8)",
      "09": "rgba(255, 255, 255, 0.9)",
    },
  },
};

const darkThemeSettings = (colors) => {
  return {
    // palette values for dark mode
    primary: {
      main: colors.primary.main,
    },
    secondary: {
      main: colors.secondary.main,
    },
    neutral: {
      dark: colors.greyAccent.dark,
      main: colors.greyAccent.medium,
      light: colors.greyAccent.light,
    },
    background: {
      default: colors.backgroundPrimary.main,
      darker: colors.backgroundPrimary.darker,
    },
  };
};

const lightThemeSettings = (colors) => {
  return {
    // palette values for light mode
    primary: {
      light: colors.cyanAccent.dark,
      main: colors.primary.main,
    },
    secondary: {
      main: colors.secondary.main,
    },
    neutral: {
      dark: colors.greyAccent.dark,
      main: colors.greyAccent.medium,
      light: colors.greyAccent.light,
      light4: colors.greyAccent.light4,
      dark4: colors.greyAccent.dark4,
    },
    background: {
      default: colors.backgroundPrimary.main,
      darker: colors.backgroundPrimary.darker,
      dashboardSection: colors.purpleAccent.main,
      cardTitleIcon: colors.cyanAccent.light,
      cardBackground: colors.cyanAccent.dark,
    },
    backgroundSecondary: {
      default: colors.backgroundBlueAccent.main,
    },
  };
};

// color design themeColors export
export const themeColors = (mode) => ({
  ...(mode === "dark" ? darkThemeColors : lightThemeColors),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = themeColors(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? darkThemeSettings(colors)
        : lightThemeSettings(colors)),
    },
    typography: {
      fontFamily: [
        '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
      ].join(","),
      fontSize: 12,
      h1: {
        fontFamily: [
          '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        ].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: [
          '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        ].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: [
          '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        ].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: [
          '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        ].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: [
          '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        ].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: [
          '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        ].join(","),
        fontSize: 14,
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
