import { useMediaQuery, useTheme } from "@mui/material";

export default function useBreakpoints() {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const sm = useMediaQuery(theme.breakpoints.only("sm"));
  const md = useMediaQuery(theme.breakpoints.only("md"));
  const lg = useMediaQuery(theme.breakpoints.only("lg"));
  const xl = useMediaQuery(theme.breakpoints.only("xl"));
  const xs_up = useMediaQuery(theme.breakpoints.up("xs"));
  const sm_up = useMediaQuery(theme.breakpoints.up("sm"));
  const md_up = useMediaQuery(theme.breakpoints.up("md"));
  const lg_up = useMediaQuery(theme.breakpoints.up("lg"));
  const xl_up = useMediaQuery(theme.breakpoints.up("xl"));

  return {
    xs,
    sm,
    md,
    lg,
    xl,
    xs_up,
    sm_up,
    md_up,
    lg_up,
    xl_up,
  };
}
