import ActivityKpi from "./ActivityKpi";

import useApiIntervalCall from "../../Hooks/useApiIntervalCall";
import { get_active_users } from "../../Services/DataService";

const ActivityKpiDataRefresher = (props) => {
  const data = useApiIntervalCall(get_active_users, props.interval, props.data);

  let activities = data?.map((ap, ix) => {
    return <ActivityKpi data={ap} key={ap.locationName} />;
  });

  return <>{activities}</>;
};

export default ActivityKpiDataRefresher;
