import * as React from "react";

import { useTheme } from "@mui/material";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { Options, Data, personIconNextToBar } from "./GraphOptions";
import { colors } from "../../Defaults/Global";

import ClockSvg from "../Icons/ClockSvg";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartDataLabels,
  Title,
  Legend
);

const HighestUniqueVisitorCountKpi = (props) => {
  const data = Data(props.data?.visitors || [], colors) || {};
  const maxBarValue = Math.max(...data?.datasets[0]?.data) + 10;
  const theme = useTheme();
  const options = Options(maxBarValue);

  return (
    <div className="relative w-full h-[230px] pb-[10px] pt-[6px] rounded-md bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]">
      <div className="flex gap-x-3 items-center px-[15px]">
        <ClockSvg color={theme.palette.neutral.dark4} size={"22"} />
        <span
          className="text-[13px] font-semibold"
          style={{ color: theme.palette.neutral.dark4 }}
        >
          {props.data?.time} / ΤΩΡΑ
        </span>
      </div>
      {/* <div className="flex gap-4 w-full h-[170px] pt-[30px] lg:pt-[10px] xl:max-h-[140px] justify-center"> */}
      <div className="overflow-x-scroll h-full py-5 mx-4">
        <div className="w-[650px] h-full">
          <Bar data={data} options={options} plugins={[personIconNextToBar]} />
        </div>
      </div>
    </div>
  );
};

export default HighestUniqueVisitorCountKpi;
