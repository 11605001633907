import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTheme } from "@mui/material/styles";
import { themeColors } from "../../../../Config/Themes/theme";
import {
  LocalizationProvider,
  CalendarIcon,
  DateCalendar,
  StaticDatePicker,
  PickersDay,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import el from "date-fns/locale/el";
import { FormLabel, IconButton, Popover, Chip, Badge } from "@mui/material";
import dayjs from "dayjs";
import clsx from "clsx";

const MultiDayPicker = ({
  className,
  label,
  disabled = false,
  size = "16px",
  value,
  onChange,
  error,
  helperText,
  placeholder,
  inputFormat = "dd/MM/yyyy",
  minDate,
  maxDate,
}) => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const [selectedDates, setSelectedDates] = useState([]);
  const classes = clsx("flex flex-col", className);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    onChange(selectedDates);
  }, []);

  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleDateSelected = (date) => {
    if (
      selectedDates.length === 10 ||
      selectedDates.map((d) => d.getTime()).includes(date.getTime())
    ) {
      handleClosePopover();
      return;
    }
    let sortedDates = [...selectedDates, date].sort(
      (a, b) => a.getTime() - b.getTime()
    );
    setSelectedDates(sortedDates);
    onChange(sortedDates);
    handleClosePopover();
  };

  const handleDateDelete = (date) => {
    let dates = selectedDates.filter((sd) => sd.getTime() !== date.getTime());
    setSelectedDates([...dates]);
    onChange(dates);
  };

  const dateChanged = (date) => {
    let dateExist = selectedDates.filter(
      (sd) => sd.getTime() === date.getTime()
    );
    if (dateExist.length !== 0 || selectedDates.length === 10) {
      return;
    }
    let sortedDates = [...selectedDates, date].sort(
      (a, b) => a.getTime() - b.getTime()
    );
    setSelectedDates(sortedDates);
    onChange(sortedDates);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const d = [...selectedDates];

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={el}>
      <div
        className={classes}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: "6px",
        }}
      >
        <div className="flex flex-row gap-1 items-center">
          <FormLabel
            sx={{
              color: colors.greyAccent.dark,
              fontSize: size,
              textTransform: "uppercase",
            }}
          >
            <span lang="el">ΗΜΕΡΟΜΗΝΙΕΣ</span>
          </FormLabel>
          <IconButton onClick={handleIconClick}>
            <CalendarIcon style={{ color: colors.primary.main }} />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              allowSameDateSelection={true}
              onChange={(date) => dateChanged(date)}
              maxDate={new Date()}
              slots={{
                day: (props) => {
                  const selected = selectedDates
                    .map((d) => d.getTime())
                    .includes(props.day.getTime());
                  return <PickersDay {...props} selected={selected} />;
                },
              }}
            />
          </Popover>
        </div>
        <div className="flex flex-row gap-1 flex-wrap">
          {selectedDates.map((sd, ix) => (
            <Chip
              key={ix}
              label={dayjs(sd).format("YYYY-MM-DD")}
              onDelete={() => handleDateDelete(sd)}
            />
          ))}
        </div>
        {error && <p className="text-[#d32f2f]">{helperText}</p>}
      </div>
    </LocalizationProvider>
  );
};

//<DateCalendar onChange={(value) => handleDateSelected(value)} maxDate={new Date()}/>
export default MultiDayPicker;
