import * as React from "react";

const ClockSvg = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? "27.984"}
      height={props.size ?? "28.019"}
      viewBox="0 0 27.984 28.019"
    >
      <path
        id="clock"
        d="M302.982,795a.667.667,0,0,1,.02-1.334,12.675,12.675,0,0,0,0-25.35h-.02a.667.667,0,0,1,.02-1.335A14.009,14.009,0,0,1,303,795Zm-3.6-.476a.666.666,0,1,1,.173.023A.667.667,0,0,1,299.381,794.527Zm-3.374-1.4a.666.666,0,1,1,.332.089A.667.667,0,0,1,296.007,793.128Zm-4.331-12.133A11.326,11.326,0,1,1,303,792.334,11.342,11.342,0,0,1,291.677,780.995Zm1.33,0A9.994,9.994,0,1,0,303,770.989,9.99,9.99,0,0,0,293.007,780.995Zm.1,9.906a.666.666,0,1,1,.471.195A.669.669,0,0,1,293.108,790.9Zm-2.223-2.9a.665.665,0,1,1,.577.333A.668.668,0,0,1,290.885,788Zm15.642-2.531-4-4a.67.67,0,0,1-.2-.471v-8.007a.666.666,0,0,1,.656-.674h.018a.667.667,0,0,1,.659.674v7.729l3.8,3.809,0,0a.667.667,0,1,1-.947.94Zm-17.041-.849a.665.665,0,1,1,.643.495A.668.668,0,0,1,289.487,784.62Zm-.476-3.626a.666.666,0,1,1,.666.667A.666.666,0,0,1,289.011,780.994Zm.948-2.809a.666.666,0,1,1,.173.023A.668.668,0,0,1,289.958,778.185Zm1.171-3.285a.665.665,0,1,1,.332.09A.668.668,0,0,1,291.129,774.9Zm1.979-2.869a.667.667,0,1,1,.471.2A.668.668,0,0,1,293.108,772.031Zm2.655-2.259a.665.665,0,1,1,.577.334A.666.666,0,0,1,295.763,769.772Zm3.147-1.494a.665.665,0,1,1,.643.495A.667.667,0,0,1,298.911,768.278Z"
        transform="translate(-289.011 -766.984)"
        fill={props.color ?? "#FFF"}
      />
    </svg>
  );
};

export default ClockSvg;
