import { action, computed, makeObservable, observable } from "mobx";
import { createContext, useContext } from "react";
import AuthSetAgent from "../Api/AuthSetAgent";
import * as signalR from "@microsoft/signalr";
import { authStore } from "./AuthStore";
import { warning } from "../Utils/Toast/Toast";
import { success } from "../Utils/Toast/Toast";
import i18n from "../../../Config/Localization/i18n";

class AlertStore {
  alertList = [];
  connection = null;
  alertHub = [];
  activeAlertList = [];

  constructor() {
    makeObservable(this, {
      alertList: observable,
      activeAlertList: observable,
      getAlerts: action,
      connection: observable,
      alertHub: observable,
      hasAlert: computed,
      getActiveAlerts: action,
      changeAllAlertStatus: action,
      changeActiveAlertsStatus: action,
    });
  }

  getAlerts = () => {
    return AuthSetAgent.alerts
      .getAlerts()
      .then((alerts) => {
        this.alertList = alerts ? alerts.result : [];
      })
      .catch((error) => {});
  };

  initHubConnection = () => {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl("https://localhost:7216/alerts", {
        accessTokenFactory: () => {
          const token = authStore.token;
          return token;
        },
      })
      .withAutomaticReconnect({
        onRetry: (retryCount, error) => {},
        retryDelays: [0, 1000, 5000, 10000],
        maxRetries: 5,
        reconnectInterval: 5000,
      })
      .build();

    this.connection.on("SendAlertAsync", (message) => {
      this.addAlert(message);
      warning(i18n.t("alertToast.Notification"));
    });

    this.connection
      .start()
      .then(() => {})
      .catch((err) => {});
  };

  addAlert = (message) => {
    this.alertHub.push(message);
  };

  get hasAlert() {
    return this.alertHub.length > 0;
  }

  clearAlerts = () => {
    this.alertHub = [];
  };

  getActiveAlerts = () => {
    return AuthSetAgent.alerts
      .getActiveAlerts()
      .then((activeAlerts) => {
        this.activeAlertList = activeAlerts ? activeAlerts.result : [];
      })
      .catch((error) => {});
  };

  changeAllAlertStatus = (alertId) => {
    const foundAlert = this.alertList.find((trashBin) =>
      trashBin?.timeLocationBinAlertList?.find((alert) => alert.id === alertId)
    );

    if (foundAlert) {
      const payload = { isActive: false };

      AuthSetAgent.alerts
        .deactivateAlert(alertId, payload)
        .then(() => {
          foundAlert.timeLocationBinAlertList.find(
            (alert) => alert.id === alertId
          ).isActive = false;
          success(i18n.t("alerts.AlertHasBeenDisabled"));
        })
        .catch((error) => {});
    }
  };

  changeActiveAlertsStatus = (alertId) => {
    const foundAlert = this.activeAlertList.find((trashBin) =>
      trashBin?.timeLocationBinAlertList?.find((alert) => alert.id === alertId)
    );

    if (foundAlert) {
      const payload = { isActive: false };

      AuthSetAgent.alerts
        .deactivateAlert(alertId, payload)
        .then(() => {
          foundAlert.timeLocationBinAlertList.find(
            (alert) => alert.id === alertId
          ).isActive = false;
          success(i18n.t("alerts.AlertHasBeenDisabled"));
        })
        .catch((error) => {});
    }
  };
}

export const alertStore = new AlertStore();
export const AlertContext = createContext(alertStore);
export const AlertProvider = ({ children, store }) => (
  <AlertContext.Provider value={store}>{children}</AlertContext.Provider>
);
export const useAlertStore = () => useContext(AlertContext);
