import * as React from "react";

const SectionAreaTitleSvg = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.684"
      height="18.968"
      viewBox="0 0 21.684 18.968"
    >
      <path
        id="Shape_1"
        data-name="Shape 1"
        d="M296.995,447.724l7.618-3.906a17.785,17.785,0,0,1-1.339-3.344,2.732,2.732,0,0,1,.607-2.324,2.945,2.945,0,0,1,4.679,0,2.722,2.722,0,0,1,.6,2.32,13.146,13.146,0,0,1-.884,2.38l10.4,7.67-10.31,5.44Zm11.45,7.181,4.87-2.57-3.519-1.72-2.409,3.524Zm-1.791-1.3,2.321-3.394-2.968-1.451-4.309,1.254Zm7.648-1.793,2.684-1.416-4.7-3.464-4.995,1.454Zm-15.588-3.959,2.059,1.492,4.029-1.173-3.436-1.679Zm7.375-.055,5.286-1.539-3.488-2.571c-.626,1.307-1.25,2.477-1.268,2.51h0l-.4.749-.4-.747c0-.006-.246-.459-.577-1.113-.073-.145-.151-.3-.231-.461l-2.639,1.353Zm.128-2.788c.293-.569.7-1.383,1.083-2.2a14.209,14.209,0,0,0,.971-2.52,1.845,1.845,0,0,0-.416-1.57,2.033,2.033,0,0,0-3.27,0,1.854,1.854,0,0,0-.421,1.573,17.35,17.35,0,0,0,1.257,3.111h0l.126.262.076.155c.21.434.412.835.578,1.16Zm-1.362-4.868a1.363,1.363,0,1,1,1.363,1.349A1.358,1.358,0,0,1,304.854,440.144Zm1.363.45a.45.45,0,1,0-.454-.45A.453.453,0,0,0,306.217,440.594Z"
        transform="translate(-296.995 -436.996)"
        fill={props.color ?? "#fff"}
      />
    </svg>
  );
};

export default SectionAreaTitleSvg;
